import {isEmpty} from "lodash";
import {MsgVersion} from "../../../api/core/base/msg/MsgVersion";
import {DefnForm} from "../../../api/meta/base/dto/DefnForm";
import {StudioEntDeployPaymentProviderMap} from "../../../api/meta/base/dto/StudioEntDeployPaymentProviderMap";
import {StudioEntDeployPluginMap} from "../../../api/meta/base/dto/StudioEntDeployPluginMap";
import {StudioEntPaymentProviderRazorPay} from "../../../api/meta/base/dto/StudioEntPaymentProviderRazorPay";
import {StudioEntPlugin} from "../../../api/meta/base/dto/StudioEntPlugin";
import {StudioEntPluginMap} from "../../../api/meta/base/dto/StudioEntPluginMap";
import {StudioVar} from "../../../api/meta/base/dto/StudioVar";
import {StudioVarMap} from "../../../api/meta/base/dto/StudioVarMap";
import {MetaIdPlugin} from "../../../api/meta/base/Types";
import {MetaIdForm} from "../../../api/meta/base/Types";
import {MetaIdVar} from "../../../api/meta/base/Types";
import {EntId} from "../../../api/meta/base/Types";
import {EnvError} from "../../../api/nucleus/base/dto/EnvError";
import {getErrorMessage} from "../../../api/nucleus/base/Protocol";
import {RpcStudioDrawer} from "../../../api/studio/studioDrawer/RpcStudioDrawer";
import {MsgPluginApiSpecGet} from "../../../api/studio/studioMain/msg/MsgPluginApiSpecGet";
import {RpcStudioMain} from "../../../api/studio/studioMain/RpcStudioMain";
import {SigEntDeployStatus} from "../../../api/studio/studioMain/sig/SigEntDeployStatus";
import {SigPluginApiSpec} from "../../../api/studio/studioMain/sig/SigPluginApiSpec";
import ISrvc from "../../../base/ISrvc";
import {getFormFieldValueAsText} from "../../../base/plus/FieldValuePlus";
import {dispatchList} from "../../../base/plus/ListPlus";
import {toLabel} from "../../../base/plus/StringPlus";
import {getVariablesUiItemsById} from "../../../base/plus/StudioPlus";
import {listRefresh} from "../../../base/slices/list/SliceListSharedActions";
import {IListItemsById} from "../../../base/types/list/TypesList";
import {TypeListItemId} from "../../../base/types/list/TypesList";
import {IListItemAPSA} from "../../../base/types/list/TypesListAPSA";
import {CbSuccess} from "../../../base/types/TypesGlobal";
import {ILineSecondaryAttributedText} from "../../../base/types/TypesGlobal";
import {setEntDeployStatus} from "../../../cache/studio/ent/SliceCacheStudioEnt";
import {dispatchEnt} from "../../../cache/studio/SrvcCacheStudio";
import {RootState} from "../../../Store";
import {Srvc} from "../../Srvc";

export default class SrvcStudioEntDeploy extends ISrvc
{
  selectDeployPluginList(state: RootState)
  {
    return state.studio.ent.entDeployPluginList;
  }

  selectDeployVariableList(state: RootState)
  {
    return state.studio.ent.entDeployVariableList;
  }

  selectDeployPaymentProviderList(state: RootState)
  {
    return state.studio.ent.entDeployPaymentProvider;
  }

  loadDeployPluginList(
    listName: string,
    importMap?: StudioEntPluginMap,
    pluginMap?: StudioEntDeployPluginMap)
  {
    const uiItemIds: TypeListItemId[] = [];
    const uiItemsById = {} as IListItemsById;

    if(!importMap || !pluginMap)
    {
      return;
    }

    pluginMap.keys.forEach((metaIdPlugin) =>
    {
      const studioPlugin = importMap.map[metaIdPlugin] as StudioEntPlugin;
      const pluginFormMap = studioPlugin?.pluginFormMap;

      if(pluginMap && pluginFormMap)
      {
        const pluginConfigFormValueMap = pluginMap.map[metaIdPlugin]?.pluginConfigFormValueMap;
        const pluginType = pluginMap.map[metaIdPlugin]?.pluginType;
        let secondaryText = "";

        if(pluginConfigFormValueMap && Object.keys(pluginConfigFormValueMap).length > 0)
        {
          Object.keys(pluginConfigFormValueMap).forEach((key) =>
          {
            let pluginConfigFormId: string | undefined;
            switch(pluginType)
            {
              case "jar":
                pluginConfigFormId = studioPlugin.pluginResourceMap?.jar?.pluginConfigFormId;
                break;
              case "rpc":
                pluginConfigFormId = studioPlugin.pluginResourceMap?.rpc?.pluginConfigFormId;
                break;
              case "dev":
                pluginConfigFormId = studioPlugin.pluginResourceMap?.dev?.pluginConfigFormId;
                break;
            }

            const {
              name,
              value
            } = this.getDefnNameAndValue(pluginConfigFormValueMap, key, pluginFormMap, pluginConfigFormId);
            const resultantName = !isEmpty(name) ? name : undefined;
            const resultantValue = !isEmpty(value) ? value : undefined;

            if(resultantName && resultantValue)
            {
              secondaryText = secondaryText.length > 0 ? `${secondaryText}, ${resultantName}` : resultantName;
            }
          });
        }

        if(studioPlugin)
        {
          uiItemIds.push(metaIdPlugin);
          uiItemsById[metaIdPlugin] = {
            type: "ps",
            primary: {
              text: studioPlugin.pluginName,
              caption: {
                text: pluginType
                  ? pluginType === "rpc"
                    ? "RPC"
                    : toLabel(pluginType)
                  : undefined,
                ignoreSelection: true
              }
            },
            secondary: {
              text: secondaryText.length > 0
                ? secondaryText
                : studioPlugin.description
            } as ILineSecondaryAttributedText,
            hideMenu: true
          } as IListItemAPSA;
        }
      }
    });

    dispatchList(listName, listRefresh({
      itemsById: uiItemsById,
      itemIds: uiItemIds
    }));
  }

  loadDeployVariableList(listName: string, varMap: StudioVarMap)
  {
    let filteredVariableList: StudioVarMap;

    const keys = [] as MetaIdVar[];
    const map = {} as Record<MetaIdVar, StudioVar>;

    if(!varMap)
    {
      return;
    }

    varMap.keys.forEach((metaIdVar) =>
    {
      const studioVar = varMap.map[metaIdVar];
      if(studioVar.deploy === "requiredOnDeploy")
      {
        keys.push(metaIdVar);
        map[metaIdVar] = studioVar;
      }
    });

    filteredVariableList = {
      keys: keys,
      map: map
    };

    const {
      uiItemIds,
      uiItemsById
    } = getVariablesUiItemsById(filteredVariableList, true);

    dispatchList(listName, listRefresh({
      itemsById: uiItemsById,
      itemIds: uiItemIds
    }));
  }

  loadDeployPaymentProviderList(listName: string, paymentProviderMap?: StudioEntDeployPaymentProviderMap)
  {
    const uiItemIds: TypeListItemId[] = [];
    const uiItemsById = {} as IListItemsById;

    paymentProviderMap?.keys.forEach((metaIdPaymentProviderId) =>
    {
      const paymentProvider = paymentProviderMap.map[metaIdPaymentProviderId] as StudioEntPaymentProviderRazorPay;
      const allowedPaymentMethodText = paymentProvider.allowedPaymentMethodSet?.length
        ? paymentProvider.allowedPaymentMethodSet.map(paymentMethod =>
        {
          if(paymentMethod === "upi")
          {
            return "UPI";
          }
          return toLabel(paymentMethod);
        }).join(", ")
        : undefined;
      const currencyText = paymentProvider.defaultCurrency && !isEmpty(paymentProvider.defaultCurrency)
        ? toLabel(paymentProvider.defaultCurrency)
        : "INR";

      if(paymentProvider)
      {
        uiItemIds.push(metaIdPaymentProviderId);
        uiItemsById[metaIdPaymentProviderId] = {
          type: "ps",
          primary: {
            text: paymentProvider.name,
            caption: {
              text: toLabel(paymentProvider.kind),
              ignoreSelection: true
            }
          },
          secondary: {
            text: allowedPaymentMethodText ? `Payment methods: ${allowedPaymentMethodText}` :
              "All payment methods are allowed",
            caption: {
              text: `Currency: ${currencyText}`
            }
          }
        } as IListItemAPSA;
      }
    });

    dispatchList(listName, listRefresh({
      itemsById: uiItemsById,
      itemIds: uiItemIds
    }));
  }

  rpcEntDeploy(
    entId: EntId,
    sendInvites: boolean,
    cbSuccess: CbSuccess,
    cbFailure?: (error: EnvError) => void)
  {
    RpcStudioDrawer.studioEntDeploy(entId, {"sendInvites": sendInvites}, envSig =>
    {
      if(envSig.error)
      {
        if(cbFailure)
        {
          cbFailure(envSig.error);
        }
        else
        {
          const errorMsg = getErrorMessage(envSig.error);
          errorMsg && Srvc.app.toast.showErrorToast(errorMsg);
        }
        return;
      }

      if(envSig.sig)
      {
        const payload = {
          entId: entId,
          executionState: "initiated",
          jobKey: envSig.sig.jobKey,
          lastUpdate: "",
          message: ""
        } as SigEntDeployStatus;

        dispatchEnt(entId, setEntDeployStatus({
          entId: entId,
          deployStatus: payload
        }));
      }

      cbSuccess();
    });
  }

  rpcEntRevertToDeploy(entId: EntId, cbSuccess: CbSuccess)
  {
    RpcStudioMain.studioEntRevertToDeploy(entId, envSig =>
    {
      if(envSig.error)
      {
        const errorMsg = getErrorMessage(envSig.error);
        errorMsg && Srvc.app.toast.showErrorToast(errorMsg);
        return;
      }

      cbSuccess();
    });
  }

  rpcEntDeployStatusGet(entId: EntId, version?: string)
  {
    const msg: MsgVersion = {
      version: version
    };

    RpcStudioMain.studioEntDeployStatusGet(entId, msg, statusSig =>
    {
      if(statusSig.sig)
      {
        Srvc.cache.studio.ent.setEntDeployStatus({
          entId: entId,
          deployStatus: statusSig.sig
        });
      }
    });
  }

  rpcEntDeployPluginApiSpecGet(
    entId: EntId,
    pluginId: MetaIdPlugin,
    cbSuccess: (pluginApiSpec: SigPluginApiSpec) => void,
    cbError?: (error: EnvError) => void)
  {
    const msg = {
      metaIdPlugin: pluginId
    } as MsgPluginApiSpecGet;

    RpcStudioMain.pluginApiSpecGet(entId, msg, (envSig) =>
    {
      if(envSig.error)
      {
        const errorMsg = getErrorMessage(envSig.error);
        errorMsg && Srvc.app.toast.showErrorToast(errorMsg);
        cbError && cbError(envSig.error);
      }
      else
      {
        envSig.sig && cbSuccess(envSig.sig);
      }
    });
  }

  private getDefnNameAndValue(
    pluginConfigFormValueMap: Record<string, any>,
    key: string,
    pluginFormMap: Record<MetaIdForm, DefnForm>,
    pluginConfigFormId?: MetaIdForm
  ): {name: string, value: string}
  {
    let name: string | undefined;
    let value: string | undefined;

    const defnForm = pluginConfigFormId
      ? pluginFormMap[pluginConfigFormId]
      : undefined;

    const compMapElement = defnForm ? defnForm.compMap[key] : undefined;
    name = (defnForm && compMapElement)
      ? compMapElement.name
      : "";

    const fieldType = (defnForm && compMapElement)
      ? compMapElement.type
      : undefined;

    const jarValue = (fieldType
      && (fieldType !== "tab" && fieldType !== "section" && fieldType !== "grid"))
      ? getFormFieldValueAsText(compMapElement, pluginConfigFormValueMap[key])
      : undefined;

    value = jarValue ?? "";

    return {
      name,
      value
    };
  }
}
