// neome.ai API - do not change
//
/* eslint-disable @typescript-eslint/no-unused-vars */
// noinspection UnusedGlobalSymbols,ES6UnusedImports,JSUnusedGlobalSymbols,JSUnusedLocalSymbols

import {EntId} from '../../meta/base/Types';
import {ISigAcceptor} from '../../nucleus/base/ISigAcceptor';
import {MsgButtonFieldReportDataGet} from './msg/MsgButtonFieldReportDataGet';
import {MsgEntLogNumberFieldDataGet} from './msg/MsgEntLogNumberFieldDataGet';
import {MsgEntUserPickerCandidateListGet} from './msg/MsgEntUserPickerCandidateListGet';
import {MsgPaymentStatus} from './msg/MsgPaymentStatus';
import {MsgPaymentVerify} from './msg/MsgPaymentVerify';
import {MsgPluginApiOutput} from './msg/MsgPluginApiOutput';
import {MsgRefFieldDataGet} from './msg/MsgRefFieldDataGet';
import {MsgRefFieldDataPaginatedGet} from './msg/MsgRefFieldDataPaginatedGet';
import {MsgReportFieldDataGet} from './msg/MsgReportFieldDataGet';
import {MsgSpreadsheetRowsGet} from './msg/MsgSpreadsheetRowsGet';
import {ServiceName} from '../../meta/base/Types';
import {SigDone} from '../../nucleus/base/sig/SigDone';
import {SigEntLogNumberFieldDataGet} from './sig/SigEntLogNumberFieldDataGet';
import {SigEntUserPickerCandidateListGet} from './sig/SigEntUserPickerCandidateListGet';
import {SigFormValue} from '../entMain/sig/SigFormValue';
import {SigPaymentStatus} from './sig/SigPaymentStatus';
import {SigPluginApiOutput} from './sig/SigPluginApiOutput';
import {SigRefFieldData} from './sig/SigRefFieldData';
import {SigRefFieldPaginatedData} from './sig/SigRefFieldPaginatedData';
import {SigReportFieldData} from './sig/SigReportFieldData';
import {SigSpreadsheetRowsGet} from './sig/SigSpreadsheetRowsGet';
import {rpcCall} from '../../nucleus/base/IRpcCall';

export class RpcEntAside
{
  static readonly SN: ServiceName = "entAside";

  static entButtonFieldReportDataGet(entId: EntId, msg: MsgButtonFieldReportDataGet, sigAcceptor: ISigAcceptor<SigFormValue>): void
  {
    rpcCall<SigFormValue>(entId, RpcEntAside.SN, "entButtonFieldReportDataGet")
      .sendBearerToken()
      .post(msg, sigAcceptor);
  }

  static entLogNumberFieldDataGet(entId: EntId, msg: MsgEntLogNumberFieldDataGet, sigAcceptor: ISigAcceptor<SigEntLogNumberFieldDataGet>): void
  {
    rpcCall<SigEntLogNumberFieldDataGet>(entId, RpcEntAside.SN, "entLogNumberFieldDataGet")
      .sendBearerToken()
      .get(msg, sigAcceptor);
  }

  static entPaymentStatusGet(entId: EntId, msg: MsgPaymentStatus, sigAcceptor: ISigAcceptor<SigPaymentStatus>): void
  {
    rpcCall<SigPaymentStatus>(entId, RpcEntAside.SN, "entPaymentStatusGet")
      .sendBearerToken()
      .get(msg, sigAcceptor);
  }

  static entPaymentVerify(entId: EntId, msg: MsgPaymentVerify, sigAcceptor: ISigAcceptor<SigDone>): void
  {
    rpcCall<SigDone>(entId, RpcEntAside.SN, "entPaymentVerify")
      .sendBearerToken()
      .post(msg, sigAcceptor);
  }

  static entRefFieldDataGet(entId: EntId, msg: MsgRefFieldDataGet, sigAcceptor: ISigAcceptor<SigRefFieldData>): void
  {
    rpcCall<SigRefFieldData>(entId, RpcEntAside.SN, "entRefFieldDataGet")
      .sendBearerToken()
      .post(msg, sigAcceptor);
  }

  static entRefFieldPaginatedDataGet(entId: EntId, msg: MsgRefFieldDataPaginatedGet, sigAcceptor: ISigAcceptor<SigRefFieldPaginatedData>): void
  {
    rpcCall<SigRefFieldPaginatedData>(entId, RpcEntAside.SN, "entRefFieldPaginatedDataGet")
      .sendBearerToken()
      .post(msg, sigAcceptor);
  }

  static entReportFieldDataGet(entId: EntId, msg: MsgReportFieldDataGet, sigAcceptor: ISigAcceptor<SigReportFieldData>): void
  {
    rpcCall<SigReportFieldData>(entId, RpcEntAside.SN, "entReportFieldDataGet")
      .sendBearerToken()
      .post(msg, sigAcceptor);
  }

  static entSpreadsheetRowsGet(entId: EntId, msg: MsgSpreadsheetRowsGet, sigAcceptor: ISigAcceptor<SigSpreadsheetRowsGet>): void
  {
    rpcCall<SigSpreadsheetRowsGet>(entId, RpcEntAside.SN, "entSpreadsheetRowsGet")
      .sendBearerToken()
      .post(msg, sigAcceptor);
  }

  static entUserPickerCandidateListGet(entId: EntId, msg: MsgEntUserPickerCandidateListGet, sigAcceptor: ISigAcceptor<SigEntUserPickerCandidateListGet>): void
  {
    rpcCall<SigEntUserPickerCandidateListGet>(entId, RpcEntAside.SN, "entUserPickerCandidateListGet")
      .sendBearerToken()
      .post(msg, sigAcceptor);
  }

  static pluginApiOutputGet(entId: EntId, msg: MsgPluginApiOutput, sigAcceptor: ISigAcceptor<SigPluginApiOutput>): void
  {
    rpcCall<SigPluginApiOutput>(entId, RpcEntAside.SN, "pluginApiOutputGet")
      .sendBearerToken()
      .post(msg, sigAcceptor);
  }
}