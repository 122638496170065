import {Typography} from "@mui/material";
import {useTheme} from "@mui/material";
import {Variant} from "@mui/material/styles/createTypography";
import {TypographyPropsVariantOverrides} from "@mui/material/Typography/Typography";
import {StandardCSSProperties} from "@mui/system/styleFunctionSx/StandardCssProperties";
import {OverridableStringUnion} from "@mui/types";
import {CSSProperties} from "react";
import React from "react";
import Highlighter from "react-highlight-words";
import {EnumDefnPlacement} from "../../../api/meta/base/Types";
import {removeRegex} from "../../../base/plus/StringPlus";
import {px} from "../../../base/plus/StringPlus";
import {TypeTooltip} from "../../../base/types/TypesGlobal";
import {TypeTextColor} from "../../../base/types/TypesGlobal";
import RawTooltip from "./RawTooltip";

export default function RawHighlighter(props: {
  value?: string,
  optional?: string,
  color?: StandardCSSProperties["color"],
  variant: OverridableStringUnion<Variant | "inherit", TypographyPropsVariantOverrides>,
  searchWords?: string[],
  flexGrow?: StandardCSSProperties["flexGrow"],
  width?: StandardCSSProperties["width"],
  maxWidth?: StandardCSSProperties["maxWidth"],
  height?: StandardCSSProperties["height"],
  noWrap?: boolean,
  ml?: number,
  mr?: number,
  mt?: number,
  mb?: number,
  bold?: boolean,
  italic?: boolean,
  textAlign?: EnumDefnPlacement,
  breakWords?: boolean,
  fakeSpace?: React.ReactNode,
  textDecoration?: StandardCSSProperties["textDecoration"],
  cursor?: CSSProperties["cursor"]
  maxNumberOfLine?: number,
  isUserSelect?: boolean,
  tooltip?: TypeTooltip,
  lineHeight?: number,
})
{
  const theme = useTheme();
  const value = props.value;

  return (
    <RawTooltip title={props.tooltip ?? ""}>
      <Typography
        variant={props.variant}
        component={"span"}
        noWrap={props.noWrap ?? true}
        sx={{
          color: theme.common.color(props.color as TypeTextColor ?? "textPrimary") || props.color,
          cursor: props.cursor,
          width: props.width,
          flexGrow: props.flexGrow,
          ...props.bold && {fontWeight: 500},
          ...props.noWrap && {overflow: "hidden"},
          ...props.italic && {
            fontStyle: "italic",
            overflow: "visible"
          },
          ml: px(props.ml),
          mr: px(props.mr),
          mt: px(props.mt),
          mb: px(props.mb),
          ...props.breakWords && {wordBreak: "break-word"},
          ...props.lineHeight && {lineHeight: props.lineHeight},
          maxWidth: props.maxWidth,
          textAlign: props.textAlign as CSSProperties["textAlign"],
          textDecoration: props.textDecoration,
          overflowWrap: props.breakWords ? "anywhere" : "normal",
          whiteSpace: props.breakWords ? "pre-wrap" : "nowrap",
          userSelect: "text",
          ...props.maxNumberOfLine && {
            display: "-webkit-box",
            overflow: "hidden",
            WebkitBoxOrient: "vertical"
          },
          WebkitLineClamp: props.maxNumberOfLine,
          ...props.isUserSelect && {userSelect: "text"}
        }}
      >
        {
          value === undefined
            ? props.optional
            : (props.searchWords === undefined
              ? value
              : (<Highlighter
                searchWords={props.searchWords.map(str => removeRegex(str))}
                textToHighlight={value}
                highlightStyle={{background: theme.common.bgcolorHighlight}}
              />))
        }
        {props.fakeSpace}
      </Typography>
    </RawTooltip>
  );
}
