import {isEmpty} from "lodash";
import {isPipelineVarId} from "../../../api/meta/base/ApiPlus";
import {StudioEntAutomation} from "../../../api/meta/base/dto/StudioEntAutomation";
import {StudioEntAutomationEvent} from "../../../api/meta/base/dto/StudioEntAutomationEvent";
import {StudioEntAutomationMap} from "../../../api/meta/base/dto/StudioEntAutomationMap";
import {StudioEntAutomationPluginWebhook} from "../../../api/meta/base/dto/StudioEntAutomationPluginWebhook";
import {StudioEntAutomationScheduled} from "../../../api/meta/base/dto/StudioEntAutomationScheduled";
import {StudioEntAutomationSpreadsheet} from "../../../api/meta/base/dto/StudioEntAutomationSpreadsheet";
import {
  StudioEntAutomationSpreadsheetStepForwardToGroups
} from "../../../api/meta/base/dto/StudioEntAutomationSpreadsheetStepForwardToGroups";
import {
  StudioEntAutomationSpreadsheetStepForwardToUsers
} from "../../../api/meta/base/dto/StudioEntAutomationSpreadsheetStepForwardToUsers";
import {StudioEntAutomationStep} from "../../../api/meta/base/dto/StudioEntAutomationStep";
import {
  StudioEntAutomationStepCalculateFormulas
} from "../../../api/meta/base/dto/StudioEntAutomationStepCalculateFormulas";
import {StudioEntAutomationStepCallPlugin} from "../../../api/meta/base/dto/StudioEntAutomationStepCallPlugin";
import {StudioEntAutomationStepCallReport} from "../../../api/meta/base/dto/StudioEntAutomationStepCallReport";
import {
  StudioEntAutomationStepGenerateDeeplink
} from "../../../api/meta/base/dto/StudioEntAutomationStepGenerateDeeplink";
import {
  StudioEntAutomationStepInsertIntoSpreadsheet
} from "../../../api/meta/base/dto/StudioEntAutomationStepInsertIntoSpreadsheet";
import {StudioEntAutomationStepMessageSend} from "../../../api/meta/base/dto/StudioEntAutomationStepMessageSend";
import {
  StudioEntAutomationStepMessageSendOnEmail
} from "../../../api/meta/base/dto/StudioEntAutomationStepMessageSendOnEmail";
import {
  StudioEntAutomationStepMessageSendOnWhatsapp
} from "../../../api/meta/base/dto/StudioEntAutomationStepMessageSendOnWhatsapp";
import {StudioEntAutomationStepPartitionSend} from "../../../api/meta/base/dto/StudioEntAutomationStepPartitionSend";
import {StudioEntAutomationStepRemoveField} from "../../../api/meta/base/dto/StudioEntAutomationStepRemoveField";
import {
  StudioEntAutomationStepRemoveFromSpreadsheet
} from "../../../api/meta/base/dto/StudioEntAutomationStepRemoveFromSpreadsheet";
import {StudioEntAutomationStepReturn} from "../../../api/meta/base/dto/StudioEntAutomationStepReturn";
import {StudioEntAutomationStepTerminate} from "../../../api/meta/base/dto/StudioEntAutomationStepTerminate";
import {StudioEntAutomationStepUpdateField} from "../../../api/meta/base/dto/StudioEntAutomationStepUpdateField";
import {
  StudioEntAutomationStepUpdateLogNumber
} from "../../../api/meta/base/dto/StudioEntAutomationStepUpdateLogNumber";
import {
  StudioEntAutomationStepUpdateSpreadsheet
} from "../../../api/meta/base/dto/StudioEntAutomationStepUpdateSpreadsheet";
import {StudioEntAutomationWebhook} from "../../../api/meta/base/dto/StudioEntAutomationWebhook";
import {StudioEntPipelineVarMap} from "../../../api/meta/base/dto/StudioEntPipelineVarMap";
import {StudioVarCondition} from "../../../api/meta/base/dto/StudioVarCondition";
import {EnumArrayDefnRoles} from "../../../api/meta/base/Types";
import {MetaIdForm} from "../../../api/meta/base/Types";
import {MetaId} from "../../../api/meta/base/Types";
import {EntId, MetaIdAutomation, MetaIdEvent, MetaIdStep} from "../../../api/meta/base/Types";
import ISrvc from "../../../base/ISrvc";
import {fnResolveArgBinderValue} from "../../../base/plus/ArgBinderPlus";
import {getResolvedConditionVar} from "../../../base/plus/ArgBinderPlus";
import {dispatchList} from "../../../base/plus/ListPlus";
import {toLabel} from "../../../base/plus/StringPlus";
import {formNeomeComment} from "../../../base/plus/StudioPlus";
import {formPaymentReceipt} from "../../../base/plus/StudioPlus";
import {formSchedulerTrigger} from "../../../base/plus/StudioPlus";
import {fnUseStudioResolver} from "../../../base/plus/StudioPlus";
import {IResolveFuncs} from "../../../base/plus/StudioPlus";
import theme from "../../../base/plus/ThemePlus";
import {listRefresh} from "../../../base/slices/list/SliceListSharedActions";
import {TypeListItemId} from "../../../base/types/list/TypesList";
import {IListItemsById} from "../../../base/types/list/TypesList";
import {IListItemAPSA} from "../../../base/types/list/TypesListAPSA";
import {IListGroup, IListGroupsById, IListItemGroup} from "../../../base/types/list/TypesListGroup";
import {FormStore} from "../../../base/types/TypesForm";
import {EnumListItemDirection} from "../../../base/types/TypesStudio";
import {TypeAutomationEventsType} from "../../../routes/studio/ent/automations/plus/StudioAutomationPlus";
import {TypeAutomationsType} from "../../../routes/studio/ent/automations/plus/StudioAutomationPlus";
import {RootState} from "../../../Store";
import {Srvc} from "../../Srvc";

type TypeSpreadSheetStepSpreadSheet =
  | StudioEntAutomationStepInsertIntoSpreadsheet
  | StudioEntAutomationStepUpdateSpreadsheet
  | StudioEntAutomationStepRemoveFromSpreadsheet;

export type AutomationListItemType = "event" | "step";

export interface IAutomationListItemUserField
{
  type: AutomationListItemType;
  event?: StudioEntAutomationEvent;
  step?: StudioEntAutomationStep;
  showMoveUp: boolean,
  showMoveDown: boolean
}

export default class SrvcStudioEntAutomations extends ISrvc
{
  selectList(state: RootState)
  {
    return state.studio.ent.entAutomationList;
  }

  selectListEvents(state: RootState)
  {
    return state.studio.ent.entAutomationEventList;
  }

  addAutomation(entId: EntId, automation: StudioEntAutomation)
  {
    Srvc.cache.studio.ent.automations.addEntAutomation(entId, automation);
  }

  removeAutomation(entId: EntId, metaIdAutomation: MetaIdAutomation)
  {
    Srvc.cache.studio.ent.automations.removeEntAutomation(entId, metaIdAutomation);
  }

  duplicateAutomation(entId: EntId, metaIdAutomation: MetaIdAutomation)
  {
    Srvc.cache.studio.ent.automations.duplicateEntAutomation(entId, metaIdAutomation);
  }

  addAutomationEvent(entId: string, metaIdAutomation: MetaIdAutomation, automationEvent: StudioEntAutomationEvent)
  {
    Srvc.cache.studio.ent.automations.addAutomationEvent(entId, metaIdAutomation, automationEvent);
  }

  removeAutomationEvent(entId: string, metaIdAutomation: MetaIdAutomation, metaIdEvent: MetaIdEvent)
  {
    Srvc.cache.studio.ent.automations.removeAutomationEvent(entId, metaIdAutomation, metaIdEvent);
  }

  duplicateAutomationEvent(entId: EntId, metaIdAutomation: MetaIdAutomation, metaIdEvent: MetaIdEvent)
  {
    Srvc.cache.studio.ent.automations.duplicateEntAutomationEvent(entId, metaIdAutomation, metaIdEvent);
  }

  addAutomationStep(
    entId: string,
    metaIdAutomation: MetaIdAutomation,
    metaIdEvent: MetaIdEvent,
    automationStep: StudioEntAutomationStep
  )
  {
    Srvc.cache.studio.ent.automations.addAutomationEventStep(
      entId,
      metaIdAutomation,
      metaIdEvent,
      automationStep
    );
  }

  duplicateAutomationStep(
    entId: EntId,
    metaIdAutomation: MetaIdAutomation,
    metaIdEvent: MetaIdEvent,
    metaIdStep: MetaIdStep
  )
  {
    Srvc.cache.studio.ent.automations.duplicateEntAutomationStep(
      entId,
      metaIdAutomation,
      metaIdEvent,
      metaIdStep
    );
  }

  removeAutomationStep(
    entId: EntId,
    metaIdAutomation: MetaIdAutomation,
    metaIdEvent: MetaIdEvent,
    metaIdStep: MetaIdStep
  )
  {
    Srvc.cache.studio.ent.automations.removeAutomationEventStep(
      entId,
      metaIdAutomation,
      metaIdEvent,
      metaIdStep
    );
  }

  moveAutomation(
    entId: EntId,
    metaIdAutomation: MetaIdAutomation,
    moveDirection: EnumListItemDirection
  )
  {
    Srvc.cache.studio.ent.automations.moveAutomation(entId, metaIdAutomation, moveDirection);
  }

  moveAutomationEvent(
    entId: EntId,
    metaIdAutomation: MetaIdAutomation,
    moveDirection: EnumListItemDirection,
    metaIdEvent: MetaIdEvent
  )
  {
    Srvc.cache.studio.ent.automations.moveAutomationEvent(
      entId,
      metaIdAutomation
      , moveDirection,
      metaIdEvent
    );
  }

  moveAutomationStep(
    entId: EntId,
    metaIdAutomation: MetaIdAutomation,
    moveDirection: EnumListItemDirection,
    metaIdEvent: MetaIdEvent,
    metaIdStep: MetaIdStep
  )
  {
    Srvc.cache.studio.ent.automations.moveAutomationStep(
      entId,
      metaIdAutomation,
      moveDirection,
      metaIdEvent,
      metaIdStep
    );
  }

  dragAutomationStep(
    entId: EntId,
    metaIdAutomation: MetaIdAutomation,
    fromEventId: MetaIdEvent,
    fromStepIndex: number,
    toEventId: MetaIdEvent,
    toStepIndex: number
  )
  {
    Srvc.cache.studio.ent.automations.dragAutomationStep(
      entId,
      metaIdAutomation,
      fromEventId,
      fromStepIndex,
      toEventId,
      toStepIndex
    );
  }

  loadAutomationList(
    entId: EntId,
    listName: string,
    automationMap: StudioEntAutomationMap,
    formStore: FormStore,
    readOnly: boolean
  )
  {
    const uiItemIds = [] as TypeListItemId[];
    const uiItemsById = {} as IListItemsById;

    const keys = automationMap.keys;
    const map = automationMap.map;

    keys?.forEach((automationId) =>
    {
      const automation = map[automationId] as StudioEntAutomation;
      const kind = automation.kind;

      if(!Srvc.studio.ent.filterListByTag(entId, automation.modules))
      {
        return;
      }

      uiItemIds.push(automationId);

      uiItemsById[automationId] = {
        type: "ps",
        hideMenu: readOnly,
        primary: {
          text: automation.name,
          caption: {
            iconButtons: !readOnly
              ? [
                {
                  icon: "EditRounded",
                  color: "primary",
                  tooltip: "Edit automation",
                  type: "apsaPrimaryCaptionButtonEdit"
                },
                {
                  icon: "AddRounded",
                  color: "primary",
                  tooltip: "Add automation event",
                  type: "apsaPrimaryCaptionButtonAdd"
                }
              ]
              : undefined
          }
        },
        secondary: {
          text: this.getSelectedAutomationInfoText(formStore, automation),
          caption: {
            type: "text",
            text: toLabel(kind),
            variant: "caption",
            ignoreSelection: true
          }
        }
      } as IListItemAPSA;
    });

    dispatchList(listName, listRefresh({
      itemIds: uiItemIds,
      itemsById: uiItemsById
    }));
  }

  loadAutomationEvents(
    listName: string,
    automation: TypeAutomationsType,
    fnResolver: IResolveFuncs,
    readOnly?: boolean
  )
  {
    const uiGroupsById = {} as IListGroupsById;
    const uiItemsById = {} as IListItemsById;

    const eventKeys = automation.eventMap?.keys;
    const eventMap = automation.eventMap?.map;

    const currentFormId = this.getCurrentFormId(fnResolver, automation);

    eventKeys?.forEach((eventId, index) =>
    {
      const event = eventMap[eventId] as TypeAutomationEventsType;

      const {
        showMoveUp,
        showMoveDown
      } = this.getCanMoveUpAndDownItem(eventKeys, index);

      const secondary = this.getEventSecondaryResolve(event, fnResolver);

      uiItemsById[eventId] = {
        type: "listGroup",
        disableDrag: true,
        header: {
          text: event.name,
          variant: "subtitle2",
          middle: {
            type: "text",
            text: toLabel(event.fire),
            variant: "caption",
            ignoreSelection: true
          },
          expand: true,
          secondary: {
            text: isEmpty(secondary) ? "Always execute" : secondary,
            color: "textSecondary"
          },
          caption: {
            iconButtons: !readOnly
              ? [
                {
                  icon: "AddRounded",
                  color: "primary",
                  tooltip: "Add step",
                  type: "apsaPrimaryCaptionButtonAdd"
                }
              ]
              : undefined
          }
        },
        hideMenu: readOnly,
        userField: {
          value: {
            type: "event",
            event: event,
            showMoveUp,
            showMoveDown
          } as IAutomationListItemUserField
        }
      } as IListItemGroup;

      this.loadAutomationSteps(
        event,
        uiGroupsById,
        uiItemsById,
        fnResolver,
        currentFormId,
        readOnly
      );
    });

    dispatchList(listName, listRefresh({
      itemsById: uiItemsById,
      groupsById: uiGroupsById
    }));
  }

  loadAutomationSteps(
    automationEvent: TypeAutomationEventsType,
    uiGroupsById: IListGroupsById,
    uiItemsById: IListItemsById,
    fnResolver: IResolveFuncs,
    currentFormId?: MetaIdForm,
    readOnly?: boolean
  )
  {
    const automationEventId = automationEvent.metaId;
    uiGroupsById[automationEventId] = {
      itemIds: []
    };

    const stepKeys = automationEvent.stepMap?.keys;
    const stepMap = automationEvent.stepMap?.map;
    const pipelineVarMap = automationEvent.pipelineVarMap;

    stepKeys?.forEach((stepId, index) =>
    {
      const step = stepMap[stepId] as StudioEntAutomationStep;

      const {
        showMoveUp,
        showMoveDown
      } = this.getCanMoveUpAndDownItem(stepKeys, index);

      (uiGroupsById[automationEventId] as IListGroup).itemIds.push(stepId);

      const secondary = this.getSpreadSheetStepSecondaryResolve(
        step,
        fnResolver,
        pipelineVarMap,
        currentFormId
      );

      uiItemsById[stepId] = {
        type: "ps",
        primary: {
          text: `${index + 1}.\t${step.name}`,
          caption: {
            text: step.kind && toLabel(step.kind),
            type: "text",
            ignoreSelection: true
          },
          pl: theme.common.listGroupItemGap
        },
        secondary: {
          text: secondary,
          color: "textSecondary",
          pl: theme.common.listGroupItemGap + (index > 8 ? 28 : 18)
        },
        hideMenu: readOnly,
        userField: {
          value: {
            type: "step",
            event: automationEvent,
            step: step,
            showMoveUp,
            showMoveDown
          } as IAutomationListItemUserField
        }
      } as IListItemAPSA;
    });
  }

  getSelectedAutomationInfoText(formStore: FormStore, automation: StudioEntAutomation)
  {
    const {
      getSpreadSheetName,
      getSpreadSheetFormName,
      getPluginName,
      getPluginApiName
    } = fnUseStudioResolver(formStore);

    const automationType = automation?.kind;
    switch(automationType)
    {
      case "spreadsheet":
      {
        const spreadSheetId = (automation as StudioEntAutomationSpreadsheet)?.spreadsheetId;
        const spreadSheetName = spreadSheetId ? getSpreadSheetName(spreadSheetId) : undefined;
        const spreadSheetFormName = spreadSheetId ? getSpreadSheetFormName(spreadSheetId) : undefined;
        return `${spreadSheetName}: ${spreadSheetFormName}`;
      }
      case "scheduled":
      {
        return (automation as StudioEntAutomationScheduled)?.name;
      }
      case "webhook":
      {
        const _automation = automation as StudioEntAutomationWebhook;
        if(_automation?.callbackKind === "razorpayPaymentReceipt")
        {
          return toLabel(_automation.callbackKind);
        }
        else
        {
          const spreadSheetName = _automation?.spreadsheetId
            ? getSpreadSheetName(_automation.spreadsheetId)
            : undefined;
          return toLabel(_automation?.callbackKind) + ": " + spreadSheetName;
        }
      }
      case "pluginWebhook":
      {
        const metaIdPlugin = (automation as StudioEntAutomationPluginWebhook)?.pluginApi?.metaIdPlugin;
        const pluginApiId = (automation as StudioEntAutomationPluginWebhook)?.pluginApi?.pluginApiId;
        const pluginName = metaIdPlugin ? getPluginName(metaIdPlugin) : undefined;
        const pluginApiName = metaIdPlugin && pluginApiId
          ? getPluginApiName(metaIdPlugin, pluginApiId)
          : undefined;
        return pluginName && pluginApiName ? `${pluginName}: ${pluginApiName}` : undefined;
      }
      default:
        return "";
    }

  }

  private getCanMoveUpAndDownItem(keys?: MetaId[], index?: number)
  {
    if(keys)
    {
      return {
        showMoveUp: keys.length !== 1 && index !== 0,
        showMoveDown: keys.length !== 1 && index !== keys.length - 1
      };
    }
    else
    {
      return {
        showMoveUp: false,
        showMoveDown: false
      };
    }
  }

  private getCurrentFormId(fnResolver: IResolveFuncs, automation: TypeAutomationsType)
  {
    if(automation.kind === "spreadsheet")
    {
      const spreadSheetId = (automation as StudioEntAutomationSpreadsheet).spreadsheetId;
      const spreadSheetForm = fnResolver.getSpreadSheetForm(spreadSheetId);
      return spreadSheetForm?.metaId;
    }
    else if(automation.kind === "scheduled")
    {
      return formSchedulerTrigger;
    }
    else if(automation.kind === "pluginWebhook")
    {
      const automationPluginApi = (automation as StudioEntAutomationPluginWebhook).pluginApi;
      const metaIdPlugin = automationPluginApi?.metaIdPlugin;
      const pluginApiId = automationPluginApi?.pluginApiId;
      const plugin = metaIdPlugin
        ? fnResolver.getPlugin(metaIdPlugin)
        : undefined;
      const pluginApi = plugin && pluginApiId
        ? plugin.pluginApiIdMap[pluginApiId]
        : undefined;

      return pluginApi?.apiType === "webhook"
        ? pluginApi?.outputFormId
        : undefined;
    }
    else if(automation.kind === "webhook")
    {
      const callbackKind = (automation as StudioEntAutomationWebhook).callbackKind;

      switch(callbackKind)
      {
        case "razorpayPaymentReceipt":
          return formPaymentReceipt;
        case "neomeComment":
          return formNeomeComment;
      }
    }
  }

  private getEventSecondaryResolve(
    automationEvent: TypeAutomationEventsType,
    fnResolver: IResolveFuncs
  )
  {
    let secondary = "";
    const conditionVarId = automationEvent.executionConditionVarId;

    if(!conditionVarId)
    {
      return secondary;
    }

    const conditionVarValue = fnResolver.getVariable(conditionVarId) as StudioVarCondition;
    if(conditionVarValue?.value)
    {
      secondary = "if(" + getResolvedConditionVar(conditionVarValue.value, fnResolver) + ")";
    }

    return isEmpty(secondary) ? "always execute" : secondary;
  }

  private getSpreadSheetStepSecondaryResolve(
    step: StudioEntAutomationStep,
    fnResolve: IResolveFuncs,
    pipelineVarMap?: StudioEntPipelineVarMap,
    currentFormId?: MetaIdForm
  )
  {
    const stepKind = step.kind;

    switch(stepKind)
    {
      case "callPlugin":
        return this.resolveStepCallPlugin(step, fnResolve, pipelineVarMap, currentFormId);
      case "insertIntoSpreadsheet":
      case "removeFromSpreadsheet":
      case "updateSpreadsheet":
        return this.resolveSpreadSheetStepSpreadSheet(step, fnResolve, pipelineVarMap, currentFormId);
      case "calculateFormulas":
        return this.resolveSpreadSheetStepCalcFormula(step);
      case "forwardToGroups":
        return this.resolveSpreadSheetStepForwardToGroups(step, fnResolve, pipelineVarMap, currentFormId);
      case "removeRow":
        return this.resolveSpreadSheetStepRemoveRow(fnResolve, currentFormId);
      case "updateField":
        return this.resolveSpreadSheetStepUpdateField(step, fnResolve, currentFormId);
      case "updateLogNumber":
        return this.resolveSpreadSheetStepUpdateLogNumber(step, fnResolve, pipelineVarMap, currentFormId);
      case "removeField":
        return this.resolveSpreadSheetStepRemoveField(step, fnResolve, pipelineVarMap, currentFormId);
      case "callReport":
        return this.resolveSpreadSheetStepCallReport(step, fnResolve, pipelineVarMap, currentFormId);
      case "forwardToUsers":
      case "messageSend":
      case "messageSendOnWhatsapp":
      case "messageSendOnEmail":
        return this.resolveSpreadSheetStepForwardToUserOrStepMessageSend(step, fnResolve);
      case "terminate":
        return this.resolveSpreadSheetStepTerminate(step, fnResolve);
      case "partitionSend":
        return this.resolveSpreadSheetStepPartition(step, fnResolve);
      case "generateDeeplink":
        return this.resolveSpreadSheetStepGenerateDeeplink(step, fnResolve);
      case "returnEvent":
        return this.resolveSpreadSheetStepReturnEvent(step, fnResolve);
    }
  }

  private resolveStepCallPlugin(
    step: StudioEntAutomationStepCallPlugin,
    fnResolve: IResolveFuncs,
    pipelineVarMap?: StudioEntPipelineVarMap,
    currentFormId?: MetaIdForm
  )
  {
    const pluginName = step.targetPluginId ? fnResolve.getPluginName(step.targetPluginId) : "";
    const inputFormPipelineVarId = step.inputFormPipelineVarId;
    const formId = inputFormPipelineVarId
      ? pipelineVarMap?.map[inputFormPipelineVarId].formId
      : currentFormId;
    const formName = formId ? fnResolve.getFormName(formId) : undefined;

    let secondaryText = "";

    if(formName && pluginName)
    {
      secondaryText = `${formName} > ${pluginName}`;
    }
    return secondaryText;
  }

  private resolveSpreadSheetStepSpreadSheet(
    step: TypeSpreadSheetStepSpreadSheet,
    fnResolve: IResolveFuncs,
    pipelineVarMap?: StudioEntPipelineVarMap,
    currentFormId?: MetaIdForm)
  {
    const inputFormPipelineVarId = step.inputFormPipelineVarId;
    const formId = (inputFormPipelineVarId && isPipelineVarId(inputFormPipelineVarId))
      ? pipelineVarMap?.map[inputFormPipelineVarId].formId
      : currentFormId;
    const formName = formId
      ? fnResolve.getFormName(formId)
      : undefined;
    const targetSpreadSheetName = step.targetSpreadsheetId
      ? fnResolve.getSpreadSheetName(step.targetSpreadsheetId)
      : undefined;

    if(formName && targetSpreadSheetName)
    {
      return formName + " > " + targetSpreadSheetName;
    }

    return "";
  }

  private resolveSpreadSheetStepCalcFormula(step: StudioEntAutomationStepCalculateFormulas)
  {
    const newFormulaMap = step.newFormulaMap;
    return `${newFormulaMap ? "New" : "Form"} formulas`;
  }

  private resolveSpreadSheetStepForwardToUserOrStepMessageSend(
    step: StudioEntAutomationSpreadsheetStepForwardToUsers
      | StudioEntAutomationStepMessageSend
      | StudioEntAutomationStepMessageSendOnWhatsapp
      | StudioEntAutomationStepMessageSendOnEmail,
    fnResolve: IResolveFuncs
  )
  {
    let rolesText = "";
    let fieldText = "";

    const roleIdSet = step.dataSourceRoleIdSet;

    if(roleIdSet)
    {
      const roleNames = [] as string[];
      roleIdSet.forEach(roleId =>
      {
        if(EnumArrayDefnRoles.includes(roleId))
        {
          roleNames.push(roleId);
        }
        else
        {
          const role = fnResolve.getRoleName(roleId);
          role && roleNames.push(role);
        }
      });

      rolesText = rolesText + roleNames.join(", ");
    }

    if(isEmpty(rolesText) && isEmpty(fieldText))
    {
      return "";
    }
    else if(isEmpty(rolesText) && !isEmpty(fieldText))
    {
      return fieldText;
    }
    else if(isEmpty(fieldText) && !isEmpty(rolesText))
    {
      return rolesText;
    }
    else
    {
      return rolesText + ", " + fieldText;
    }
  }

  private resolveSpreadSheetStepTerminate(
    step: StudioEntAutomationStepTerminate,
    fnResolve: IResolveFuncs
  )
  {
    let secondary = "";
    const conditionVarId = step.executionConditionVarId;

    if(!conditionVarId)
    {
      return secondary;
    }

    const conditionVarValue = fnResolve.getVariable(conditionVarId) as StudioVarCondition;
    if(conditionVarValue?.value)
    {
      secondary = "if(" + getResolvedConditionVar(conditionVarValue.value, fnResolve) + ")";
    }

    return isEmpty(secondary) ? "Always execute" : secondary;
  }

  private resolveSpreadSheetStepPartition(
    step: StudioEntAutomationStepPartitionSend,
    fnResolve: IResolveFuncs
  )
  {
    const targetSpreadSheetId = step.targetSpreadsheetId;
    return targetSpreadSheetId ? fnResolve.getSpreadSheetName(targetSpreadSheetId) : "";
  }

  private resolveSpreadSheetStepGenerateDeeplink(
    step: StudioEntAutomationStepGenerateDeeplink,
    fnResolve: IResolveFuncs
  )
  {
    const deeplinkId = step.deeplinkId;
    return deeplinkId ? fnResolve.getDeeplinkName(deeplinkId) : "";
  }

  private resolveSpreadSheetStepReturnEvent(
    step: StudioEntAutomationStepReturn,
    fnResolve: IResolveFuncs
  )
  {
    let secondary = "";
    const conditionVarId = step.executionConditionVarId;

    if(!conditionVarId)
    {
      return secondary;
    }

    const conditionVarValue = fnResolve.getVariable(conditionVarId) as StudioVarCondition;
    if(conditionVarValue?.value)
    {
      secondary = "if(" + getResolvedConditionVar(conditionVarValue.value, fnResolve) + ")";
    }

    return isEmpty(secondary) ? "always execute" : secondary;
  }

  private resolveSpreadSheetStepForwardToGroups(
    step: StudioEntAutomationSpreadsheetStepForwardToGroups,
    fnResolve: IResolveFuncs,
    pipelineVarMap?: StudioEntPipelineVarMap,
    currentFormId?: MetaIdForm
  )
  {
    let str = "";
    const groupIdList = step.groupIdSet;
    const senderFieldId = step.senderFieldId;
    const senderRoleId = step.senderRoleId;
    const senderFormPipelineVarId = step.senderFormPipelineVarId;
    const formId = senderFormPipelineVarId
      ? pipelineVarMap?.map[senderFormPipelineVarId].formId
      : currentFormId;

    if(senderFieldId && formId)
    {
      str = str
        + (fnResolve.getFormFieldName(formId, senderFieldId) ?? "")
        + " > ";
    }
    else if(senderRoleId)
    {
      str = str + senderRoleId + " > ";
    }

    if(groupIdList)
    {
      const groupNames = [] as string[];
      groupIdList.forEach(groupId =>
      {
        const group = fnResolve.getGroupName(groupId);
        group && groupNames.push(group);
      });

      str = str + groupNames.join(", ");
    }

    return str;
  }

  private resolveSpreadSheetStepRemoveRow(
    fnResolve: IResolveFuncs,
    currentFormId?: MetaIdForm
  )
  {
    const formName = currentFormId
      ? fnResolve.getFormName(currentFormId)
      : "";

    return formName ? `Remove current ${formName} form` : "";
  }

  private resolveSpreadSheetStepUpdateField(
    step: StudioEntAutomationStepUpdateField,
    fnResolve: IResolveFuncs,
    currentFormId?: MetaIdForm
  )
  {
    let assignFieldValueText = "";
    let argValueText = "";
    const argValue = step.value?.argBinder;
    const assignValueField = step.assignValueFieldId;

    if(currentFormId && assignValueField)
    {
      assignFieldValueText = fnResolve.getFormFieldName(currentFormId, assignValueField) ?? "";
    }
    if(currentFormId && argValue)
    {
      argValueText = fnResolveArgBinderValue(fnResolve, argValue, currentFormId);
    }

    return !isEmpty(argValueText) && !isEmpty(assignFieldValueText) ? argValueText + " > " + assignFieldValueText : "";
  }

  private resolveSpreadSheetStepUpdateLogNumber(
    step: StudioEntAutomationStepUpdateLogNumber,
    fnResolve: IResolveFuncs,
    pipelineVarMap?: StudioEntPipelineVarMap,
    currentFormId?: MetaIdForm
  )
  {
    let targetFieldValueText;
    let argValueText;
    const argValue = step.value?.argBinder;
    const targetFieldId = step.targetFieldId;
    const targetSpreadsheetId = step.targetSpreadsheetId;
    const sourcePipelineVarId = step.sourcePipelineVarId;
    const formId = sourcePipelineVarId
      ? pipelineVarMap?.map[sourcePipelineVarId].formId
      : currentFormId;

    if(targetSpreadsheetId && targetFieldId)
    {
      const targetSpreadsheetFormId = fnResolve.getSpreadSheetForm(targetSpreadsheetId)?.metaId;
      targetFieldValueText = targetSpreadsheetFormId
        ? fnResolve.getFormFieldName(targetSpreadsheetFormId, targetFieldId)
        : "";
    }
    if(formId && argValue)
    {
      argValueText = fnResolveArgBinderValue(fnResolve, argValue, formId);
    }

    return !isEmpty(argValueText) && !isEmpty(targetFieldValueText) ? argValueText + " > " + targetFieldValueText : "";
  }

  private resolveSpreadSheetStepRemoveField(
    step: StudioEntAutomationStepRemoveField,
    fnResolve: IResolveFuncs,
    pipelineVarMap?: StudioEntPipelineVarMap,
    currentFormId?: MetaIdForm
  )
  {
    let str = "";
    const removeFieldId = step.removeFieldId;
    const formPipelineVarId = step.formPipelineVarId;
    const formId = formPipelineVarId
      ? pipelineVarMap?.map[formPipelineVarId].formId
      : currentFormId;
    const removeFieldName = (removeFieldId && formId)
      ? fnResolve.getFormFieldName(formId, removeFieldId)
      : "";

    if(removeFieldName)
    {
      str = `Remove field ${removeFieldName}`;
    }

    return str;
  }

  private resolveSpreadSheetStepCallReport(
    step: StudioEntAutomationStepCallReport,
    fnResolve: IResolveFuncs,
    pipelineVarMap?: StudioEntPipelineVarMap,
    currentFormId?: MetaIdForm
  )
  {
    const reportId = step.targetReportId;
    const inputFormPipelineVarId = step.inputFormPipelineVarId;
    const formId = inputFormPipelineVarId
      ? pipelineVarMap?.map[inputFormPipelineVarId].formId
      : currentFormId;
    const formName = formId ? fnResolve.getFormName(formId) : undefined;
    const reportName = reportId ? fnResolve.getReportName(reportId) : undefined;

    if(formName && reportName)
    {
      return formName + " > " + reportName;
    }

    return "";
  }
}
