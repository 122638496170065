import {FormHelperText} from "@mui/material";
import {FormControl} from "@mui/material";
import {TextField} from "@mui/material";
import {ChangeEvent} from "react";
import React from "react";
import {FieldError} from "react-hook-form";
import {Controller} from "react-hook-form";
import {ControllerRenderProps} from "react-hook-form/dist/types/controller";
import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnFieldDuration} from "../../../../api/meta/base/dto/DefnFieldDuration";
import {DefnFieldEditable} from "../../../../api/meta/base/dto/DefnFieldEditable";
import {DefnFieldLabel} from "../../../../api/meta/base/dto/DefnFieldLabel";
import {FieldValueDuration} from "../../../../api/meta/base/dto/FieldValueDuration";
import {EnumArrayDefnDurationUnit} from "../../../../api/meta/base/Types";
import {EnumDefnDurationUnit} from "../../../../api/meta/base/Types";
import {EnumDefnThemeFieldMargin} from "../../../../api/meta/base/Types";
import {EnumDefnThemeFieldSize} from "../../../../api/meta/base/Types";
import {EnumDefnThemeFieldVariant} from "../../../../api/meta/base/Types";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {optionsToMapOfOption} from "../../../../base/plus/JsPlus";
import {arrayToOptions} from "../../../../base/plus/JsPlus";
import {px} from "../../../../base/plus/StringPlus";
import {gapQuarter} from "../../../../base/plus/ThemePlus";
import {gapHalf} from "../../../../base/plus/ThemePlus";
import LayoutFlexRow from "../../../atom/layout/LayoutFlexRow";
import {LayoutGap} from "../../../atom/layout/LayoutGap";
import RawPickOneAutoComplete from "../../../atom/raw/RawPickOneAutoComplete";
import {useFieldPropertiesResolver} from "../../base/FormHooks";
import {useFormCtx} from "../base/CtxForm";
import {useFormSectionCtx} from "../base/CtxFormSection";
import {getCompLabel} from "../base/FormViewerPlus";
import FieldLabel from "../basic/FieldLabel";
import FieldRawRefButton from "../raw/FieldRawRefButton";
import FieldRawTemplate from "../raw/FieldRawTemplate";

export default function FieldDuration(props: {
  defn: DefnFieldDuration,
})
{
  const defn = props.defn;
  const {
    getFieldPlaceHolder,
    getFieldRequired,
    getFieldHelperText
  } = useFieldPropertiesResolver(defn);

  const fieldId = getFieldKey(defn);
  const label = getCompLabel(defn);
  const formCtx = useFormCtx();
  const defnTheme = formCtx.getDefnTheme();
  const fieldSize = defnTheme.fieldSize ?? "medium";
  const fieldVariant = defnTheme.fieldVariant;

  const formSectionCtx = useFormSectionCtx();
  const formSection = formSectionCtx.getParent();
  const isPropertyEditor = formSection.sectionVariant === "propertyEditor";
  const required = getFieldRequired();
  const helperText = getFieldHelperText();
  const placeHolder = getFieldPlaceHolder();

  const defnLabel = {
    label: Boolean(required) ? label + " *" : label
  } as DefnFieldLabel;

  const disabled = formCtx.isFieldDisable(defn as DefnFieldEditable);
  const readOnly = formCtx.isFieldReadonly(defn);

  return (
    <Controller
      name={fieldId}
      control={formCtx.control()}
      render={({
        field,
        fieldState
      }) =>
      {
        const {
          isTouched,
          error
        } = fieldState;

        const isError = isTouched && Boolean(error);
        const onChange = field.onChange;
        const fieldValue = field.value as FieldValueDuration | undefined;

        const handleValueChange = (event: ChangeEvent<HTMLInputElement>) =>
        {
          if(parseInt(event.target.value) >= 0)
          {
            onChange({
              value: {
                unit: fieldValue?.value?.unit,
                value: parseInt(event.target.value)
              }
            } as FieldValueDuration);
          }
          else
          {
            onChange({
              value: {
                unit: undefined,
                value: undefined
              }
            });
          }
        };

        const handleUnitChange = (unit: EnumDefnDurationUnit | null) =>
        {
          if(unit === null)
          {
            onChange(null);
          }
          else
          {
            onChange({
              value: {
                unit: unit,
                value: fieldValue?.value?.value
              }
            } as FieldValueDuration);
          }
        };

        return (
          <FieldRawTemplate
            defn={defn}
            fieldValue={fieldValue}
          >
            <FormControl
              fullWidth
              variant={fieldVariant === "standard" ? "outlined" : fieldVariant}
              error={isError}
            >
              {(!defn.hideLabel && !isPropertyEditor) && <FieldLabel defn={defnLabel} />}
              <LayoutGap height={px(gapQuarter)} />

              <FieldRawDuration
                defn={defn}
                fieldValue={fieldValue}
                fieldSize={fieldSize}
                fieldMargin={defnTheme.fieldMargin}
                fieldVariant={fieldVariant}
                disabled={disabled}
                isError={isError}
                error={error}
                readOnly={readOnly}
                handleValueChange={handleValueChange}
                handleUnitChange={handleUnitChange}
                placeHolder={placeHolder}
                field={field}
              />

              {(isError || helperText) &&
                <FormHelperText
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    flexGrow: 1
                  }}
                >
                  {error?.message ? error.message : helperText}
                </FormHelperText>
              }

            </FormControl>
          </FieldRawTemplate>
        );
      }}
    />
  );
}

function FieldRawDuration(props: {
  defn: DefnFieldDuration,
  field: ControllerRenderProps<FieldValues, string>,
  fieldValue?: FieldValueDuration,
  fieldSize?: EnumDefnThemeFieldSize,
  fieldMargin?: EnumDefnThemeFieldMargin,
  fieldVariant?: EnumDefnThemeFieldVariant,
  disabled?: boolean,
  handleUnitChange: (unit: EnumDefnDurationUnit | null) => void,
  handleValueChange: (event: ChangeEvent<HTMLInputElement>) => void,
  error?: FieldError,
  isError?: boolean,
  readOnly?: boolean,
  required?: boolean,
  placeHolder?: string
})
{
  const formCtx = useFormCtx();

  const fieldValue = props.fieldValue;
  const defn = props.defn;
  const field = props.field;
  const fieldId = getFieldKey(defn);
  const error = props.error;
  const isError = props.isError;
  const filterOptionSet = defn?.filterOptionSet;
  const options = arrayToOptions(filterOptionSet || EnumArrayDefnDurationUnit);

  const disabled = props.disabled;
  const readOnly = props.readOnly;
  const fieldSize = props.fieldSize;
  const fieldVariant = props.fieldVariant;
  const required = props.required;
  const placeHolder = props.placeHolder;

  const getOnClick = formCtx.getOnClick();
  const onClick = getOnClick
    ? () =>
    {
      getOnClick(fieldId, "field");
    }
    : undefined;

  const fieldBorderColor = formCtx.getFieldBorderColor;
  const borderColor = fieldBorderColor && fieldBorderColor(fieldId);

  return (
    <LayoutFlexRow
      justifyContent={"space-between"}
      overflowX={"visible"}
      overflowY={"visible"}
      width={"100%"}
    >
      <TextField
        sx={{
          width: "50%",
          paddingRight: px(gapHalf),
          ...borderColor && {
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: borderColor
              }
            }
          }
        }}
        required={required}
        placeholder={placeHolder}
        onClick={onClick}
        InputProps={{
          readOnly: readOnly
        }}
        ref={field.ref}
        inputRef={field.ref}
        type={"number"}
        label={"Duration"}
        size={fieldSize}
        margin={props.fieldMargin}
        variant={fieldVariant}
        autoComplete={Boolean(defn.autoFill) ? "on" : "off"}
        autoFocus={Boolean(defn.autoFocus)}
        disabled={disabled}
        name={fieldId}
        value={fieldValue?.value?.value ?? ""}
        onBlur={field.onBlur}
        onChange={props.handleValueChange}
        onKeyDown={(event) =>
        {
          if(event.key === "e" || event.key === "E" || event.key === ".")
          {
            event.preventDefault();
          }
        }}
        error={isError}
      />

      <LayoutFlexRow
        width={"50%"}
        overflowX={"visible"}
        overflowY={"visible"}
      >
        <RawPickOneAutoComplete
          optionMap={optionsToMapOfOption(options)}
          label={"Unit"}
          required={required}
          placeHolder={placeHolder}
          value={fieldValue?.value?.unit}
          disabled={Boolean(disabled || fieldValue?.value === undefined)}
          readOnly={readOnly}
          error={error
            ? {
              ...error,
              message: undefined
            }
            : undefined
          }
          onChange={(unit) =>
          {
            if(unit === null)
            {
              props.handleUnitChange(null);
            }
            else
            {
              props.handleUnitChange(unit as EnumDefnDurationUnit);
            }
          }}
          onBlur={field.onBlur}
          fieldVariant={fieldVariant}
          fieldSize={fieldSize}
          name={field.name}
          fieldId={fieldId}
        />
        <FieldRawRefButton
          defn={defn}
        />
      </LayoutFlexRow>

    </LayoutFlexRow>
  );
}
