import {useMemo} from "react";
import {useCallback} from "react";
import {useRef} from "react";
import {useEffect} from "react";
import {useState} from "react";
import React from "react";
import {FieldValues} from "react-hook-form";
import {isGridId} from "../../api/meta/base/ApiPlus";
import {DefnDtoOption} from "../../api/meta/base/dto/DefnDtoOption";
import {DefnField} from "../../api/meta/base/dto/DefnField";
import {DefnFieldChipSet} from "../../api/meta/base/dto/DefnFieldChipSet";
import {DefnFieldLabel} from "../../api/meta/base/dto/DefnFieldLabel";
import {DefnFieldNumber} from "../../api/meta/base/dto/DefnFieldNumber";
import {DefnFieldPickEnum} from "../../api/meta/base/dto/DefnFieldPickEnum";
import {DefnFieldSwitch} from "../../api/meta/base/dto/DefnFieldSwitch";
import {DefnSection} from "../../api/meta/base/dto/DefnSection";
import {DefnStudioMapOfDtoOption} from "../../api/meta/base/dto/DefnStudioMapOfDtoOption";
import {DefnStudioPickFieldId} from "../../api/meta/base/dto/DefnStudioPickFieldId";
import {DefnStudioPickGridId} from "../../api/meta/base/dto/DefnStudioPickGridId";
import {DefnStudioPickLayoutGridId} from "../../api/meta/base/dto/DefnStudioPickLayoutGridId";
import {DefnTab} from "../../api/meta/base/dto/DefnTab";
import {StudioCompositeMap} from "../../api/meta/base/dto/StudioCompositeMap";
import {StudioDtoLayoutGrid} from "../../api/meta/base/dto/StudioDtoLayoutGrid";
import {StudioDtoLayoutTable} from "../../api/meta/base/dto/StudioDtoLayoutTable";
import {StudioFieldEditable} from "../../api/meta/base/dto/StudioFieldEditable";
import {StudioGrid} from "../../api/meta/base/dto/StudioGrid";
import {SortBy} from "../../api/meta/base/StudioSetsFieldType";
import {MetaIdLayoutGrid} from "../../api/meta/base/Types";
import {MetaIdGrid} from "../../api/meta/base/Types";
import {EnumDefnFields} from "../../api/meta/base/Types";
import {MetaIdComposite} from "../../api/meta/base/Types";
import {EnumArrayDefnCompType} from "../../api/meta/base/Types";
import {MetaIdSpreadsheet} from "../../api/meta/base/Types";
import {MetaIdField, MetaIdForm} from "../../api/meta/base/Types";
import {stringToDefnDtoText} from "../../base/plus/ArgBinderPlus";
import {fnFieldValueToRawValue} from "../../base/plus/FieldValuePlus";
import {fnRawValueToFieldValue} from "../../base/plus/FieldValuePlus";
import {getFormSectionCompositeId} from "../../base/plus/FormPlus";
import {createDefaultDefnFormStudio, defaultSectionKey} from "../../base/plus/FormPlus";
import {getCombinedString} from "../../base/plus/StringPlus";
import {loopStudioForm} from "../../base/plus/StudioFormPlus";
import {gapStd} from "../../base/plus/ThemePlus";
import {IFormFieldError} from "../../base/types/TypesForm";
import {IFormRef} from "../../base/types/TypesForm";
import {FormStore} from "../../base/types/TypesForm";
import {SPREADSHEETS_LAYOUT_TABLE_ROW_PER_PAGE} from "../atom/assets/HelperTextStudio";
import {SPREADSHEETS_LAYOUT_TABLE_COLUMN_ALIGNMENTS} from "../atom/assets/HelperTextStudio";
import {SPREADSHEETS_LAYOUT_TABLE_COLUMN_SIZE} from "../atom/assets/HelperTextStudio";
import {SPREADSHEETS_LAYOUT_SORT_BY_FIELDS} from "../atom/assets/HelperTextStudio";
import {fieldGap3} from "../form/builder/base/TypesFormBuilder";
import {fieldGap2} from "../form/builder/base/TypesFormBuilder";
import {
  fieldGap1,
  fieldKeyDescription,
  fieldKeyLabel,
  fieldKeyName,
  fieldTabDetails,
  getFieldGap
} from "../form/builder/base/TypesFormBuilder";

import {useDialogFormValidationError} from "./base/DialogPlus";
import DialogDefnForm from "./base/impl/DialogDefnForm";
import {getCompMapSpreadSheetBrTabDetails} from "./base/LayoutBuilderPlus";
import {fieldLayoutSortOrder} from "./base/TypesLayoutBuilder";
import {fieldLayoutSortByFields} from "./base/TypesLayoutBuilder";
import {fieldSpreadsheetLayoutSwitcherIdSet} from "./base/TypesLayoutBuilder";
import {fieldLayoutDetailBgColorField, fieldLayoutDetailTooltipField} from "./base/TypesLayoutBuilder";

const dialogContentWidth = 900;
const dialogContentHeight = 700;

const fieldShowCompIdSet = "showCompIdSet";
const fieldColumnSizeSet = "columnSizeSet";
const fieldColumnAlignment = "columnAlignmentArray";
const fieldShowComments = "showCommentCount";
const fieldPagination = "pagination";
const fieldRowPerPage = "rowsPerPage";
const fieldMasterDetailGridIdSet = "masterDetailGridIdSet";
const fieldHideRowSeparator = "hideRowSeparator";
const fieldNote = "info";

const tabTable = "table";
const tabSparkline = "sparkline";
const tabMasterDetail = "masterDetail";

export default function DialogNewLayoutSpreadsheetTable(props: {
  formStore: FormStore,
  isFormReadOnly?: boolean,
  metaIdForm?: MetaIdForm,
  spreadsheetId: MetaIdSpreadsheet
  values?: StudioDtoLayoutTable,
  defaultValues?: StudioDtoLayoutTable,
  onClickOk: (values: StudioDtoLayoutTable) => void,
  onClose?: () => void,
  validationError?: IFormFieldError[]
})
{

  const oldValues = props.values;
  const defaultValues = props.defaultValues;
  const formStore = props.formStore;
  const formId = props.metaIdForm;
  const excludeSpreadsheetLayoutIdSet = props.values?.metaId ? [props.values.metaId] : undefined;
  const spreadsheetId = props.spreadsheetId;

  const compositeMap: StudioCompositeMap | undefined = formId
    ? formStore.formMap?.map[formId]?.compositeMap
    : undefined;
  const spreadSheetMap = formStore.spreadsheetMap?.map;
  const form = formId
    ? formStore.formMap?.map[formId]
    : undefined;

  const validationError = props.validationError;
  const cbRef = useRef({} as IFormRef);

  useDialogFormValidationError({
    cbFormRef: cbRef.current,
    validationError: validationError
  });

  const [isPaginationEnable, setIsPaginationEnable] = useState<boolean>(false);
  const [showGridIdSet, setShowGridIdSet] = useState<MetaIdGrid[]>([]);
  const [masterDetailGridSet, setMasterDetailGridSet] = useState<MetaIdGrid[]>([]);
  const [queryableFields, setQueryableFields] = useState<MetaIdField[]>([]);

  const defnForm = useMemo(() => getDefnForm(spreadsheetId,
      formId,
      excludeSpreadsheetLayoutIdSet,
      isPaginationEnable,
      formStore,
      compositeMap,
      showGridIdSet,
      masterDetailGridSet,
      queryableFields
    ),
    [
      spreadsheetId,
      formId,
      excludeSpreadsheetLayoutIdSet,
      isPaginationEnable,
      compositeMap,
      showGridIdSet,
      masterDetailGridSet,
      queryableFields
    ]
  );

  useEffect(() =>
  {
    if(oldValues)
    {
      setIsPaginationEnable(Boolean(oldValues.pagination));
      setShowGridIdSet(oldValues?.showCompIdSet?.filter(compId => isGridId(compId)) ?? []);
      const masterDetailGridLayoutMap = oldValues.masterDetailGridLayoutMap ?? {};
      const metaIdGrids = Object.keys(masterDetailGridLayoutMap);

      setMasterDetailGridSet(metaIdGrids);
    }
    else if(defaultValues)
    {
      setIsPaginationEnable(Boolean(defaultValues.pagination));
      setShowGridIdSet(defaultValues?.showCompIdSet?.filter(compId => isGridId(compId)) ?? []);
      const masterDetailGridLayoutMap = defaultValues.masterDetailGridLayoutMap ?? {};
      const metaIdGrids = Object.keys(masterDetailGridLayoutMap);

      setMasterDetailGridSet(metaIdGrids);
    }
  }, [oldValues, defaultValues]);

  const onWatch = useCallback((key: MetaIdField, value: FieldValues) =>
  {
    if(key === fieldPagination)
    {
      setIsPaginationEnable(fnFieldValueToRawValue("bool", value) as boolean);
    }
    if(key === fieldShowCompIdSet)
    {
      setShowGridIdSet((value as MetaIdGrid[] ?? []).filter(compId => isGridId(compId)));
    }
    if(key === fieldMasterDetailGridIdSet)
    {
      setMasterDetailGridSet(value as MetaIdGrid[] ?? []);
    }
  }, []);

  useEffect(() =>
  {
    if(!form || !spreadSheetMap)
    {
      return;
    }
    const spreadSheet = spreadSheetMap[spreadsheetId];
    const queryableIdSet = spreadSheet.queryableIdSet;

    loopStudioForm(form, (_, field) =>
    {
      const _field = (field as StudioFieldEditable);
      const refParentFieldId = _field?.refFieldId;

      if(queryableIdSet && queryableIdSet?.includes(_field.metaId) && !refParentFieldId)
      {
        setQueryableFields(queryable => [...queryable, _field.metaId]);
      }
    });
  }, [form, spreadSheetMap, spreadsheetId]);

  return (
    <DialogDefnForm
      title={`${oldValues ? "Update" : "New"} spreadsheet layout - Table`}
      formProps={{
        cbRef: cbRef.current,
        defnForm: defnForm,
        formReadonly: props.isFormReadOnly,
        store: formStore,
        initValues: dtoToValue(oldValues || defaultValues),
        onSubmit: values => props.onClickOk(valueToDto(values, oldValues, compositeMap)),
        onWatch: onWatch
      }}
      contentHeight={dialogContentHeight}
      contentWidth={dialogContentWidth}
      onClose={props.onClose}
      addMoreCheckBoxLabel={!oldValues
        ? "Add more spreadsheet layouts"
        : undefined}
      preventEnterKey={true}
    />
  );
}

function getDefnForm(
  spreadsheetId: MetaIdSpreadsheet,
  metaIdForm?: MetaIdForm,
  excludeSpreadsheetLayoutIdSet?: MetaIdLayoutGrid[],
  isPaginationEnable?: boolean,
  formStore?: FormStore,
  compositeMap?: StudioCompositeMap,
  showGridIdSet?: MetaIdGrid[],
  masterDetailGridSet?: MetaIdGrid[],
  queryableFields?: MetaIdField[]
)
{
  const compositeIdSet = getFormSectionCompositeId(formStore, metaIdForm);

  return createDefaultDefnFormStudio({
    ...getCompMapSpreadSheetBrTabDetails(spreadsheetId, metaIdForm, excludeSpreadsheetLayoutIdSet, undefined,
      compositeIdSet
    ),
    ...getCompMapSpreadSheetBrTabTable(metaIdForm, isPaginationEnable, compositeIdSet, compositeMap, queryableFields),

    ...getCompMapSpreadSheetBrTabMasterDetail(metaIdForm, masterDetailGridSet, compositeMap),

    ...getCompMapSpreadSheetBrSparklineTab(showGridIdSet, compositeMap, metaIdForm),

    [defaultSectionKey]: {
      type: "tab",
      metaId: defaultSectionKey,
      showDivider: true,
      tabVariant: "standard",
      tabIdSet: showGridIdSet && showGridIdSet.length > 0
        ? [
          fieldTabDetails,
          tabTable,
          tabMasterDetail,
          tabSparkline
        ]
        : [
          fieldTabDetails,
          tabTable,
          tabMasterDetail
        ]
    } as DefnTab

  } as Record<MetaIdField, DefnField>);
}

function getCompMapSpreadSheetBrTabTable(
  metaIdForm?: MetaIdForm,
  isPaginationEnable?: boolean,
  compositeIdSet?: MetaIdComposite[],
  compositeMap?: StudioCompositeMap,
  queryableFields?: MetaIdField[]
)
{
  const filterFieldTypeSet: string[] = EnumArrayDefnCompType.filter(compType => compType !== "ref");

  const includeOptionMap = {
    keys: [] as string[],
    map: {} as Record<string, DefnDtoOption>
  } as DefnStudioMapOfDtoOption;

  const systemFields = [
    "$CreatedBy",
    "$CreatedOn",
    "$UpdatedBy",
    "$UpdatedOn"
  ] as EnumDefnFields[];

  compositeMap?.keys.forEach(compId =>
  {
    const comp = compositeMap?.map[compId];
    if(comp?.type === "grid")
    {
      includeOptionMap.keys.push(compId);
      includeOptionMap.map[compId] = {
        value: (comp as StudioGrid).details.name,
        metaId: compId
      };
    }
  });

  systemFields.forEach(fieldId =>
  {
    includeOptionMap.keys.push(fieldId);
    includeOptionMap.map[fieldId] = {
      value: fieldId,
      metaId: fieldId
    };
  });

  return {
    [fieldShowCompIdSet]: {
      type: "studioSetOfFieldId",
      metaId: fieldShowCompIdSet,
      name: fieldShowCompIdSet,
      label: "Show fields or grids",
      showChip: true,
      formId: metaIdForm,
      required: true,
      filterFieldTypeSet: filterFieldTypeSet,
      compositeIdSet: compositeIdSet,
      includeOptionMap: includeOptionMap
    } as DefnStudioPickFieldId,
    [fieldColumnSizeSet]: {
      type: "chipSet",
      metaId: fieldColumnSizeSet,
      name: fieldColumnSizeSet,
      label: "Column sizes",
      allowDuplicate: true,
      helperTextVar: stringToDefnDtoText(SPREADSHEETS_LAYOUT_TABLE_COLUMN_SIZE)
    } as DefnFieldChipSet,
    [fieldColumnAlignment]: {
      type: "chipSet",
      metaId: fieldColumnAlignment,
      name: fieldColumnAlignment,
      label: "Column alignments",
      allowDuplicate: true,
      helperTextVar: stringToDefnDtoText(SPREADSHEETS_LAYOUT_TABLE_COLUMN_ALIGNMENTS)
    } as DefnFieldChipSet,
    [fieldShowComments]: {
      type: "bool",
      metaId: fieldShowComments,
      name: fieldShowComments,
      label: "Show comment count",
      showAsCheckboxVar: true
    } as DefnFieldSwitch,

    ...getFieldGap(fieldGap1, "thick"),

    [fieldPagination]: {
      type: "bool",
      name: fieldPagination,
      label: "Pagination",
      showAsCheckboxVar: true,
      metaId: fieldPagination
    } as DefnFieldSwitch,

    [fieldRowPerPage]: {
      type: "number",
      metaId: fieldRowPerPage,
      name: fieldRowPerPage,
      label: "Rows per page",
      helperTextVar: stringToDefnDtoText(SPREADSHEETS_LAYOUT_TABLE_ROW_PER_PAGE),
      disabled: !isPaginationEnable
    } as DefnFieldNumber,

    [fieldHideRowSeparator]: {
      type: "bool",
      metaId: fieldHideRowSeparator,
      name: fieldHideRowSeparator,
      label: "Hide row separator",
      showAsCheckboxVar: true
    } as DefnFieldSwitch,

    ...getFieldGap(fieldGap2, "thick"),

    [fieldLayoutSortByFields]: {
      type: "studioSetOfFieldId",
      metaId: fieldLayoutSortByFields,
      name: fieldLayoutSortByFields,
      label: "Sort by fields",
      formId: metaIdForm,
      filterFieldTypeSet: SortBy,
      compositeIdSet: compositeIdSet,
      includeFieldIdSet: queryableFields,
      showCompositeName: true,
      helperTextVar: stringToDefnDtoText(SPREADSHEETS_LAYOUT_SORT_BY_FIELDS)
    } as DefnStudioPickFieldId,

    [fieldLayoutSortOrder]: {
      type: "enumSortOrder",
      metaId: fieldLayoutSortOrder,
      name: fieldLayoutSortOrder,
      label: "Sort order"
    } as DefnFieldPickEnum,

    ...getFieldGap(fieldGap3, "thick"),

    [tabTable]: {
      type: "section",
      metaId: tabTable,
      name: "table",
      fieldIdSet: [
        fieldShowCompIdSet,
        fieldColumnSizeSet,
        fieldColumnAlignment,
        fieldGap1,
        fieldLayoutSortByFields,
        fieldLayoutSortOrder,
        fieldGap2,
        fieldShowComments,
        fieldHideRowSeparator,
        fieldGap3,
        fieldPagination,
        fieldRowPerPage
      ]
    } as DefnSection
  };
}

function getCompMapSpreadSheetBrTabMasterDetail(
  metaIdForm?: MetaIdForm,
  masterDetailGridSet?: MetaIdGrid[],
  compositeMap?: StudioCompositeMap)
{

  if(!masterDetailGridSet || !compositeMap)
  {
    return {};
  }

  const {
    masterDetailCompMap,
    masterDetailCompKeys
  } = getMasterDetailCompMap(masterDetailGridSet, compositeMap, metaIdForm);

  return {
    [fieldMasterDetailGridIdSet]: {
      type: "studioSetOfGridId",
      metaId: fieldMasterDetailGridIdSet,
      name: fieldMasterDetailGridIdSet,
      label: "Pick grids",
      formId: metaIdForm
    } as DefnStudioPickGridId,

    ...getFieldGap(fieldGap1, "thick"),

    [fieldNote]: {
      type: "label",
      name: fieldNote,
      metaId: fieldNote,
      textSizeVar: "caption",
      italicVar: true,
      disabled: true,
      label: "Note: You can only choose the table layout"
    } as DefnFieldLabel,

    ...masterDetailCompMap,

    [tabMasterDetail]: {
      type: "section",
      metaId: tabMasterDetail,
      name: "Master detail",
      fieldIdSet: masterDetailCompKeys.length > 0 ? [
          fieldMasterDetailGridIdSet,
          fieldGap1,
          fieldNote,
          ...masterDetailCompKeys
        ] :
        [
          fieldMasterDetailGridIdSet
        ]
    } as DefnSection
  };
}

function getCompMapSpreadSheetBrSparklineTab(
  showGridIdSet?: MetaIdGrid[],
  compositeMap?: StudioCompositeMap,
  formId?: MetaIdForm
)
{
  if(!showGridIdSet || !compositeMap)
  {
    return {};
  }

  const {
    sparklineCompMap,
    sparklineCompKeys
  } = getSparklineCompMap(showGridIdSet, compositeMap, formId);

  return {

    ...sparklineCompMap,

    [tabSparkline]: {
      type: "section",
      metaId: tabSparkline,
      name: "sparkline",
      label: "Sparkline",
      fieldIdSet: sparklineCompKeys
    } as DefnSection
  };
}

function getMasterDetailCompMap(
  masterDetailGridSet: MetaIdGrid[],
  compositeMap: StudioCompositeMap,
  formId?: MetaIdForm)
{
  const compMap = {} as Record<MetaIdField, DefnField>;

  masterDetailGridSet?.forEach(gridId =>
  {
    const grid = compositeMap.map[gridId] as StudioGrid | undefined;
    const gridName = grid?.details.name;

    const fieldLabelKey = getMasterDetailLabelKey(gridId);
    const fieldLayoutIdKey = getMasterDetailLayoutKey(gridId);
    const fieldSectionKey = getMasterDetailSectionKey(gridId);

    compMap[fieldLabelKey] = {
      type: "label",
      name: fieldLabelKey,
      metaId: fieldLabelKey,
      label: gridName
    } as DefnFieldLabel;

    compMap[fieldLayoutIdKey] = {
      type: "pickLayoutGridId",
      name: fieldLayoutIdKey,
      metaId: fieldLayoutIdKey,
      label: "Layout",
      filterLayoutKindSet: ["table"],
      formId: formId,
      gridId: gridId,
      required: true
    } as DefnStudioPickLayoutGridId;

    compMap[fieldSectionKey] = {
      type: "section",
      name: fieldSectionKey,
      metaId: fieldSectionKey,
      sectionDirection: "horizontal",
      fieldIdSet: [fieldLabelKey, fieldLayoutIdKey],
      pl: gapStd,
      pr: gapStd,
      fieldSpanMap: {
        [fieldLabelKey]: 2,
        [fieldLayoutIdKey]: 3
      }
    } as DefnSection;
  });

  return {
    masterDetailCompKeys: masterDetailGridSet.map(gridId => getMasterDetailSectionKey(gridId)) ?? [],
    masterDetailCompMap: compMap
  };
}

function getSparklineCompMap(
  showGridIdSet: MetaIdGrid[],
  compositeMap: StudioCompositeMap,
  formId?: MetaIdForm
)
{
  const compMap = {} as Record<MetaIdField, DefnField>;

  showGridIdSet.forEach(gridId =>
  {
    const grid = compositeMap.map[gridId] as StudioGrid | undefined;
    const gridName = grid?.details.name;

    const fieldLabelKey = getSparklineLabelKey(gridId);
    const fieldLayoutIdKey = getSparklineLayoutKey(gridId);
    const fieldSectionIdKey = getSparklineSectionKey(gridId);

    compMap[fieldLabelKey] = {
      type: "label",
      name: fieldLabelKey,
      metaId: fieldLabelKey,
      label: gridName
    } as DefnFieldLabel;

    compMap[fieldLayoutIdKey] = {
      type: "pickLayoutGridId",
      name: fieldLayoutIdKey,
      metaId: fieldLayoutIdKey,
      label: "Layout",
      filterLayoutKindSet: [
        "xyChartBarGraph",
        "xyChartLineChart",
        "xyChartPieChart",
        "xyChartDoughnut",
        "xyChartScatterPlot"
      ],
      formId: formId,
      gridId: gridId
    } as DefnStudioPickLayoutGridId;

    compMap[fieldSectionIdKey] = {
      type: "section",
      name: fieldSectionIdKey,
      metaId: fieldSectionIdKey,
      sectionDirection: "horizontal",
      fieldIdSet: [fieldLabelKey, fieldLayoutIdKey],
      pl: gapStd,
      pr: gapStd,
      fieldSpanMap: {
        [fieldLabelKey]: 2,
        [fieldLayoutIdKey]: 3
      }
    } as DefnSection;
  });

  return {
    sparklineCompKeys: showGridIdSet.map(gridId => getSparklineSectionKey(gridId)) ?? [],
    sparklineCompMap: compMap
  };
}

function getSparklineLabelKey(gridId: MetaIdGrid)
{
  return getCombinedString([gridId, "sparklineLabel"]);
}

function getSparklineLayoutKey(gridId: MetaIdGrid)
{
  return getCombinedString([gridId, "sparklineLayout"]);
}

function getSparklineSectionKey(gridId: MetaIdGrid)
{
  return getCombinedString([gridId, "sparklineSection"]);
}

function getMasterDetailLabelKey(gridId: MetaIdGrid)
{
  return getCombinedString([gridId, "masterDetailLabel"]);
}

function getMasterDetailLayoutKey(gridId: MetaIdGrid)
{
  return getCombinedString([gridId, "masterDetailLayout"]);
}

function getMasterDetailSectionKey(gridId: MetaIdGrid)
{
  return getCombinedString([gridId, "masterDetailSection"]);
}

function dtoToValue(table?: StudioDtoLayoutTable)
{
  if(!table)
  {
    return {
      [fieldKeyName]: fnRawValueToFieldValue("symbol", "Table")
    };
  }

  const masterDetailGridLayoutMap = table?.masterDetailGridLayoutMap ?? {};
  const metaIdGrids = Object.keys(masterDetailGridLayoutMap);

  return {
    // tab details
    [fieldKeyName]: fnRawValueToFieldValue("symbol", table.name ?? "Table"),
    [fieldKeyLabel]: fnRawValueToFieldValue("text", table.label),
    [fieldKeyDescription]: fnRawValueToFieldValue("text", table.description),
    [fieldLayoutDetailBgColorField]: fnRawValueToFieldValue("pickFieldId", table.bgColorFieldId),
    [fieldLayoutDetailTooltipField]: fnRawValueToFieldValue("pickFieldId", table.toolTipFieldId),
    [fieldSpreadsheetLayoutSwitcherIdSet]: fnRawValueToFieldValue("studioSetOfLayoutSpreadsheetId",
      table.allowToSwitchLayoutIdSet
    ),

    // tab table
    [fieldShowCompIdSet]: fnRawValueToFieldValue("studioSetOfFieldId", table?.showCompIdSet),
    [fieldColumnSizeSet]: fnRawValueToFieldValue("chipSet", table?.columnSizeSet),
    [fieldColumnAlignment]: fnRawValueToFieldValue("chipSet", table?.columnAlignmentArray),
    [fieldShowComments]: fnRawValueToFieldValue("bool", table?.showCommentCount),
    [fieldPagination]: fnRawValueToFieldValue("bool", table?.pagination),
    [fieldRowPerPage]: fnRawValueToFieldValue("number", table?.rowsPerPage),
    [fieldMasterDetailGridIdSet]: fnRawValueToFieldValue("studioSetOfGridId", metaIdGrids),
    [fieldHideRowSeparator]: fnRawValueToFieldValue("bool", table?.hideRowSeparator),
    [fieldLayoutSortByFields]: fnRawValueToFieldValue("studioSetOfFieldId", table?.sortByFieldIdSet),
    [fieldLayoutSortOrder]: fnRawValueToFieldValue("enumSortOrder", table?.sortOrder),
    ...getSparklineDtoToValue(table?.sparklineLayoutMap),
    ...getMasterDetailDtoToValue(table?.masterDetailGridLayoutMap)
  };
}

function getMasterDetailDtoToValue(layoutMap?: Record<MetaIdGrid, MetaIdLayoutGrid>)
{
  const values = {} as FieldValues;

  if(!layoutMap)
  {
    return values;
  }

  Object.keys(layoutMap).forEach(gridId =>
  {
    const fieldLayoutIdKey = getMasterDetailLayoutKey(gridId);
    values[fieldLayoutIdKey] = fnRawValueToFieldValue("pickLayoutGridId", layoutMap[gridId]);
  });

  return values;
}

function getSparklineDtoToValue(layoutMap?: Record<MetaIdGrid, MetaIdLayoutGrid>)
{
  const values = {} as FieldValues;

  if(!layoutMap)
  {
    return values;
  }

  Object.keys(layoutMap).forEach(gridId =>
  {
    const fieldLayoutIdKey = getSparklineLayoutKey(gridId);
    values[fieldLayoutIdKey] = fnRawValueToFieldValue("pickLayoutGridId", layoutMap[gridId]);
  });

  return values;
}

function valueToDto(
  values: FieldValues,
  dto?: StudioDtoLayoutGrid,
  compositeMap?: StudioCompositeMap
): StudioDtoLayoutGrid
{
  const sparklineLayoutMap = {} as Record<MetaIdGrid, MetaIdLayoutGrid>;
  const masterDetailMap = {} as Record<MetaIdGrid, MetaIdLayoutGrid>;

  compositeMap?.keys.forEach(compId =>
  {
    const comp = compositeMap?.map[compId];

    if(comp?.type === "grid")
    {
      const fieldSparklineLayoutIdKey = getSparklineLayoutKey(compId);
      const fieldMasterDetailLayoutIdKey = getMasterDetailLayoutKey(compId);
      const sparklineLayoutIdValue = fnFieldValueToRawValue("pickLayoutGridId",
        values[fieldSparklineLayoutIdKey]
      ) as MetaIdLayoutGrid | undefined;

      const masterDetailLayoutIdValue = fnFieldValueToRawValue("pickLayoutGridId",
        values[fieldMasterDetailLayoutIdKey]
      ) as MetaIdLayoutGrid | undefined;

      if(sparklineLayoutIdValue)
      {
        sparklineLayoutMap[compId] = sparklineLayoutIdValue;
      }

      if(masterDetailLayoutIdValue)
      {
        masterDetailMap[compId] = masterDetailLayoutIdValue;
      }
    }

  });

  return {
    metaId: dto?.metaId,
    name: fnFieldValueToRawValue("symbol", values[fieldKeyName]),
    label: fnFieldValueToRawValue("text", values[fieldKeyLabel]),
    description: fnFieldValueToRawValue("text", values[fieldKeyDescription]),
    toolTipFieldId: fnFieldValueToRawValue("pickFieldId", values[fieldLayoutDetailTooltipField]),
    bgColorFieldId: fnFieldValueToRawValue("pickFieldId", values[fieldLayoutDetailBgColorField]),
    allowToSwitchLayoutIdSet: fnFieldValueToRawValue("studioSetOfLayoutSpreadsheetId",
      values[fieldSpreadsheetLayoutSwitcherIdSet]
    ),
    kind: fnFieldValueToRawValue("enumLayoutGridKind", "table"),
    masterDetailGridIdSet: fnFieldValueToRawValue("studioSetOfGridId", values[fieldMasterDetailGridIdSet]),
    pagination: fnFieldValueToRawValue("bool", values[fieldPagination]),
    rowsPerPage: fnFieldValueToRawValue("bool", values[fieldPagination])
      ? fnFieldValueToRawValue("number", values[fieldRowPerPage])
      : undefined,
    showCommentCount: fnFieldValueToRawValue("bool", values[fieldShowComments]),
    showCompIdSet: fnFieldValueToRawValue("studioSetOfFieldId", values[fieldShowCompIdSet]),
    columnSizeSet: fnFieldValueToRawValue("chipSet", values[fieldColumnSizeSet]),
    columnAlignmentArray: fnFieldValueToRawValue("chipSet", values[fieldColumnAlignment]),
    hideRowSeparator: fnFieldValueToRawValue("bool", values[fieldHideRowSeparator]),
    sortByFieldIdSet: fnFieldValueToRawValue("studioSetOfFieldId", values[fieldLayoutSortByFields]),
    sortOrder: fnFieldValueToRawValue("enumSortOrder", values[fieldLayoutSortOrder]),
    sparklineLayoutMap: sparklineLayoutMap,
    masterDetailGridLayoutMap: masterDetailMap
  } as StudioDtoLayoutTable;
}


