// neome.ai API - do not change
//
/* eslint-disable @typescript-eslint/no-unused-vars */
// noinspection UnusedGlobalSymbols,ES6UnusedImports,JSUnusedGlobalSymbols,JSUnusedLocalSymbols

export const CardLineTextVar = [
  "text"
];

export const ConditionComparableVarType = [
  "number",
  "decimal",
  "date",
  "dateTime",
  "bool",
  "html",
  "paragraph",
  "text"
];

export const MediaVarType = [
  "image",
  "html",
  "hyperlink"
];

export const PaymentLinkStepAmountType = [
  "decimal",
  "number"
];

export const PaymentLinkStepCurrencyType = [
  "currency"
];

export const PaymentLinkStepDescriptionType = [
  "text",
  "paragraph"
];

export const PaymentLinkStepLinkExpiryDurationType = [
  "duration"
];

export const StudioBuildArgBinderMappingVariableType = [
  "bool",
  "symbol",
  "date",
  "dateTime",
  "decimal",
  "email",
  "function",
  "html",
  "hyperlink",
  "icon",
  "number",
  "paragraph",
  "setOfText",
  "text",
  "duration",
  "mobileNumber",
  "currency",
  "textSize",
  "language",
  "stroke",
  "month",
  "mapPinShape",
  "quarter",
  "timeZone",
  "location",
  "time",
  "image",
  "document"
];

export const StudioBuildArgBinderParamVariableType = [
  "bool",
  "symbol",
  "date",
  "dateTime",
  "decimal",
  "email",
  "function",
  "html",
  "hyperlink",
  "icon",
  "number",
  "paragraph",
  "setOfText",
  "text",
  "duration",
  "mobileNumber",
  "currency",
  "textSize",
  "language",
  "stroke",
  "month",
  "mapPinShape",
  "quarter",
  "timeZone",
  "location",
  "time"
];

export const StudioBuildArgBinderVariableType = [
  "bool",
  "symbol",
  "date",
  "dateTime",
  "decimal",
  "email",
  "function",
  "html",
  "hyperlink",
  "icon",
  "number",
  "paragraph",
  "setOfText",
  "text",
  "duration",
  "mobileNumber",
  "currency",
  "textSize",
  "language",
  "stroke",
  "month",
  "mapPinShape",
  "quarter",
  "timeZone",
  "location",
  "time"
];

export const StudioPluginValidVarKind = [
  "any",
  "bool",
  "date",
  "dateTime",
  "day",
  "decimal",
  "document",
  "duration",
  "email",
  "html",
  "hyperlink",
  "icon",
  "image",
  "location",
  "mobileNumber",
  "number",
  "paragraph",
  "symbol",
  "text",
  "time",
  "setOfDay",
  "setOfTime",
  "mapOfText",
  "setOfText",
  "setOfNumber",
  "tree",
  "buttonVariant",
  "color",
  "currency",
  "deviceSize",
  "deviceType",
  "textSize",
  "imageCorner",
  "language",
  "mapPinShape",
  "month",
  "quarter",
  "ratingKind",
  "stroke",
  "placement",
  "timeZone"
];

export const StudioVarCollectionsType = [
  "setOfDay",
  "setOfTime",
  "mapOfText",
  "setOfNumber",
  "setOfText",
  "setOfUser",
  "setOfDate",
  "tree"
];

export const StudioVarConstantType = [
  "any",
  "bool",
  "date",
  "dateTime",
  "day",
  "decimal",
  "document",
  "duration",
  "email",
  "html",
  "hyperlink",
  "icon",
  "image",
  "location",
  "mobileNumber",
  "number",
  "paragraph",
  "symbol",
  "text",
  "time"
];

export const StudioVarObjectsType = [
  "condition",
  "function",
  "mapping",
  "sequence",
  "userSetting"
];

export const StudioVarPickersType = [
  "buttonVariant",
  "color",
  "currency",
  "deviceSize",
  "deviceType",
  "textSize",
  "imageCorner",
  "language",
  "mapPinShape",
  "month",
  "quarter",
  "ratingKind",
  "stroke",
  "placement",
  "timeZone"
];
