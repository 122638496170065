import {Typography} from "@mui/material";
import {useTheme} from "@mui/material";
import React from "react";
import {DtoMessagePayloadSpreadsheetRowDeleted} from "../../../../api/home/base/dto/DtoMessagePayloadSpreadsheetRowDeleted";
import {formatCaptionOnlyTime} from "../../../../base/plus/DatePlus";
import {px} from "../../../../base/plus/StringPlus";
import {IBubbleHeader} from "../../../../base/types/TypesBubble";
import {CbMenuAnchor} from "../../../../base/types/TypesGlobal";
import IconStrip from "../../icon/IconStrip";
import LayoutFlexRow from "../../layout/LayoutFlexRow";
import BubbleRawCaption from "../raw/BubbleRawCaption";
import BubbleRawHeader from "../raw/BubbleRawHeader";
import BubbleRawText from "../raw/BubbleRawText";
import BubbleShell from "./BubbleShell";

export default function BubbleDelete(props: {
  payload: DtoMessagePayloadSpreadsheetRowDeleted,
  isCallerSender: boolean,
  creationTime: string,
  maxWidth: number,
  text: string,
  header?: IBubbleHeader,
  cbOnClickBubbleShell?: CbMenuAnchor,
  cbOnClickMenu?: CbMenuAnchor,
  cbOnClickBubbleHeaderPrimary?: CbMenuAnchor,
})
{
  const theme = useTheme();
  const bgColorShell = theme.common.bubbleBgcolorDelete;
  const gapQuarter = theme.common.gapQuarter;
  const captionTime = formatCaptionOnlyTime(new Date(props.creationTime));

  return (
    <BubbleShell
      isCallerSender={props.isCallerSender}
      maxWidth={props.maxWidth}
      onClickMenu={props.cbOnClickMenu}
      bgColorShell={bgColorShell}
    >
      {
        props.header !== undefined &&
        <BubbleRawHeader
          header={props.header}
          onClickPrimary={props.cbOnClickBubbleHeaderPrimary}
        />
      }

      {props.text && (
        <>
          <LayoutFlexRow>
            <LayoutFlexRow pb={px(gapQuarter)}>
              <IconStrip
                value={"msgDelete"}
              />
            </LayoutFlexRow>
            <BubbleRawText
              value={props.text}
              maxCharLimit={300}
              fakeSpace={
                <Typography
                  ml={px(gapQuarter)}
                  variant={"caption"}
                >
                  {captionTime}
                </Typography>
              }
              caption={
                <BubbleRawCaption
                  textRight={captionTime}
                  icon={undefined}
                  iconColor={"dark"}
                />}
            />
          </LayoutFlexRow>
        </>
      )}
    </BubbleShell>
  );
}
