// neome.ai API - do not change
//
/* eslint-disable @typescript-eslint/no-unused-vars */
// noinspection UnusedGlobalSymbols,ES6UnusedImports,JSUnusedGlobalSymbols,JSUnusedLocalSymbols

import {ArtifactId} from '../../meta/base/Types';
import {ENT_ID_GLOBAL} from '../../meta/base/ApiPlus';
import {EntId} from '../../meta/base/Types';
import {ISigAcceptor} from '../../nucleus/base/ISigAcceptor';
import {MsgChatBlock} from './msg/MsgChatBlock';
import {MsgChatId} from '../base/msg/MsgChatId';
import {MsgEntUserIdNoVersion} from '../../core/base/msg/MsgEntUserIdNoVersion';
import {MsgGroupId} from '../base/msg/MsgGroupId';
import {MsgMessageForwardCandidateList} from './msg/MsgMessageForwardCandidateList';
import {MsgMessageList} from './msg/MsgMessageList';
import {MsgMessageListJump} from './msg/MsgMessageListJump';
import {MsgMessageListOffset} from './msg/MsgMessageListOffset';
import {MsgMessageReport} from './msg/MsgMessageReport';
import {MsgMessageSend} from './msg/MsgMessageSend';
import {MsgOffset} from './msg/MsgOffset';
import {MsgOffsetWithVersion} from './msg/MsgOffsetWithVersion';
import {MsgReaction} from './msg/MsgReaction';
import {MsgTopic} from './msg/MsgTopic';
import {MsgTopicList} from './msg/MsgTopicList';
import {MsgUrl} from './msg/MsgUrl';
import {ServiceName} from '../../meta/base/Types';
import {SigChatCandidateMap} from './sig/SigChatCandidateMap';
import {SigDone} from '../../nucleus/base/sig/SigDone';
import {SigGroupMessageCandidateList} from './sig/SigGroupMessageCandidateList';
import {SigLinkPreview} from './sig/SigLinkPreview';
import {SigMessage} from './sig/SigMessage';
import {SigMessageList} from './sig/SigMessageList';
import {SigUserLastOnline} from './sig/SigUserLastOnline';
import {SigUserMessageCandidateList} from './sig/SigUserMessageCandidateList';
import {wsocCall} from '../../nucleus/base/IWsocCall';

export class WsocMain
{
  static readonly SN: ServiceName = "main";

  static chatBlock(msg: MsgChatBlock, sigAcceptor: ISigAcceptor<SigDone>): void
  {
    wsocCall<SigDone>(ENT_ID_GLOBAL, WsocMain.SN, "chatBlock")
      .put(msg, sigAcceptor);
  }

  static chatClear(entId: EntId, msg: MsgChatId, sigAcceptor: ISigAcceptor<SigDone>): void
  {
    wsocCall<SigDone>(entId, WsocMain.SN, "chatClear")
      .delete(msg, sigAcceptor);
  }

  static chatRemove(entId: EntId, msg: MsgChatId, sigAcceptor: ISigAcceptor<SigDone>): void
  {
    wsocCall<SigDone>(entId, WsocMain.SN, "chatRemove")
      .delete(msg, sigAcceptor);
  }

  static groupJoin(msg: MsgGroupId, sigAcceptor: ISigAcceptor<SigDone>): void
  {
    wsocCall<SigDone>(ENT_ID_GLOBAL, WsocMain.SN, "groupJoin")
      .put(msg, sigAcceptor);
  }

  static groupMessageCandidateListGet(entId: EntId, sigAcceptor: ISigAcceptor<SigGroupMessageCandidateList>): void
  {
    wsocCall<SigGroupMessageCandidateList>(entId, WsocMain.SN, "groupMessageCandidateListGet")
      .get(undefined, sigAcceptor);
  }

  static linkPreviewGet(msg: MsgUrl, sigAcceptor: ISigAcceptor<SigLinkPreview>): void
  {
    wsocCall<SigLinkPreview>(ENT_ID_GLOBAL, WsocMain.SN, "linkPreviewGet")
      .get(msg, sigAcceptor);
  }

  static messageForwardCandidateListGet(entId: EntId, msg: MsgMessageForwardCandidateList, sigAcceptor: ISigAcceptor<SigChatCandidateMap>): void
  {
    wsocCall<SigChatCandidateMap>(entId, WsocMain.SN, "messageForwardCandidateListGet")
      .get(msg, sigAcceptor);
  }

  static messageGet(entId: EntId, msg: MsgOffsetWithVersion, sigAcceptor: ISigAcceptor<SigMessage>): void
  {
    wsocCall<SigMessage>(entId, WsocMain.SN, "messageGet")
      .get(msg, sigAcceptor);
  }

  static messageListGet(entId: EntId, msg: MsgMessageList, sigAcceptor: ISigAcceptor<SigMessageList>): void
  {
    wsocCall<SigMessageList>(entId, WsocMain.SN, "messageListGet")
      .get(msg, sigAcceptor);
  }

  static messageListJump(entId: EntId, msg: MsgMessageListJump, sigAcceptor: ISigAcceptor<SigMessageList>): void
  {
    wsocCall<SigMessageList>(entId, WsocMain.SN, "messageListJump")
      .get(msg, sigAcceptor);
  }

  static messageListNext(entId: EntId, msg: MsgMessageListOffset, sigAcceptor: ISigAcceptor<SigMessageList>): void
  {
    wsocCall<SigMessageList>(entId, WsocMain.SN, "messageListNext")
      .get(msg, sigAcceptor);
  }

  static messageListPrev(entId: EntId, msg: MsgMessageListOffset, sigAcceptor: ISigAcceptor<SigMessageList>): void
  {
    wsocCall<SigMessageList>(entId, WsocMain.SN, "messageListPrev")
      .get(msg, sigAcceptor);
  }

  static messageMarkRead(entId: EntId, msg: MsgOffset, sigAcceptor: ISigAcceptor<SigDone>): void
  {
    wsocCall<SigDone>(entId, WsocMain.SN, "messageMarkRead")
      .put(msg, sigAcceptor);
  }

  static messageMarkReceived(entId: EntId, msg: MsgOffset, sigAcceptor: ISigAcceptor<SigDone>): void
  {
    wsocCall<SigDone>(entId, WsocMain.SN, "messageMarkReceived")
      .put(msg, sigAcceptor);
  }

  static messageReactionPut(entId: EntId, msg: MsgReaction, sigAcceptor: ISigAcceptor<SigDone>): void
  {
    wsocCall<SigDone>(entId, WsocMain.SN, "messageReactionPut")
      .put(msg, sigAcceptor);
  }

  static messageRemoveForEveryone(entId: EntId, msg: MsgOffset, sigAcceptor: ISigAcceptor<SigDone>): void
  {
    wsocCall<SigDone>(entId, WsocMain.SN, "messageRemoveForEveryone")
      .delete(msg, sigAcceptor);
  }

  static messageRemoveForMe(entId: EntId, msg: MsgOffset, sigAcceptor: ISigAcceptor<SigDone>): void
  {
    wsocCall<SigDone>(entId, WsocMain.SN, "messageRemoveForMe")
      .delete(msg, sigAcceptor);
  }

  static messageReport(entId: EntId, msg: MsgMessageReport, sigAcceptor: ISigAcceptor<SigDone>): void
  {
    wsocCall<SigDone>(entId, WsocMain.SN, "messageReport")
      .post(msg, sigAcceptor);
  }

  static messageSend(entId: EntId, msg: MsgMessageSend, sigAcceptor: ISigAcceptor<SigDone>): void
  {
    wsocCall<SigDone>(entId, WsocMain.SN, "messageSend")
      .post(msg, sigAcceptor);
  }

  static messageTyping(entId: EntId, msg: MsgChatId, sigAcceptor: ISigAcceptor<SigDone>): void
  {
    wsocCall<SigDone>(entId, WsocMain.SN, "messageTyping")
      .post(msg, sigAcceptor);
  }

  static subscribe(artifactId: ArtifactId, msg: MsgTopic, sigAcceptor: ISigAcceptor<SigDone>): void
  {
    wsocCall<SigDone>(artifactId, WsocMain.SN, "subscribe")
      .put(msg, sigAcceptor);
  }

  static topicSubscribe(msg: MsgTopicList, sigAcceptor: ISigAcceptor<SigDone>): void
  {
    wsocCall<SigDone>(ENT_ID_GLOBAL, WsocMain.SN, "topicSubscribe")
      .put(msg, sigAcceptor);
  }

  static topicSubscriptionCheck(artifactId: ArtifactId, msg: MsgTopic, sigAcceptor: ISigAcceptor<SigDone>): void
  {
    wsocCall<SigDone>(artifactId, WsocMain.SN, "topicSubscriptionCheck")
      .get(msg, sigAcceptor);
  }

  static topicUnsubscribe(msg: MsgTopicList, sigAcceptor: ISigAcceptor<SigDone>): void
  {
    wsocCall<SigDone>(ENT_ID_GLOBAL, WsocMain.SN, "topicUnsubscribe")
      .put(msg, sigAcceptor);
  }

  static unsubscribe(artifactId: ArtifactId, msg: MsgTopic, sigAcceptor: ISigAcceptor<SigDone>): void
  {
    wsocCall<SigDone>(artifactId, WsocMain.SN, "unsubscribe")
      .delete(msg, sigAcceptor);
  }

  static userLastOnlineGet(msg: MsgEntUserIdNoVersion, sigAcceptor: ISigAcceptor<SigUserLastOnline>): void
  {
    wsocCall<SigUserLastOnline>(ENT_ID_GLOBAL, WsocMain.SN, "userLastOnlineGet")
      .get(msg, sigAcceptor);
  }

  static userMessageCandidateListGet(sigAcceptor: ISigAcceptor<SigUserMessageCandidateList>): void
  {
    wsocCall<SigUserMessageCandidateList>(ENT_ID_GLOBAL, WsocMain.SN, "userMessageCandidateListGet")
      .get(undefined, sigAcceptor);
  }
}