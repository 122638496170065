import {Typography} from "@mui/material";
import {Button} from "@mui/material";
import {Box} from "@mui/material";
import {Variant} from "@mui/material/styles/createTypography";
import {TypographyPropsVariantOverrides} from "@mui/material/Typography/Typography";
import {darken} from "@mui/system/colorManipulator";
import {OverridableStringUnion} from "@mui/types";
import {forwardRef} from "react";
import React from "react";
import {DefnFieldButton} from "../../../../api/meta/base/dto/DefnFieldButton";
import {EnumDefnPlacement} from "../../../../api/meta/base/Types";
import {EnumDefnThemeButtonSize} from "../../../../api/meta/base/Types";
import {getLabel} from "../../../../base/plus/StringPlus";
import {px} from "../../../../base/plus/StringPlus";
import theme from "../../../../base/plus/ThemePlus";
import RawIcon from "../../../atom/raw/RawIcon";
import RawIconButton from "../../../atom/raw/RawIconButton";
import RawTooltip from "../../../atom/raw/RawTooltip";
import {useFormCtx} from "../base/CtxForm";
import {getCompLabel} from "../base/FormViewerPlus";

type IFieldRawBtnProps = {
  defn: DefnFieldButton
  cbOnClickBtn: () => void,
  disabled?: boolean,
  type?: "button" | "submit" | "reset",
  size?: EnumDefnThemeButtonSize,
  fullWidth?: boolean,
  pl?: number,
  pr?: number,
  pt?: number,
  pb?: number,
  isReport?: boolean
  toolbar?: boolean,
  footerBtn?: boolean,
  disabledElevation?: boolean,
  textSize?: string,
  icon?: string
};

const FieldRawButton = forwardRef<HTMLButtonElement, IFieldRawBtnProps>((props: IFieldRawBtnProps, ref) =>
{
  const formCtx = useFormCtx();
  const defn = props.defn;

  const disabledElevation = props.disabledElevation;
  const textSize = props.textSize;
  const icon = props.icon;

  const buttonPositionVar = defn.buttonPositionVar ?? defn.buttonPosition;

  const variant = textSize as OverridableStringUnion<Variant | "inherit", TypographyPropsVariantOverrides>
    || (props.isReport
    === true ? "caption" : "subtitle1") as OverridableStringUnion<Variant | "inherit", TypographyPropsVariantOverrides>;

  const readOnly = formCtx.isFieldReadonly && formCtx.isFieldReadonly(defn);
  const disabled = props.disabled || readOnly;
  const label = getCompLabel(defn);
  const color = defn.bgColor?.value ?? defn.bgColorVar?.value;
  const shade = defn.bgColor?.shade ?? defn.bgColorVar?.shade;
  const bgColor = color
    ? theme.common.colorWithShade(color, shade)
    : theme.common.colorWithShade("primary");
  const hoverBgColor = bgColor
    ? darken(bgColor, 0.2)
    : undefined;
  const buttonVariant = defn.buttonVariant || defn.buttonVariantVar || "contained";
  const iconPosition = defn.iconPosition || defn.iconPositionVar;

  return (
    <Box
      sx={{
        width: Boolean(props.toolbar || props.footerBtn) ? "auto" : "100%",
        display: "flex",
        pl: px(props.pl),
        pr: px(props.pr),
        pt: px(props.pt),
        pb: px(props.pb),
        "@media print": {
          visibility: "hidden"
        }
      }}
      justifyContent={defn.btnPosToolbar ? undefined : buttonPositionVar ?? "start"}
    >
      {
        buttonVariant === "icon" || props.toolbar
          ? <RawTooltip
            title={getLabel(defn)}
          >
            <Box
              sx={{
                display: "flex"
              }}
              width={defn.btnPosToolbar ? undefined : "100%"}
              justifyContent={!defn.btnPosToolbar
                ? buttonPositionVar
                : undefined}
              ref={ref}
            >
              <RawIconButton
                name={defn.name}
                icon={icon ?? "grid"}
                enableRipple={true}
                onClick={props.cbOnClickBtn}
                disabled={disabled}
                color={color}
              />
            </Box>
          </RawTooltip>
          : <Button
            id={defn.metaId}
            disableFocusRipple={true}
            startIcon={(icon && (iconPosition === "start" || iconPosition === undefined))
              && <RawIcon
                icon={icon}
                disabled={disabled}
                color={(buttonVariant === "contained") ? "white" : color}
                shade={shade}
              />}
            endIcon={(icon && iconPosition === "end")
              && <RawIcon
                icon={icon}
                disabled={disabled}
                color={(buttonVariant === "contained") ? "white" : color}
                shade={shade}
              />}
            fullWidth={props.fullWidth || buttonPositionVar === "flexCenter"}
            disabled={disabled}
            disableElevation={Boolean(disabledElevation)}
            type={props.type}
            variant={buttonVariant ?? "contained"}
            size={props.size}
            onClick={props.cbOnClickBtn}
            ref={ref}
            sx={{
              textTransform: "none",
              color: bgColor,
              borderColor: bgColor,
              ...buttonVariant === "contained" && {
                color: theme.common.bgcolorContent,
                backgroundColor: bgColor,
                "&:hover": {
                  backgroundColor: hoverBgColor,
                  outline: "none"
                }
              },
              "&:focus": {},
              justifyContent: defn.justifyContent
                ? getTextJustify(defn.justifyContent)
                : "center"
            }}
          >
            <Typography
              variant={variant}
            >
              {label}
            </Typography>
          </Button>
      }
    </Box>
  );
});

function getTextJustify(justifyProperty?: EnumDefnPlacement)
{
  switch(justifyProperty)
  {
    case "start":
      return "start";
    case "end":
      return "end";
    case "center":
      return "center";
    case "spaceBetween":
      return "space-between";
  }
}

export default FieldRawButton;
