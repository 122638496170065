import {useTheme} from "@mui/material";
import {ICellRendererParams} from "ag-grid-community";
import {useCallback} from "react";
import {useRef} from "react";
import React from "react";
import {FieldValueCamera} from "../../../../api/meta/base/dto/FieldValueCamera";
import {MediaStore} from "../../../../base/plus/MediaPlus";
import {getMediaSrc} from "../../../../base/plus/MediaPlus";
import {px} from "../../../../base/plus/StringPlus";
import {IDataGridCell} from "../../../../base/types/TypeDataGrid";
import ImageViewDialog from "../../../atom/avatar/ImageViewDialog";
import RawLazyImage from "../../../atom/raw/RawLazyImage";
import {usePageCtx} from "../../../ctx/CtxPage";

export default function GridCellImage(props: ICellRendererParams<IDataGridCell>)
{
  const pageCtx = usePageCtx();
  const theme = useTheme();
  const isDialogOpen = useRef<NodeJS.Timeout>();
  const vertInner = theme.common.vertInner;
  const colId = props.colDef?.colId;
  const rowHeight = props.node.rowHeight || 0;
  const height = rowHeight - vertInner;
  const width = rowHeight - vertInner;
  const value = (colId ? props.data?.valueMap[colId] : undefined) as FieldValueCamera | undefined;

  const src = value?.value;
  const mediaIdImage = src?.mediaIdImage;
  const mediaIdBlurImage = src?.mediaIdBlurImage;

  const tempImgUrl = mediaIdImage ? MediaStore.getMedia(mediaIdImage) : undefined;
  const tempBlurImgUrl = mediaIdBlurImage ? MediaStore.getMedia(mediaIdBlurImage) : undefined;

  const imgUrl = tempImgUrl || getMediaSrc(mediaIdImage);
  const blurImgUrl = tempBlurImgUrl || getMediaSrc(mediaIdBlurImage);

  const onClickImage = useCallback((e: React.MouseEvent<HTMLElement>) =>
  {
    if(e.detail === 2)
    {
      clearTimeout(isDialogOpen.current);
      return;
    }
    isDialogOpen.current = setTimeout(() =>
    {
      if(src)
      {
        pageCtx.showDialog(
          <ImageViewDialog
            srcBlur={blurImgUrl}
            objectFit={"cover"}
            bgColor={src.primaryColor}
            src={imgUrl}
            onClose={() =>
            {
              pageCtx.showDialog(undefined);
            }}
          />);
      }
    }, theme.common.durationTransition);
  }, [blurImgUrl, imgUrl, pageCtx, src, theme.common.durationTransition]);

  if(mediaIdImage)
  {
    return <RawLazyImage
      src={imgUrl}
      srcBlur={blurImgUrl}
      primaryColor={src.primaryColor}
      alt={src.mediaIdImage}
      width={px(height)}
      height={px(width)}
      objectFit={"fill"}
      onClick={onClickImage}
    />;
  }
  return null;
}

