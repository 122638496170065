import {FieldValues} from "react-hook-form";
import {DefnFieldPickEnum} from "../../../../../api/meta/base/dto/DefnFieldPickEnum";
import {DefnFieldSwitch} from "../../../../../api/meta/base/dto/DefnFieldSwitch";
import {DefnSection} from "../../../../../api/meta/base/dto/DefnSection";
import {DefnStudioBuildColor} from "../../../../../api/meta/base/dto/DefnStudioBuildColor";
import {DefnStudioPickFieldId} from "../../../../../api/meta/base/dto/DefnStudioPickFieldId";
import {DefnStudioPickImportPluginApiId} from "../../../../../api/meta/base/dto/DefnStudioPickImportPluginApiId";
import {DefnStudioPickImportPluginId} from "../../../../../api/meta/base/dto/DefnStudioPickImportPluginId";
import {DefnStudioPickReportId} from "../../../../../api/meta/base/dto/DefnStudioPickReportId";
import {DefnStudioPickSpreadsheetId} from "../../../../../api/meta/base/dto/DefnStudioPickSpreadsheetId";
import {DefnStudioPickVarId} from "../../../../../api/meta/base/dto/DefnStudioPickVarId";
import {DefnTab} from "../../../../../api/meta/base/dto/DefnTab";
import {StudioFieldButton} from "../../../../../api/meta/base/dto/StudioFieldButton";
import {StudioVarMap} from "../../../../../api/meta/base/dto/StudioVarMap";
import {Position} from "../../../../../api/meta/base/StudioSetsFieldType";
import {IconPosition} from "../../../../../api/meta/base/StudioSetsFieldType";
import {LayoutFormContentItem} from "../../../../../api/meta/base/StudioSetsFieldType";
import {EnumDefnButtonTargetType} from "../../../../../api/meta/base/Types";
import {MetaIdComposite} from "../../../../../api/meta/base/Types";
import {EnumDefnPlacement} from "../../../../../api/meta/base/Types";
import {MetaIdField} from "../../../../../api/meta/base/Types";
import {EnumArrayDefnKindButton} from "../../../../../api/meta/base/Types";
import {MetaIdPlugin} from "../../../../../api/meta/base/Types";
import {MetaIdForm} from "../../../../../api/meta/base/Types";
import {stringToDefnDtoText} from "../../../../../base/plus/ArgBinderPlus";
import {fnFieldValueToRawValue} from "../../../../../base/plus/FieldValuePlus";
import {fnRawValueToFieldValue} from "../../../../../base/plus/FieldValuePlus";
import {getExcludeVarIdSet} from "../../../../../base/plus/FormPlus";
import {px} from "../../../../../base/plus/StringPlus";
import {IResolvedIOFormNames} from "../../../../../base/plus/StudioPlus";
import {IExcludeMappingVars} from "../../../../../base/plus/StudioPlus";
import {gapStd} from "../../../../../base/plus/ThemePlus";
import {getFieldBuilderPropertySubTabs} from "../../base/FieldBuilderFactory";
import {propKeyBackgroundColor} from "../../base/TypesFormBuilder";
import {propKeyButtonVariant} from "../../base/TypesFormBuilder";
import {propKeyIconValue} from "../../base/TypesFormBuilder";
import {fieldReport} from "../../base/TypesFormBuilder";
import {propKeyButtonReportOutputMappingVarId} from "../../base/TypesFormBuilder";
import {propKeyButtonReportInputMappingVarId} from "../../base/TypesFormBuilder";
import {propKeyTargetType} from "../../base/TypesFormBuilder";
import {propKeyTextSizeFieldId} from "../../base/TypesFormBuilder";
import {propKeyDisabledElevationFieldId} from "../../base/TypesFormBuilder";
import {propKeyIconPosition} from "../../base/TypesFormBuilder";
import {propKeyTextSize} from "../../base/TypesFormBuilder";
import {propKeyButtonPosition} from "../../base/TypesFormBuilder";
import {propKeyDisabledElevation} from "../../base/TypesFormBuilder";
import {fieldGap1} from "../../base/TypesFormBuilder";
import {propKeyButtonPositionVar} from "../../base/TypesFormBuilder";
import {propKeyButtonSpreadsheetMappingVarId} from "../../base/TypesFormBuilder";
import {propKeyPluginErrorField} from "../../base/TypesFormBuilder";
import {propKeyButtonPluginOutputMappingVarId} from "../../base/TypesFormBuilder";
import {propKeyButtonPluginInputMappingVarId} from "../../base/TypesFormBuilder";
import {propKeyButtonSpreadsheetId} from "../../base/TypesFormBuilder";
import {propKeyPlugin} from "../../base/TypesFormBuilder";
import {propKeyActionClickButtonFields} from "../../base/TypesFormBuilder";
import {propKeyVarIcon} from "../../base/TypesFormBuilder";
import {propKeyActionPluginSection} from "../../base/TypesFormBuilder";
import {propKeyActionFormSection} from "../../base/TypesFormBuilder";
import {propKeyActionLinkSection} from "../../base/TypesFormBuilder";
import {propKeyActionTab} from "../../base/TypesFormBuilder";
import {propKeyPluginApi} from "../../base/TypesFormBuilder";
import {propKeyExecuteActionsAfterTargetSuccess} from "../../base/TypesFormBuilder";
import {propKeyActionCloseAside} from "../../base/TypesFormBuilder";
import {propKeyOpenLinkNewTabVar} from "../../base/TypesFormBuilder";
import {propKeyOpenLinkSameTabVar} from "../../base/TypesFormBuilder";
import {propKeyOpenLinkNewTabField} from "../../base/TypesFormBuilder";
import {propKeyOpenLinkSameTabField} from "../../base/TypesFormBuilder";
import {propKeyActionToggleBooleanFields} from "../../base/TypesFormBuilder";
import {propKeyActionDefaultFields} from "../../base/TypesFormBuilder";
import {propKeyActionVisibleFields} from "../../base/TypesFormBuilder";
import {propKeyActionInvisibleFields} from "../../base/TypesFormBuilder";
import {propKeyActionEnableFields} from "../../base/TypesFormBuilder";
import {propKeyActionDisableFields} from "../../base/TypesFormBuilder";
import {fieldGap4} from "../../base/TypesFormBuilder";
import {propKeyBackgroundColorVar} from "../../base/TypesFormBuilder";
import {propKeyIconPositionVar} from "../../base/TypesFormBuilder";
import {propKeyTextSizeVar} from "../../base/TypesFormBuilder";
import {propKeyButtonVariantVar} from "../../base/TypesFormBuilder";
import {fieldGap3} from "../../base/TypesFormBuilder";
import {propKeyDisabledElevationVar} from "../../base/TypesFormBuilder";
import {propKeyAddToMenu} from "../../base/TypesFormBuilder";
import {propKeyAddToFooter} from "../../base/TypesFormBuilder";
import {propKeyAddToToolBar} from "../../base/TypesFormBuilder";
import {propKeyDoNotInLine} from "../../base/TypesFormBuilder";
import {propKeyButtonKind} from "../../base/TypesFormBuilder";
import {fieldGap2} from "../../base/TypesFormBuilder";
import {getFieldGap} from "../../base/TypesFormBuilder";

export function getDefnFieldButton(
  formId: MetaIdForm,
  fieldId?: MetaIdField,
  showIconPosition?: boolean,
  varMap?: StudioVarMap,
  sectionIdSetWithCurrentGridId?: MetaIdComposite[]
)
{
  const fieldIds = [
    propKeyDisabledElevationFieldId,
    propKeyTextSizeFieldId
  ];

  const varIds = [
    propKeyDisabledElevationVar,
    propKeyButtonVariantVar,
    propKeyButtonPositionVar,
    propKeyTextSizeVar,
    propKeyVarIcon,
    propKeyBackgroundColorVar,
    ...showIconPosition ? [propKeyIconPositionVar] : []
  ];

  const constIds = [
    propKeyDoNotInLine,
    propKeyAddToToolBar,
    propKeyAddToFooter,
    propKeyAddToMenu,
    fieldGap1,
    propKeyDisabledElevation,
    fieldGap2,
    propKeyIconValue,
    propKeyButtonVariant,
    propKeyButtonPosition,
    propKeyTextSize,
    propKeyBackgroundColor,
    ...showIconPosition ? [propKeyIconPosition] : [],
    fieldGap3,
    propKeyButtonKind
  ];

  return {
    ...getFieldBuilderPropertySubTabs(
      "button",
      formId,
      "buttonVariant",
      fieldId,
      fieldIds,
      varIds,
      constIds,
      undefined,
      sectionIdSetWithCurrentGridId
    ),

    [propKeyButtonVariant]: {
      type: "enumThemeButtonVariant",
      name: propKeyButtonVariant,
      metaId: propKeyButtonVariant,
      label: "Button variant"
    } as DefnFieldPickEnum,

    [propKeyDoNotInLine]: {
      type: "bool",
      metaId: propKeyDoNotInLine,
      name: "Do not inline",
      label: "Do not inline",
      showAsCheckboxVar: true
    } as DefnFieldSwitch,

    [propKeyAddToToolBar]: {
      type: "bool",
      metaId: propKeyAddToToolBar,
      name: "Add to toolbar",
      label: "Add to toolbar",
      showAsCheckboxVar: true
    } as DefnFieldSwitch,

    [propKeyAddToFooter]: {
      type: "bool",
      metaId: propKeyAddToFooter,
      name: "Add to footer",
      label: "Add to footer",
      showAsCheckboxVar: true
    } as DefnFieldSwitch,

    [propKeyAddToMenu]: {
      type: "bool",
      metaId: propKeyAddToMenu,
      name: propKeyAddToMenu,
      label: "Add to menu",
      showAsCheckboxVar: true
    } as DefnFieldSwitch,

    [propKeyDisabledElevation]: {
      type: "bool",
      metaId: propKeyDisabledElevation,
      name: propKeyDisabledElevation,
      label: "Disable elevation",
      showAsCheckboxVar: true
    } as DefnFieldSwitch,

    [propKeyDisabledElevationVar]: {
      type: "pickVarId",
      metaId: propKeyDisabledElevationVar,
      name: propKeyDisabledElevationVar,
      label: "Disable elevation",
      varKind: "bool",
      showAsEdit: true,
      formId: formId
    } as DefnStudioPickVarId,

    [propKeyDisabledElevationFieldId]: {
      type: "pickFieldId",
      metaId: propKeyDisabledElevationFieldId,
      name: propKeyDisabledElevationFieldId,
      label: "Disable elevation",
      filterFieldTypeSet: ["bool"],
      formId: formId,
      excludeFieldIdSet: fieldId ? [fieldId] : undefined,
      showCompositeName: true
    } as DefnStudioPickFieldId,

    [propKeyButtonVariantVar]: {
      type: "pickVarId",
      metaId: propKeyButtonVariantVar,
      label: "Button variant",
      varKind: "buttonVariant",
      showAsEdit: true,
      formId: formId
    } as DefnStudioPickVarId,

    [propKeyButtonPosition]: {
      type: "enumPlacement",
      metaId: propKeyButtonPosition,
      name: propKeyButtonPosition,
      label: "Button position"
    } as DefnFieldPickEnum,

    [propKeyButtonPositionVar]: {
      type: "pickVarId",
      metaId: propKeyButtonPositionVar,
      label: "Button position",
      varKind: "placement",
      showAsEdit: true,
      formId: formId,
      filterOptionSet: Position,
      excludeVarIdSet: getExcludeVarIdSet(Position as EnumDefnPlacement[], varMap)
    } as DefnStudioPickVarId,

    [propKeyTextSize]: {
      type: "textSize",
      metaId: propKeyTextSize,
      name: propKeyTextSize,
      label: "Font"
    } as DefnFieldPickEnum,

    [propKeyTextSizeVar]: {
      type: "pickVarId",
      metaId: propKeyTextSizeVar,
      name: propKeyTextSizeVar,
      label: "Font",
      varKind: "textSize",
      showAsEdit: true,
      formId: formId
    } as DefnStudioPickVarId,

    [propKeyTextSizeFieldId]: {
      type: "pickFieldId",
      metaId: propKeyTextSizeFieldId,
      name: propKeyTextSizeFieldId,
      label: "Font",
      formId: formId,
      excludeFieldIdSet: fieldId ? [fieldId] : undefined,
      filterFieldTypeSet: ["textSize"],
      showCompositeName: true,
      compositeIdSet: sectionIdSetWithCurrentGridId

    } as DefnStudioPickFieldId,

    [propKeyBackgroundColorVar]: {
      type: "pickVarId",
      metaId: propKeyBackgroundColorVar,
      label: "Background color",
      varKind: "color",
      showAsEdit: true,
      formId: formId
    } as DefnStudioPickVarId,

    ...showIconPosition && {
      [propKeyIconPosition]: {
        type: "enumPlacement",
        metaId: propKeyIconPosition,
        name: propKeyIconPosition,
        label: "Icon position"
      } as DefnFieldPickEnum,

      [propKeyIconPositionVar]: {
        type: "pickVarId",
        metaId: propKeyIconPositionVar,
        label: "Icon position",
        varKind: "placement",
        showAsEdit: true,
        formId: formId,
        filterOptionSet: IconPosition,
        excludeVarIdSet: getExcludeVarIdSet(IconPosition as EnumDefnPlacement[], varMap)
      } as DefnStudioPickVarId
    },

    [propKeyButtonKind]: {
      type: "enumKindButton",
      name: propKeyButtonKind,
      label: "Kind",
      metaId: propKeyButtonKind,
      varKind: "number",
      pb: px(gapStd),
      filterOptionSet: EnumArrayDefnKindButton.filter(item => item !== "submit")
    } as DefnFieldPickEnum,

    [propKeyBackgroundColor]: {
      type: "studioBuildColor",
      metaId: propKeyBackgroundColor,
      name: propKeyBackgroundColor,
      label: "Background color",
      allowShades: true
    } as DefnStudioBuildColor
  };
}

export function getDefnButtonActionTab(params: {
  formId?: MetaIdForm,
  isNormalFieldButtonKind?: boolean,
  targetType?: EnumDefnButtonTargetType,
  pluginId?: MetaIdPlugin,
  isPluginInputFormPresent?: boolean,
  isPluginOutputFormPresent?: boolean,
  isReportInputFormPresent?: boolean,
  isReportOutputFormPresent?: boolean,
  isPluginApiHelperTextVisible?: boolean,
  fieldId?: MetaIdField,
  notValidPluginInputFormMappingVars?: IExcludeMappingVars,
  notValidPluginOutputFormMappingVars?: IExcludeMappingVars,
  excludeButtonSpreadSheetMappingVars?: IExcludeMappingVars,
  isPluginForm?: boolean,
  helperTextSpreadsheetFormName?: string,
  helperTextPluginApiIOFormName?: IResolvedIOFormNames
})
{
  const {
    formId,
    isNormalFieldButtonKind,
    targetType,
    pluginId,
    isPluginInputFormPresent,
    isPluginOutputFormPresent,
    isReportInputFormPresent,
    isReportOutputFormPresent,
    isPluginApiHelperTextVisible,
    fieldId,
    notValidPluginInputFormMappingVars,
    notValidPluginOutputFormMappingVars,
    excludeButtonSpreadSheetMappingVars,
    isPluginForm,
    helperTextSpreadsheetFormName,
    helperTextPluginApiIOFormName
  } = params;

  return {
    ...geActionFormTab(formId, fieldId),
    ...geActionLinkTab(formId),
    ...geActionTargetTab(
      targetType,
      pluginId,
      isPluginInputFormPresent,
      isPluginOutputFormPresent,
      isReportInputFormPresent,
      isReportOutputFormPresent,
      formId,
      helperTextSpreadsheetFormName,
      helperTextPluginApiIOFormName,
      isPluginApiHelperTextVisible,
      notValidPluginInputFormMappingVars,
      notValidPluginOutputFormMappingVars,
      excludeButtonSpreadSheetMappingVars,
      isPluginForm
    ),

    [propKeyActionTab]: {
      type: "tab",
      metaId: propKeyActionTab,
      name: propKeyActionTab,
      tabIdSet: isNormalFieldButtonKind
        ? [
          propKeyActionFormSection,
          propKeyActionLinkSection,
          propKeyActionPluginSection
        ]
        : []
    } as DefnTab

  };
}

function geActionFormTab(formId?: MetaIdForm, fieldId?: MetaIdField)
{
  return {
    [propKeyActionDefaultFields]: {
      type: "studioSetOfFieldId",
      metaId: propKeyActionDefaultFields,
      label: "Set default values of fields",
      filterFieldTypeSet: LayoutFormContentItem.filter(item => item !== "button"),
      formId: formId
    } as DefnStudioPickFieldId,
    [propKeyActionToggleBooleanFields]: {
      type: "studioSetOfFieldId",
      metaId: propKeyActionToggleBooleanFields,
      label: "Toggle boolean fields",
      formId: formId,
      filterFieldTypeSet: ["bool"]
    } as DefnStudioPickFieldId,
    [propKeyActionVisibleFields]: {
      type: "studioSetOfFieldId",
      metaId: propKeyActionVisibleFields,
      label: "Make visible fields",
      formId: formId
    } as DefnStudioPickFieldId,
    [propKeyActionInvisibleFields]: {
      type: "studioSetOfFieldId",
      metaId: propKeyActionInvisibleFields,
      label: "Make invisible fields",
      formId: formId
    } as DefnStudioPickFieldId,
    [propKeyActionEnableFields]: {
      type: "studioSetOfFieldId",
      metaId: propKeyActionEnableFields,
      label: "Enable fields",
      formId: formId
    } as DefnStudioPickFieldId,
    [propKeyActionDisableFields]: {
      type: "studioSetOfFieldId",
      metaId: propKeyActionDisableFields,
      label: "Disable fields",
      formId: formId
    } as DefnStudioPickFieldId,
    ...getFieldGap(fieldGap2, "thick"),
    [propKeyActionClickButtonFields]: {
      type: "studioSetOfFieldId",
      metaId: propKeyActionClickButtonFields,
      label: "Click button fields",
      formId: formId,
      excludeFieldIdSet: [fieldId],
      filterFieldTypeSet: ["button"]
    } as DefnStudioPickFieldId,
    [propKeyActionFormSection]: {
      type: "section",
      metaId: propKeyActionFormSection,
      name: "Form",
      label: "Form",
      fieldIdSet: [
        propKeyActionDefaultFields,
        propKeyActionToggleBooleanFields,
        propKeyActionVisibleFields,
        propKeyActionInvisibleFields,
        propKeyActionEnableFields,
        propKeyActionDisableFields,
        fieldGap2,
        propKeyActionClickButtonFields
      ]
    } as DefnSection
  };
}

function geActionLinkTab(formId?: MetaIdForm)
{
  return {
    [propKeyOpenLinkNewTabVar]: {
      type: "pickVarId",
      metaId: propKeyOpenLinkNewTabVar,
      label: "Open link in new tab variable",
      showAsEdit: true,
      formId: formId,
      varKind: "hyperlink"
    } as DefnStudioPickVarId,
    [propKeyOpenLinkSameTabVar]: {
      type: "pickVarId",
      metaId: propKeyOpenLinkSameTabVar,
      label: "Open link in same tab variable",
      showAsEdit: true,
      formId: formId,
      varKind: "hyperlink"
    } as DefnStudioPickVarId,
    ...getFieldGap(fieldGap3, "thick"),
    [propKeyOpenLinkSameTabField]: {
      type: "pickFieldId",
      metaId: propKeyOpenLinkSameTabField,
      label: "Open link in same tab as per field",
      formId: formId,
      filterFieldTypeSet: ["hyperlink"],
      showCompositeName: true
    } as DefnStudioPickFieldId,
    [propKeyOpenLinkNewTabField]: {
      type: "pickFieldId",
      metaId: propKeyOpenLinkNewTabField,
      label: "Open link in new tab as per field",
      showCompositeName: true,
      formId: formId,
      filterFieldTypeSet: ["hyperlink"]
    } as DefnStudioPickFieldId,
    [propKeyActionLinkSection]: {
      type: "section",
      metaId: propKeyActionLinkSection,
      name: "Link",
      label: "Link",
      fieldIdSet: [
        propKeyOpenLinkSameTabVar,
        propKeyOpenLinkNewTabVar,
        fieldGap3,
        propKeyOpenLinkSameTabField,
        propKeyOpenLinkNewTabField
      ]
    } as DefnSection
  };
}

function geActionTargetTab(
  targetType?: EnumDefnButtonTargetType,
  pluginId?: MetaIdPlugin,
  isPluginInputFormPresent?: boolean,
  isPluginOutputFormPresent?: boolean,
  isReportInputFormPresent?: boolean,
  isReportOutputFormPresent?: boolean,
  formId?: MetaIdForm,
  helperTextSpreadsheetFormName?: string,
  helperTextPluginApiIOFormName?: IResolvedIOFormNames,
  isPluginApiHelperTextVisible?: boolean,
  notValidPluginInputFormMappingVars?: IExcludeMappingVars,
  notValidPluginOutputFormMappingVars?: IExcludeMappingVars,
  excludeButtonSpreadSheetMappingVars?: IExcludeMappingVars,
  isPluginForm?: boolean)
{

  return {
    [propKeyTargetType]: {
      type: "enumTargetType",
      name: propKeyTargetType,
      metaId: propKeyTargetType,
      label: "Target type"
    } as DefnFieldPickEnum,

    [propKeyButtonSpreadsheetId]: {
      type: "pickSpreadsheetId",
      metaId: propKeyButtonSpreadsheetId,
      name: propKeyButtonSpreadsheetId,
      label: "Spreadsheet",
      helperTextVar: stringToDefnDtoText(helperTextSpreadsheetFormName
        ? `Spreadsheet form: ${helperTextSpreadsheetFormName}`
        : "")
    } as DefnStudioPickSpreadsheetId,

    [propKeyButtonSpreadsheetMappingVarId]: {
      type: "pickVarId",
      metaId: propKeyButtonSpreadsheetMappingVarId,
      name: propKeyButtonSpreadsheetMappingVarId,
      label: "Spreadsheet mapping variable",
      varKind: "mapping",
      showAsEdit: true,
      formId: formId,
      disabled: !excludeButtonSpreadSheetMappingVars?.src
        || !excludeButtonSpreadSheetMappingVars?.target,
      excludeVarIdSet: excludeButtonSpreadSheetMappingVars?.excludeIdSet
    } as DefnStudioPickVarId,

    ...getFieldGap(fieldGap4, "thick"),

    [propKeyPlugin]: {
      type: "pickImportPluginId",
      metaId: propKeyPlugin,
      label: "Plugin"
    } as DefnStudioPickImportPluginId,

    [propKeyPluginApi]: {
      type: "pickImportPluginApiId",
      metaId: propKeyPluginApi,
      label: "Plugin API",
      metaIdPlugin: pluginId,
      helperTextVar: isPluginApiHelperTextVisible
        ? stringToDefnDtoText(`Input form: ${helperTextPluginApiIOFormName?.inputFormName
        ?? "None"}, Output form: ${helperTextPluginApiIOFormName?.outputFormName ?? "None"}`)
        : undefined
    } as DefnStudioPickImportPluginApiId,

    ...isPluginInputFormPresent && {
      [propKeyButtonPluginInputMappingVarId]: {
        type: "pickVarId",
        metaId: propKeyButtonPluginInputMappingVarId,
        name: propKeyButtonPluginInputMappingVarId,
        label: "Plugin input mapping variable",
        varKind: "mapping",
        showAsEdit: true,
        formId: formId,
        excludeVarIdSet: notValidPluginInputFormMappingVars?.excludeIdSet,
        disabled: !notValidPluginInputFormMappingVars?.src
          || !notValidPluginInputFormMappingVars?.target
      } as DefnStudioPickVarId
    },

    ...isPluginOutputFormPresent && {
      [propKeyButtonPluginOutputMappingVarId]: {
        type: "pickVarId",
        metaId: propKeyButtonPluginOutputMappingVarId,
        name: propKeyButtonPluginOutputMappingVarId,
        label: "Plugin output mapping variable",
        varKind: "mapping",
        showAsEdit: true,
        required: true,
        formId: formId,
        disabled: !notValidPluginOutputFormMappingVars?.src
          || !notValidPluginOutputFormMappingVars?.target,
        excludeVarIdSet: notValidPluginOutputFormMappingVars?.excludeIdSet
      } as DefnStudioPickVarId
    },

    [propKeyPluginErrorField]: {
      type: "pickFieldId",
      name: propKeyPluginErrorField,
      metaId: propKeyPluginErrorField,
      label: "Plugin error field",
      filterFieldTypeSet: ["error"],
      showCompositeName: true,
      formId: formId
    } as DefnStudioPickFieldId,

    [fieldReport]: {
      type: "pickReportId",
      name: fieldReport,
      metaId: fieldReport,
      label: "Report"
    } as DefnStudioPickReportId,

    ...isReportInputFormPresent && {
      [propKeyButtonReportInputMappingVarId]: {
        type: "pickVarId",
        metaId: propKeyButtonReportInputMappingVarId,
        name: propKeyButtonReportInputMappingVarId,
        label: "Report input mapping variable",
        varKind: "mapping",
        showAsEdit: true,
        formId: formId
      } as DefnStudioPickVarId
    },

    ...isReportOutputFormPresent && {
      [propKeyButtonReportOutputMappingVarId]: {
        type: "pickVarId",
        metaId: propKeyButtonReportOutputMappingVarId,
        name: propKeyButtonReportOutputMappingVarId,
        label: "Report output mapping variable",
        varKind: "mapping",
        showAsEdit: true,
        formId: formId
      } as DefnStudioPickVarId
    },

    ...(targetType === "invokePlugin" || targetType === "callReport") && {
      [propKeyExecuteActionsAfterTargetSuccess]: {
        type: "bool",
        metaId: propKeyExecuteActionsAfterTargetSuccess,
        name: propKeyExecuteActionsAfterTargetSuccess,
        label: targetType === "invokePlugin"
          ? `Execute actions after plugin success`
          : `Execute actions after report success`,
        showAsCheckboxVar: true
      } as DefnFieldSwitch
    },

    [propKeyActionPluginSection]: {
      type: "section",
      metaId: propKeyActionPluginSection,
      name: propKeyActionPluginSection,
      label: "Target",
      fieldIdSet: [
        propKeyTargetType,
        ...targetType === "saveToSpreadsheet"
          ? [
            fieldGap4,
            propKeyButtonSpreadsheetId,
            propKeyButtonSpreadsheetMappingVarId
          ]
          : [],
        ...!isPluginForm && targetType === "invokePlugin"
          ? [
            fieldGap4,
            propKeyPlugin,
            propKeyPluginApi,
            ...isPluginInputFormPresent
              ? [propKeyButtonPluginInputMappingVarId]
              : [],
            ...isPluginOutputFormPresent
              ? [propKeyButtonPluginOutputMappingVarId]
              : [],
            propKeyPluginErrorField
          ]
          : [],
        ...targetType === "callReport"
          ? [
            fieldGap4, fieldReport,
            ...isReportInputFormPresent
              ? [propKeyButtonReportInputMappingVarId]
              : [],
            ...isReportOutputFormPresent
              ? [propKeyButtonReportOutputMappingVarId]
              : []
          ]
          : [],
        ...(targetType === "invokePlugin" || targetType === "callReport")
          ? [propKeyExecuteActionsAfterTargetSuccess]
          : []
      ]
    } as DefnSection
  };
}

export function defnValueToStudioFieldButton(values: FieldValues): StudioFieldButton
{
  return {
    type: "button",
    buttonKind: fnFieldValueToRawValue("enumKindButton", values[propKeyButtonKind]),
    btnPosDoNotInline: fnFieldValueToRawValue("bool", values[propKeyDoNotInLine]),
    btnPosToolbar: fnFieldValueToRawValue("bool", values[propKeyAddToToolBar]),
    btnPosMenu: fnFieldValueToRawValue("bool", values[propKeyAddToMenu]),
    btnPosFooter: fnFieldValueToRawValue("bool", values[propKeyAddToFooter]),
    disabledElevation: fnFieldValueToRawValue("bool", values[propKeyDisabledElevation]),
    disableElevationVarId: fnFieldValueToRawValue("pickVarId", values[propKeyDisabledElevationVar]),
    disableElevationFieldId: fnFieldValueToRawValue("pickFieldId", values[propKeyDisabledElevationFieldId]),
    buttonVariantVarId: fnFieldValueToRawValue("pickVarId", values[propKeyButtonVariantVar]),
    buttonPosition: fnFieldValueToRawValue("enumPlacement", values[propKeyButtonPosition]),
    buttonPositionVarId: fnFieldValueToRawValue("pickVarId", values[propKeyButtonPositionVar]),
    textSize: fnFieldValueToRawValue("textSize", values[propKeyTextSize]),
    textSizeVarId: fnFieldValueToRawValue("pickVarId", values[propKeyTextSizeVar]),
    textSizeFieldId: fnFieldValueToRawValue("pickFieldId", values[propKeyTextSizeFieldId]),
    iconVarId: fnFieldValueToRawValue("pickVarId", values[propKeyVarIcon]),
    iconPosition: fnFieldValueToRawValue("enumPlacement", values[propKeyIconPosition]),
    iconPositionVarId: fnFieldValueToRawValue("pickVarId", values[propKeyIconPositionVar]),
    bgColorVarId: fnFieldValueToRawValue("pickVarId", values[propKeyBackgroundColorVar]),
    buttonVariant: fnFieldValueToRawValue("enumThemeButtonVariant", values[propKeyButtonVariant]),
    bgColor: fnFieldValueToRawValue("studioBuildColor", values[propKeyBackgroundColor]),
    ...defnValueToStudioFieldButtonAction(values)
  } as StudioFieldButton;
}

export function studioFieldToDefnValueButton(studioField: StudioFieldButton)
{
  return {
    [propKeyButtonKind]: fnFieldValueToRawValue("enumKindButton", studioField.buttonKind),
    [propKeyDoNotInLine]: fnRawValueToFieldValue("bool", studioField.btnPosDoNotInline),
    [propKeyAddToToolBar]: fnRawValueToFieldValue("bool", studioField.btnPosToolbar),
    [propKeyAddToMenu]: fnRawValueToFieldValue("bool", studioField.btnPosMenu),
    [propKeyAddToFooter]: fnRawValueToFieldValue("bool", studioField.btnPosFooter),
    [propKeyDisabledElevation]: fnRawValueToFieldValue("bool", studioField.disabledElevation),
    [propKeyDisabledElevationVar]: fnRawValueToFieldValue("pickVarId", studioField.disableElevationVarId),
    [propKeyDisabledElevationFieldId]: fnRawValueToFieldValue("pickFieldId", studioField.disableElevationFieldId),
    [propKeyButtonVariantVar]: fnRawValueToFieldValue("pickVarId", studioField.buttonVariantVarId),
    [propKeyButtonPosition]: fnRawValueToFieldValue("enumPlacement", studioField.buttonPosition),
    [propKeyButtonPositionVar]: fnRawValueToFieldValue("pickVarId", studioField.buttonPositionVarId),
    [propKeyTextSize]: fnRawValueToFieldValue("textSize", studioField.textSize),
    [propKeyTextSizeVar]: fnRawValueToFieldValue("pickVarId", studioField.textSizeVarId),
    [propKeyTextSizeFieldId]: fnRawValueToFieldValue("pickFieldId", studioField.textSizeFieldId),
    [propKeyVarIcon]: fnRawValueToFieldValue("pickVarId", studioField.iconVarId),
    [propKeyIconPosition]: fnRawValueToFieldValue("enumPlacement", studioField.iconPosition),
    [propKeyIconPositionVar]: fnRawValueToFieldValue("pickVarId", studioField.iconPositionVarId),
    [propKeyBackgroundColorVar]: fnRawValueToFieldValue("pickVarId", studioField.bgColorVarId),
    [propKeyBackgroundColor]: fnRawValueToFieldValue("pickVarId", studioField.bgColor),
    [propKeyButtonVariant]: fnRawValueToFieldValue("enumThemeButtonVariant", studioField.buttonVariant),
    ...studioFieldToDefnValueButtonAction(studioField)
  };
}

export function defnValueToStudioFieldButtonAction(values: FieldValues): StudioFieldButton
{
  const targetType = fnFieldValueToRawValue("enumTargetType",
    values[propKeyTargetType]
  ) as EnumDefnButtonTargetType;
  const pluginApi = Boolean(values[propKeyPlugin] && values[propKeyPluginApi]);

  return {
    actionCloseAside: fnFieldValueToRawValue("bool", values[propKeyActionCloseAside]),
    actionDisableFieldIdArray: values[propKeyActionDisableFields],
    actionEnableFieldIdArray: values[propKeyActionEnableFields],
    actionInvisibleFieldIdArray: values[propKeyActionInvisibleFields],
    actionVisibleFieldIdArray: values[propKeyActionVisibleFields],
    actionClickButtonFieldIdArray: values[propKeyActionClickButtonFields],
    actionSetDefaultFieldIdArray: values[propKeyActionDefaultFields],
    actionToggleBooleanFieldIdArray: values[propKeyActionToggleBooleanFields],
    actionOpenLinkInSameTabFieldId: values[propKeyOpenLinkSameTabField],
    actionOpenLinkInNewTabFieldId: values[propKeyOpenLinkNewTabField],
    actionOpenLinkInNewTabVarId: values[propKeyOpenLinkNewTabVar],
    actionOpenLinkInSameTabVarId: values[propKeyOpenLinkSameTabVar],
    spreadsheetId: undefined,
    spreadsheetMappingVarId: undefined,
    executeActionsAfterTargetSuccess: undefined,
    pluginApi: undefined,
    pluginInputMappingVarId: undefined,
    pluginOutputMappingVarId: undefined,
    pluginErrorFieldId: undefined,
    reportId: undefined,
    reportInputMappingVarId: undefined,
    reportOutputMappingVarId: undefined,

    targetType: targetType,

    ...targetType === "saveToSpreadsheet" && {
      spreadsheetId: values[propKeyButtonSpreadsheetId],
      spreadsheetMappingVarId: values[propKeyButtonSpreadsheetMappingVarId]
    },

    ...targetType === "invokePlugin" && {
      executeActionsAfterTargetSuccess: fnFieldValueToRawValue("bool", values[propKeyExecuteActionsAfterTargetSuccess]),
      pluginApi: pluginApi
        ? {
          pluginApiId: values[propKeyPluginApi],
          metaIdPlugin: values[propKeyPlugin]
        }
        : undefined,
      pluginInputMappingVarId: values[propKeyButtonPluginInputMappingVarId],
      pluginOutputMappingVarId: values[propKeyButtonPluginOutputMappingVarId],
      pluginErrorFieldId: values[propKeyPluginErrorField]
    },

    ...targetType === "callReport" && {
      reportId: fnFieldValueToRawValue("pickReportId", values[fieldReport]),
      reportInputMappingVarId: values[propKeyButtonReportInputMappingVarId],
      reportOutputMappingVarId: values[propKeyButtonReportOutputMappingVarId]
    }

  } as StudioFieldButton;
}

export function studioFieldToDefnValueButtonAction(studioField: StudioFieldButton)
{
  return {
    [propKeyActionCloseAside]: fnRawValueToFieldValue("bool", studioField.actionCloseAside),
    [propKeyActionDisableFields]: studioField.actionDisableFieldIdArray,
    [propKeyActionEnableFields]: studioField.actionEnableFieldIdArray,
    [propKeyActionInvisibleFields]: studioField.actionInvisibleFieldIdArray,
    [propKeyActionVisibleFields]: studioField.actionVisibleFieldIdArray,
    [propKeyActionClickButtonFields]: studioField.actionClickButtonFieldIdArray,
    [propKeyActionDefaultFields]: studioField.actionSetDefaultFieldIdArray,
    [propKeyActionToggleBooleanFields]: studioField.actionToggleBooleanFieldIdArray,
    [propKeyOpenLinkSameTabField]: studioField.actionOpenLinkInSameTabFieldId,
    [propKeyOpenLinkNewTabField]: studioField.actionOpenLinkInNewTabFieldId,
    [propKeyOpenLinkNewTabVar]: studioField.actionOpenLinkInNewTabVarId,
    [propKeyOpenLinkSameTabVar]: studioField.actionOpenLinkInSameTabVarId,
    [propKeyExecuteActionsAfterTargetSuccess]: fnRawValueToFieldValue("bool",
      studioField.executeActionsAfterTargetSuccess
    ),

    [propKeyTargetType]: fnRawValueToFieldValue("enumTargetType", studioField.targetType),

    [propKeyButtonSpreadsheetId]: studioField.spreadsheetId,
    [propKeyButtonSpreadsheetMappingVarId]: studioField.spreadsheetMappingVarId,

    [propKeyPlugin]: studioField.pluginApi?.metaIdPlugin,
    [propKeyPluginApi]: studioField.pluginApi?.pluginApiId,
    [propKeyButtonPluginInputMappingVarId]: studioField.pluginInputMappingVarId,
    [propKeyButtonPluginOutputMappingVarId]: studioField.pluginOutputMappingVarId,
    [propKeyPluginErrorField]: studioField.pluginErrorFieldId,

    [fieldReport]: studioField.reportId,
    [propKeyButtonReportInputMappingVarId]: studioField.reportInputMappingVarId,
    [propKeyButtonReportOutputMappingVarId]: studioField.reportOutputMappingVarId
  };
}
