import {Property} from "csstype";
import {ReactNode} from "react";
import React from "react";
import {useContext} from "react";
import {createContext} from "react";
import {Control} from "react-hook-form";
import {DefnComp} from "../../../../api/meta/base/dto/DefnComp";
import {DefnDtoFormTheme} from "../../../../api/meta/base/dto/DefnDtoFormTheme";
import {DefnFieldEditable} from "../../../../api/meta/base/dto/DefnFieldEditable";
import {MetaIdComp} from "../../../../api/meta/base/Types";
import {MetaIdField} from "../../../../api/meta/base/Types";
import {SelectCalendar} from "../../../../base/plus/CalendarPlus";
import {SelectKanban} from "../../../../base/plus/KanbanPlus";
import {SelectList} from "../../../../base/plus/ListPlus";
import {TypeFieldOptionResponse} from "../../../../base/types/TypesForm";
import {IDefnDynamicFieldArgs} from "../../../../base/types/TypesForm";
import {CbFieldRemoteClick} from "../../../../base/types/TypesForm";
import {IDefnFormVisibilityOptions} from "../../../../base/types/TypesForm";
import {TypeFocusFieldOptions} from "../../../../base/types/TypesForm";
import {FormStore} from "../../../../base/types/TypesForm";
import {FormClickVariant} from "../../../../base/types/TypesForm";
import {IMenuProps} from "../../../atom/raw/RawMenu";
import {IFieldTooltip} from "../../base/FormHooks";
import {FieldValueFormListItem} from "../uiOnly/FieldFormList";
import {FieldValueFormList} from "../uiOnly/FieldFormList";

export type CbOnClickForm = (
  metaId: MetaIdField,
  variant: FormClickVariant,
  value?: any,
  menuAnchor?: Element,
  menuProps?: IMenuProps,
  overlayNode?: ReactNode
) => void
export type CbOnGetFieldOptions = (key: MetaIdField, cb: (options?: TypeFieldOptionResponse) => void) => void

export interface IFormFieldListApis
{
  updateItem: (itemId: string, value?: FieldValueFormListItem, replace?: boolean) => void;
  updateItems: (value?: FieldValueFormList | null) => void;
  appendItems: (value?: FieldValueFormList | null) => void;
  refresh: () => void;
}

export default interface IFormCtx
{
  control(): Control;

  getCbFieldRemoteClick(key: MetaIdField): CbFieldRemoteClick | undefined;

  getDefnTheme(): DefnDtoFormTheme;

  getFieldIdHyperlinkRow(key: MetaIdField): MetaIdField | undefined;

  getDefnComp(key: MetaIdField): DefnComp | undefined;

  getFieldVisibilityOption(key: MetaIdComp): IDefnFormVisibilityOptions | undefined;

  getFieldDynamicFieldArgs(key: MetaIdField): IDefnDynamicFieldArgs | undefined;

  getFocusField(): TypeFocusFieldOptions | undefined;

  getOnClick(): CbOnClickForm | undefined;

  getOnGetFieldOptions(): CbOnGetFieldOptions | undefined;

  getSelectList(key: MetaIdField): SelectList | undefined;

  getSelectKanban(key: MetaIdField): SelectKanban | undefined;

  getSelectCalendar(key: MetaIdField): SelectCalendar | undefined;

  getShakeField(key: MetaIdField): number | undefined;

  getStore(): FormStore | undefined;

  getTooltipMap(key: MetaIdField): IFieldTooltip;

  getSearchText(): string | undefined;

  isFirstField(key: MetaIdField, parentKey?: MetaIdField): boolean;

  isDirty(): boolean;

  isFieldDisable(defn: DefnFieldEditable): boolean;

  isFieldReadonly(defn: DefnFieldEditable): boolean;

  isLastField(key: MetaIdField, parentKey?: MetaIdField): boolean;

  isLastFieldInDisplay(key: MetaIdField): boolean;

  isReadonly(): boolean;

  isSubmitting(): boolean;

  isValid(): boolean;

  resetField(key: MetaIdField): void;

  setCbFieldRemoteClick(key: MetaIdField, cb: CbFieldRemoteClick): void;

  setFieldVisibilityOption(key: MetaIdField, options?: IDefnFormVisibilityOptions): void;

  setFocusField(options?: TypeFocusFieldOptions): void;

  setFieldDisable(key: MetaIdField, disable?: boolean): void;

  setFieldFormListApis(key: MetaIdField, props: IFormFieldListApis): void;

  getFieldBorderColor(key: MetaIdField): Property.BorderColor | undefined;

  setRefChildParentId(childId: MetaIdField, parentId: MetaIdField): void;

  getRefChildParentId(key: MetaIdField): MetaIdField | undefined;

  getParentMap(): Record<MetaIdField, MetaIdField>;
}

const ctxForm = createContext({} as IFormCtx);

export const useFormCtx = () =>
{
  return useContext(ctxForm);
};

export function FormCtxProvider(props: {
  ctx: IFormCtx,
  children: ReactNode
})
{
  return (
    <ctxForm.Provider value={props.ctx}>
      {props.children}
    </ctxForm.Provider>
  );
}
