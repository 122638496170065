import {DeleteRounded} from "@mui/icons-material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import RefreshIcon from "@mui/icons-material/Refresh";
import {IconButton} from "@mui/material";
import {isEmpty} from "lodash";
import {useCallback} from "react";
import {useState} from "react";
import {useEffect} from "react";
import React from "react";
import {useFormContext} from "react-hook-form";
import {DefnField} from "../../../../api/meta/base/dto/DefnField";
import {DefnFieldRef} from "../../../../api/meta/base/dto/DefnFieldRef";
import {px} from "../../../../base/plus/StringPlus";
import {gapQuarter} from "../../../../base/plus/ThemePlus";
import {gapHalf} from "../../../../base/plus/ThemePlus";
import {stripIconSx} from "../../../../base/plus/ThemePlus";
import theme from "../../../../base/plus/ThemePlus";
import LayoutFlexRow from "../../../atom/layout/LayoutFlexRow";
import RawTooltip from "../../../atom/raw/RawTooltip";
import {useFormCtx} from "../base/CtxForm";
import {useFormSectionCtx} from "../base/CtxFormSection";

type typeRefButton =
  | "add"
  | "refresh"
  | "delete"

export default function FieldRawRefButton(props: {
  defn: DefnField,
})
{
  const formCtx = useFormCtx();
  const formSectionCtx = useFormSectionCtx();
  const hookFormCtx = useFormContext();

  const defn = props.defn;

  const [showDeleteIcon, setShowDeleteIcon] = useState<boolean>(false);

  const fieldId = defn.metaId;
  const cbOnClick = formCtx.getOnClick();
  const parentRefId = formCtx.getRefChildParentId && formCtx.getRefChildParentId(fieldId);
  const tooltip = formCtx.getTooltipMap && formCtx.getTooltipMap(fieldId);
  const defnFormUi = formSectionCtx.getDefnForm();
  const fieldRef = parentRefId
    ? defnFormUi.compMap[parentRefId]
    : undefined;
  const isFieldRef = fieldRef?.type === "ref";
  const values = hookFormCtx.getValues && hookFormCtx.getValues();
  const canCreateRecord = Boolean(isFieldRef && (fieldRef as DefnFieldRef).canCreateRefRecord);
  const showRefreshBtnOnFieldIdSet = (fieldRef as DefnFieldRef)?.showRefreshOnFieldIdSet;
  const copyFieldMap = (fieldRef as DefnFieldRef)?.copyFieldMap;
  const isRefreshOnDemand = Boolean(isFieldRef && (fieldRef as DefnFieldRef).refreshOn === "refreshOnDemand");
  const refCopyFieldId = copyFieldMap ? copyFieldMap[fieldId] : undefined;
  const isFirstRefField = copyFieldMap
    && Object.keys(copyFieldMap).length > 0
    && Object.keys(copyFieldMap)[0] === fieldId;

  let showRefreshButton = false;
  if(showRefreshBtnOnFieldIdSet !== undefined
    && refCopyFieldId !== undefined
    && showRefreshBtnOnFieldIdSet.includes(refCopyFieldId))
  {
    showRefreshButton = true;
  }
  else if(showRefreshBtnOnFieldIdSet !== undefined && isRefreshOnDemand)
  {
    showRefreshButton = false;
  }
  else if(isRefreshOnDemand)
  {
    showRefreshButton = true;
  }

  const onClick = useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>, icon: typeRefButton) =>
  {
    if(icon === "add")
    {
      event.stopPropagation();
      cbOnClick && cbOnClick(fieldId, "refAddNew");
    }
    else if(icon === "refresh")
    {
      event.stopPropagation();
      cbOnClick && cbOnClick(fieldId, "refRefresh");
    }
    else if(icon === "delete" && parentRefId)
    {
      event.stopPropagation();
      cbOnClick && cbOnClick(parentRefId, "refDelete");
      setShowDeleteIcon(false);
    }

  }, [cbOnClick, fieldId, parentRefId]);

  useEffect(() =>
  {
    if(copyFieldMap && values && !showDeleteIcon && isFirstRefField)
    {
      Object.keys(copyFieldMap).forEach(fieldId =>
      {
        if(!isEmpty(values[fieldId]))
        {
          setShowDeleteIcon(true);
          return;
        }
      });
    }

  }, [copyFieldMap, values]);

  return <RawRefButton
    cbOnClick={onClick}
    canCreateRecord={canCreateRecord && isFirstRefField}
    isShowRefreshBtn={showRefreshButton}
    tooltip={tooltip?.plusButton}
    showDeleteIcon={showDeleteIcon && isFirstRefField}
  />;
}

function RawRefButton(props: {
  disabled?: boolean,
  canCreateRecord?: boolean,
  isShowRefreshBtn?: boolean,
  showDeleteIcon?: boolean,
  tooltip?: string,
  cbOnClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, icon: typeRefButton) => void
})
{
  const canCreateRecord = props.canCreateRecord;
  const disabled = props.disabled;
  const onClick = props.cbOnClick;
  const isRefreshOnDemand = props.isShowRefreshBtn;
  const showDeleteIcon = props.showDeleteIcon;

  const sx = stripIconSx(disabled
    ? theme.common.color("textDisabled")
    : undefined);

  if(!(canCreateRecord || isRefreshOnDemand || showDeleteIcon))
  {
    return null;
  }

  return (
    <LayoutFlexRow
      ml={px(gapHalf)}
      mr={`-${gapHalf}px`}
    >
      {isRefreshOnDemand &&
        <IconButton
          size={"small"}
          sx={{
            mr: px(gapQuarter)

          }}
          onClick={(e) => onClick && onClick(e, "refresh")}
          disabled={disabled}
        >
          <RefreshIcon sx={sx} />
        </IconButton>
      }

      {canCreateRecord &&
        <RawTooltip
          title={props.tooltip}
        >
          <IconButton
            size={"small"}
            sx={{
              mr: px(gapQuarter)
            }}
            onClick={(e) => onClick && onClick(e, "add")}
            disabled={disabled}
          >
            <AddOutlinedIcon sx={sx} />
          </IconButton>
        </RawTooltip>
      }

      {showDeleteIcon &&
        <IconButton
          size={"small"}
          sx={{
            mr: px(gapQuarter)
          }}
          onClick={(e) => onClick && onClick(e, "delete")}
          disabled={disabled}
        >
          <DeleteRounded sx={sx} />
        </IconButton>
      }

    </LayoutFlexRow>
  );
}
