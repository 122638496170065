import {useTheme} from "@mui/material";
import React from "react";
import {DtoMessagePayloadAudio} from "../../../../api/home/base/dto/DtoMessagePayloadAudio";
import {DtoMessageReplyPayload} from "../../../../api/home/base/dto/DtoMessageReplyPayload";
import {EnumReceiptStatus} from "../../../../api/home/base/Types";
import {formatCaptionOnlyTime} from "../../../../base/plus/DatePlus";
import {getMediaSrc} from "../../../../base/plus/MediaPlus";
import {px} from "../../../../base/plus/StringPlus";
import {IBubbleReplyInfo} from "../../../../base/types/TypesBubble";
import {IBubbleHeader} from "../../../../base/types/TypesBubble";
import {CbMenuAnchor} from "../../../../base/types/TypesGlobal";
import RawAudioBar from "../../audioBar/RawAudioBar";
import LayoutFlexCol from "../../layout/LayoutFlexCol";
import {LayoutGap} from "../../layout/LayoutGap";
import BubbleLineFooter from "../line/BubbleLineFooter";
import BubbleRawHeader from "../raw/BubbleRawHeader";
import BubbleRawReplyPreview from "../raw/BubbleRawReplyPreview";
import BubbleShell from "./BubbleShell";

export default function BubbleAudio(props: {
  payload: DtoMessagePayloadAudio,
  replyPayload?: DtoMessageReplyPayload,
  replyInfo?: IBubbleReplyInfo;
  isCallerSender: boolean,
  creationTime: string,
  maxWidth: number,
  receiptStatus?: EnumReceiptStatus,
  header?: IBubbleHeader,
  isBubbleBlinking?: boolean,
  isStar?: boolean,
  isMsgForwardable?: boolean,
  isBubbleHover?: boolean,
  cbOnClickFooterCaption?: CbMenuAnchor,
  cbOnClickBubbleShell?: CbMenuAnchor,
  cbOnClickMenu?: CbMenuAnchor,
  cbOnClickBubbleHeaderPrimary?: CbMenuAnchor,
  cbOnClickChatItemForward?: CbMenuAnchor,
  cbOnClickReplyPreview?: CbMenuAnchor,
  cbOnClickChatItemReaction?: CbMenuAnchor
})
{
  const theme = useTheme();
  const heightLine = theme.common.bubbleHeightLine;
  const payload = props.payload;
  const captionTime = formatCaptionOnlyTime(new Date(props.creationTime));
  const width = theme.common.calcBubbleFixedWidth(props.maxWidth);

  return (
    <BubbleShell
      isCallerSender={Boolean(props.isCallerSender)}
      maxWidth={props.maxWidth}
      onClickBubbleShell={props.cbOnClickBubbleShell}
      onClickMenu={props.cbOnClickMenu}
      isBubbleBlinking={props.isBubbleBlinking}
      isMsgForwardable={props.isMsgForwardable}
      isBubbleHover={props.isBubbleHover}
      onClickChatItemForward={props.cbOnClickChatItemForward}
      cbOnClickChatItemReaction={props.cbOnClickChatItemReaction}
    >
      <LayoutFlexCol
        width={px(width)}
        height={"auto"}
      >
        {
          props.header !== undefined
            ? <BubbleRawHeader
              header={props.header}
              onClickPrimary={props.cbOnClickBubbleHeaderPrimary}
            />
            : <LayoutGap height={px(heightLine)} />
        }

        {
          props.replyPayload &&
          <BubbleRawReplyPreview
            replyPayload={props.replyPayload}
            replyInfo={props.replyInfo}
            onClickPreview={props.cbOnClickReplyPreview}
          />
        }

        <RawAudioBar
          scrollable={true}
          src={getMediaSrc(payload.mediaIdAudio) as string}
          durationMs={payload.durationMs}
          cursorColor={"#6f8171"}
          progressColor={"#728977"}
          waveColor={"#b0ceae"}
        />

        <BubbleLineFooter
          isStar={props.isStar}
          heightLine={heightLine}
          textRight={{
            iconRight: props.receiptStatus,
            text: captionTime,
            onClick: props.cbOnClickFooterCaption
          }}
        />

      </LayoutFlexCol>
    </BubbleShell>
  );
}
