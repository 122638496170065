import {FormControl} from "@mui/material";
import {FormHelperText} from "@mui/material";
import React from "react";
import {Controller} from "react-hook-form";
import {DefnFieldEditable} from "../../../../api/meta/base/dto/DefnFieldEditable";
import {DefnFieldLabel} from "../../../../api/meta/base/dto/DefnFieldLabel";
import {DefnFieldLogCounter} from "../../../../api/meta/base/dto/DefnFieldLogCounter";
import {FieldValueNumber} from "../../../../api/meta/base/dto/FieldValueNumber";
import {fnRawValueToFieldValue} from "../../../../base/plus/FieldValuePlus";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import LayoutFlexRow from "../../../atom/layout/LayoutFlexRow";
import RawCounter from "../../../atom/raw/RawCounter";
import {useFieldPropertiesResolver} from "../../base/FormHooks";
import {useFormCtx} from "../base/CtxForm";
import {useFormSectionCtx} from "../base/CtxFormSection";
import {getCompLabel} from "../base/FormViewerPlus";
import FieldLabel from "../basic/FieldLabel";
import FieldRawKeyValuePair from "../raw/FieldRawKeyValuePair";
import FieldRawLogInfoButton from "../raw/FieldRawLogInfoButton";
import FieldRawTemplate from "../raw/FieldRawTemplate";

export default function FieldLogCounter(props: {
  defn: DefnFieldLogCounter
})
{
  const defn = props.defn;

  const {
    getFieldRequired,
    getFieldHelperText,
    getFieldMinCounter,
    getFieldMaxCounter,
    getFieldStepCounter
  } = useFieldPropertiesResolver(defn);

  const required = getFieldRequired();
  const helperText = getFieldHelperText();
  const min = getFieldMinCounter() as number;
  const max = getFieldMaxCounter() as number;
  const step = getFieldStepCounter() as number;

  const formCtx = useFormCtx();

  const fieldId = getFieldKey(defn);
  const label = getCompLabel(defn);

  const formSectionCtx = useFormSectionCtx();
  const formSection = formSectionCtx.getParent();
  const isPropertyEditor = formSection.sectionVariant === "propertyEditor";
  const defnTheme = formCtx.getDefnTheme();
  const fieldVariant = defnTheme.fieldVariant;
  const hideLabel = defn.hideLabel;
  const readOnly = formCtx.isFieldReadonly(defn);
  const disabled = formCtx.isFieldDisable(defn as DefnFieldEditable);

  const defnLabel = {
    label: Boolean(required) ? label + " *" : label
  } as DefnFieldLabel;

  return (
    <Controller
      name={fieldId}
      control={formCtx.control()}
      render={({
        field,
        fieldState
      }) =>
      {
        const {
          error,
          isTouched
        } = fieldState;

        const isError = isTouched && Boolean(error);
        const fieldValue = field.value as FieldValueNumber | undefined;

        return (
          <FieldRawTemplate
            defn={defn}
            fieldValue={fieldValue}
          >
            <FormControl
              fullWidth
              variant={fieldVariant === "standard" ? "outlined" : fieldVariant}
              error={isError}
            >
              {
                (!hideLabel && !isPropertyEditor)
                  ? <FieldRawKeyValuePair
                    left={
                      <FieldLabel defn={defnLabel} />
                    }
                    right={
                      <RealLogCounter
                        readOnly={readOnly}
                        disabled={disabled}
                        defn={defn}
                        max={max}
                        min={min}
                        step={step}
                        value={fieldValue?.value}
                        onChange={(value) => field.onChange(fnRawValueToFieldValue("logCounter", value))}
                      />
                    }
                  />
                  : <RealLogCounter
                    readOnly={readOnly}
                    disabled={disabled}
                    defn={defn}
                    max={max}
                    min={min}
                    step={step}
                    value={fieldValue?.value}
                    onChange={(value) => field.onChange(fnRawValueToFieldValue("logCounter", value))}
                  />
              }

              {(error?.message || helperText) &&
                <FormHelperText
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    flexGrow: 1
                  }}
                >
                  {error?.message || helperText}
                </FormHelperText>
              }
            </FormControl>
          </FieldRawTemplate>
        );
      }}
    />
  );
}

function RealLogCounter(props: {
  defn: DefnFieldLogCounter,
  value?: number,
  readOnly?: boolean,
  disabled?: boolean,
  min?: number,
  max?: number,
  step?: number,
  onChange: (value?: number) => void,
})
{
  const defn = props.defn;
  const value = props.value;
  const onChange = props.onChange;

  const readOnly = props.readOnly;
  const disabled = props.disabled;
  const step = props.step;
  const min = props.min;
  const max = props.max;

  return (
    <LayoutFlexRow
      overflowX={"visible"}
      overflowY={"visible"}
      width={"100%"}
    >
      <RawCounter
        readOnly={readOnly}
        disabled={disabled}
        value={value}
        maxVar={max}
        minVar={min}
        step={step}
        disableAnimation={true}
        position={"right"}
        onChange={(e, value) => onChange(value)}
      />
      {!defn.hideInfo &&
        <FieldRawLogInfoButton
          defn={defn}
        />
      }
    </LayoutFlexRow>
  );
}
