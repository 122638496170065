// neome.ai API - do not change
//
/* eslint-disable @typescript-eslint/no-unused-vars */
// noinspection UnusedGlobalSymbols,ES6UnusedImports,JSUnusedGlobalSymbols,JSUnusedLocalSymbols

import {ENT_ID_GLOBAL} from '../../meta/base/ApiPlus';
import {ISigAcceptor} from '../../nucleus/base/ISigAcceptor';
import {MsgAccountCreate} from './msg/MsgAccountCreate';
import {MsgAppVersion} from './msg/MsgAppVersion';
import {MsgCallerSettingPut} from './msg/MsgCallerSettingPut';
import {MsgDeviceGet} from './msg/MsgDeviceGet';
import {MsgFCMToken} from './msg/MsgFCMToken';
import {MsgForgotPassword} from './msg/MsgForgotPassword';
import {MsgGrantBearerToken} from './msg/MsgGrantBearerToken';
import {MsgOtpSignIn} from './msg/MsgOtpSignIn';
import {MsgSignIn} from './msg/MsgSignIn';
import {MsgSignInUserId} from './msg/MsgSignInUserId';
import {MsgSocialSignIn} from './msg/MsgSocialSignIn';
import {MsgVersion} from '../base/msg/MsgVersion';
import {ServiceName} from '../../meta/base/Types';
import {SigAppVersion} from './sig/SigAppVersion';
import {SigBearerToken} from './sig/SigBearerToken';
import {SigCaller} from './sig/SigCaller';
import {SigCallerDevice} from './sig/SigCallerDevice';
import {SigDone} from '../../nucleus/base/sig/SigDone';
import {SigRefreshToken} from '../../nucleus/stem/sig/SigRefreshToken';
import {SigSignout} from '../../nucleus/base/sig/SigSignout';
import {SigUserSetting} from './sig/SigUserSetting';
import {SigVerifyKey} from '../otp/sig/SigVerifyKey';
import {rpcCall} from '../../nucleus/base/IRpcCall';

export class RpcUser
{
  static readonly SN: ServiceName = "user";

  static accountCreate(msg: MsgAccountCreate, sigAcceptor: ISigAcceptor<SigVerifyKey>): void
  {
    rpcCall<SigVerifyKey>(ENT_ID_GLOBAL, RpcUser.SN, "accountCreate")
      .sendRefreshToken()
      .post(msg, sigAcceptor);
  }

  static appVersionGet(msg: MsgAppVersion, sigAcceptor: ISigAcceptor<SigAppVersion>): void
  {
    rpcCall<SigAppVersion>(ENT_ID_GLOBAL, RpcUser.SN, "appVersionGet")
      .get(msg, sigAcceptor);
  }

  static callerDeviceGet(msg: MsgDeviceGet, sigAcceptor: ISigAcceptor<SigCallerDevice>): void
  {
    rpcCall<SigCallerDevice>(ENT_ID_GLOBAL, RpcUser.SN, "callerDeviceGet")
      .sendBearerToken()
      .get(msg, sigAcceptor);
  }

  static callerGet(msg: MsgVersion, sigAcceptor: ISigAcceptor<SigCaller>): void
  {
    rpcCall<SigCaller>(ENT_ID_GLOBAL, RpcUser.SN, "callerGet")
      .sendBearerToken()
      .get(msg, sigAcceptor);
  }

  static callerSettingGet(msg: MsgVersion, sigAcceptor: ISigAcceptor<SigUserSetting>): void
  {
    rpcCall<SigUserSetting>(ENT_ID_GLOBAL, RpcUser.SN, "callerSettingGet")
      .sendBearerToken()
      .get(msg, sigAcceptor);
  }

  static callerSettingPut(msg: MsgCallerSettingPut, sigAcceptor: ISigAcceptor<SigDone>): void
  {
    rpcCall<SigDone>(ENT_ID_GLOBAL, RpcUser.SN, "callerSettingPut")
      .sendBearerToken()
      .put(msg, sigAcceptor);
  }

  static fCMTokenUpdate(msg: MsgFCMToken, sigAcceptor: ISigAcceptor<SigDone>): void
  {
    rpcCall<SigDone>(ENT_ID_GLOBAL, RpcUser.SN, "fCMTokenUpdate")
      .sendBearerToken()
      .put(msg, sigAcceptor);
  }

  static forgotPassword(msg: MsgForgotPassword, sigAcceptor: ISigAcceptor<SigVerifyKey>): void
  {
    rpcCall<SigVerifyKey>(ENT_ID_GLOBAL, RpcUser.SN, "forgotPassword")
      .patch(msg, sigAcceptor);
  }

  static grantBearerToken(msg: MsgGrantBearerToken, sigAcceptor: ISigAcceptor<SigBearerToken>): void
  {
    rpcCall<SigBearerToken>(ENT_ID_GLOBAL, RpcUser.SN, "grantBearerToken")
      .sendRefreshToken()
      .get(msg, sigAcceptor);
  }

  static grantRefreshToken(sigAcceptor: ISigAcceptor<SigRefreshToken>): void
  {
    rpcCall<SigRefreshToken>(ENT_ID_GLOBAL, RpcUser.SN, "grantRefreshToken")
      .sendRefreshToken()
      .get(undefined, sigAcceptor);
  }

  static otpSignIn(msg: MsgOtpSignIn, sigAcceptor: ISigAcceptor<SigVerifyKey>): void
  {
    rpcCall<SigVerifyKey>(ENT_ID_GLOBAL, RpcUser.SN, "otpSignIn")
      .sendRefreshToken()
      .post(msg, sigAcceptor);
  }

  static ping(sigAcceptor: ISigAcceptor<SigDone>): void
  {
    rpcCall<SigDone>(ENT_ID_GLOBAL, RpcUser.SN, "ping")
      .sendBearerToken()
      .get(undefined, sigAcceptor);
  }

  static signIn(msg: MsgSignIn, sigAcceptor: ISigAcceptor<SigRefreshToken>): void
  {
    rpcCall<SigRefreshToken>(ENT_ID_GLOBAL, RpcUser.SN, "signIn")
      .sendRefreshToken()
      .post(msg, sigAcceptor);
  }

  static signInUserId(msg: MsgSignInUserId, sigAcceptor: ISigAcceptor<SigRefreshToken>): void
  {
    rpcCall<SigRefreshToken>(ENT_ID_GLOBAL, RpcUser.SN, "signInUserId")
      .sendRefreshToken()
      .post(msg, sigAcceptor);
  }

  static signOut(sigAcceptor: ISigAcceptor<SigSignout>): void
  {
    rpcCall<SigSignout>(ENT_ID_GLOBAL, RpcUser.SN, "signOut")
      .sendRefreshToken()
      .post(undefined, sigAcceptor);
  }

  static socialSignIn(msg: MsgSocialSignIn, sigAcceptor: ISigAcceptor<SigRefreshToken>): void
  {
    rpcCall<SigRefreshToken>(ENT_ID_GLOBAL, RpcUser.SN, "socialSignIn")
      .sendRefreshToken()
      .post(msg, sigAcceptor);
  }
}