import "ag-grid-community/styles/ag-grid.min.css";
import "ag-grid-community/styles/ag-theme-material.min.css";
import "ag-grid-enterprise";
import {useTheme} from "@mui/material";
import {Box} from "@mui/material";
import {CellClickedEvent} from "ag-grid-community";
import {FirstDataRenderedEvent} from "ag-grid-community/dist/types/core/events";
import {RowValueChangedEvent} from "ag-grid-community/dist/types/core/events";
import {SelectionChangedEvent} from "ag-grid-community/dist/types/core/events";
import {GetRowIdParams} from "ag-grid-community/dist/types/core/interfaces/iCallbackParams";
import {AgGridReact} from "ag-grid-react";
import {useEffect} from "react";
import {useCallback} from "react";
import {useState} from "react";
import React from "react";
import {useLayoutEffect} from "react";
import {useRef} from "react";
import {useMemo} from "react";
import {RowId} from "../../api/meta/base/Types";
import {IDataGridCellRendererParams} from "../../base/types/TypeDataGrid";
import {CbOnSetGridRow} from "../../base/types/TypeDataGrid";
import {IDataGridRowData} from "../../base/types/TypeDataGrid";
import {AgGridContext} from "../../base/types/TypeDataGrid";
import {IDataGridRef} from "../../base/types/TypeDataGrid";
import {IGridBinderAll} from "../../base/types/TypeDataGrid";
import {CbOnClickDataGrid} from "../../base/types/TypeDataGrid";
import {IDataGridDataLayout} from "../../base/types/TypeDataGrid";
import {IDataGridCell} from "../../base/types/TypeDataGrid";
import {TypeColDef} from "../../base/types/TypeDataGrid";
import {IDataGrid} from "../../base/types/TypeDataGrid";
import helperTextData from "../atom/assets/PlaceholderTextHome.json";
import RawNothingHere from "../atom/raw/RawNothingHere";
import {getHyperLinkRowFieldIdMap} from "../form/viewer/base/FormViewerPlus";
import GridCellMasterDetail from "./base/cell/GridCellMasterDetail";
import {IDataGridExternalSx} from "./base/DataGridPlus";
import {useDataGridSubscription} from "./base/DataGridPlus";
import {dataGridPaginationPageSize} from "./base/DataGridPlus";
import {GRID_COL_ID_ACTION} from "./base/DataGridPlus";
import {calcActionCellWidth} from "./base/DataGridPlus";
import {getDefaultColumnDef} from "./base/DataGridPlus";
import {dataGridRowHeight} from "./base/DataGridPlus";
import {dataGridTooltipShowDelay} from "./base/DataGridPlus";
import {dataGridRowBuffer} from "./base/DataGridPlus";
import {getDataGridSx} from "./base/DataGridPlus";
import {convertGridRowToAgGridFooterRows} from "./base/DataGridPlus";
import {getRowStyle} from "./base/DataGridPlus";
import {GRID_COL_ID_INDEX_LABEL} from "./base/DataGridPlus";
import {GRID_COL_ID_ACTION_LABEL} from "./base/DataGridPlus";
import {GRID_COL_ID_EXPIRY_LABEL} from "./base/DataGridPlus";
import {GRID_COL_ID_COMMENT_LABEL} from "./base/DataGridPlus";
import {onFirstDataRendered} from "./base/DataGridPlus";
import {fnInsertAgGridColDef} from "./base/DataGridPlus";

export function DataGrid<SR1, SR2, SR3, SR4, SR5, SR6>(props: {
  initValues: IDataGrid,
  initRows: IDataGridRowData,
  cbRef?: IDataGridRef,
  gridBinder?: IGridBinderAll<SR1, SR2, SR3, SR4, SR5, SR6>,
  cbOnClickDataGrid?: CbOnClickDataGrid
  cbOnSetGridRow?: CbOnSetGridRow,
  editable?: boolean,
  externalSx?: IDataGridExternalSx,
  onRowSelected?: (rowIds: RowId[]) => void,
  onRowsSubscribe?: (rowIds: RowId[]) => void,
  onRowsUnsubscribe?: (rowIds: RowId[]) => void,
})
{
  const theme = useTheme();
  const cbOnClickDataGrid = props.cbOnClickDataGrid;
  const cbOnSetGridRow = props.cbOnSetGridRow;
  const initValues = props.initValues;
  const initialRows = props.initRows;
  const gridBinder = props.gridBinder;
  const editable = props.editable;
  const onRowSelected = props.onRowSelected;

  const version = initValues.version;
  const defnForm = initValues.defnForm;
  const layout = initValues.layout;
  const showColMenu = initValues.showColMenu;
  const rowsVersion = initialRows.version;
  const searchWords = initValues.searchWords;
  const showHeaderToolPanel = initValues.showHeaderToolPanel;
  const ignoreSelection = initValues?.ignoreSelection;
  const isMasterDetail = Boolean(layout?.masterDetailGridLayoutMap
    && Object.keys(layout.masterDetailGridLayoutMap).length
    > 0);
  const rowSelection = initValues?.pickType === "pickMany"
    ? "multiple"
    : "single";
  const showColAction = (showColMenu || isMasterDetail || rowSelection === "multiple");
  const gridRef = useRef<AgGridReact>(null);
  const [columnDefs, setColumnDefs] = useState<TypeColDef[]>([]);
  const [rowData, setRowData] = useState<IDataGridCell[]>([]);
  const initialFlex = (layout.columnSizeSet?.length === 1 && layout.columnSizeSet[0] === "Flex")
    ? 1
    : undefined;

  const clickTimeoutRef = useRef<NodeJS.Timeout>();

  const {
    onBodyScrollEnd,
    onRowDataUpdated,
    onPaginationChanged,
    onFilterChanged,
    onSortChanged,
    calcSubscription
  } = useDataGridSubscription({
    initRows: initialRows,
    onRowsSubscribe: props.onRowsSubscribe,
    onRowsUnsubscribe: props.onRowsUnsubscribe
  });

  const sx = useMemo(() => getDataGridSx(layout.hideRowSeparator, props.externalSx),
    [layout.hideRowSeparator, props.externalSx]
  );
  const masterDetailsParams = useMemo(() => ({
      gridBinder: gridBinder
    } as IDataGridCellRendererParams<SR1, SR2, SR3, SR4, SR5, SR6>)
    , [gridBinder]);

  const containerStyle = useMemo(() => ({
    ...initValues.borderTop && {borderTop: theme.common.reportBorder},
    ...initValues.borderBottom && {borderBottom: theme.common.reportBorder},
    ...initValues.borderRight && {borderRight: theme.common.reportBorder},
    ...initValues.borderLeft && {borderLeft: theme.common.reportBorder}
  }), [initValues.borderTop, initValues.borderBottom, initValues.borderRight, initValues.borderLeft]);

  const hyperLinkColIdMap = useMemo(() =>
  {
    return getHyperLinkRowFieldIdMap(defnForm);
  }, [defnForm]);

  const agGridContext = useMemo(() => ({
      defnForm: defnForm,
      layout: layout,
      footerValue: initValues.footer,
      searchWords: searchWords,
      isPickMany: rowSelection === "multiple",
      showColMenu: showColMenu,
      hyperLinkColIdMap: hyperLinkColIdMap
    } as AgGridContext),
    [defnForm, layout, initValues.footer, searchWords, rowSelection, showColMenu, hyperLinkColIdMap]
  );

  const defaultColDef = useMemo<TypeColDef>(() =>
  {
    return getDefaultColumnDef(initialFlex,
      showHeaderToolPanel,
      cbOnClickDataGrid,
      gridBinder,
      editable
    );
  }, [cbOnClickDataGrid, gridBinder, initialFlex, showHeaderToolPanel, editable]);

  const colActionIconCount = useMemo(() =>
  {
    let count = 0;
    if(isMasterDetail)
    {
      count += 1;
    }
    if(showColMenu)
    {
      count += 1;
    }
    if(rowSelection === "multiple")
    {
      count += 1;
    }
    return count;
  }, [isMasterDetail, rowSelection, showColMenu]);

  const setFooter = useCallback((layout: IDataGridDataLayout, columnDefs: TypeColDef[]) =>
  {
    if(layout.footer)
    {
      const row = convertGridRowToAgGridFooterRows(columnDefs,
        layout
      );
      gridRef.current?.api.setGridOption("pinnedBottomRowData", [row]);
    }
  }, []);

  const handleCellSingleClick = useCallback((params: CellClickedEvent<IDataGridCell>) =>
  {
    if(cbOnClickDataGrid)
    {
      const isEditing = params.api.getEditingCells().at(-1)?.rowIndex === params.node.childIndex;
      if(isEditing)
      {
        clearTimeout(clickTimeoutRef.current);
        return;
      }
      if((params.event as MouseEvent)?.detail === 2)
      {
        clearTimeout(clickTimeoutRef.current);
        return;
      }
      clickTimeoutRef.current = setTimeout(() =>
      {
        const menuAnchor = params.event?.target;
        const rowId = params.data?.rowId;
        const colId = params.colDef.colId;
        const defnForm = (params.context as AgGridContext).defnForm;

        if(rowId && colId !== GRID_COL_ID_ACTION)
        {
          const row = params.data;
          if(menuAnchor && colId && row && cbOnClickDataGrid)
          {
            const hyperlinkFieldId = colId ? (params.context as AgGridContext).hyperLinkColIdMap?.[colId] : undefined;
            const cellType = hyperlinkFieldId ? defnForm?.compMap[hyperlinkFieldId]?.type : undefined;
            const isHyperlinkRow = cellType === "hyperlinkRow";

            if(isHyperlinkRow)
            {
              cbOnClickDataGrid(menuAnchor as Element,
                "hyperlinkRow",
                row,
                colId,
                !params.node.isSelected(),
                undefined,
                hyperlinkFieldId
              );
            }
            else
            {
              cbOnClickDataGrid(menuAnchor as Element,
                "cell",
                row,
                colId,
                !params.node.isSelected()
              );
            }

          }
          if(!ignoreSelection)
          {
            params.node.setSelected(!params.node.isSelected());
          }
        }
      }, 200);
    }
  }, [cbOnClickDataGrid, ignoreSelection]);

  const cbOnRowValueChanged = useCallback((params: RowValueChangedEvent<IDataGridCell>) =>
  {
    if(params.data && cbOnSetGridRow)
    {
      cbOnSetGridRow(params.data);
    }
  }, [cbOnSetGridRow]);

  const firstDataRendered = useCallback((event: FirstDataRenderedEvent<IDataGridCell>) =>
  {
    const columnDefs = event.api.getColumnDefs();
    columnDefs && setFooter(layout, columnDefs);
    onFirstDataRendered(event);
  }, [layout, setFooter]);

  const cbOnRowSelected = useCallback((event: SelectionChangedEvent<IDataGridCell>) =>
  {
    onRowSelected && onRowSelected(event.api.getSelectedRows().map(row => row.rowId));
  }, [onRowSelected]);

  useLayoutEffect(() =>
  {
    const showColIndex = initValues.showColIndex;
    const showColComment = initValues.showColComment;
    const showColExpiry = initValues.showColExpiry;
    const colIndexName = showColIndex ? (initValues.colIndexName || GRID_COL_ID_INDEX_LABEL) : undefined;
    const colCommentName = showColComment ? (initValues.colCommentName || GRID_COL_ID_COMMENT_LABEL) : undefined;
    const colExpiryName = showColExpiry ? (initValues.colExpiryName || GRID_COL_ID_EXPIRY_LABEL) : undefined;
    const colActionName = showColAction ? (initValues.colActionName || GRID_COL_ID_ACTION_LABEL) : undefined;

    const columnDefs = fnInsertAgGridColDef(defnForm,
      layout,
      rowSelection === "multiple",
      colActionIconCount,
      colIndexName,
      colActionName,
      colCommentName,
      colExpiryName
    );
    setColumnDefs(columnDefs);
  }, [defnForm, layout, version]);

  useLayoutEffect(() =>
  {
    const _rowData = [] as IDataGridCell[];
    initialRows.keys?.forEach((rowId) =>
    {
      const row = initialRows.map[rowId] as IDataGridCell | undefined;
      _rowData.push({
        ...row,
        rowId: rowId,
        valueMap: row?.valueMap || {}
      });
    });
    setRowData(_rowData);
    if(gridRef.current?.api)
    {
      gridRef.current?.api.refreshCells();
    }
  }, [rowsVersion]);

  useEffect(() =>
  {
    if(gridRef.current?.api)
    {
      setFooter(layout, columnDefs);
    }

  }, [columnDefs, layout]);

  useEffect(() =>
  {
    const width = calcActionCellWidth(colActionIconCount);
    if(gridRef.current?.api)
    {
      gridRef.current?.api.setColumnWidths([
        {
          key: GRID_COL_ID_ACTION,
          newWidth: width
        }
      ]);
    }
  }, [colActionIconCount]);

  useEffect(() =>
  {
    if(gridRef.current?.api)
    {
      const defaultColDef = getDefaultColumnDef(
        initialFlex,
        showHeaderToolPanel,
        cbOnClickDataGrid,
        gridBinder,
        editable
      );

      gridRef.current.api.setGridOption("defaultColDef", defaultColDef);
    }
  }, [cbOnClickDataGrid, gridBinder, initialFlex, showHeaderToolPanel]);

  if(rowData.length === 0)
  {
    return <RawNothingHere helperTextData={helperTextData.nothingToShow} />;
  }

  if(props.cbRef && gridRef.current?.api)
  {
    props.cbRef.api = gridRef.current.api;
    props.cbRef.refresh = () => gridRef.current?.api ? calcSubscription(gridRef.current?.api) : undefined;
    props.cbRef.exportDataAsExcel = () => gridRef.current?.api?.exportDataAsExcel();
  }

  return (
    <Box
      className="ag-theme-material"
      sx={sx}
    >
      <AgGridReact
        containerStyle={containerStyle}
        ref={gridRef}
        defaultColDef={defaultColDef}
        columnDefs={columnDefs}
        onCellClicked={handleCellSingleClick}
        rowData={rowData}
        context={agGridContext}
        editType={editable ? "fullRow" : undefined}
        masterDetail={isMasterDetail}
        getRowId={getRowId}
        detailCellRenderer={GridCellMasterDetail}
        detailCellRendererParams={masterDetailsParams}
        rowHeight={dataGridRowHeight}
        headerHeight={dataGridRowHeight}
        pagination={layout.pagination}
        paginationPageSize={layout.pagination ? (layout.rowsPerPage || dataGridPaginationPageSize) : undefined}
        rowSelection={rowSelection}
        keepDetailRowsCount={5}
        keepDetailRows={true}
        columnMenu={"new"}
        suppressRowClickSelection={true}
        rowMultiSelectWithClick={true}
        detailRowAutoHeight={true}
        groupIncludeFooter={false}
        suppressMovableColumns={true}
        valueCache={true}
        alwaysShowVerticalScroll={true}
        suppressRowVirtualisation={initialRows.keys.length <= 150}
        suppressColumnVirtualisation={true}
        animateRows={true}
        rowBuffer={dataGridRowBuffer}
        noRowsOverlayComponent={RawNothingHere}
        tooltipShowDelay={dataGridTooltipShowDelay}
        getRowStyle={getRowStyle}
        onPaginationChanged={onPaginationChanged}
        onBodyScrollEnd={onBodyScrollEnd}
        onSelectionChanged={cbOnRowSelected}
        onSortChanged={onSortChanged}
        onFilterModified={onFilterChanged}
        onRowDataUpdated={onRowDataUpdated}
        onRowValueChanged={cbOnRowValueChanged}
        onFirstDataRendered={firstDataRendered}
      />
    </Box>
  );
}

function getRowId(params: GetRowIdParams<IDataGridCell>)
{
  return params.data.rowId;
}
