import {getAnalytics} from "firebase/analytics";
import {getToken, onMessage} from "firebase/messaging";
import {MsgFCMToken} from "../../../api/core/user/msg/MsgFCMToken";
import {WsocUser} from "../../../api/core/user/WsocUser";
import ISrvc from "../../../base/ISrvc";
import {logDebug} from "../../../base/util/AppLog";
import {setFcmToken} from "../../../cache/app/user/SliceCacheUser";
import {store} from "../../../Store";
import {firebaseApp} from "./base/FirebaseConfig";
import {messaging} from "./base/FirebaseMessaging";

export default class SrvcFirebase extends ISrvc
{
  constructor()
  {
    super();

    // Analytics setup
    getAnalytics(firebaseApp);

    // Handle push notification message
    onMessage(messaging, (payload) =>
    {
      logDebug("NotificationPayload", JSON.stringify(payload));

      // Handle the message and update UI accordingly
      const title = payload.notification?.title ?? "";
      const body = payload.notification?.body ?? "";
      const icon = `${process.env.PUBLIC_URL}/logo192.png`;

      const notificationOptions = {
        body: body,
        icon: icon,
        tag: payload.messageId //To avoid multiple notifications when multiple tabs are open
      };

      // Show notification
      new Notification(title, notificationOptions);

      // Play notification sound
      try
      {
        let audio = new Audio(`${process.env.PUBLIC_URL}/audio-message-tone.mp3`);
        audio.play().then(_ =>
        {
          audio.remove();
        });
      }
      catch(e)
      {

      }
    });
  }

  async requestNotificationPermission()
  {
    try
    {
      if("Notification" in window)
      {
        if(Notification.permission !== "denied")
        {
          await Notification.requestPermission();

          const token = await this.requestToken();

          store.dispatch(setFcmToken(token));
        }
      }
    }
    catch(error)
    {
      console.error("Permission denied:", error);
    }
  };

  async requestToken()
  {
    try
    {
      return await getToken(messaging);
    }
    catch(error)
    {
      console.error("An error occurred while retrieving token:", error);
      throw error;
    }
  };

  wsocFCMTokenUpdate(token?: string)
  {
    const msg = {
      fcmToken: token
    } as MsgFCMToken;

    WsocUser.fCMTokenUpdate(msg, (_) =>
    {

    });
  }
}

