import {FieldValues} from "react-hook-form";
import {DefnField} from "../../../../api/meta/base/dto/DefnField";
import {DefnFieldLabel} from "../../../../api/meta/base/dto/DefnFieldLabel";
import {DefnFieldParagraph} from "../../../../api/meta/base/dto/DefnFieldParagraph";
import {DefnFieldPickEnum} from "../../../../api/meta/base/dto/DefnFieldPickEnum";
import {DefnFieldSwitch} from "../../../../api/meta/base/dto/DefnFieldSwitch";
import {DefnFieldText} from "../../../../api/meta/base/dto/DefnFieldText";
import {DefnSection} from "../../../../api/meta/base/dto/DefnSection";
import {DefnStudioPickVarId} from "../../../../api/meta/base/dto/DefnStudioPickVarId";
import {DefnStudioVarIdTextEditor} from "../../../../api/meta/base/dto/DefnStudioVarIdTextEditor";
import {DefnTab} from "../../../../api/meta/base/dto/DefnTab";
import {StudioDtoLayoutFormContentTemplate} from "../../../../api/meta/base/dto/StudioDtoLayoutFormContentTemplate";
import {StudioLayoutFormFooterCommon} from "../../../../api/meta/base/dto/StudioLayoutFormFooterCommon";
import {StudioLayoutFormFooterCommonLine} from "../../../../api/meta/base/dto/StudioLayoutFormFooterCommonLine";
import {StudioLayoutFormHeaderCommon} from "../../../../api/meta/base/dto/StudioLayoutFormHeaderCommon";
import {StudioLayoutFormWatermarkCommon} from "../../../../api/meta/base/dto/StudioLayoutFormWatermarkCommon";
import {StudioVarMap} from "../../../../api/meta/base/dto/StudioVarMap";
import {Position} from "../../../../api/meta/base/StudioSetsFieldType";
import {WatermarkPosition} from "../../../../api/meta/base/StudioSetsFieldType";
import {EnumDefnRenderingKind} from "../../../../api/meta/base/Types";
import {MetaIdVar} from "../../../../api/meta/base/Types";
import {EnumDefnPlacement} from "../../../../api/meta/base/Types";
import {EnumStudioVarKind} from "../../../../api/meta/base/Types";
import {MetaIdField} from "../../../../api/meta/base/Types";
import {MetaIdForm} from "../../../../api/meta/base/Types";
import {fnFieldValueToRawValue} from "../../../../base/plus/FieldValuePlus";
import {fnRawValueToFieldValue} from "../../../../base/plus/FieldValuePlus";
import {getCombinedFieldId} from "../../../../base/plus/FormPlus";
import {getExcludeVarIdSet} from "../../../../base/plus/FormPlus";
import {defaultSectionKey} from "../../../../base/plus/FormPlus";
import {createDefaultDefnFormStudio} from "../../../../base/plus/FormPlus";
import {fieldGap1} from "./TypesFormBuilder";
import {fieldTabDetails} from "./TypesFormBuilder";
import {fieldKeyDescription} from "./TypesFormBuilder";
import {fieldKeyName} from "./TypesFormBuilder";
import {fieldGap4} from "./TypesFormBuilder";
import {fieldGap3} from "./TypesFormBuilder";
import {getFieldGap} from "./TypesFormBuilder";

type TypeLayoutForm = "commonHeader" | "commonFooter" | "commonWatermark";
type TypeLayoutFormFooterLine = "first" | "second" | "third";

interface LayoutVariables
{
  fieldMetaId: string;
  fieldLabel: string;
  varKind?: EnumStudioVarKind;
  filterOptionSet?: EnumDefnPlacement[];
  excludeVarIdSet?: MetaIdVar[];
}

export function fnGetFormBuilderFormTemplateLayoutBuilder()
{
  return {
    getDefn,
    dtoToValues,
    valueToDto
  };
}

function getDefn(metaIdForm?: MetaIdForm, varMap?: StudioVarMap)
{
  return createDefaultDefnFormStudio({
    ...getFormLayoutDetails(),
    ...getFormLayoutHeaderTab(metaIdForm, varMap),
    ...getFormLayoutFooterTab(metaIdForm),
    ...getFormLayoutWatermarkTab(metaIdForm, varMap),

    [defaultSectionKey]: {
      type: "tab",
      name: defaultSectionKey,
      metaId: defaultSectionKey,
      tabIdSet: [
        fieldTabDetails,
        fieldHeaderSection,
        fieldFooterSection,
        fieldWatermarkSection
      ]
    } as DefnTab
  } as Record<MetaIdField, DefnField>);
}

function getFormLayoutDetails()
{
  return {
    [fieldKeyName]: {
      type: "symbol",
      name: fieldKeyName,
      metaId: fieldKeyName,
      label: "Name",
      required: true
    } as DefnFieldText,

    [fieldKeyDescription]: {
      type: "paragraph",
      name: fieldKeyDescription,
      metaId: fieldKeyDescription,
      label: "Description"
    } as DefnFieldParagraph,

    ...getFieldGap(fieldGap1, "thick"),

    [fieldPaperSize]: {
      type: "enumRenderingKind",
      metaId: fieldPaperSize,
      name: fieldPaperSize,
      label: "Paper size"
    } as DefnFieldPickEnum,

    [fieldTabDetails]: {
      type: "section",
      metaId: fieldTabDetails,
      name: "Details",
      fieldIdSet: [fieldKeyName, fieldKeyDescription, fieldGap1, fieldPaperSize]
    } as DefnSection
  };
}

function getFormLayoutHeaderTab(formId?: MetaIdForm, varMap?: StudioVarMap)
{
  const sectionId = header;
  const headerFontVariable = {
    fieldMetaId: fieldHeaderFontVariable,
    fieldLabel: "Form name font variable"
  } as LayoutVariables;
  const headerPatternVariable = {
    fieldMetaId: fieldHeaderPatternVariable,
    fieldLabel: "Form name pattern variable"
  } as LayoutVariables;
  const headerOpacityVariable = {
    fieldMetaId: fieldHeaderOpacityVariable,
    fieldLabel: "Form name opacity variable"
  } as LayoutVariables;
  const headerPositionVariable = {
    fieldMetaId: fieldHeaderPositionVariable,
    fieldLabel: "Form name position variable",
    filterOptionSet: Position,
    excludeVarIdSet: getExcludeVarIdSet(Position as EnumDefnPlacement[], varMap)
  } as LayoutVariables;

  return {
    [fieldHeaderShowSeparator]: {
      type: "bool",
      name: fieldHeaderShowSeparator,
      metaId: fieldHeaderShowSeparator,
      showAsCheckboxVar: true,
      label: "Show separator"
    } as DefnFieldSwitch,
    [fieldHeaderBackgroundColorVariable]: {
      type: "pickVarId",
      name: fieldHeaderBackgroundColorVariable,
      metaId: fieldHeaderBackgroundColorVariable,
      label: "Background color variable",
      varKind: "color",
      showAsEdit: true,
      formId: formId
    } as DefnStudioPickVarId,

    [fieldHeaderForegroundColorVariable]: {
      type: "pickVarId",
      name: fieldHeaderForegroundColorVariable,
      metaId: fieldHeaderForegroundColorVariable,
      label: "Foreground color variable",
      varKind: "color",
      showAsEdit: true,
      formId: formId
    },

    ...getFieldGap(fieldGap3, "thick"),

    [fieldShowEnterprise]: {
      type: "bool",
      name: fieldShowEnterprise,
      metaId: fieldShowEnterprise,
      showAsCheckboxVar: true,
      label: "Show enterprise"
    } as DefnFieldSwitch,

    [fieldHyperLinkVariable]: {
      type: "studioSetOfVarId",
      metaId: fieldHyperLinkVariable,
      showAsEdit: true,
      formId: formId,
      label: "Hyperlinks variable",
      varKind: "hyperlink"
    } as DefnStudioPickVarId,

    ...getFieldGap(fieldGap4, "thick"),

    ...getLayoutVariables(
      sectionId,
      headerFontVariable,
      headerPatternVariable,
      headerOpacityVariable,
      headerPositionVariable,
      formId
    ),

    [fieldHeaderShowSeparatorSection]: {
      type: "section",
      name: fieldHeaderShowSeparatorSection,
      metaId: fieldHeaderShowSeparatorSection,
      fieldIdSet: [fieldHeaderShowSeparator]
    } as DefnSection,

    [fieldHeaderSection]: {
      type: "section",
      metaId: fieldHeaderSection,
      name: "Header",
      fieldIdSet: [
        fieldShowEnterprise,
        fieldGap3,
        fieldHyperLinkVariable,
        sectionId,
        fieldHeaderBackgroundColorVariable,
        fieldHeaderForegroundColorVariable,
        fieldGap4,
        fieldHeaderShowSeparatorSection

      ]
    } as DefnSection
  };
}

function getFormLayoutFooterTab(formId?: MetaIdForm)
{
  const firstLineSection = "firstLineSection";
  const firstLineFirstLabel = "firstLineFirstLabel";
  const firstLineSecondLabel = "firstLineSecondLabel";
  const firstLineThirdLabel = "firstLineThirdLabel";

  const secondLineSection = "secondLineSection";
  const secondLineFirstLabel = "secondLineFirstLabel";
  const secondLineSectionId2 = "secondLineSecondLabel";
  const secondLineSectionId3 = "secondLineThirdLabel";

  const thirdLineSection = "thirdLineSection";
  const thirdLineFirstLabel = "thirdLineFirstLabel";
  const thirdLineSecondLabel = "thirdLineSecondLabel";
  const thirdLineThirdLabel = "thirdLineThirdLabel";

  //footer first line

  const footerFirstLabel = {
    fieldMetaId: firstLabel,
    fieldLabel: "First label"
  } as LayoutVariables;

  const firstLineFirstLabelFont = fieldFooterFontVar("first", "first");
  const firstLineSecondLabelFont = fieldFooterFontVar("first", "second");
  const firstLineThirdLabelFont = fieldFooterFontVar("first", "third");

  const firstLineFirstLabelPattern = fieldFooterPatternVar("first", "first");
  const firstLineSecondLabelPattern = fieldFooterPatternVar("first", "second");
  const firstLineThirdLabelPattern = fieldFooterPatternVar("first", "third");

  const firstLineFirstLabelOpacity = fieldFooterOpacityVar("first", "first");
  const firstLineSecondLabelOpacity = fieldFooterOpacityVar("first", "second");
  const firstLineThirdLabelOpacity = fieldFooterOpacityVar("first", "third");

  //footer second line

  const footerSecondLabel = {
    fieldMetaId: secondLabel,
    fieldLabel: "Second label"
  } as LayoutVariables;

  const secondLineFirstLabelFont = fieldFooterFontVar("second", "first");
  const secondLineSecondLabelFont = fieldFooterFontVar("second", "second");
  const secondLineThirdLabelFont = fieldFooterFontVar("second", "third");

  const secondLineFirstLabelPattern = fieldFooterPatternVar("second", "first");
  const secondLineSecondLabelPattern = fieldFooterPatternVar("second", "second");
  const secondLineThirdLabelPattern = fieldFooterPatternVar("second", "third");

  const secondLineFirstLabelOpacity = fieldFooterOpacityVar("second", "first");
  const secondLineSecondLabelOpacity = fieldFooterOpacityVar("second", "second");
  const secondLineThirdLabelOpacity = fieldFooterOpacityVar("second", "third");

  //footer third line

  const footerThirdLabel = {
    fieldMetaId: thirdLabel,
    fieldLabel: "Third label"
  } as LayoutVariables;

  const thirdLineFirstFont = fieldFooterFontVar("third", "first");
  const thirdLineSecondFont = fieldFooterFontVar("third", "second");
  const thirdLineThirdFont = fieldFooterFontVar("third", "third");

  const thirdLineFirstPattern = fieldFooterPatternVar("third", "first");
  const thirdLineSecondPattern = fieldFooterPatternVar("third", "second");
  const thirdLineThirdPattern = fieldFooterPatternVar("third", "third");

  const thirdLineFirstOpacity = fieldFooterOpacityVar("third", "first");
  const thirdLineSecondOpacity = fieldFooterOpacityVar("third", "second");
  const thirdLineThirdOpacity = fieldFooterOpacityVar("third", "third");

  return {
    [fieldFooterShowSeparator]: {
      type: "bool",
      name: fieldFooterShowSeparator,
      metaId: fieldFooterShowSeparator,
      label: "Show separator",
      showAsCheckboxVar: true
    } as DefnFieldSwitch,

    ...getFieldGap(fieldGap3, "thick"),

    [fieldFooterBackgroundColorVariable]: {
      type: "pickVarId",
      name: fieldFooterBackgroundColorVariable,
      metaId: fieldFooterBackgroundColorVariable,
      label: "Background color variable",
      varKind: "color",
      showAsEdit: true,
      formId: formId
    },

    [fieldFooterForegroundColorVariable]: {
      type: "pickVarId",
      name: fieldFooterForegroundColorVariable,
      metaId: fieldFooterForegroundColorVariable,
      label: "Foreground color variable",
      varKind: "color",
      showAsEdit: true,
      formId: formId
    },

    ...getFieldGap(fieldGap4, "thick"),

    [lineLabel]: {
      type: "label",
      metaId: lineLabel,
      label: "Lines",
      colorVar: {
        value: "textSecondary"
      }
    } as DefnFieldLabel,

    //First line
    ...getLayoutVariables(firstLineFirstLabel,
      firstLineFirstLabelFont,
      firstLineFirstLabelPattern,
      firstLineFirstLabelOpacity,
      undefined,
      formId,
      footerFirstLabel
    ),
    ...getLayoutVariables(firstLineSecondLabel,
      firstLineSecondLabelFont,
      firstLineSecondLabelPattern,
      firstLineSecondLabelOpacity,
      undefined,
      formId,
      footerSecondLabel
    ),
    ...getLayoutVariables(firstLineThirdLabel,
      firstLineThirdLabelFont,
      firstLineThirdLabelPattern,
      firstLineThirdLabelOpacity,
      undefined,
      formId,
      footerThirdLabel
    ),

    //Second line
    ...getLayoutVariables(secondLineFirstLabel,
      secondLineFirstLabelFont,
      secondLineFirstLabelPattern,
      secondLineFirstLabelOpacity,
      undefined,
      formId,
      footerFirstLabel
    ),
    ...getLayoutVariables(secondLineSectionId2,
      secondLineSecondLabelFont,
      secondLineSecondLabelPattern,
      secondLineSecondLabelOpacity,
      undefined,
      formId,
      footerSecondLabel
    ),
    ...getLayoutVariables(secondLineSectionId3,
      secondLineThirdLabelFont,
      secondLineThirdLabelPattern,
      secondLineThirdLabelOpacity,
      undefined,
      formId,
      footerThirdLabel
    ),
    //Third line
    ...getLayoutVariables(thirdLineFirstLabel,
      thirdLineFirstFont,
      thirdLineFirstPattern,
      thirdLineFirstOpacity,
      undefined,
      formId,
      footerFirstLabel
    ),
    ...getLayoutVariables(thirdLineSecondLabel,
      thirdLineSecondFont,
      thirdLineSecondPattern,
      thirdLineSecondOpacity,
      undefined,
      formId,
      footerSecondLabel
    ),
    ...getLayoutVariables(thirdLineThirdLabel,
      thirdLineThirdFont,
      thirdLineThirdPattern,
      thirdLineThirdOpacity,
      undefined,
      formId,
      footerThirdLabel
    ),

    [firstLineSection]: {
      type: "section",
      metaId: firstLineSection,
      name: firstLineSection,
      fieldIdSet: [firstLineFirstLabel, firstLineSecondLabel, firstLineThirdLabel],
      label: "First"
    } as DefnSection,

    [secondLineSection]: {
      type: "section",
      metaId: secondLineSection,
      name: secondLineSection,
      fieldIdSet: [secondLineFirstLabel, secondLineSectionId2, secondLineSectionId3],
      label: "Second"
    } as DefnSection,

    [thirdLineSection]: {
      type: "section",
      metaId: thirdLineSection,
      name: thirdLineSection,
      fieldIdSet: [thirdLineFirstLabel, thirdLineSecondLabel, thirdLineThirdLabel],
      label: "Third"
    } as DefnSection,

    [fieldFooterTab]: {
      type: "tab",
      metaId: fieldFooterTab,
      name: fieldFooterTab,
      tabIdSet: [firstLineSection, secondLineSection, thirdLineSection]
    } as DefnTab,
    [fieldFooterSection]: {
      type: "section",
      metaId: fieldFooterSection,
      name: "Footer",
      fieldIdSet: [
        fieldFooterShowSeparator,
        fieldGap3,
        fieldFooterBackgroundColorVariable,
        fieldFooterForegroundColorVariable,
        fieldGap4,
        lineLabel,
        fieldFooterTab
      ]
    } as DefnSection
  };
}

function getFormLayoutWatermarkTab(formId?: MetaIdForm, varMap?: StudioVarMap)
{
  const sectionId = waterMark;
  const watermarkFontVariable = {
    fieldMetaId: fieldWatermarkFontVariable,
    fieldLabel: textFontLabel
  } as LayoutVariables;
  const watermarkPatternVariable = {
    fieldMetaId: fieldWatermarkPatternVariable,
    fieldLabel: textPatternLabel
  } as LayoutVariables;
  const watermarkPositionVariable = {
    fieldMetaId: fieldTextPositionVariable,
    fieldLabel: "Text position variable",
    varKind: "placement",
    filterOptionSet: WatermarkPosition,
    excludeVarIdSet: getExcludeVarIdSet(WatermarkPosition as EnumDefnPlacement[], varMap)
  } as LayoutVariables;
  const watermarkOpacityVariable = {
    fieldMetaId: fieldWatermarkOpacityVariable,
    fieldLabel: textOpacityLabel
  } as LayoutVariables;

  return {
    ...getLayoutVariables(
      sectionId,
      watermarkFontVariable,
      watermarkPatternVariable,
      watermarkOpacityVariable,
      watermarkPositionVariable,
      formId
    ),
    [fieldWatermarkSection]: {
      type: "section",
      metaId: fieldWatermarkSection,
      name: "Watermark",
      fieldIdSet: [sectionId]
    } as DefnSection
  };
}

function getLayoutVariables(
  sectionId: string,
  fieldFont: LayoutVariables,
  fieldPattern: LayoutVariables,
  fieldOpacity: LayoutVariables,
  fieldPosition?: LayoutVariables,
  formId?: MetaIdForm,
  fieldLabel?: LayoutVariables)
{
  return {
    ...fieldLabel && {
      [fieldLabel.fieldMetaId]: {
        type: "label",
        metaId: fieldLabel.fieldMetaId,
        label: fieldLabel.fieldLabel,
        disabled: true
      } as DefnFieldLabel
    },

    [fieldPattern.fieldMetaId]: {
      type: "studioVarIdTextEditor",
      metaId: fieldPattern.fieldMetaId,
      label: fieldPattern.fieldLabel,
      argBinderFormId: formId,
      showAsEdit: true,
      filterVarKindSet: ["text"]
    } as DefnStudioVarIdTextEditor,
    [fieldFont.fieldMetaId]: {
      type: "pickVarId",
      metaId: fieldFont.fieldMetaId,
      label: fieldFont.fieldLabel,
      varKind: "textSize",
      showAsEdit: true,
      formId: formId
    } as DefnStudioPickVarId,
    ...fieldPosition && {
      [fieldPosition.fieldMetaId]: {
        type: "pickVarId",
        metaId: fieldPosition?.fieldMetaId,
        label: fieldPosition?.fieldLabel,
        varKind: fieldPosition?.varKind ?? "placement",
        showAsEdit: true,
        formId: formId,
        filterOptionSet: fieldPosition?.filterOptionSet,
        excludeVarIdSet: fieldPosition?.excludeVarIdSet
      } as DefnStudioPickVarId
    },
    [fieldOpacity.fieldMetaId]: {
      type: "pickVarId",
      metaId: fieldOpacity.fieldMetaId,
      label: fieldOpacity.fieldLabel,
      varKind: "decimal",
      showAsEdit: true,
      formId: formId
    } as DefnStudioPickVarId,

    [sectionId]: {
      type: "section",
      metaId: sectionId,
      name: sectionId,
      fieldIdSet: [
        ...fieldLabel
          ? [fieldLabel.fieldMetaId]
          : [],
        fieldPattern.fieldMetaId,
        fieldFont.fieldMetaId,
        ...fieldPosition
          ? [fieldPosition.fieldMetaId]
          : [],
        fieldOpacity.fieldMetaId
      ]
    } as DefnSection

  };
}

function dtoToValues(dto: StudioDtoLayoutFormContentTemplate): FieldValues
{
  const commonFooter = dto.commonFooter;

  const first = commonFooter?.first;
  const second = commonFooter?.second;
  const third = commonFooter?.third;

  return {
    [fieldKeyName]: fnRawValueToFieldValue("symbol", dto.name),
    [fieldKeyDescription]: fnRawValueToFieldValue("paragraph", dto.description),
    [fieldPaperSize]: fnRawValueToFieldValue("enumRenderingKind", dto.paperSize
      ? getPaperSizeDtoToValue(dto.paperSize)
      : undefined
    ),

    [fieldShowEnterprise]: fnRawValueToFieldValue("bool", dto.commonHeader?.showEnterprise),
    [fieldHyperLinkVariable]: fnRawValueToFieldValue("studioSetOfVarId", dto.commonHeader?.hyperlinkVarIdSet),
    [fieldHeaderShowSeparator]: fnRawValueToFieldValue("bool", dto.commonHeader?.showSeparator),
    [fieldHeaderFontVariable]: fnRawValueToFieldValue("pickVarId", dto.commonHeader?.formNameTextSizeVarId),
    [fieldHeaderPatternVariable]: fnRawValueToFieldValue("studioVarIdTextEditor",
      dto.commonHeader?.formNamePatternVarId
    ),
    [fieldHeaderBackgroundColorVariable]: fnRawValueToFieldValue("pickVarId", dto.commonHeader?.backgroundColorVarId),
    [fieldHeaderForegroundColorVariable]: fnRawValueToFieldValue("pickVarId", dto.commonHeader?.foregroundColorVarId),
    [fieldHeaderOpacityVariable]: fnRawValueToFieldValue("pickVarId", dto.commonHeader?.formNameOpacityVarId),
    [fieldHeaderPositionVariable]: fnRawValueToFieldValue("pickVarId", dto.commonHeader?.formNamePositionVarId),

    [fieldWatermarkFontVariable]: fnRawValueToFieldValue("pickVarId", dto.commonWatermark?.textSizeVarId),
    [fieldWatermarkPatternVariable]: fnRawValueToFieldValue("studioVarIdTextEditor",
      dto.commonWatermark?.textPatternVarId
    ),
    [fieldWatermarkOpacityVariable]: fnRawValueToFieldValue("pickVarId", dto.commonWatermark?.textOpacityVarId),
    [fieldTextPositionVariable]: fnRawValueToFieldValue("pickVarId",
      dto.commonWatermark?.textPositionVarId
    ),

    [fieldFooterShowSeparator]: fnRawValueToFieldValue("bool", commonFooter?.showSeparator),
    [fieldFooterBackgroundColorVariable]: fnRawValueToFieldValue("pickVarId", commonFooter?.backgroundColorVarId),
    [fieldFooterForegroundColorVariable]: fnRawValueToFieldValue("pickVarId", commonFooter?.foregroundColorVarId),

    [fieldFooterFontVarId("first", "first")]: fnRawValueToFieldValue("pickVarId",
      first?.first?.textSizeVarId
    ),
    [fieldFooterPatternVarId("first", "first")]: fnRawValueToFieldValue("studioVarIdTextEditor",
      first?.first?.textPatternVarId
    ),
    [fieldFooterOpacityVarId("first", "first")]: fnRawValueToFieldValue("pickVarId",
      first?.first?.textOpacityVarId
    ),

    [fieldFooterFontVarId("first", "second")]: fnRawValueToFieldValue("pickVarId",
      first?.second?.textSizeVarId
    ),
    [fieldFooterPatternVarId("first", "second")]: fnRawValueToFieldValue("studioVarIdTextEditor",
      first?.second?.textPatternVarId
    ),
    [fieldFooterOpacityVarId("first", "second")]: fnRawValueToFieldValue("pickVarId",
      first?.second?.textOpacityVarId
    ),

    [fieldFooterFontVarId("first", "third")]: fnRawValueToFieldValue("pickVarId",
      first?.third?.textSizeVarId
    ),
    [fieldFooterPatternVarId("first", "third")]: fnRawValueToFieldValue("studioVarIdTextEditor",
      first?.third?.textPatternVarId
    ),
    [fieldFooterOpacityVarId("first", "third")]: fnRawValueToFieldValue("pickVarId",
      first?.third?.textOpacityVarId
    ),

    [fieldFooterFontVarId("second", "first")]: fnRawValueToFieldValue("pickVarId",
      second?.first?.textSizeVarId
    ),
    [fieldFooterPatternVarId("second", "first")]: fnRawValueToFieldValue("studioVarIdTextEditor",
      second?.first?.textPatternVarId
    ),
    [fieldFooterOpacityVarId("second", "first")]: fnRawValueToFieldValue("pickVarId",
      second?.third?.textOpacityVarId
    ),

    [fieldFooterFontVarId("second", "second")]: fnRawValueToFieldValue("pickVarId",
      second?.second?.textSizeVarId
    ),
    [fieldFooterPatternVarId("second", "second")]: fnRawValueToFieldValue("studioVarIdTextEditor",
      second?.second?.textPatternVarId
    ),
    [fieldFooterOpacityVarId("second", "second")]: fnRawValueToFieldValue("pickVarId",
      second?.third?.textOpacityVarId
    ),

    [fieldFooterFontVarId("second", "third")]: fnRawValueToFieldValue("pickVarId",
      second?.third?.textSizeVarId
    ),
    [fieldFooterPatternVarId("second", "third")]: fnRawValueToFieldValue("studioVarIdTextEditor",
      second?.third?.textPatternVarId
    ),
    [fieldFooterOpacityVarId("second", "third")]: fnRawValueToFieldValue("pickVarId",
      second?.third?.textOpacityVarId
    ),

    [fieldFooterFontVarId("third", "first")]: fnRawValueToFieldValue("pickVarId",
      third?.third?.textSizeVarId
    ),
    [fieldFooterPatternVarId("third", "first")]: fnRawValueToFieldValue("studioVarIdTextEditor",
      third?.third?.textPatternVarId
    ),
    [fieldFooterOpacityVarId("third", "first")]: fnRawValueToFieldValue("pickVarId",
      first?.third?.textOpacityVarId
    ),

    [fieldFooterFontVarId("third", "second")]: fnRawValueToFieldValue("pickVarId",
      third?.second?.textSizeVarId
    ),
    [fieldFooterPatternVarId("third", "second")]: fnRawValueToFieldValue("studioVarIdTextEditor",
      third?.second?.textPatternVarId
    ),
    [fieldFooterOpacityVarId("third", "second")]: fnRawValueToFieldValue("pickVarId",
      first?.third?.textOpacityVarId
    ),

    [fieldFooterFontVarId("third", "third")]: fnRawValueToFieldValue("pickVarId",
      third?.third?.textSizeVarId
    ),
    [fieldFooterPatternVarId("third", "third")]: fnRawValueToFieldValue("studioVarIdTextEditor",
      third?.third?.textPatternVarId
    ),
    [fieldFooterOpacityVarId("third", "third")]: fnRawValueToFieldValue("pickVarId",
      third?.third?.textOpacityVarId
    )
  };
}

function valueToDto(
  values: FieldValues,
  layout?: StudioDtoLayoutFormContentTemplate
): StudioDtoLayoutFormContentTemplate
{
  const commonHeader = {
    ...layout?.commonHeader,
    formNameTextSizeVarId: fnFieldValueToRawValue("pickVarId", values[fieldHeaderFontVariable]),
    formNameOpacityVarId: fnFieldValueToRawValue("pickVarId", values[fieldHeaderOpacityVariable]),
    formNamePatternVarId: fnFieldValueToRawValue("studioVarIdTextEditor", values[fieldHeaderPatternVariable]),
    formNamePositionVarId: fnFieldValueToRawValue("pickVarId", values[fieldHeaderPositionVariable]),
    hyperlinkVarIdSet: fnFieldValueToRawValue("pickVarId", values[fieldHyperLinkVariable]),
    showEnterprise: fnFieldValueToRawValue("bool", values[fieldShowEnterprise]),
    showSeparator: fnFieldValueToRawValue("bool", values[fieldHeaderShowSeparator]),
    backgroundColorVarId: fnFieldValueToRawValue("pickVarId", values[fieldHeaderBackgroundColorVariable]),
    foregroundColorVarId: fnFieldValueToRawValue("pickVarId", values[fieldHeaderForegroundColorVariable])
  } as StudioLayoutFormHeaderCommon;

  const commonWatermark = {
    ...layout?.commonWatermark,
    textSizeVarId: fnFieldValueToRawValue("pickVarId", values[fieldWatermarkFontVariable]),
    textOpacityVarId: fnFieldValueToRawValue("pickVarId", values[fieldWatermarkOpacityVariable]),
    textPatternVarId: fnFieldValueToRawValue("studioVarIdTextEditor", values[fieldWatermarkPatternVariable]),
    textPositionVarId: fnFieldValueToRawValue("pickVarId", values[fieldTextPositionVariable])
  } as StudioLayoutFormWatermarkCommon;

  const footerFirstLineFirstLabel = fnFieldValueToRawValueFooterLine(values,
    "first",
    "first"
  ) as StudioLayoutFormFooterCommonLine;
  const footerFirstLineSecondLabel = fnFieldValueToRawValueFooterLine(values,
    "first",
    "second"
  ) as StudioLayoutFormFooterCommonLine;
  const footerFirstLineThirdLabel = fnFieldValueToRawValueFooterLine(values,
    "first",
    "third"
  ) as StudioLayoutFormFooterCommonLine;

  const footerSecondLineFirstLabel = fnFieldValueToRawValueFooterLine(values,
    "second",
    "first"
  ) as StudioLayoutFormFooterCommonLine;
  const footerSecondLineSecondLabel = fnFieldValueToRawValueFooterLine(values,
    "second",
    "second"
  ) as StudioLayoutFormFooterCommonLine;
  const footerSecondLineThirdLabel = fnFieldValueToRawValueFooterLine(values,
    "second",
    "third"
  ) as StudioLayoutFormFooterCommonLine;

  const footerThirdLineFirstLabel = fnFieldValueToRawValueFooterLine(values,
    "third",
    "first"
  ) as StudioLayoutFormFooterCommonLine;
  const footerThirdLineSecondLabel = fnFieldValueToRawValueFooterLine(values,
    "third",
    "second"
  ) as StudioLayoutFormFooterCommonLine;
  const footerThirdLineThirdLabel = fnFieldValueToRawValueFooterLine(values,
    "third",
    "third"
  ) as StudioLayoutFormFooterCommonLine;

  const first = {
    ...footerFirstLineFirstLabel && {first: footerFirstLineFirstLabel},
    ...footerFirstLineSecondLabel && {second: footerFirstLineSecondLabel},
    ...footerFirstLineThirdLabel && {third: footerFirstLineThirdLabel}
  } as StudioLayoutFormFooterCommonLine;

  const second = {
    ...footerSecondLineFirstLabel && {first: footerSecondLineFirstLabel},
    ...footerSecondLineSecondLabel && {second: footerSecondLineSecondLabel},
    ...footerSecondLineThirdLabel && {third: footerSecondLineThirdLabel}
  } as StudioLayoutFormFooterCommonLine;

  const third = {
    ...footerThirdLineFirstLabel && {first: footerThirdLineFirstLabel},
    ...footerThirdLineSecondLabel && {second: footerThirdLineSecondLabel},
    ...footerThirdLineThirdLabel && {third: footerThirdLineThirdLabel}
  } as StudioLayoutFormFooterCommonLine;

  const commonFooter = {
    ...layout?.commonFooter,
    first: first,
    second: second,
    third: third,
    showSeparator: fnFieldValueToRawValue("bool", values[fieldFooterShowSeparator]),
    backgroundColorVarId: fnFieldValueToRawValue("pickVarId", values[fieldFooterBackgroundColorVariable]),
    foregroundColorVarId: fnFieldValueToRawValue("pickVarId", values[fieldFooterForegroundColorVariable])
  } as StudioLayoutFormFooterCommon;

  return {
    name: fnFieldValueToRawValue("symbol", values[fieldKeyName]),
    description: fnFieldValueToRawValue("paragraph", values[fieldKeyDescription]),
    paperSize: fnFieldValueToRawValue("enumRenderingKind", getPaperSizeValueToDto(values[fieldPaperSize])),
    commonFooter: commonFooter,
    commonHeader: commonHeader,
    commonWatermark: commonWatermark,
    type: "template"
  } as StudioDtoLayoutFormContentTemplate;
}

function fnFieldValueToRawValueFooterLine(
  values: FieldValues,
  line: TypeLayoutFormFooterLine,
  label: TypeLayoutFormFooterLine): StudioLayoutFormFooterCommonLine | undefined
{
  const firstFirstTextFontVar = fnFieldValueToRawValue("pickVarId", values[fieldFooterFontVarId(line, label)]);
  const firstFirstTextOpacityVarId = fnFieldValueToRawValue("pickVarId",
    values[fieldFooterOpacityVarId(line, label)]
  );
  const firstFirstTextPatternVarId = fnFieldValueToRawValue("studioVarIdTextEditor",
    values[fieldFooterPatternVarId(line, label)]
  );

  if(!firstFirstTextFontVar && !firstFirstTextOpacityVarId && !firstFirstTextPatternVarId)
  {
    return undefined;
  }
  return {
    ...firstFirstTextFontVar && {textFontVarId: firstFirstTextFontVar},
    ...firstFirstTextOpacityVarId && {textOpacityVarId: firstFirstTextOpacityVarId},
    ...firstFirstTextPatternVarId && {textPatternVarId: firstFirstTextPatternVarId}
  } as StudioLayoutFormFooterCommonLine;
}

//layout type
const header = "commonHeader" as TypeLayoutForm;
const waterMark = "commonWatermark" as TypeLayoutForm;
const footer = "commonFooter" as TypeLayoutForm;

const fieldPaperSize = "paperSize";

//Tab Header
const fieldShowEnterprise = "showEnterprise";
const fieldHyperLinkVariable = "hyperlinkVarIdSet";
const fieldHeaderShowSeparatorSection = "fieldHeaderShowSeparatorSection";
const fieldHeaderShowSeparator = "showSeparator";
const fieldHeaderBackgroundColorVariable = getCombinedFieldId([header, "backgroundColorVarId"]);
const fieldHeaderForegroundColorVariable = getCombinedFieldId([header, "foregroundColorVarId"]);

const fieldHeaderFontVariable = "formNameFontVarId";
const fieldHeaderPatternVariable = "formNamePatternVarId";
const fieldHeaderOpacityVariable = "formNameOpacityVarId";
const fieldHeaderPositionVariable = "formNamePositionVarId";

const fieldHeaderSection = "formLayoutHeaderSection";

//Tab Footer
const fieldFooterSection = getCombinedFieldId([footer, "Section"]);
const fieldFooterTab = getCombinedFieldId([footer, "Tab"]);
const fieldFooterShowSeparator = getCombinedFieldId([footer, "ShowSeparator"]);//`${footer}ShowSeparator`;
const fieldFooterBackgroundColorVariable = getCombinedFieldId([footer, "backgroundColorVarId"]);
const fieldFooterForegroundColorVariable = getCombinedFieldId([footer, "foregroundColorVarId"]);

const fieldFooterFontVar = (footerLine: TypeLayoutFormFooterLine, footerLineLabel: TypeLayoutFormFooterLine) =>
{
  return {
    fieldMetaId: fieldFooterFontVarId(footerLine, footerLineLabel),
    fieldLabel: textFontLabel
  } as LayoutVariables;
};

const fieldFooterPatternVar = (footerLine: TypeLayoutFormFooterLine, footerLineLabel: TypeLayoutFormFooterLine) =>
{
  return {
    fieldMetaId: fieldFooterPatternVarId(footerLine, footerLineLabel),
    fieldLabel: textPatternLabel
  } as LayoutVariables;
};

const fieldFooterOpacityVar = (footerLine: TypeLayoutFormFooterLine, footerLineLabel: TypeLayoutFormFooterLine) =>
{
  return {
    fieldMetaId: fieldFooterOpacityVarId(footerLine, footerLineLabel),
    fieldLabel: textOpacityLabel
  } as LayoutVariables;
};

const fieldFooterFontVarId = (footerLine: TypeLayoutFormFooterLine, footerLineLabel: TypeLayoutFormFooterLine) =>
{
  return getCombinedFieldId([footerLine, footerLineLabel, "textFontVarId"]);
};
const fieldFooterPatternVarId = (footerLine: TypeLayoutFormFooterLine, footerLineLabel: TypeLayoutFormFooterLine) =>
{
  return getCombinedFieldId([footerLine, footerLineLabel, "textPatternVarId"]);
};

const fieldFooterOpacityVarId = (footerLine: TypeLayoutFormFooterLine, footerLineLabel: TypeLayoutFormFooterLine) =>
{
  return getCombinedFieldId([footerLine, footerLineLabel, "textOpacityVarId"]);
};

export function getPaperSizeDtoToValue(item: EnumDefnRenderingKind): string
{
  switch(item)
  {
    case "mm_48":
      return "48 mm";
    case "mm_104":
      return "104 mm";
    case "mm_72":
      return "72 mm";
    case "mm_80":
      return "80 mm";
    case "mm_58":
      return "58 mm";
    default:
      return item;
  }
}

export function getPaperSizeValueToDto(item: string): EnumDefnRenderingKind
{
  switch(item)
  {
    case "48 mm":
      return "mm_48";
    case "104 mm":
      return "mm_104";
    case "72 mm":
      return "mm_72";
    case "80 mm":
      return "mm_80";
    case "58 mm":
      return "mm_58";
    default:
      return item as EnumDefnRenderingKind;
  }
}

//Tab WaterMark
const fieldWatermarkSection = "formLayoutWatermarkSection";

const fieldWatermarkFontVariable = getCombinedFieldId([waterMark, "textFontVarId"]);
const fieldWatermarkPatternVariable = getCombinedFieldId([waterMark, "textPatternVarId"]);
const fieldWatermarkOpacityVariable = getCombinedFieldId([waterMark, "textOpacityVarId"]);
const fieldTextPositionVariable = getCombinedFieldId([waterMark, "textPositionVarId"]);

//common label
const textFontLabel = "Text font variable";
const textPatternLabel = "Text pattern variable";
const textOpacityLabel = "Text opacity variable";

const firstLabel = "First label";
const secondLabel = "Second label";
const thirdLabel = "Third label";

const lineLabel = "lineLabel";
