import {EnumDefnFields} from "../../../../api/meta/base/Types";
import {getSystemFieldComp} from "../../../../base/plus/StudioFormPlus";
import {isSystemField} from "../../../../base/plus/StudioFormPlus";
import {IDataGridCellRendererParams} from "../../../../base/types/TypeDataGrid";
import {AgGridContext} from "../../../../base/types/TypeDataGrid";
import {isGridColAction} from "../DataGridPlus";
import {isGridColExpiry} from "../DataGridPlus";
import {isGridColIndex} from "../DataGridPlus";
import {isGridColComments} from "../DataGridPlus";
import GridCellActions from "./GridCellActions";
import GridCellComments from "./GridCellComments";
import GridCellFactory from "./GridCellFactory";
import GridCellFooter from "./GridCellFooter";
import GridCellIndex from "./GridCellIndex";
import GridCellTimer from "./GridCellTimer";

export default function GridCellBase<SR1, SR2, SR3, SR4, SR5, SR6>(props: IDataGridCellRendererParams<SR1, SR2, SR3, SR4, SR5, SR6>)
{
  const colDef = props.colDef;
  const colId = colDef?.colId;

  const isFooterRow = props.data?.isFooterRow;

  const defnForm = (props.context as AgGridContext).defnForm;

  if(isFooterRow)
  {
    return <GridCellFooter {...props} />;
  }
  if(colId)
  {
    if(isGridColComments(colId))
    {
      return <GridCellComments {...props} />;
    }
    else if(isGridColExpiry(colId))
    {
      return <GridCellTimer {...props} />;
    }
    else if(isGridColIndex(colId))
    {
      return <GridCellIndex {...props} />;
    }
    else if(isGridColAction(colId))
    {
      return <GridCellActions {...props} />;
    }
  }

  const cellType = colId
    ? isSystemField(colId)
      ? getSystemFieldComp(colId as EnumDefnFields).type
      : defnForm?.compMap[colId]?.type
    : undefined;
  return <GridCellFactory params={props} cellType={cellType} />;
}

