import {initializeApp} from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyA3WRiDggOUIxi6IAWkfiF67TT9gGKtHO4",
  authDomain: "neome-staging.firebaseapp.com",
  projectId: "neome-staging",
  storageBucket: "neome-staging.appspot.com",
  messagingSenderId: "364482309046",
  appId: "1:364482309046:web:efef975a1ba6345d332f17",
  measurementId: "G-2VMHRK4T8S"
};

const firebaseApp = initializeApp(firebaseConfig);

export {firebaseApp};
