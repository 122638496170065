import {PublicClientApplication} from "@azure/msal-browser";
import {MsalProvider} from "@azure/msal-react";
import {GoogleOAuthProvider} from "@react-oauth/google";
import {LicenseManager} from "ag-grid-enterprise";
import {lazy} from "react";
import React, {Suspense} from "react";
import {ErrorBoundary} from "react-error-boundary";
import {useLocation} from "react-router-dom";
import {Route} from "react-router-dom";
import {Routes} from "react-router-dom";
import {BrowserRouter} from "react-router-dom";
import {Navigate} from "react-router-dom";
import {ROUTE_SIGN_IN_PASSWORD} from "./base/plus/ConstantsPlus";
import {ROUTE_USER_PROFILE} from "./base/plus/ConstantsPlus";
import {STR_AG_GRID_LICENSE_KEY} from "./base/plus/ConstantsPlus";
import {ROUTE_API_Browser} from "./base/plus/ConstantsPlus";
import {ROUTE_WIZARD} from "./base/plus/ConstantsPlus";
import {ROUTE_CONTROL_PANEL} from "./base/plus/ConstantsPlus";
import {ROUTE_STORE} from "./base/plus/ConstantsPlus";
import {ROUTE_COMPONENTS_BROWSER} from "./base/plus/ConstantsPlus";
import {ROUTE_USER_SETTING} from "./base/plus/ConstantsPlus";
import {ROUTE_SIGN_UP} from "./base/plus/ConstantsPlus";
import {ROUTE_REGISTER} from "./base/plus/ConstantsPlus";
import {ROUTE_CREATE_ACCOUNT} from "./base/plus/ConstantsPlus";
import {ROUTE_PREVIEW} from "./base/plus/ConstantsPlus";
import {ROUTE_PREVIEW_CODE} from "./base/plus/ConstantsPlus";
import {ROUTE_DEEP_LINK} from "./base/plus/ConstantsPlus";
import {ROUTE_STUDIO} from "./base/plus/ConstantsPlus";
import {ROUTE_SIGN_IN} from "./base/plus/ConstantsPlus";
import {ROUTE_SIGN_IN_USER_ID} from "./base/plus/ConstantsPlus";
import {ROUTE_USER_ID} from "./base/plus/ConstantsPlus";
import {ROUTE_USER_ID_SIGN_IN} from "./base/plus/ConstantsPlus";
import {ROUTE_LOGIN} from "./base/plus/ConstantsPlus";
import {ROUTE_QR_CODE} from "./base/plus/ConstantsPlus";
import {ROUTE_VERIFY_OTP} from "./base/plus/ConstantsPlus";
import {ROUTE_FORGOT_PASSWORD} from "./base/plus/ConstantsPlus";
import {ROUTE_CHANGE_PASSWORD} from "./base/plus/ConstantsPlus";
// import {ROUTE_RESET_PASSWORD} from "./base/plus/ConstantsPlus";
import {ROUTE_HOME} from "./base/plus/ConstantsPlus";
import {STR_APP_NAME} from "./base/plus/ConstantsPlus";
import {isProd} from "./base/plus/SysPlus";
import {getWindowName} from "./base/plus/SysPlus";
import {LocationState} from "./base/types/TypesGlobal";
import {useAppSelector} from "./nucleus/app/AppHooks";
import PageCtxProvider from "./nucleus/ctx/CtxPage";
import UiAuthFadeLoader from "./routes/auth/atom/UiAuthFadeLoader";
import UiAuthPageFallback from "./routes/auth/page/UiAuthPageFallback";
import {ErrorFallback} from "./routes/auth/page/UiAuthPageFallback";
import {msalConfig} from "./srvc/app/auth/MSAuthConfig";
import {Srvc} from "./srvc/Srvc";

// ent pages
const DeeplinkPage = lazy(() => import("./routes/deeplink/UiDeepLinkPage"));
const HomePage = lazy(() => import("./routes/home/UiHomePage"));
const StudioPage = lazy(() => import("./routes/studio/UiStudioPage"));
const ApiBrowserPage = lazy(() => import("./routes/apiBr/ApiBrPage"));
const ComponentBrowserPage = lazy(() => import("./routes/app/compBr/CompBrPage"));
const StorePage = lazy(() => import("./routes/store/UiStorePage"));
const ProductionPage = lazy(() => import("./routes/controlPanel/UiControlPanelPage"));
const WizardPage = lazy(() => import("./routes/wizard/UiWizardPage"));

// auth pages
const AuthPageResetPassword = lazy(() => import("./routes/auth/page/UiAuthPageResetPassword"));
const AuthPageForgotPassword = lazy(() => import("./routes/auth/page/UiAuthPageForgotPassword"));
const AuthPageQrCode = lazy(() => import("./routes/auth/page/UiAuthPageQrCode"));
const AuthPageSignIn = lazy(() => import("./routes/auth/page/UiAuthPageSignIn"));
const AuthPageSignInPassword = lazy(() => import("./routes/auth/page/UiAuthPageSignInPassword"));
const AuthPageSignInUserId = lazy(() => import("./routes/auth/page/UiAuthPageSignInUserId"));
const AuthPageUserProfile = lazy(() => import("./routes/auth/page/UiAuthPageUserProfile"));
const AuthPageUserSetting = lazy(() => import("./routes/auth/page/UiAuthPageUserSetting"));
const AuthPageSignUp = lazy(() => import("./routes/auth/page/UiAuthPageSignUp"));
const AuthPageVerifyOtp = lazy(() => import("./routes/auth/page/UiAuthPageVerifyOtp"));

Srvc.init();

LicenseManager.setLicenseKey(STR_AG_GRID_LICENSE_KEY);

export default function App()
{
  if(!window.sessionStorage)
  {
    return (
      <UiAuthPageFallback title="A modern browser is required to run this app." />
    );
  }

  const msalInstance = new PublicClientApplication(msalConfig);

  return (
    <BrowserRouter>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <Suspense fallback={<UiAuthFadeLoader />}>
          <PageCtxProvider>
            <GoogleOAuthProvider clientId="364482309046-gsntseuvu66ge3ilgpbcvku7k21o43b6.apps.googleusercontent.com">
              <MsalProvider instance={msalInstance}>
                <RouteSwitch />
              </MsalProvider>
            </GoogleOAuthProvider>
          </PageCtxProvider>
        </Suspense>
      </ErrorBoundary>
    </BrowserRouter>
  );
}

function RouteSwitch(): React.JSX.Element
{
  return (
    <Routes>
      <Route
        path={ROUTE_SIGN_IN}
        element={<AuthPageSignIn />}
      />
      <Route
        path={ROUTE_SIGN_IN_PASSWORD}
        element={<SkipRoute actual={<AuthPageSignInPassword />} />}
      />
      <Route
        path={ROUTE_SIGN_IN_USER_ID}
        element={<SkipRoute actual={<AuthPageSignInUserId />} />}
      />
      <Route
        path={ROUTE_LOGIN}
        element={<AuthPageSignIn />}
      />
      <Route
        path={ROUTE_USER_PROFILE}
        element={<AuthPageUserProfile />}
      />

      <Route
        path={ROUTE_USER_ID}
        element={<AuthPageSignInUserId />}
      />
      <Route
        path={ROUTE_USER_ID_SIGN_IN}
        element={<AuthPageSignInUserId />}
      />

      <Route
        path={ROUTE_SIGN_UP}
        element={<SkipRoute actual={<AuthPageSignUp />} />}
      />
      <Route
        path={ROUTE_REGISTER}
        element={<SkipRoute actual={<AuthPageSignUp />} />}
      />
      <Route
        path={ROUTE_CREATE_ACCOUNT}
        element={<SkipRoute actual={<AuthPageSignUp />} />}
      />
      <Route
        path={ROUTE_FORGOT_PASSWORD}
        element={<SkipRoute actual={<AuthPageForgotPassword />} />}
      />
      <Route
        path={ROUTE_CHANGE_PASSWORD}
        element={<SkipRoute actual={<AuthPageForgotPassword />} />}
      />
      {/*<Route*/}
      {/*  path={ROUTE_RESET_PASSWORD}*/}
      {/*  element={<RequireAuth actual={<AuthPageResetPassword />} />}*/}
      {/*/>*/}

      <Route
        path={ROUTE_QR_CODE}
        element={<SkipRoute actual={<AuthPageQrCode />} />}
      />
      <Route
        path={ROUTE_VERIFY_OTP}
        element={<SkipRoute actual={<AuthPageVerifyOtp />} />}
      />

      <Route
        path={ROUTE_PREVIEW}
        element={<RequireAuth actual={<DeeplinkPage />} />}
      />
      <Route
        path={ROUTE_DEEP_LINK}
        element={<RequireAuth actual={<DeeplinkPage />} />}
      />
      <Route
        path={ROUTE_PREVIEW_CODE}
        element={<DlRequireAuth />}
      />

      <Route
        path={ROUTE_STUDIO}
        element={<RequireAuth actual={<StudioPage />} />}
      />

      <Route
        path={ROUTE_CONTROL_PANEL}
        element={<RequireAuth actual={<ProductionPage />} />}
      />

      <Route
        path={ROUTE_HOME}
        element={<RequireAuth actual={<HomePage />} />}
      />

      <Route
        path={ROUTE_API_Browser}
        element={<RequireAuth actual={<ApiBrowserPage />} />}
      />

      {!isProd() &&
        <Route
          path={ROUTE_COMPONENTS_BROWSER}
          element={<SkipRoute actual={<ComponentBrowserPage />} />}
        />
      }

      <Route
        path={ROUTE_STORE}
        element={<RequireAuth actual={<StorePage />} />}
      />

      <Route
        path={ROUTE_WIZARD}
        element={<RequireAuth actual={<WizardPage />} />}
      />

      <Route
        path={ROUTE_USER_SETTING}
        element={<RequireAuth actual={<AuthPageUserSetting />} />}
      />

      <Route
        path="*"
        element={<Navigate
          to={ROUTE_HOME}
          replace
        />}
      />
    </Routes>
  );
}

function RequireAuth(props: {
  actual: React.JSX.Element
}): React.JSX.Element
{
  const location = useLocation();
  const flagBearerToken = useAppSelector(state => state.app.auth.flagBearerToken);

  if(flagBearerToken)
  {
    return props.actual;
  }
  else
  {
    return (
      <Navigate
        to={ROUTE_SIGN_IN}
        state={{from: location} as LocationState}
        replace
      />
    );
  }
}

// If already signed in, skip this route (typically happens if the URL is typed after sign-in)
function SkipRoute(props: {
  actual: React.JSX.Element
}): React.JSX.Element
{
  const windowName = getWindowName();
  if(windowName && windowName === STR_APP_NAME)
  {
    return (
      <Navigate
        to={ROUTE_HOME}
        replace
      />
    );
  }
  else
  {
    return props.actual;
  }
}

function DlRequireAuth(): React.JSX.Element
{
  return <DeeplinkPage />;
}
