import {useEffect} from "react";
import React, {useMemo} from "react";
import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnField} from "../../api/meta/base/dto/DefnField";
import {DefnSection} from "../../api/meta/base/dto/DefnSection";
import {DefnStudioBuildArgBinder} from "../../api/meta/base/dto/DefnStudioBuildArgBinder";
import {StudioBuildArgBinder} from "../../api/meta/base/dto/StudioBuildArgBinder";
import {StudioMapOfArgBinder} from "../../api/meta/base/dto/StudioMapOfArgBinder";
import {StudioVarParagraph} from "../../api/meta/base/dto/StudioVarParagraph";
import {StudioVarText} from "../../api/meta/base/dto/StudioVarText";
import {ArgBinderValueVarIdBaseFieldType} from "../../api/meta/base/StudioSetsFieldType";
import {StudioBuildArgBinderParamVariableType} from "../../api/meta/base/StudioSetsVarType";
import {EnumStudioVarKind} from "../../api/meta/base/Types";
import {MetaIdVar} from "../../api/meta/base/Types";
import {MetaIdField, MetaIdForm} from "../../api/meta/base/Types";
import {fnRawValueToFieldValueArgBinder} from "../../base/plus/FieldValuePlus";
import {fnFieldValueToRawValueArgBinder} from "../../base/plus/FieldValuePlus";
import {createDefaultDefnFormStudio, defaultSectionKey} from "../../base/plus/FormPlus";
import {FormStore, IFormRef} from "../../base/types/TypesForm";
import DialogDefnForm from "./base/impl/DialogDefnForm";

export default function DialogLinkVariable(props: {
  paramsSet: string[],
  formId: MetaIdForm,
  formStore: FormStore,
  values?: StudioMapOfArgBinder,
  onClickOk: (values: StudioMapOfArgBinder) => void,
  onClose?: () => void,
  excludeFieldIdSet?: MetaIdField[],
  excludeVarIdSet?: MetaIdVar[],
  argBinderFilterVarKindSet?: EnumStudioVarKind[];
})
{
  const values = props.values;
  const onClickSubmit = props.onClickOk;
  const paramSet = props.paramsSet;
  const formId = props.formId;
  const excludeFieldIdSet = props.excludeFieldIdSet;
  const excludeVarIdSet = props.excludeVarIdSet
    ? props.excludeVarIdSet
    : [];
  const argBinderFilterVarKindSet = props.argBinderFilterVarKindSet;
  const formStore = props.formStore;

  const formRef = useMemo(() => ({} as IFormRef), []);

  const varMap = formStore?.varMap;

  useEffect(() =>
  {
    if(varMap?.keys && varMap.keys.length > 0)
    {
      varMap.keys.forEach((varId) =>
      {
        const variable = varMap.map[varId];
        if(variable.kind === "text" || variable.kind === "paragraph")
        {
          const _variable = variable as StudioVarText | StudioVarParagraph;
          if(_variable.value?.paramSet)
          {
            excludeVarIdSet.push(varId);
          }
        }
      });
    }
  }, [varMap]);

  return (
    <DialogDefnForm
      title={`Link default text variable`}
      formProps={{
        store: formStore,
        defnForm: getDefnForm(
          paramSet,
          formId,
          excludeFieldIdSet,
          excludeVarIdSet,
          argBinderFilterVarKindSet
        ),
        cbRef: formRef,
        onSubmit: values => onClickSubmit(valueToDto(paramSet, values)),
        initValues: {...dtoToValue(values)}
      }}
      onClose={props.onClose}
      contentWidth={550}
    />
  );
}

function valueToDto(
  paramSet: string[],
  values: FieldValues
): StudioMapOfArgBinder
{
  const keys = [] as string[];
  const map = {} as Record<string, StudioBuildArgBinder>;

  paramSet.forEach((_, index) =>
  {
    const value = fnFieldValueToRawValueArgBinder(values[getParamField(index)]) as StudioBuildArgBinder;

    if(value)
    {
      keys.push(value.argName);
      map[value.argName] = value;
    }

  });

  return {
    keys: keys,
    map: map
  };
}

function dtoToValue(dto?: StudioMapOfArgBinder)
{
  let valueMap = {} as FieldValues;

  if(dto)
  {
    const keys = dto.keys;
    const map = dto.map;

    keys?.forEach((param, index) =>
    {
      const argBinder = map[param];
      if(argBinder)
      {
        const paramFieldKey = getParamField(index);
        valueMap = {
          ...valueMap,
          ...fnRawValueToFieldValueArgBinder(paramFieldKey, argBinder)
        };
      }
    });

    return valueMap;
  }
}

function getDefnForm(
  paramSet: string[],
  formId: MetaIdForm,
  excludeFieldIdSet?: MetaIdField[],
  excludeVarIdSet?: MetaIdVar[],
  argBinderFilterVarKindSet?: EnumStudioVarKind[])
{
  const allSections = getAllSections(
    paramSet,
    formId,
    excludeFieldIdSet,
    excludeVarIdSet,
    argBinderFilterVarKindSet
  );

  return createDefaultDefnFormStudio({
    ...allSections,

    [defaultSectionKey]: {
      type: "section",
      metaId: defaultSectionKey,
      fieldIdSet: Object.keys(allSections)
    } as DefnSection
  });
}

function getAllSections(
  paramSet: string[],
  formId: MetaIdForm,
  excludeFieldIdSet?: MetaIdField[],
  excludeVarIdSet?: MetaIdVar[],
  argBinderFilterVarKindSet?: EnumStudioVarKind[]
): Record<MetaIdField, DefnField>
{
  const fieldSectionMap = {} as Record<MetaIdField, DefnField>;
  paramSet.forEach((param, index) =>
  {
    const fieldKey = getParamField(index);

    fieldSectionMap[fieldKey] = {
      type: "studioBuildArgBinder",
      metaId: fieldKey,
      name: param,
      label: param,
      formId: formId,
      derivedFormId: formId,
      excludeVarIdSet: excludeVarIdSet,
      excludeFieldIdSet: excludeFieldIdSet,
      filterVarKindSet: argBinderFilterVarKindSet || StudioBuildArgBinderParamVariableType,
      filterFieldTypeSet: ArgBinderValueVarIdBaseFieldType,
      direction: "horizontal"
    } as DefnStudioBuildArgBinder;
  });

  return fieldSectionMap;
}

const fieldParamKey = "fieldParam";

function getParamField(index: number)
{
  return fieldParamKey + index;
}


