import {isArray} from "lodash";
import React from "react";
import {Controller} from "react-hook-form";
import {DefnFieldEditable} from "../../../../api/meta/base/dto/DefnFieldEditable";
import {FieldChipSetDeviceType} from "../../../../api/meta/base/dto/FieldChipSetDeviceType";
import {EnumArrayDeviceType} from "../../../../api/meta/base/Types";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {arrayToMapOfOption} from "../../../../base/plus/JsPlus";
import {useFormCtx} from "../base/CtxForm";
import FieldRawStudioPick from "../raw/FieldRawStudioPick";

export default function FieldDeviceTypeSet(props: {
  defn: DefnFieldEditable
})
{
  const formCtx = useFormCtx();
  const defn = props.defn;
  const fieldId = getFieldKey(defn);

  return (
    <Controller
      name={fieldId}
      control={formCtx.control()}
      render={({
        field,
        fieldState
      }) =>
      {
        const fieldValue = field.value as FieldChipSetDeviceType | undefined;

        const onChange = (values: string[]) =>
        {
          if(values && isArray(values))
          {
            field.onChange({
              valueSet: values
            } as FieldChipSetDeviceType);
          }
        };

        return (
          <FieldRawStudioPick
            fieldProps={{
              field: {
                ...field,
                value: fieldValue?.valueSet,
                onChange: onChange
              },
              fieldState: fieldState
            }}
            defn={props.defn}
            optionMap={arrayToMapOfOption(EnumArrayDeviceType)}
            multiSelect={true}
            showChip={true}
          />
        );
      }}
    />
  );
}
