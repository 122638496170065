import React from "react";
import {Controller} from "react-hook-form";
import {DefnFieldPickEnum} from "../../../../api/meta/base/dto/DefnFieldPickEnum";
import {EnumArrayDefnShowBorderKind} from "../../../../api/meta/base/Types";
import {EnumArrayDefnMonth} from "../../../../api/meta/base/Types";
import {EnumArrayDefnCaptureValueKind} from "../../../../api/meta/base/Types";
import {EnumArrayDefnDocFileExt} from "../../../../api/meta/base/Types";
import {EnumArrayStoreLabel} from "../../../../api/meta/base/Types";
import {EnumArrayDefnPluginSecurityAccess} from "../../../../api/meta/base/Types";
import {EnumDefnCompType} from "../../../../api/meta/base/Types";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {arrayToMapOfOption} from "../../../../base/plus/JsPlus";
import {useFormCtx} from "../base/CtxForm";
import FieldRawStudioPick from "../raw/FieldRawStudioPick";

export default function FieldPickEnumSet(props: {
  type: EnumDefnCompType,
  defn: DefnFieldPickEnum
})
{
  const formCtx = useFormCtx();

  const defn = props.defn;
  const type = props.type;
  const filterOptionSet = defn.filterOptionSet;

  const fieldId = getFieldKey(defn);

  let options: string[] = [];

  switch(type)
  {
    case "studioSetOfBorder":
      options = EnumArrayDefnShowBorderKind;
      break;
    case "studioSetOfPluginSecurityAccess":
      options = EnumArrayDefnPluginSecurityAccess;
      break;
    case "studioSetOfStoreItemCategory":
      options = EnumArrayStoreLabel;
      break;
    case "studioSetOfDocFileExt":
      options = EnumArrayDefnDocFileExt;
      break;
    case "enumCaptureValueKind":
      options = EnumArrayDefnCaptureValueKind;
      break;
    case "studioSetOfMonth":
      options = EnumArrayDefnMonth;
      break;
  }

  if(filterOptionSet)
  {
    options = filterOptionSet;
  }

  return (
    <Controller
      name={fieldId}
      control={formCtx.control()}
      render={(fieldProps) =>
        <FieldRawStudioPick
          fieldProps={fieldProps}
          defn={defn}
          optionMap={arrayToMapOfOption(options, type === "studioSetOfStoreItemCategory")}
          multiSelect={true}
          showChip={true}
        />
      }
    />
  );
}
