import {useMemo} from "react";
import {useEffect} from "react";
import {useCallback} from "react";
import React from "react";
import {useFormContext} from "react-hook-form";
import {DefnFieldEditable} from "../../../../api/meta/base/dto/DefnFieldEditable";
import {DefnLayoutGridTable} from "../../../../api/meta/base/dto/DefnLayoutGridTable";
import {FieldValueGrid} from "../../../../api/meta/base/dto/FieldValueGrid";
import {FormValueRaw} from "../../../../api/meta/base/dto/FormValueRaw";
import {STR_CLICK_HERE_TO_ADD_RECORDS} from "../../../../base/plus/ConstantsPlus";
import {px} from "../../../../base/plus/StringPlus";
import {random} from "../../../../base/plus/StringPlus";
import {moveListItem} from "../../../../base/plus/StudioPlus";
import {getEmptyKeysAndMap} from "../../../../base/plus/StudioPlus";
import {gapHalf} from "../../../../base/plus/ThemePlus";
import theme from "../../../../base/plus/ThemePlus";
import {gapStd} from "../../../../base/plus/ThemePlus";
import {CbOnClickDataGrid} from "../../../../base/types/TypeDataGrid";
import {IDataGridData} from "../../../../base/types/TypeDataGrid";
import LayoutFlexCol from "../../../atom/layout/LayoutFlexCol";
import RawButtonStrip from "../../../atom/raw/RawButtonStrip";
import RawQuickLink from "../../../atom/raw/RawQuickLink";
import {dataGridRowHeight} from "../../../grid/base/DataGridPlus";
import {DataGrid} from "../../../grid/DataGrid";
import {useFormCtx} from "../base/CtxForm";
import {IFieldGridRawProps} from "../composite/FieldGrid";

export default function FieldRawFieldGridTable(props: IFieldGridRawProps)
{
  const defnGrid = props.defnGrid;
  const fieldId = defnGrid.metaId;
  const defnForm = props.defnForm;
  const onChange = props.onChange;
  const layoutTable = props.layout as DefnLayoutGridTable;
  const fieldValue = useMemo(() => props.fieldValue
      ? props.fieldValue
      : getEmptyKeysAndMap() as FieldValueGrid
    , [props.fieldValue]);

  const formCtx = useFormCtx();
  const hookFormCtx = useFormContext();
  const defnTheme = formCtx.getDefnTheme();
  const isReport = defnTheme.formVariant === "report";
  const getOnClick = formCtx.getOnClick();
  const readOnly = formCtx.isFieldReadonly(defnGrid);
  const disabled = formCtx.isFieldDisable(defnGrid as DefnFieldEditable) || defnGrid.disabled;
  const cbClick = useCallback(() => getOnClick && getOnClick(fieldId, "fieldGridAddNew"), [fieldId, getOnClick]);
  const isRowAction = Boolean(defnGrid?.rowActionPermissionMap?.keys.length);
  const hideMenu = (readOnly || disabled) || (isReport && !isRowAction);
  const showIndex = Boolean(defnGrid.indexFieldNameVar?.value?.length);
  const indexLabel = defnGrid.indexFieldNameVar?.value?.join("");

  const hideAddRecordText = useMemo(() =>
  {

    return Boolean(readOnly || disabled || isReport);

  }, [disabled, isReport, readOnly]);

  const hideAddBtn = useMemo(() =>
  {
    const isFieldValuePresent = Boolean(fieldValue && fieldValue.keys.length > 0);

    return Boolean(!isFieldValuePresent || readOnly || disabled || isReport);

  }, [fieldValue, disabled, isReport, readOnly]);

  const footerValueMap = useMemo(() =>
  {
    const valueMap = hookFormCtx.getValues();
    return layoutTable?.footer?.keys.reduce((acc, key) =>
    {
      const footer = layoutTable.footer?.map[key];
      if(footer?.displayFieldId)
      {
        acc[footer.displayFieldId] = valueMap[footer.displayFieldId];
      }
      return acc;
    }, {} as Record<string, any>);
  }, [hookFormCtx, defnForm]);

  const initRows = useMemo(() =>
  {
    return {
      keys: fieldValue.keys,
      map: fieldValue.map as Record<string, FormValueRaw>,
      version: random()
    };
  }, [fieldValue.keys, fieldValue.map]);

  const initValues = useMemo(() =>
  {
    if(layoutTable && fieldValue)
    {
      return {
        defnForm: defnForm,
        layout: layoutTable,
        showColIndex: showIndex,
        colIndexName: indexLabel,
        footer: footerValueMap,
        showColMenu: !hideMenu,
        borderTop: !isReport ? theme.common.reportBorder : undefined,
        version: random()
      } as IDataGridData;
    }
  }, [defnForm, fieldValue, footerValueMap, hideMenu, indexLabel, layoutTable, showIndex]);

  const moveRow = useCallback((rowId: string, direction: "up" | "down" | "top" | "bottom") =>
  {
    const keys = fieldValue.keys;
    moveListItem(rowId, keys, direction);
    onChange && onChange(fieldValue);
  }, [fieldValue, onChange]);

  const onClickMenu = useCallback<CbOnClickDataGrid>((menuAnchor, variant, row) =>
  {
    const isFirst = row.rowId === fieldValue.keys[0];
    const isLast = row.rowId === fieldValue.keys[fieldValue.keys.length - 1];
    const menuProps = {
      "Edit": {
        onClick: () =>
        {
          getOnClick && getOnClick(fieldId, "fieldGridItem", fieldValue.map[row.rowId], menuAnchor);
        }
      },
      "Remove": {
        onClick: () =>
        {
          const newGridValue = {
            keys: fieldValue.keys.filter(key => key !== row.rowId),
            map: {
              ...fieldValue.map,
              [row.rowId]: undefined
            }
          } as FieldValueGrid;
          onChange && onChange(newGridValue);
        }
      },
      "gap1": undefined,
      "Move up": {
        onClick: () =>
        {
          moveRow(row.rowId, "up");
        },
        disabled: isFirst
      },
      "Move down": {
        onClick: () =>
        {
          moveRow(row.rowId, "down");
        },
        disabled: isLast
      },
      "Move top": {
        onClick: () =>
        {
          moveRow(row.rowId, "top");
        },
        disabled: isFirst
      },
      "Move bottom": {
        onClick: () =>
        {
          moveRow(row.rowId, "bottom");
        },
        disabled: isLast
      }
    };
    getOnClick && getOnClick(fieldId,
      "fieldGridRowAction",
      fieldValue.map[row.rowId],
      menuAnchor,
      !isReport ? menuProps : undefined
    );

  }, [fieldId, fieldValue.keys, fieldValue.map, getOnClick, moveRow, onChange]);

  const cbOnClickDataGrid: CbOnClickDataGrid = useCallback((menuAnchor, variant, row, cellId) =>
  {
    switch(variant)
    {
      case "actionBtnSpotMenu":
        onClickMenu(menuAnchor, variant, row);
        break;
      case "cell":
        if(!row.isFooterRow)
        {
          getOnClick && getOnClick(fieldId, "fieldGridItem", fieldValue.map[row.rowId], menuAnchor);
          if(cellId)
          {
            const fieldHyperlinkRow = formCtx.getFieldIdHyperlinkRow(cellId);
            if(fieldHyperlinkRow)
            {
              getOnClick && getOnClick(cellId, "fieldGridRowHyperlinkRow", fieldValue.map[row.rowId], menuAnchor);
            }
          }
        }
    }
  }, [fieldId, fieldValue.map, getOnClick, onClickMenu]);

  const onClickIconAddBtn = useCallback(() =>
  {
    getOnClick && getOnClick(fieldId, "fieldGridAddNew");

  }, []);

  const containerHeight = useMemo(() =>
  {
    if(layoutTable.renderingMode === "auto")
    {
      return `${px((fieldValue.keys.length * dataGridRowHeight) + dataGridRowHeight
        + 2)} !important`;
    }
  }, [fieldValue.keys.length, layoutTable.renderingMode]);

  useEffect(() =>
  {
    formCtx.setCbFieldRemoteClick(fieldId, cbClick);
  }, [cbClick, fieldId, formCtx]);

  if(!hideAddRecordText && !fieldValue.keys.length)
  {
    return (
      <LayoutFlexCol
        height={"100%"}
        width={"100%"}
      >
        <RawQuickLink
          name={STR_CLICK_HERE_TO_ADD_RECORDS}
          Icon={"AddRounded"}
          onClick={onClickIconAddBtn}
          color={"primary"}
        />
      </LayoutFlexCol>
    );
  }

  if(initValues)
  {
    return (
      <LayoutFlexCol
        alignItems={"end"}
        justifyContent={"end"}
        {...layoutTable.renderingMode === "auto"
          ? {height: containerHeight}
          : {flexGrow: 1}
        }
      >
        <DataGrid
          initValues={initValues}
          initRows={initRows}
          cbOnClickDataGrid={cbOnClickDataGrid}
        />

        {!hideAddBtn &&
          <LayoutFlexCol
            pt={px(gapHalf)}
            pr={px(gapStd)}
          >
            <RawButtonStrip
              iconButtonList={["add"]}
              onClick={onClickIconAddBtn}
              size={"large"}
            />
          </LayoutFlexCol>
        }
      </LayoutFlexCol>
    );
  }
}
