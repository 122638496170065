import {Typography} from "@mui/material";
import {useTheme} from "@mui/material";
import {Box} from "@mui/material";
import {StandardCSSProperties} from "@mui/system/styleFunctionSx/StandardCssProperties";
import {Property} from "csstype";
import {useLayoutEffect} from "react";
import React from "react";
import {useState} from "react";
import Highlighter from "react-highlight-words";
import {fnRemovedMarkDownSymbols} from "../../../../base/plus/StringPlus";
import {removeRegex} from "../../../../base/plus/StringPlus";
import {px} from "../../../../base/plus/StringPlus";
import theme from "../../../../base/plus/ThemePlus";
import {parseLinkText} from "../../../../base/plus/UrlPlus";
import LayoutFlexRow from "../../layout/LayoutFlexRow";
import RawQuickLink from "../../raw/RawQuickLink";

export default function BubbleRawText(props: {
  value: string,
  searchWords?: string[],
  mentionWords?: string[],
  maxCharLimit?: number,
  pl?: StandardCSSProperties["paddingLeft"],
  pr?: StandardCSSProperties["paddingRight"],
  pt?: StandardCSSProperties["paddingTop"],
  pb?: StandardCSSProperties["paddingBottom"],
  fakeSpace?: React.ReactNode,
  caption?: React.ReactNode,
  justifyContent?: Property.JustifyContent,
  onClickSendMessageToUser?: (mentionUser: string, menuAnchor: Element) => void
})
{
  const {
    value,
    searchWords,
    maxCharLimit,
    pl,
    pr,
    pt,
    pb,
    justifyContent,
    fakeSpace,
    mentionWords
  } = props;

  const [currText, setCurrText] = useState("");
  const [isReadMore, setIsReadMore] = useState<boolean | undefined>(undefined);

  const theme = useTheme();
  const gapHalf = theme.common.gapHalf;
  const gapQuarter = theme.common.gapQuarter;
  const vertInner = theme.common.vertInner;
  const gapLeft = gapHalf - (theme.common.bubbleShellGap + theme.common.bubbleShellBorder);
  const gapRight = theme.common.gapHalf + theme.common.gapQuarter;

  const onClick = () =>
  {
    setCurrText(value);
    setIsReadMore(false);
  };

  useLayoutEffect(() =>
  {
    if(maxCharLimit && value.length > maxCharLimit)
    {
      setCurrText(value.slice(0, maxCharLimit) + "...");
      setIsReadMore(true);
      return;
    }
    setCurrText(value);
  }, [value, maxCharLimit]);

  if(isReadMore === undefined && maxCharLimit && value.length > maxCharLimit)
  {
    setCurrText(value.slice(0, maxCharLimit) + "...");
    setIsReadMore(true);
  }

  return (
    <Box
      sx={{
        width: "100%",
        position: "relative",
        pt: pt ?? px(gapQuarter),
        pr: pr ?? px(gapRight),
        pb: pb,
        pl: pl ?? px(gapLeft),
        userSelect: "text",
        WebkitUserSelect: "text"
      }}
    >
      <LayoutFlexRow justifyContent={justifyContent || "start"}>
        <RealText
          textValue={currText}
          searchWords={searchWords}
          mentionWords={mentionWords}
          fakeSpace={fakeSpace}
          onClickSendMessageToUser={props.onClickSendMessageToUser}
        />
      </LayoutFlexRow>

      {isReadMore &&
        <RawQuickLink
          name={"Read more"}
          variant={"body2"}
          color={"success"}
          onClick={onClick}
        />}
      <Box
        component={"span"}
        justifyContent={"end"}
        sx={{
          position: "absolute",
          right: 0,
          bottom: -vertInner
        }}
      >
        {props.caption}
      </Box>
    </Box>
  );
}

function RealText(props: {
  textValue: string,
  searchWords?: string[],
  mentionWords?: string[],
  fakeSpace?: React.ReactNode,
  onClickSendMessageToUser?: (mentionUser: string, menuAnchor: Element) => void
})
{
  const textValue = props.textValue;
  const searchWords = props.searchWords;
  const mentionWords = props.mentionWords;
  const fakeSpace = props.fakeSpace;
  const onClickSendMessageToUser = props.onClickSendMessageToUser;
  const parseLink = false;

  return (
    <Typography
      component={"span"}
      variant={"body2"}
      noWrap={false}
      sx={{
        color: theme.common.color("textPrimary"),
        overflow: "hidden",
        overflowWrap: "anywhere",
        whiteSpace: "pre-wrap",
        WebkitUserSelect: "text"
      }}
    >
      <Box
        component={"span"}
        sx={{userSelect: "text", wordBreak: "break-word"}}
      >
        {
          (searchWords === undefined
            ? parseLinkText(textValue, mentionWords, onClickSendMessageToUser, parseLink)
            : (<Highlighter
              searchWords={searchWords.map(str => removeRegex(str))}
              textToHighlight={fnRemovedMarkDownSymbols(textValue)}
              highlightStyle={{background: theme.common.bgcolorHighlight}}
            />))
        }
      </Box>

      <Box
        component={"span"}
        visibility={"hidden"}
      >
        {fakeSpace}
      </Box>
    </Typography>
  );
}
