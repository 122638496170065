import {DefnBuildDate} from "../../../../api/meta/base/dto/DefnBuildDate";
import {DefnBuildDateTime} from "../../../../api/meta/base/dto/DefnBuildDateTime";
import {DefnComp} from "../../../../api/meta/base/dto/DefnComp";
import {DefnDtoOption} from "../../../../api/meta/base/dto/DefnDtoOption";
import {DefnField} from "../../../../api/meta/base/dto/DefnField";
import {DefnFieldDate} from "../../../../api/meta/base/dto/DefnFieldDate";
import {DefnFieldDateTime} from "../../../../api/meta/base/dto/DefnFieldDateTime";
import {DefnFieldDecimal} from "../../../../api/meta/base/dto/DefnFieldDecimal";
import {DefnFieldEditable} from "../../../../api/meta/base/dto/DefnFieldEditable";
import {DefnFieldLogDecimal} from "../../../../api/meta/base/dto/DefnFieldLogDecimal";
import {DefnFieldLogNumber} from "../../../../api/meta/base/dto/DefnFieldLogNumber";
import {DefnFieldNumber} from "../../../../api/meta/base/dto/DefnFieldNumber";
import {DefnFieldParagraph} from "../../../../api/meta/base/dto/DefnFieldParagraph";
import {DefnFieldSlider} from "../../../../api/meta/base/dto/DefnFieldSlider";
import {DefnFieldSwitch} from "../../../../api/meta/base/dto/DefnFieldSwitch";
import {DefnFieldText} from "../../../../api/meta/base/dto/DefnFieldText";
import {DefnSection} from "../../../../api/meta/base/dto/DefnSection";
import {DefnStudioMapOfDtoOption} from "../../../../api/meta/base/dto/DefnStudioMapOfDtoOption";
import {DefnStudioPickFieldId} from "../../../../api/meta/base/dto/DefnStudioPickFieldId";
import {DefnStudioPickVarId} from "../../../../api/meta/base/dto/DefnStudioPickVarId";
import {DefnStudioVarIdTextEditor} from "../../../../api/meta/base/dto/DefnStudioVarIdTextEditor";
import {DefnTab} from "../../../../api/meta/base/dto/DefnTab";
import {FieldDtoTree} from "../../../../api/meta/base/dto/FieldDtoTree";
import {StudioVarMap} from "../../../../api/meta/base/dto/StudioVarMap";
import {EnumDefnDate} from "../../../../api/meta/base/Types";
import {EnumDefnFormContentLayoutType} from "../../../../api/meta/base/Types";
import {MetaIdComposite} from "../../../../api/meta/base/Types";
import {EnumDefnCompType} from "../../../../api/meta/base/Types";
import {MetaIdVar} from "../../../../api/meta/base/Types";
import {PluginApiId} from "../../../../api/meta/base/Types";
import {MetaIdField} from "../../../../api/meta/base/Types";
import {MetaIdPlugin} from "../../../../api/meta/base/Types";
import {MetaIdGrid} from "../../../../api/meta/base/Types";
import {MetaIdSpreadsheet} from "../../../../api/meta/base/Types";
import {MetaIdForm} from "../../../../api/meta/base/Types";
import {EnumStudioCompType} from "../../../../api/meta/base/Types";
import {EnumStudioVarKind} from "../../../../api/meta/base/Types";
import {IExcludeMappingVars} from "../../../../base/plus/StudioPlus";
import {IResolvedIOFormNames} from "../../../../base/plus/StudioPlus";
import {FORMS_MAX_SIZE} from "../../../atom/assets/HelperTextStudio";
import {getDefnFieldAudio} from "../field/advanced/FieldBuilderAudio";
import {getDefnFieldCamera} from "../field/advanced/FieldBuilderCamera";
import {getDefnFieldColor} from "../field/advanced/FieldBuilderColor";
import {getDefnFieldCounter} from "../field/advanced/FieldBuilderCounter";
import {getDefnFieldCounterLogged} from "../field/advanced/FieldBuilderCounterLogged";
import {getDefnFieldDateRange} from "../field/advanced/FieldBuilderDateRange";
import {getDefnFieldDateTime} from "../field/advanced/FieldBuilderDateTime";
import {getDefnFieldDateTimeRange} from "../field/advanced/FieldBuilderDateTimeRange";
import {getDefnFieldDuration} from "../field/advanced/FieldBuilderDuration";
import {getEmailFieldEmail} from "../field/advanced/FieldBuilderEmail";
import {getDefnFieldHandle} from "../field/advanced/FieldBuilderHandle";
import {getDefnFieldHyperLink} from "../field/advanced/FieldBuilderHyperLink";
import {getDefnFieldLocation} from "../field/advanced/FieldBuilderLocation";
import {getDefnFieldMobileNumber} from "../field/advanced/FieldBuilderMobileNumber";
import {getDefnFieldRating} from "../field/advanced/FieldBuilderRating";
import {getDefnFieldSignature} from "../field/advanced/FieldBuilderSignature";
import {getDefnFieldSlider} from "../field/advanced/FieldBuilderSlider";
import {getDefnFieldTime} from "../field/advanced/FieldBuilderTime";
import {getDefnFieldVideo} from "../field/advanced/FieldBuilderVideo";
import {getDefnFieldVoice} from "../field/advanced/FieldBuilderVoice";
import {getDefnFieldBool} from "../field/basic/FieldBuilderBool";
import {getDefnFieldDate} from "../field/basic/FieldBuilderDate";
import {getDefnFieldDecimal} from "../field/basic/FieldBuilderDecimal";
import {getDefnFieldDecimalLogged} from "../field/basic/FieldBuilderDecimalLogged";
import {getDefnFieldImage} from "../field/basic/FieldBuilderImage";
import {getDefnFieldLabel} from "../field/basic/FieldBuilderLabel";
import {getDefnFieldNumber} from "../field/basic/FieldBuilderNumber";
import {getDefnFieldNumberLogged} from "../field/basic/FieldBuilderNumberLogged";
import {getDefnFieldParagraph} from "../field/basic/FieldBuilderParagraph";
import {getDefnFieldText} from "../field/basic/FieldBuilderText";
import {getDefnFieldChipSet} from "../field/chipset/FieldBuilderChipSet";
import {getDefnFieldPaymentStatus} from "../field/enums/FieldBuilderPaymentStatus";
import {getDefnFieldPickCurrency} from "../field/enums/FieldBuilderPickCurrency";
import {getDefnFieldPickIcon} from "../field/enums/FieldBuilderPickIcon";
import {getDefnFieldPickLanguage} from "../field/enums/FieldBuilderPickLanguage";
import {getDefnFieldPickLineStroke} from "../field/enums/FieldBuilderPickLineStroke";
import {getDefnFieldPickMonth} from "../field/enums/FieldBuilderPickMonth";
import {getDefnFieldPickPinShape} from "../field/enums/FieldBuilderPickPinShape";
import {getDefnFieldPickQuarter} from "../field/enums/FieldBuilderPickQuarter";
import {getDefnFieldPickTextSize} from "../field/enums/FieldBuilderPickTextSize";
import {getDefnFieldPickTimeZone} from "../field/enums/FieldBuilderPickTimeZone";
import {getDefnFieldPickGridRowSourceTab} from "../field/picker/FieldBuilderPickGridRow";
import {getDefnFieldPickGridRow} from "../field/picker/FieldBuilderPickGridRow";
import {getDefnFieldPickRole} from "../field/picker/FieldBuilderPickRole";
import {getDefnFieldPickTextSourceTab} from "../field/picker/FieldBuilderPickText";
import {getDefnFieldPickText} from "../field/picker/FieldBuilderPickText";
import {getDefnFieldPickTree} from "../field/picker/FieldBuilderPickTree";
import {getDefnFieldTreeSourceTab} from "../field/picker/FieldBuilderPickTree";
import {getDefnFieldUserSourceTab} from "../field/picker/FieldBuilderPickUser";
import {getDefnFieldUser} from "../field/picker/FieldBuilderPickUser";
import {getDefnFieldButton} from "../field/pro/FieldBuilderButton";
import {getDefnFieldDivider} from "../field/pro/FieldBuilderDivider";
import {getDefnFieldDocument} from "../field/pro/FieldBuilderDocument";
import {getDefnFieldDynamic} from "../field/pro/FieldBuilderDynamic";
import {getDefnFieldError} from "../field/pro/FieldBuilderError";
import {getDefnFieldHtml} from "../field/pro/FieldBuilderHtml";
import {getDefnFieldHyperlinkRow} from "../field/pro/FieldBuilderHyperlinkRow";
import {getDefnFieldIdentifier} from "../field/pro/FieldBuilderIdentifier";
import {getDefnFieldInfo} from "../field/pro/FieldBuilderInfo";
import {getDefnFieldPickReportRowSourceTab} from "../field/pro/FieldBuilderPickReportRow";
import {getDefnFieldPickReportRow} from "../field/pro/FieldBuilderPickReportRow";
import {getDefnFieldPropertyMap} from "../field/pro/FieldBuilderPropertyMap";
import {getDefnFieldRef} from "../field/pro/FieldBuilderRef";
import {getDefnFieldRefReport} from "../field/pro/FieldBuilderRefReport";
import {getDefnFieldRefUserSourceTab} from "../field/pro/FieldBuilderRefUser";
import {getDefnFieldRefUser} from "../field/pro/FieldBuilderRefUser";
import {getDefnFieldRowId} from "../field/pro/FieldBuilderRowId";
import {getDefnFieldScanCode} from "../field/pro/FieldBuilderScanCode";
import {getDefnFieldShowCode} from "../field/pro/FieldBuilderShowCode";
import {getDefnFieldSymbol} from "../field/pro/FieldBuilderSymbol";
import {getDefnFieldUserId} from "../field/pro/FieldBuilderUserId";
import {getDefnFieldSetOfDate} from "../field/set/FieldBuilderSetOfDate";
import {getDefnFieldSetOfDateTime} from "../field/set/FieldBuilderSetOfDateTime";
import {getDefnFieldSetOfDay} from "../field/set/FieldBuilderSetOfDay";
import {getDefnFieldSetOfDeviceSize} from "../field/set/FieldBuilderSetOfDeviceSize";
import {getDefnFieldSetOfDeviceType} from "../field/set/FieldBuilderSetOfDeviceType";
import {getDefnFieldSetOfRole} from "../field/set/FieldBuilderSetOfRole";
import {getDefnFieldPickSetOfTextSourceTab} from "../field/set/FieldBuilderSetOfText";
import {getDefnFieldSetOfText} from "../field/set/FieldBuilderSetOfText";
import {getDefnFieldSetOfTime} from "../field/set/FieldBuilderSetOfTime";
import {getDefnFieldSetOfEntUserIdSourceTab} from "../field/set/FieldBuilderSetOfUser";
import {getDefnFieldSetOfEntUserId} from "../field/set/FieldBuilderSetOfUser";
import {TypeTextValidationPattern} from "./FieldBuilderPlus";
import {showSourceSubTab} from "./FieldBuilderPlus";
import {showPropertiesSubTab} from "./FieldBuilderPlus";
import {propKeyMaxSizeFieldId} from "./TypesFormBuilder";
import {propKeyMaxSizeVar} from "./TypesFormBuilder";
import {propKeyMaxSize} from "./TypesFormBuilder";
import {fieldSourcePluginTab} from "./TypesFormBuilder";
import {fieldSourceVariableTab} from "./TypesFormBuilder";
import {fieldSourceConstTab} from "./TypesFormBuilder";
import {fieldSourceSubTabId} from "./TypesFormBuilder";
import {propKeyDefaultValueDateTime} from "./TypesFormBuilder";
import {propKeyDefaultValueCustom} from "./TypesFormBuilder";
import {propKeySuffix} from "./TypesFormBuilder";
import {propKeyPrefix} from "./TypesFormBuilder";
import {propKeyIconValue} from "./TypesFormBuilder";
import {fieldGap5} from "./TypesFormBuilder";
import {fieldGap4} from "./TypesFormBuilder";
import {fieldGap3} from "./TypesFormBuilder";
import {fieldGap2} from "./TypesFormBuilder";
import {propKeyMax} from "./TypesFormBuilder";
import {propKeyMin} from "./TypesFormBuilder";
import {propKeyMaxCharCount} from "./TypesFormBuilder";
import {propKeyMinCharCount} from "./TypesFormBuilder";
import {propKeyHelperTextValue} from "./TypesFormBuilder";
import {propKeyPlaceHolderValue} from "./TypesFormBuilder";
import {propKeyDefaultValue} from "./TypesFormBuilder";
import {propKeyMaxCharacterCount} from "./TypesFormBuilder";
import {propKeyMinCharacterCount} from "./TypesFormBuilder";
import {propKeyDisabledVarId} from "./TypesFormBuilder";
import {propKeyRequiredVarId} from "./TypesFormBuilder";
import {propKeyPlaceHolderFieldId} from "./TypesFormBuilder";
import {propKeyHelperTextFieldId} from "./TypesFormBuilder";
import {propKeyMinCharCountFieldId} from "./TypesFormBuilder";
import {propKeyMaxCharCountFieldId} from "./TypesFormBuilder";
import {propKeyMinFieldId} from "./TypesFormBuilder";
import {propKeyMaxFieldId} from "./TypesFormBuilder";
import {propKeyDefaultFieldId} from "./TypesFormBuilder";
import {propKeyDisabledField} from "./TypesFormBuilder";
import {propKeyRequiredField} from "./TypesFormBuilder";
import {fieldPropertiesConst} from "./TypesFormBuilder";
import {fieldPropertiesVar} from "./TypesFormBuilder";
import {fieldPropertiesField} from "./TypesFormBuilder";
import {fieldPropertiesSubTabId} from "./TypesFormBuilder";
import {propKeyShowLabel} from "./TypesFormBuilder";
import {propKeyDefaultValueTextVar} from "./TypesFormBuilder";
import {propKeyAutoFill} from "./TypesFormBuilder";
import {propKeyDefaultValueVar} from "./TypesFormBuilder";
import {propKeyVarPlaceholder} from "./TypesFormBuilder";
import {propKeyVarIcon} from "./TypesFormBuilder";
import {propKeyRequired} from "./TypesFormBuilder";
import {propKeyDisabled} from "./TypesFormBuilder";
import {propKeyAutoFocus} from "./TypesFormBuilder";
import {propKeyVarHelperText} from "./TypesFormBuilder";
import {propKeyPrefixVar} from "./TypesFormBuilder";
import {propKeySuffixVar} from "./TypesFormBuilder";
import {propKeyMinVar} from "./TypesFormBuilder";
import {propKeyMaxVar} from "./TypesFormBuilder";
import {fieldGap1} from "./TypesFormBuilder";
import {getFieldGap} from "./TypesFormBuilder";

export function fieldBuilderFactory(
  fieldType: EnumStudioCompType,
  formId: MetaIdForm,
  spreadsheetId?: MetaIdSpreadsheet,
  optionArray?: DefnDtoOption[],
  sourceVar?: FieldDtoTree,
  includeFieldIdSet?: MetaIdField[],
  fieldId?: MetaIdField,
  helperTextSpreadsheetFormName?: string,
  showIconPosition?: boolean,
  allowRangePicker?: boolean,
  selectedRefFieldIdSet?: MetaIdField[],
  sourceFormId?: MetaIdForm,
  varMap?: StudioVarMap,
  reportOutputFormId?: MetaIdForm,
  gridId?: MetaIdGrid,
  helperTextReportFormName?: IResolvedIOFormNames,
  hideRefreshInMenu?: boolean,
  showCustomDate?: boolean,
  defaultValue?: EnumDefnDate,
  showCustomDateFrom?: boolean,
  showCustomDateTo?: boolean,
  showCustomMax?: boolean,
  showCustomMin?: boolean,
  sectionIdSetWithCurrentGridId ?: MetaIdComposite[],
  defaultStepCount?: number,
  defaultValueTime?: string,
  minValueTime?: string,
  maxValueTime?: string,
  compositeId?: MetaIdComposite,
  categoryDisplayFields?: DefnStudioMapOfDtoOption,
  validationPattern?: TypeTextValidationPattern
)
{

  switch(fieldType)
  {
    case "pickText":
    case "ref":
    case "pickTree":
    case "camera":
    case "color":
    case "rowId" :
    case "hyperlinkRow" :
    case "propertyMap":
    case "userId":
    case "bool":
    case "date":
    case "document":
    case "divider":
    case "decimal":
    case "number":
    case "paragraph":
    case "text":
    case "label":
    case "audio":
    case "counter":
    case "dateRange":
    case "dateTime":
    case "dateTimeRange":
    case "duration":
    case "email":
    case "error":
    case "handle":
    case "hyperlink":
    case "location":
    case "mobileNumber":
    case "rating":
    case "time":
    case "video":
    case "voice":
    case "textSize":
    case "lineStroke":
    case "month":
    case "pinShape":
    case "quarter":
    case "dynamic":
    case "html":
    case "info":
    case "symbol":
    case "showCode":
    case "button":
    case "image":
    case "refReport":
    case "chipSetDate":
    case "chipSetDateTime":
    case "chipSetTime":
    case "icon":
      return {
        ...fieldBuilderUnCommonFieldProperties(
          fieldType,
          formId,
          spreadsheetId,
          optionArray,
          sourceVar,
          includeFieldIdSet,
          fieldId,
          helperTextSpreadsheetFormName,
          showIconPosition,
          allowRangePicker,
          selectedRefFieldIdSet,
          sourceFormId,
          varMap,
          reportOutputFormId,
          gridId,
          helperTextReportFormName,
          hideRefreshInMenu,
          showCustomDate,
          defaultValue,
          showCustomDateFrom,
          showCustomDateTo,
          showCustomMax,
          showCustomMin,
          sectionIdSetWithCurrentGridId,
          defaultStepCount,
          defaultValueTime,
          minValueTime,
          maxValueTime,
          compositeId,
          categoryDisplayFields,
          validationPattern
        )
      };
    default:
      return {
        ...getDefaultCheckBoxes(fieldType),
        ...getFieldGap(fieldGap1, "thick"),
        ...fieldBuilderUnCommonFieldProperties(
          fieldType,
          formId,
          spreadsheetId,
          optionArray,
          sourceVar,
          includeFieldIdSet,
          fieldId,
          helperTextSpreadsheetFormName,
          showIconPosition,
          allowRangePicker,
          selectedRefFieldIdSet,
          sourceFormId,
          varMap,
          reportOutputFormId,
          gridId,
          helperTextReportFormName,
          hideRefreshInMenu,
          showCustomDate,
          defaultValue,
          showCustomDateFrom,
          showCustomDateTo,
          showCustomMax,
          showCustomMin,
          sectionIdSetWithCurrentGridId,
          defaultStepCount,
          defaultValueTime,
          minValueTime,
          maxValueTime,
          compositeId,
          categoryDisplayFields,
          validationPattern
        )
      };
  }
}

export function getDefaultCheckBoxes(fieldType?: EnumStudioCompType)
{
  return {
    [propKeyAutoFocus]: {
      type: "bool",
      metaId: propKeyAutoFocus,
      name: propKeyAutoFocus,
      label: "Auto focus",
      showAsCheckboxVar: true
    } as DefnFieldSwitch,
    ...getAutofill(fieldType),
    ...getShowLabel(fieldType)
  };
}

export function getShowLabel(fieldType?: EnumStudioCompType)
{
  switch(fieldType)
  {
    case "image":
    case "camera":
    case "showCode":
    case "info":
      return {
        [propKeyShowLabel]: {
          type: "bool",
          metaId: "showLabel",
          name: "Show label",
          label: "Show label",
          showAsCheckboxVar: true
        } as DefnFieldSwitch
      };
    default:
      return {};
  }
}

function getAutofill(fieldType?: EnumStudioCompType)
{
  switch(fieldType)
  {
    case "text":
    case "number":
    case "hyperlink":
    case "handle":
    case "mobileNumber":
    case "email":
    case "identifier":
    case "symbol":
      return {
        [propKeyAutoFill]: {
          type: "bool",
          metaId: propKeyAutoFill,
          name: propKeyAutoFill,
          label: "Auto fill",
          showAsCheckboxVar: true
        } as DefnFieldSwitch
      };
    default:
      return {};
  }
}

export function getDefaultValueVariableStudioField(
  type: EnumStudioVarKind,
  formId: MetaIdForm,
  fieldId?: MetaIdField)
{
  const excludeFieldIdSet = fieldId ? [fieldId] : undefined;

  return {
    ...(type === "paragraph" || type === "text")
      ? {
        [propKeyDefaultValueTextVar]: {
          type: "studioVarIdTextEditor",
          metaId: propKeyDefaultValueTextVar,
          name: "Default value",
          argBinderFormId: formId,
          showAsEdit: true,
          excludeFieldIdSet: excludeFieldIdSet,
          filterVarKindSet: [type]
        } as DefnStudioVarIdTextEditor
      }
      : {
        [propKeyDefaultValueVar]: {
          type: "pickVarId",
          metaId: propKeyDefaultValueVar,
          name: "Default value",
          varKind: type,
          showAsEdit: true,
          formId: formId
        } as DefnStudioPickVarId
      }
  };
}

export function getSourceTab(params: {
  formId: MetaIdForm,
  fieldType: EnumStudioCompType,
  metaIdPlugin?: MetaIdPlugin,
  excludeGridId?: MetaIdGrid,
  gridId?: MetaIdGrid,
  isPluginInputFormPresent?: boolean,
  helperTextPluginApiIOFormName?: IResolvedIOFormNames,
  helperTextReportFormName?: IResolvedIOFormNames,
  isPluginApiHelperTextVisible?: boolean,
  excludePluginApiIdSet?: PluginApiId[],
  notValidPluginInputFormMappingVars?: IExcludeMappingVars,
  isPluginForm?: boolean,
  excludeGridRowVarIdSet?: MetaIdVar[],
  selectedRefFieldIdSet?: MetaIdField[],
  reportOutputFormId?: MetaIdForm,
  disableCopyFields?: boolean,
  asideLayoutPickEnum?: EnumDefnFormContentLayoutType
})
{
  const {
    formId,
    fieldType,
    metaIdPlugin,
    excludeGridId,
    gridId,
    isPluginInputFormPresent,
    helperTextPluginApiIOFormName,
    helperTextReportFormName,
    isPluginApiHelperTextVisible,
    excludePluginApiIdSet,
    notValidPluginInputFormMappingVars,
    isPluginForm,
    excludeGridRowVarIdSet,
    selectedRefFieldIdSet,
    reportOutputFormId,
    disableCopyFields
  } = params;

  switch(fieldType)
  {
    case "pickText":
      return getDefnFieldPickTextSourceTab(
        metaIdPlugin,
        formId,
        isPluginInputFormPresent,
        helperTextPluginApiIOFormName,
        isPluginApiHelperTextVisible,
        excludePluginApiIdSet,
        notValidPluginInputFormMappingVars,
        isPluginForm
      );
    case "pickTree":
      return getDefnFieldTreeSourceTab(
        formId,
        metaIdPlugin,
        isPluginInputFormPresent,
        helperTextPluginApiIOFormName,
        isPluginApiHelperTextVisible,
        excludePluginApiIdSet,
        notValidPluginInputFormMappingVars,
        isPluginForm
      );
    case "pickUser":
      return getDefnFieldUserSourceTab(
        formId,
        metaIdPlugin,
        isPluginInputFormPresent,
        helperTextPluginApiIOFormName,
        isPluginApiHelperTextVisible,
        excludePluginApiIdSet,
        notValidPluginInputFormMappingVars,
        isPluginForm
      );
    case "refUser":
      return getDefnFieldRefUserSourceTab(
        formId,
        metaIdPlugin,
        isPluginInputFormPresent,
        helperTextPluginApiIOFormName,
        isPluginApiHelperTextVisible,
        excludePluginApiIdSet,
        notValidPluginInputFormMappingVars,
        isPluginForm
      );
    case "pickGridRow":
      return getDefnFieldPickGridRowSourceTab(
        formId,
        excludeGridId,
        gridId,
        excludeGridRowVarIdSet,
        selectedRefFieldIdSet
      );
    case "pickReportRow":
      return getDefnFieldPickReportRowSourceTab(
        reportOutputFormId,
        gridId,
        helperTextReportFormName,
        selectedRefFieldIdSet,
        disableCopyFields
      );
    case "setOfText":
      return getDefnFieldPickSetOfTextSourceTab(
        formId,
        metaIdPlugin,
        isPluginInputFormPresent,
        helperTextPluginApiIOFormName,
        isPluginApiHelperTextVisible,
        excludePluginApiIdSet,
        notValidPluginInputFormMappingVars,
        isPluginForm
      );
    case "setOfUser":
      return getDefnFieldSetOfEntUserIdSourceTab(
        formId,
        metaIdPlugin,
        isPluginInputFormPresent,
        helperTextPluginApiIOFormName,
        isPluginApiHelperTextVisible,
        excludePluginApiIdSet,
        notValidPluginInputFormMappingVars,
        isPluginForm
      );
    default:
      return {};
  }
}

function fieldBuilderUnCommonFieldProperties(
  fieldType: EnumStudioCompType,
  formId: MetaIdForm,
  spreadsheetId?: MetaIdSpreadsheet,
  optionArray?: DefnDtoOption[],
  sourceVar?: FieldDtoTree,
  includeFieldIdSet?: MetaIdField[],
  fieldId?: MetaIdField,
  helperTextSpreadsheetFormName?: string,
  showIconPosition?: boolean,
  allowRangePicker?: boolean,
  selectedRefFieldIdSet?: MetaIdField[],
  sourceFormId?: MetaIdForm,
  varMap?: StudioVarMap,
  reportOutputFormId?: MetaIdForm,
  gridId?: MetaIdGrid,
  helperTextReportFormName?: IResolvedIOFormNames,
  hideRefreshInMenu?: boolean,
  showCustom?: boolean,
  defaultValue?: EnumDefnDate,
  showCustomFrom?: boolean,
  showCustomDateTo?: boolean,
  showCustomMax?: boolean,
  showCustomMin?: boolean,
  filterCompositeIdSet?: MetaIdComposite[],
  defaultStepCount?: number,
  defaultValueTime?: string,
  minValueTime?: string,
  maxValueTime?: string,
  compositeId?: MetaIdComposite,
  categoryDisplayFields?: DefnStudioMapOfDtoOption,
  validationPattern?: TypeTextValidationPattern
)
{
  switch(fieldType)
  {
    case "audio":
      return getDefnFieldAudio(formId, fieldId, filterCompositeIdSet);
    case "bool":
      return getDefnFieldBool(formId, fieldId, varMap, filterCompositeIdSet);
    case "button":
      return getDefnFieldButton(formId, fieldId, showIconPosition, varMap, filterCompositeIdSet);
    case "camera":
      return getDefnFieldCamera(formId, fieldId, filterCompositeIdSet);
    case "chipSet":
      return getDefnFieldChipSet(formId, fieldId, filterCompositeIdSet);
    case "color":
      return getDefnFieldColor(formId, fieldId, filterCompositeIdSet);
    case "counter":
      return getDefnFieldCounter(formId, fieldId, filterCompositeIdSet, defaultStepCount);
    case "logCounter":
      return getDefnFieldCounterLogged(formId, fieldId, filterCompositeIdSet);
    case "date":
      return getDefnFieldDate(formId, fieldId, showCustom, showCustomMax, showCustomMin, filterCompositeIdSet);
    case "dateRange":
      return getDefnFieldDateRange(formId,
        fieldId,
        showCustomFrom,
        showCustomDateTo,
        showCustomMax,
        showCustomMin,
        filterCompositeIdSet
      );
    case "dateTime":
      return getDefnFieldDateTime(formId,
        fieldId,
        showCustom,
        defaultValue,
        showCustomMax,
        showCustomMin,
        filterCompositeIdSet
      );
    case "dateTimeRange":
      return getDefnFieldDateTimeRange(formId,
        fieldId,
        showCustomFrom,
        showCustomDateTo,
        showCustomMax,
        showCustomMin,
        filterCompositeIdSet
      );
    case "decimal":
      return getDefnFieldDecimal(formId, fieldId, filterCompositeIdSet);
    case "logDecimal":
      return getDefnFieldDecimalLogged(formId, fieldId, filterCompositeIdSet);
    case "divider":
      return getDefnFieldDivider(formId, fieldId, filterCompositeIdSet);
    case "document":
      return getDefnFieldDocument(formId, fieldId, filterCompositeIdSet);
    case "duration":
      return getDefnFieldDuration(formId, fieldId, filterCompositeIdSet);
    case "email":
      return getEmailFieldEmail(formId, fieldId, filterCompositeIdSet);
    case "error":
      return getDefnFieldError(formId, fieldId, filterCompositeIdSet);
    case "grid":
      break;
    case "handle":
      return getDefnFieldHandle(formId, fieldId, filterCompositeIdSet);
    case "html":
      return getDefnFieldHtml(formId, fieldId, filterCompositeIdSet);
    case "hyperlink":
      return getDefnFieldHyperLink(formId, fieldId, filterCompositeIdSet);
    case "identifier":
      return getDefnFieldIdentifier(formId, fieldId, filterCompositeIdSet);
    case "image":
      return getDefnFieldImage(formId, fieldId, filterCompositeIdSet);
    case "info":
      return getDefnFieldInfo(formId, fieldId, varMap, filterCompositeIdSet);
    case "label":
      return getDefnFieldLabel(formId, fieldId, varMap, filterCompositeIdSet);
    case "location":
      return getDefnFieldLocation(formId, fieldId, filterCompositeIdSet);
    case "mobileNumber":
      return getDefnFieldMobileNumber(formId, fieldId, filterCompositeIdSet);
    case "number":
      return getDefnFieldNumber(formId, fieldId, filterCompositeIdSet);
    case "logNumber":
      return getDefnFieldNumberLogged(formId, fieldId, filterCompositeIdSet);
    case "paragraph":
      return getDefnFieldParagraph(formId, fieldId, filterCompositeIdSet);
    case "currency":
      return getDefnFieldPickCurrency(formId, fieldId, filterCompositeIdSet);
    case "icon":
      return getDefnFieldPickIcon(formId, fieldId, filterCompositeIdSet);
    case "language":
      return getDefnFieldPickLanguage(formId, fieldId, filterCompositeIdSet);
    case "pickText":
      return getDefnFieldPickText(formId, fieldId, optionArray, includeFieldIdSet, filterCompositeIdSet);
    case "pickRole":
      return getDefnFieldPickRole(formId, fieldId, includeFieldIdSet, filterCompositeIdSet);
    case "setOfRole":
      return getDefnFieldSetOfRole(formId, fieldId, includeFieldIdSet, filterCompositeIdSet);
    case "timeZone":
      return getDefnFieldPickTimeZone(formId, fieldId, filterCompositeIdSet);
    case "pickUser":
      return getDefnFieldUser(formId, fieldId, filterCompositeIdSet);
    case "refUser":
      return getDefnFieldRefUser(formId, fieldId, filterCompositeIdSet);
    case "userId":
      return getDefnFieldUserId(formId, fieldId, filterCompositeIdSet);
    case "pickGridRow":
      return getDefnFieldPickGridRow(formId, undefined, filterCompositeIdSet);
    case "pinShape":
      return getDefnFieldPickPinShape(formId, fieldId, filterCompositeIdSet);
    case "pickTree":
      return getDefnFieldPickTree(formId, fieldId, filterCompositeIdSet, sourceVar, includeFieldIdSet);
    case "propertyMap":
      return getDefnFieldPropertyMap(formId, fieldId, filterCompositeIdSet);
    case "lineStroke":
      return getDefnFieldPickLineStroke(formId, fieldId, filterCompositeIdSet);
    case "month":
      return getDefnFieldPickMonth(formId, fieldId, filterCompositeIdSet);
    case "quarter":
      return getDefnFieldPickQuarter(formId, fieldId, filterCompositeIdSet);
    case "textSize":
      return getDefnFieldPickTextSize(formId, fieldId, filterCompositeIdSet);
    case "rating":
      return getDefnFieldRating(formId, fieldId, filterCompositeIdSet);
    case "ref":
      return getDefnFieldRef(
        spreadsheetId,
        helperTextSpreadsheetFormName,
        selectedRefFieldIdSet,
        sourceFormId,
        hideRefreshInMenu,
        compositeId,
        categoryDisplayFields
      );
    case "refReport":
      return getDefnFieldRefReport(
        spreadsheetId,
        reportOutputFormId,
        helperTextReportFormName,
        selectedRefFieldIdSet,
        gridId
      );
    case "pickReportRow":
      return getDefnFieldPickReportRow(formId, fieldId, filterCompositeIdSet);
    case "paymentStatus":
      return getDefnFieldPaymentStatus(formId, fieldId, filterCompositeIdSet);
    case "rowId":
      return getDefnFieldRowId(formId, fieldId, filterCompositeIdSet);
    case "hyperlinkRow":
      return getDefnFieldHyperlinkRow(formId, fieldId, filterCompositeIdSet);
    case "scanCode":
      return getDefnFieldScanCode(formId, fieldId, filterCompositeIdSet);
    case "showCode":
      return getDefnFieldShowCode(formId, fieldId, filterCompositeIdSet);
    case "section":
      break;
    case "chipSetDate":
      return getDefnFieldSetOfDate(formId, fieldId, filterCompositeIdSet);
    case "chipSetDateTime":
      return getDefnFieldSetOfDateTime(formId, fieldId, filterCompositeIdSet);
    case "chipSetDay":
      return getDefnFieldSetOfDay(formId, fieldId, filterCompositeIdSet);
    case "chipSetDeviceSize":
      return getDefnFieldSetOfDeviceSize(formId, fieldId, filterCompositeIdSet);
    case "chipSetDeviceType":
      return getDefnFieldSetOfDeviceType(formId, fieldId, filterCompositeIdSet);
    case "setOfUser":
      return getDefnFieldSetOfEntUserId(formId, fieldId, filterCompositeIdSet);
    case "setOfText":
      return getDefnFieldSetOfText(formId, fieldId, optionArray, includeFieldIdSet, filterCompositeIdSet);
    case "chipSetTime":
      return getDefnFieldSetOfTime(formId, fieldId, filterCompositeIdSet);
    case "signature":
      return getDefnFieldSignature(formId, fieldId, filterCompositeIdSet);
    case "slider":
      return getDefnFieldSlider(formId, fieldId, allowRangePicker, filterCompositeIdSet);
    case "symbol":
      return getDefnFieldSymbol(formId, fieldId, filterCompositeIdSet);
    case "text":
      return getDefnFieldText(formId, fieldId, filterCompositeIdSet, validationPattern);
    case "dynamic":
      return getDefnFieldDynamic(formId, fieldId, filterCompositeIdSet);
    case "time":
      return getDefnFieldTime(
        formId,
        fieldId,
        filterCompositeIdSet,
        defaultValueTime,
        minValueTime,
        maxValueTime
      );
    case "video":
      return getDefnFieldVideo(formId, fieldId, filterCompositeIdSet);
    case "voice":
      return getDefnFieldVoice(formId, fieldId, filterCompositeIdSet);
    default:
      return {};
  }
}

function fieldBuilderPropertySubTabsField(
  fieldType: EnumStudioCompType,
  formId: MetaIdForm,
  fieldId?: MetaIdField,
  propertyFields?: MetaIdField[],
  sectionIdSetWithCurrentGridId?: MetaIdComposite[]
)
{
  const isRequiredIncluded = propertyFields?.includes(propKeyRequiredField);
  const isDisabledIncluded = propertyFields?.includes(propKeyDisabledField);
  const isDefaultValueIncluded = propertyFields?.includes(propKeyDefaultFieldId);
  const isPlaceHolderIncluded = propertyFields?.includes(propKeyPlaceHolderFieldId);
  const isHelperTextIncluded = propertyFields?.includes(propKeyHelperTextFieldId);
  const isMinCharCountIncluded = propertyFields?.includes(propKeyMinCharCountFieldId);
  const isMaxCharCountIncluded = propertyFields?.includes(propKeyMaxCharCountFieldId);
  const isMinIncluded = propertyFields?.includes(propKeyMinFieldId);
  const isMaxIncluded = propertyFields?.includes(propKeyMaxFieldId);
  const isMaxSizeIncluded = propertyFields?.includes(propKeyMaxSizeFieldId);
  const {
    isGap1Included,
    isGap2Included,
    isGap3Included,
    isGap4Included,
    isGap5Included
  } = getPropertyFieldsGapIncludedKeys(propertyFields);

  const excludeFieldIdSet = fieldId ? [fieldId] : undefined;
  const fieldIdSet = propertyFields ?? [];
  const filterMinMaxFieldTypeSet = [] as EnumDefnCompType[];
  const filterDefaultFieldTypeSet = [] as EnumDefnCompType[];

  switch(fieldType)
  {
    case "decimal":
    case "logDecimal":
      filterMinMaxFieldTypeSet.push("decimal");
      filterMinMaxFieldTypeSet.push("logDecimal");
      break;
    case "date":
    case "dateRange":
      filterMinMaxFieldTypeSet.push("date");
      break;
    case "dateTime":
    case "dateTimeRange":
      filterMinMaxFieldTypeSet.push("dateTime");
      break;
    case "duration":
      filterMinMaxFieldTypeSet.push("duration");
      break;
    case "time":
      filterMinMaxFieldTypeSet.push("time");
      break;

    default:
      filterMinMaxFieldTypeSet.push("number");
      filterMinMaxFieldTypeSet.push("logNumber");
      filterMinMaxFieldTypeSet.push("counter");
      filterMinMaxFieldTypeSet.push("logCounter");
      break;
  }

  switch(fieldType)
  {
    case "info":
    case "showCode":
      filterDefaultFieldTypeSet.push("paragraph");
      break;
    case "slider":
    case "rating":
    case "number":
    case "logNumber":
    case "counter":
    case "logCounter":
      filterDefaultFieldTypeSet.push("number");
      filterDefaultFieldTypeSet.push("logNumber");
      filterDefaultFieldTypeSet.push("counter");
      filterDefaultFieldTypeSet.push("logCounter");
      break;
    case "decimal":
    case "logDecimal":
      filterDefaultFieldTypeSet.push("decimal");
      filterDefaultFieldTypeSet.push("logDecimal");
      break;

    default:
      filterDefaultFieldTypeSet.push(fieldType);
      break;
  }

  return {
    ...isRequiredIncluded && {
      [propKeyRequiredField]: {
        type: "pickFieldId",
        metaId: propKeyRequiredField,
        name: propKeyRequiredField,
        label: "Required",
        formId: formId,
        filterFieldTypeSet: ["bool"],
        compositeIdSet: sectionIdSetWithCurrentGridId,
        showCompositeName: true,
        excludeFieldIdSet: excludeFieldIdSet
      } as DefnStudioPickFieldId
    },

    ...isDisabledIncluded && {
      [propKeyDisabledField]: {
        type: "pickFieldId",
        metaId: propKeyDisabledField,
        name: propKeyDisabledField,
        label: "Disabled",
        formId: formId,
        filterFieldTypeSet: ["bool"],
        compositeIdSet: sectionIdSetWithCurrentGridId,
        showCompositeName: true,
        excludeFieldIdSet: excludeFieldIdSet
      } as DefnStudioPickFieldId
    },

    ...isDefaultValueIncluded && {
      [propKeyDefaultFieldId]: {
        type: "pickFieldId",
        metaId: propKeyDefaultFieldId,
        name: propKeyDefaultFieldId,
        label: "Default value",
        formId: formId,
        filterFieldTypeSet: filterDefaultFieldTypeSet,
        compositeIdSet: sectionIdSetWithCurrentGridId,
        showCompositeName: true,
        excludeFieldIdSet: excludeFieldIdSet
      } as DefnStudioPickFieldId
    },

    ...isPlaceHolderIncluded && {
      [propKeyPlaceHolderFieldId]: {
        type: "pickFieldId",
        metaId: propKeyPlaceHolderFieldId,
        name: propKeyPlaceHolderFieldId,
        label: "Placeholder",
        formId: formId,
        filterFieldTypeSet: ["text"],
        compositeIdSet: sectionIdSetWithCurrentGridId,
        showCompositeName: true,
        excludeFieldIdSet: excludeFieldIdSet
      } as DefnStudioPickFieldId
    },

    ...isHelperTextIncluded && {
      [propKeyHelperTextFieldId]: {
        type: "pickFieldId",
        metaId: propKeyHelperTextFieldId,
        name: propKeyHelperTextFieldId,
        label: "Helper text",
        formId: formId,
        filterFieldTypeSet: ["text"],
        compositeIdSet: sectionIdSetWithCurrentGridId,
        showCompositeName: true,
        excludeFieldIdSet: excludeFieldIdSet
      } as DefnStudioPickFieldId
    },

    ...isMinCharCountIncluded && {
      [propKeyMinCharCountFieldId]: {
        type: "pickFieldId",
        metaId: propKeyMinCharCountFieldId,
        name: propKeyMinCharCountFieldId,
        label: "Min character count",
        formId: formId,
        filterFieldTypeSet: filterMinMaxFieldTypeSet,
        compositeIdSet: sectionIdSetWithCurrentGridId,
        showCompositeName: true,
        excludeFieldIdSet: excludeFieldIdSet
      } as DefnStudioPickFieldId
    },

    ...isMaxCharCountIncluded && {
      [propKeyMaxCharCountFieldId]: {
        type: "pickFieldId",
        metaId: propKeyMaxCharCountFieldId,
        name: propKeyMaxCharCountFieldId,
        label: "Max character count",
        formId: formId,
        filterFieldTypeSet: filterMinMaxFieldTypeSet,
        compositeIdSet: sectionIdSetWithCurrentGridId,
        showCompositeName: true,
        excludeFieldIdSet: excludeFieldIdSet
      } as DefnStudioPickFieldId
    },

    ...isMinIncluded && {
      [propKeyMinFieldId]: {
        type: "pickFieldId",
        metaId: propKeyMinFieldId,
        name: propKeyMinFieldId,
        label: "Min",
        formId: formId,
        filterFieldTypeSet: filterMinMaxFieldTypeSet,
        compositeIdSet: sectionIdSetWithCurrentGridId,
        showCompositeName: true,
        excludeFieldIdSet: excludeFieldIdSet
      } as DefnStudioPickFieldId
    },

    ...isMaxIncluded && {
      [propKeyMaxFieldId]: {
        type: "pickFieldId",
        metaId: propKeyMaxFieldId,
        name: propKeyMaxFieldId,
        label: "Max",
        formId: formId,
        filterFieldTypeSet: filterMinMaxFieldTypeSet,
        compositeIdSet: sectionIdSetWithCurrentGridId,
        showCompositeName: true,
        excludeFieldIdSet: excludeFieldIdSet
      } as DefnStudioPickFieldId
    },

    ...isMaxSizeIncluded && {
      [propKeyMaxSizeFieldId]: {
        type: "pickFieldId",
        metaId: propKeyMaxSizeFieldId,
        name: propKeyMaxSizeFieldId,
        label: "Max size",
        formId: formId,
        filterFieldTypeSet: filterMinMaxFieldTypeSet,
        compositeIdSet: sectionIdSetWithCurrentGridId,
        showCompositeName: true,
        excludeFieldIdSet: excludeFieldIdSet,
        helperText: FORMS_MAX_SIZE
      } as DefnStudioPickFieldId
    },

    ...isGap1Included && {
      ...getFieldGap(fieldGap1, "thick")
    },

    ...isGap2Included && {
      ...getFieldGap(fieldGap2, "thick")
    },

    ...isGap3Included && {
      ...getFieldGap(fieldGap3, "thick")
    },

    ...isGap4Included && {
      ...getFieldGap(fieldGap4, "thick")
    },

    ...isGap5Included && {
      ...getFieldGap(fieldGap5, "thick")
    },

    [fieldPropertiesField]: {
      type: "section",
      metaId: fieldPropertiesField,
      name: fieldPropertiesField,
      label: "Field",
      fieldIdSet: fieldIdSet
    } as DefnSection
  } as Record<string, DefnComp>;
}

function fieldBuilderPropertySubTabsVar(
  formId: MetaIdForm,
  varKind?: EnumStudioVarKind,
  fieldId?: MetaIdField,
  propertyFields?: MetaIdField[]
)
{
  const excludeFieldIdSet = fieldId ? [fieldId] : undefined;
  const defaultValueVariableStudioField = varKind
    ? getDefaultValueVariableStudioField(varKind, formId, fieldId)
    : undefined;

  const isRequiredIncluded = propertyFields?.includes(propKeyRequiredVarId);
  const isDisabledIncluded = propertyFields?.includes(propKeyDisabledVarId);
  const isDefaultValueIncluded = defaultValueVariableStudioField
    ? propertyFields?.includes(Object.keys(defaultValueVariableStudioField)[0])
    : undefined;
  const isPlaceHolderIncluded = propertyFields?.includes(propKeyVarPlaceholder);
  const isIconIncluded = propertyFields?.includes(propKeyVarIcon);
  const isHelperTextIncluded = propertyFields?.includes(propKeyVarHelperText);
  const isPrefixIncluded = propertyFields?.includes(propKeyPrefixVar);
  const isSuffixIncluded = propertyFields?.includes(propKeySuffixVar);
  const isMinCharCountIncluded = propertyFields?.includes(propKeyMinCharacterCount);
  const isMaxCharCountIncluded = propertyFields?.includes(propKeyMaxCharacterCount);
  const isMinIncluded = propertyFields?.includes(propKeyMinVar);
  const isMaxIncluded = propertyFields?.includes(propKeyMaxVar);
  const isMaxSizeIncluded = propertyFields?.includes(propKeyMaxSizeVar);
  const {
    isGap1Included,
    isGap2Included,
    isGap3Included,
    isGap4Included,
    isGap5Included
  } = getPropertyFieldsGapIncludedKeys(propertyFields);

  const fieldIdSet = propertyFields ?? [];

  return {

    ...isRequiredIncluded && {
      [propKeyRequiredVarId]: {
        type: "pickVarId",
        metaId: propKeyRequiredVarId,
        name: propKeyRequiredVarId,
        label: "Required",
        argBinderFormId: formId,
        showAsEdit: true,
        excludeFieldIdSet: excludeFieldIdSet,
        varKind: "bool"
      } as DefnStudioPickVarId
    },

    ...isDisabledIncluded && {
      [propKeyDisabledVarId]: {
        type: "pickVarId",
        metaId: propKeyDisabledVarId,
        name: propKeyDisabledVarId,
        label: "Disabled",
        argBinderFormId: formId,
        showAsEdit: true,
        excludeFieldIdSet: excludeFieldIdSet,
        varKind: "bool"
      } as DefnStudioPickVarId
    },

    ...isDefaultValueIncluded && {
      ...defaultValueVariableStudioField
    },

    ...isPlaceHolderIncluded && {
      [propKeyVarPlaceholder]: {
        type: "studioVarIdTextEditor",
        metaId: propKeyVarPlaceholder,
        name: propKeyVarPlaceholder,
        label: "Placeholder",
        argBinderFormId: formId,
        showAsEdit: true,
        excludeFieldIdSet: excludeFieldIdSet,
        filterVarKindSet: ["text"]
      } as DefnStudioVarIdTextEditor
    },

    ...isIconIncluded && {
      [propKeyVarIcon]: {
        type: "pickVarId",
        metaId: propKeyVarIcon,
        name: propKeyVarIcon,
        label: "Icon",
        varKind: "icon",
        formId: formId,
        showAsEdit: true
      } as DefnStudioPickVarId
    },

    ...isHelperTextIncluded && {
      [propKeyVarHelperText]: {
        type: "studioVarIdTextEditor",
        metaId: propKeyVarHelperText,
        name: propKeyVarHelperText,
        label: "Helper text",
        argBinderFormId: formId,
        showAsEdit: true,
        excludeFieldIdSet: excludeFieldIdSet,
        filterVarKindSet: ["text"]
      } as DefnStudioVarIdTextEditor
    },

    ...isPrefixIncluded && {
      [propKeyPrefixVar]: {
        type: "studioVarIdTextEditor",
        metaId: propKeyPrefixVar,
        name: propKeyPrefixVar,
        label: "Prefix",
        argBinderFormId: formId,
        showAsEdit: true,
        excludeFieldIdSet: excludeFieldIdSet,
        filterVarKindSet: ["text"]
      } as DefnStudioVarIdTextEditor
    },

    ...isSuffixIncluded && {
      [propKeySuffixVar]: {
        type: "studioVarIdTextEditor",
        metaId: propKeySuffixVar,
        name: propKeySuffixVar,
        label: "Suffix",
        argBinderFormId: formId,
        showAsEdit: true,
        excludeFieldIdSet: excludeFieldIdSet,
        filterVarKindSet: ["text"]
      } as DefnStudioVarIdTextEditor
    },

    ...isMinCharCountIncluded && {
      [propKeyMinCharacterCount]: {
        type: "pickVarId",
        metaId: propKeyMinCharacterCount,
        label: "Min character count",
        varKind: "number",
        showAsEdit: true,
        formId: formId
      } as DefnStudioPickVarId
    },

    ...isMaxCharCountIncluded && {
      [propKeyMaxCharacterCount]: {
        type: "pickVarId",
        metaId: propKeyMaxCharacterCount,
        label: "Max character count",
        varKind: "number",
        showAsEdit: true,
        formId: formId
      } as DefnStudioPickVarId
    },

    ...isMinIncluded && {
      [propKeyMinVar]: {
        type: "pickVarId",
        metaId: propKeyMinVar,
        name: propKeyMinVar,
        label: "Min",
        varKind: varKind,
        showAsEdit: true,
        formId: formId
      } as DefnStudioPickVarId
    },

    ...isMaxIncluded && {
      [propKeyMaxVar]: {
        type: "pickVarId",
        metaId: propKeyMaxVar,
        name: propKeyMaxVar,
        label: "Max",
        varKind: varKind,
        showAsEdit: true,
        formId: formId
      } as DefnStudioPickVarId
    },

    ...isMaxSizeIncluded && {
      [propKeyMaxSizeVar]: {
        type: "pickVarId",
        metaId: propKeyMaxSizeVar,
        name: propKeyMaxSizeVar,
        label: "Max size",
        varKind: "number",
        showAsEdit: true,
        formId: formId,
        helperText: FORMS_MAX_SIZE
      } as DefnStudioPickVarId
    },

    ...isGap1Included && {
      ...getFieldGap(fieldGap1, "thick")
    },

    ...isGap2Included && {
      ...getFieldGap(fieldGap2, "thick")
    },

    ...isGap3Included && {
      ...getFieldGap(fieldGap3, "thick")
    },

    ...isGap4Included && {
      ...getFieldGap(fieldGap4, "thick")
    },

    ...isGap5Included && {
      ...getFieldGap(fieldGap5, "thick")
    },

    [fieldPropertiesVar]: {
      type: "section",
      metaId: fieldPropertiesVar,
      name: fieldPropertiesVar,
      label: "Variable",
      fieldIdSet: fieldIdSet
    } as DefnSection

  } as Record<string, DefnComp>;
}

function fieldBuilderPropertySubTabsConst(
  fieldType: EnumStudioCompType,
  propertyFields?: MetaIdField[],
  showCustomDate?: boolean,
  defaultStepCount?: number
)
{
  const isRequiredIncluded = propertyFields?.includes(propKeyRequired);
  const isDisabledIncluded = propertyFields?.includes(propKeyDisabled);
  const isAutofocusIncluded = propertyFields?.includes(propKeyAutoFocus);
  const isAutofillIncluded = propertyFields?.includes(propKeyAutoFill);
  const isShowLabelIncluded = propertyFields?.includes(propKeyShowLabel);
  const isDefaultValueIncluded = propertyFields?.includes(propKeyDefaultValue);
  const isDefaultValueDateIncluded = propertyFields?.includes(propKeyDefaultValueCustom);
  const isDefaultValueDateTimeIncluded = propertyFields?.includes(propKeyDefaultValueDateTime);
  const isPlaceHolderIncluded = propertyFields?.includes(propKeyPlaceHolderValue);
  const isIconIncluded = propertyFields?.includes(propKeyIconValue);
  const isHelperTextIncluded = propertyFields?.includes(propKeyHelperTextValue);
  const isMinCharCountIncluded = propertyFields?.includes(propKeyMinCharCount);
  const isMaxCharCountIncluded = propertyFields?.includes(propKeyMaxCharCount);
  const isMinIncluded = propertyFields?.includes(propKeyMin);
  const isMaxSizeIncluded = propertyFields?.includes(propKeyMaxSize);
  const isMaxIncluded = propertyFields?.includes(propKeyMax);
  const isPrefixIncluded = propertyFields?.includes(propKeyPrefix);
  const isSuffixIncluded = propertyFields?.includes(propKeySuffix);
  const {
    isGap1Included,
    isGap2Included,
    isGap3Included,
    isGap4Included,
    isGap5Included
  } = getPropertyFieldsGapIncludedKeys(propertyFields);

  const fieldIdSet = propertyFields ?? [];

  return {

    ...isRequiredIncluded && {
      [propKeyRequired]: {
        type: "bool",
        metaId: propKeyRequired,
        name: propKeyRequired,
        label: "Required",
        showAsCheckboxVar: true
      } as DefnFieldSwitch
    },

    ...isDisabledIncluded && {
      [propKeyDisabled]: {
        type: "bool",
        metaId: propKeyDisabled,
        name: propKeyDisabled,
        label: "Disabled",
        showAsCheckboxVar: true
      } as DefnFieldSwitch
    },

    ...isAutofocusIncluded && {
      [propKeyAutoFocus]: {
        type: "bool",
        metaId: propKeyAutoFocus,
        name: propKeyAutoFocus,
        label: "Auto focus",
        showAsCheckboxVar: true
      } as DefnFieldSwitch
    },

    ...isAutofillIncluded && {
      [propKeyAutoFill]: {
        type: "bool",
        metaId: propKeyAutoFill,
        name: propKeyAutoFill,
        label: "Auto fill",
        showAsCheckboxVar: true
      } as DefnFieldSwitch
    },

    ...isShowLabelIncluded && {
      [propKeyShowLabel]: {
        type: "bool",
        metaId: "showLabel",
        name: "Show label",
        label: "Show label",
        showAsCheckboxVar: true
      } as DefnFieldSwitch
    },

    ...isDefaultValueIncluded && {
      ...(fieldType === "info" || fieldType === "showCode")
        ? {
          [propKeyDefaultValue]: {
            type: "paragraph",
            metaId: propKeyDefaultValue,
            name: propKeyDefaultValue,
            label: "Default value"
          } as DefnFieldParagraph
        }
        : fieldType === "logNumber" ? {
          [propKeyDefaultValue]: {
            type: "logNumber",
            metaId: propKeyDefaultValue,
            name: propKeyDefaultValue,
            label: "Default value",
            hideInfo: true
          } as DefnFieldLogNumber
        } : fieldType === "logDecimal" ? {
          [propKeyDefaultValue]: {
            type: "logDecimal",
            metaId: propKeyDefaultValue,
            name: propKeyDefaultValue,
            label: "Default value",
            hideInfo: true
          } as DefnFieldLogDecimal
        } : fieldType === "date" ? {
          [propKeyDefaultValue]: {
            type: "studioBuildDate",
            metaId: propKeyDefaultValue,
            name: propKeyDefaultValue,
            label: "Default value"
          } as DefnField
        } : fieldType === "dateTime" ? {
          [propKeyDefaultValue]: {
            type: "studioBuildDateTime",
            metaId: propKeyDefaultValue,
            name: propKeyDefaultValue,
            label: "Default value"
          } as DefnField
        } : fieldType === "rating" ? {
          [propKeyDefaultValue]: {
            type: "number",
            metaId: propKeyDefaultValue,
            name: propKeyDefaultValue,
            label: "Default value",
            min: 1,
            max: 5
          } as DefnFieldNumber
        } : fieldType === "slider" ? {
          [propKeyDefaultValue]: {
            type: "number",
            metaId: propKeyDefaultValue,
            name: propKeyDefaultValue,
            label: "Default value"
          } as DefnFieldSlider
        } : fieldType === "counter" ? {
          [propKeyDefaultValue]: {
            type: "counter",
            metaId: propKeyDefaultValue,
            name: propKeyDefaultValue,
            label: "Default value",
            step: defaultStepCount
          } as DefnFieldSlider
        } : {
          [propKeyDefaultValue]: {
            type: fieldType,
            metaId: propKeyDefaultValue,
            name: propKeyDefaultValue,
            label: "Default value",
            ...fieldType === "bool" && {
              showAsCheckboxVar: true
            } as DefnFieldSwitch
          } as DefnField
        }
    },

    ...isDefaultValueDateIncluded && showCustomDate && {
      [propKeyDefaultValueCustom]: {
        type: "date",
        metaId: propKeyDefaultValueCustom,
        name: propKeyDefaultValueCustom,
        label: "Custom date"
      } as DefnFieldDate
    },

    ...isDefaultValueDateTimeIncluded && showCustomDate && {
      [propKeyDefaultValueDateTime]: {
        type: "dateTime",
        metaId: propKeyDefaultValueDateTime,
        name: propKeyDefaultValueDateTime,
        label: "Custom date time"
      } as DefnFieldDateTime
    },

    ...isPlaceHolderIncluded && {
      [propKeyPlaceHolderValue]: {
        type: "text",
        metaId: propKeyPlaceHolderValue,
        name: propKeyPlaceHolderValue,
        label: "Placeholder"
      } as DefnFieldText
    },

    ...isIconIncluded && {
      [propKeyIconValue]: {
        type: "icon",
        metaId: propKeyIconValue,
        name: propKeyIconValue,
        label: "Icon"
      } as DefnFieldEditable
    },

    ...isHelperTextIncluded && {
      [propKeyHelperTextValue]: {
        type: "text",
        metaId: propKeyHelperTextValue,
        name: propKeyHelperTextValue,
        label: "Helper text"
      } as DefnFieldText
    },

    ...isMinCharCountIncluded && {
      [propKeyMinCharCount]: {
        type: "number",
        metaId: propKeyMinCharCount,
        name: propKeyMinCharCount,
        label: "Min character count"
      } as DefnFieldNumber
    },

    ...isMaxCharCountIncluded && {
      [propKeyMaxCharCount]: {
        type: "number",
        metaId: propKeyMaxCharCount,
        name: propKeyMaxCharCount,
        label: "Max character count"
      } as DefnFieldNumber
    },

    ...isPrefixIncluded && {
      [propKeyPrefix]: {
        type: "text",
        name: propKeyPrefix,
        metaId: propKeyPrefix,
        label: "Prefix"
      } as DefnFieldText
    },

    ...isSuffixIncluded && {
      [propKeySuffix]: {
        type: "text",
        name: propKeySuffix,
        metaId: propKeySuffix,
        label: "Suffix"
      } as DefnFieldText
    },

    ...isMinIncluded && {
      ...getConstFieldMin(fieldType)
    },

    ...isMaxSizeIncluded && {
      [propKeyMaxSize]: {
        type: "number",
        metaId: propKeyMaxSize,
        name: propKeyMaxSize,
        label: "Max size",
        helperText: FORMS_MAX_SIZE,
        min: 0
      } as DefnFieldNumber
    },

    ...isMaxIncluded && {
      ...getConstFieldMax(fieldType)
    },

    ...isGap1Included && {
      ...getFieldGap(fieldGap1, "thick")
    },

    ...isGap2Included && {
      ...getFieldGap(fieldGap2)
    },

    ...isGap3Included && {
      ...getFieldGap(fieldGap3, "thick")
    },

    ...isGap4Included && {
      ...getFieldGap(fieldGap4, "thick")
    },

    ...isGap5Included && {
      ...getFieldGap(fieldGap5, "thick")
    },

    [fieldPropertiesConst]: {
      type: "section",
      metaId: fieldPropertiesConst,
      name: fieldPropertiesConst,
      label: "Constant",
      fieldIdSet: fieldIdSet
    } as DefnSection
  } as Record<string, DefnComp>;
}

function getConstFieldMin(fieldType: EnumStudioCompType)
{
  switch(fieldType)
  {
    case "decimal":
      return {
        [propKeyMin]: {
          type: "decimal",
          metaId: propKeyMin,
          name: propKeyMin,
          label: "Min"
        } as DefnFieldDecimal
      };
    case "date":
    case "dateRange":
      return {
        [propKeyMin]: {
          type: "studioBuildDate",
          metaId: propKeyMin,
          name: propKeyMin,
          label: "Min"
        } as DefnBuildDate
      };
    case "dateTime":
    case "dateTimeRange":
      return {
        [propKeyMin]: {
          type: "studioBuildDateTime",
          metaId: propKeyMin,
          name: propKeyMin,
          label: "Min"
        } as DefnBuildDateTime
      };

    default:
      return {
        [propKeyMin]: {
          type: "number",
          metaId: propKeyMin,
          name: propKeyMin,
          label: "Min"
        } as DefnFieldNumber
      };
  }
}

function getConstFieldMax(fieldType: EnumStudioCompType)
{
  switch(fieldType)
  {
    case "decimal":
      return {
        [propKeyMax]: {
          type: "decimal",
          metaId: propKeyMax,
          name: propKeyMax,
          label: "Max"
        } as DefnFieldDecimal
      };
    case "date":
    case "dateRange":
      return {
        [propKeyMax]: {
          type: "studioBuildDate",
          metaId: propKeyMax,
          name: propKeyMax,
          label: "Max"
        } as DefnBuildDate
      };
    case "dateTime":
    case "dateTimeRange":
      return {
        [propKeyMax]: {
          type: "studioBuildDateTime",
          metaId: propKeyMax,
          name: propKeyMax,
          label: "Max"
        } as DefnBuildDateTime
      };

    default:
      return {
        [propKeyMax]: {
          type: "number",
          metaId: propKeyMax,
          name: propKeyMax,
          label: "Max"
        } as DefnFieldNumber
      };
  }
}

export function getFieldBuilderPropertySubTabs(
  fieldType: EnumStudioCompType,
  formId: MetaIdForm,
  varKind?: EnumStudioVarKind,
  fieldId?: MetaIdField,
  propertyFieldFieldIdSet?: MetaIdField[],
  propertyFieldVarIdSet?: MetaIdField[],
  propertyFieldConstIdSet?: MetaIdField[],
  showCustomDate?: boolean,
  sectionIdSetWithCurrentGridId?: MetaIdComposite[],
  defaultStepCount?: number
)
{
  return {
    ...fieldBuilderPropertySubTabsConst(
      fieldType,
      propertyFieldConstIdSet,
      showCustomDate,
      defaultStepCount
    ),
    ...fieldBuilderPropertySubTabsVar(
      formId,
      varKind,
      fieldId,
      propertyFieldVarIdSet
    ),
    ...fieldBuilderPropertySubTabsField(
      fieldType,
      formId,
      fieldId,
      propertyFieldFieldIdSet,
      sectionIdSetWithCurrentGridId
    ),

    [fieldPropertiesSubTabId]: {
      type: "tab",
      metaId: fieldPropertiesSubTabId,
      name: fieldPropertiesSubTabId,
      label: "Field properties",
      tabIdSet: [
        ...propertyFieldConstIdSet ? [fieldPropertiesConst] : [],
        ...propertyFieldVarIdSet ? [fieldPropertiesVar] : [],
        ...propertyFieldFieldIdSet ? [fieldPropertiesField] : []
      ]
    } as DefnTab
  };
}

export function getFieldBuilderPropertyFieldIdSet(
  propertyTab: Record<MetaIdField, DefnComp>,
  fieldType?: EnumStudioCompType)
{
  const _fieldIdSet = [] as MetaIdField[];

  if(fieldType)
  {
    const tabField = propertyTab[fieldPropertiesField] as DefnSection | undefined;
    const tabVar = propertyTab[fieldPropertiesVar] as DefnSection | undefined;
    const tabConst = propertyTab[fieldPropertiesConst] as DefnSection | undefined;

    const tabFieldFieldIdSet = tabField?.fieldIdSet || [];
    const tabVarFieldIdSet = tabVar?.fieldIdSet || [];
    const tabConstFieldIdSet = tabConst?.fieldIdSet || [];

    const propertySubTabFieldIds = {} as Record<MetaIdField, boolean>;

    [...tabFieldFieldIdSet, ...tabVarFieldIdSet, ...tabConstFieldIdSet].forEach(fieldId =>
    {
      propertySubTabFieldIds[fieldId] = true;
    });

    propertySubTabFieldIds[fieldPropertiesField] = true;
    propertySubTabFieldIds[fieldPropertiesVar] = true;
    propertySubTabFieldIds[fieldPropertiesConst] = true;
    propertySubTabFieldIds[fieldPropertiesSubTabId] = true;

    Object.keys(propertyTab).forEach((fieldId) =>
    {
      if(!propertySubTabFieldIds[fieldId])
      {
        _fieldIdSet.push(fieldId);
      }
    });
  }

  if(showPropertiesSubTab(fieldType))
  {
    _fieldIdSet.push(fieldPropertiesSubTabId);
  }

  return _fieldIdSet;

}

function getPropertyFieldsGapIncludedKeys(
  propertyFields?: MetaIdField[]
)
{
  const isGap1Included = propertyFields?.includes(fieldGap1);
  const isGap2Included = propertyFields?.includes(fieldGap2);
  const isGap3Included = propertyFields?.includes(fieldGap3);
  const isGap4Included = propertyFields?.includes(fieldGap4);
  const isGap5Included = propertyFields?.includes(fieldGap5);

  return {
    isGap1Included,
    isGap2Included,
    isGap3Included,
    isGap4Included,
    isGap5Included
  };
}

export function getFieldBuilderSourceSubTabs(
  constFieldIdSet?: MetaIdField[],
  variableFieldIdSet?: MetaIdField[],
  pluginFieldIdSet?: MetaIdField[]
)
{
  const isConstTabPresent = constFieldIdSet && constFieldIdSet.length > 0;
  const isVariableTabPresent = variableFieldIdSet && variableFieldIdSet.length > 0;
  const isPluginTabPresent = pluginFieldIdSet && pluginFieldIdSet.length > 0;

  return {
    ...isConstTabPresent && {
      [fieldSourceConstTab]: {
        type: "section",
        metaId: fieldSourceConstTab,
        name: fieldSourceConstTab,
        label: "Constant",
        fieldIdSet: constFieldIdSet
      } as DefnSection
    },

    ...isVariableTabPresent && {
      [fieldSourceVariableTab]: {
        type: "section",
        metaId: fieldSourceVariableTab,
        name: fieldSourceVariableTab,
        label: "Variable",
        fieldIdSet: variableFieldIdSet
      } as DefnSection
    },

    ...isPluginTabPresent && {
      [fieldSourcePluginTab]: {
        type: "section",
        metaId: fieldSourcePluginTab,
        name: fieldSourcePluginTab,
        label: "Plugin",
        fieldIdSet: pluginFieldIdSet
      } as DefnSection
    },

    [fieldSourceSubTabId]: {
      type: "tab",
      metaId: fieldSourceSubTabId,
      name: fieldSourceSubTabId,
      label: "Source",
      tabIdSet: [
        ...isConstTabPresent ? [fieldSourceConstTab] : [],
        ...isVariableTabPresent ? [fieldSourceVariableTab] : [],
        ...isPluginTabPresent ? [fieldSourcePluginTab] : []
      ]
    } as DefnTab
  };
}

export function getFieldBuilderSourceFieldIdSet(
  sourceTab: Record<MetaIdField, DefnComp>,
  fieldType?: EnumStudioCompType)
{
  const _fieldIdSet = [] as MetaIdField[];

  if(fieldType)
  {
    const tabConst = sourceTab[fieldSourceConstTab] as DefnSection | undefined;
    const tabVar = sourceTab[fieldSourceVariableTab] as DefnSection | undefined;
    const tabPlugin = sourceTab[fieldSourcePluginTab] as DefnSection | undefined;

    const tabConstFieldIdSet = tabConst?.fieldIdSet || [];
    const tabVarFieldIdSet = tabVar?.fieldIdSet || [];
    const tabPluginFieldIdSet = tabPlugin?.fieldIdSet || [];

    const sourceSubTabFieldIds = {} as Record<MetaIdField, boolean>;

    [...tabConstFieldIdSet, ...tabVarFieldIdSet, ...tabPluginFieldIdSet].forEach(fieldId =>
    {
      sourceSubTabFieldIds[fieldId] = true;
    });

    sourceSubTabFieldIds[fieldSourceConstTab] = true;
    sourceSubTabFieldIds[fieldSourceVariableTab] = true;
    sourceSubTabFieldIds[fieldSourcePluginTab] = true;
    sourceSubTabFieldIds[fieldSourceSubTabId] = true;

    Object.keys(sourceTab).forEach((fieldId) =>
    {
      if(!sourceSubTabFieldIds[fieldId])
      {
        _fieldIdSet.push(fieldId);
      }
    });
  }

  if(showSourceSubTab(fieldType))
  {
    _fieldIdSet.push(fieldSourceSubTabId);
  }

  return _fieldIdSet;
}



