// neome.ai API - do not change
//
/* eslint-disable @typescript-eslint/no-unused-vars */
// noinspection UnusedGlobalSymbols,ES6UnusedImports,JSUnusedGlobalSymbols,JSUnusedLocalSymbols

export const ArgBinderType = [
  "context",
  "derived",
  "field",
  "input",
  "variable",
  "constant"
  ];

export const ConditionLhsUnaryOnly = [
  "field",
  "input",
  "context"
  ];

export const ForFormFormula = [
  "variable",
  "field",
  "context"
  ];

export const FormVariableCondition = [
  "field",
  "derived",
  "context",
  "variable",
  "input"
  ];

export const FormVariableMapping = [
  "field",
  "derived",
  "context",
  "variable"
  ];

export const ForPaymentLinkStepAmountField = [
  "field",
  "constant",
  "variable",
  "derived"
  ];

export const ForPaymentLinkStepCurrencyField = [
  "field",
  "constant",
  "variable",
  "derived"
  ];

export const ForPaymentLinkStepDescriptionField = [
  "field",
  "constant",
  "variable",
  "derived",
  "context"
  ];

export const ForPaymentLinkStepLinkExpiryDurationField = [
  "field",
  "constant",
  "variable",
  "derived"
  ];

export const ForPluginApi = [
  "variable",
  "field",
  "input",
  "output",
  "response",
  "constant"
  ];

export const ForPrompt = [
  "field",
  "input",
  "sep"
  ];

export const ForQueryReportNeoQL = [
  "argument",
  "variable",
  "input",
  "output",
  "context",
  "spreadsheet"
  ];

export const ForSpreadsheetPartition = [
  "variable",
  "context"
  ];

export const ForSpreadsheetStepCalculateFormula = [
  "variable",
  "field",
  "context"
  ];

export const ForSpreadsheetStepUpdateField = [
  "derived",
  "field"
  ];

export const ForSpreadsheetStepUpdateLogNumber = [
  "derived",
  "field"
  ];

export const ForStudioVarFunc = [
  "variable",
  "context"
  ];

export const ForText = [
  "name",
  "about",
  "label",
  "color",
  "entUserId",
  "handle",
  "managerId",
  "nickName",
  "userId"
  ];

export const ForTextResolvedOnClient = [
  "color",
  "entUserId",
  "handle",
  "managerId",
  "nickName",
  "userId"
  ];

export const ForTextResolvedOnServer = [
  "name",
  "about",
  "label"
  ];

export const ForValueVarIdText = [
  "context",
  "derived",
  "field",
  "variable"
  ];

export const ForVariableMapOfText = [
  "field",
  "derived",
  "context",
  "variable"
  ];