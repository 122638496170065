import {ICellRendererParams} from "ag-grid-community";
import {IDataGridCell} from "../../../../base/types/TypeDataGrid";
import {AgGridContext} from "../../../../base/types/TypeDataGrid";
import RawMarkDown from "../../../atom/raw/RawMarkDown";

export default function GridCellInfo(props: ICellRendererParams<IDataGridCell>)
{
  const searchWords = (props.context as AgGridContext).searchWords;

  const cellValue = props.valueFormatted
    ? props.valueFormatted
    : props.value;
  const colId = props.colDef?.colId;
  const isHyperLink = colId ? (props.context as AgGridContext).hyperLinkColIdMap?.[colId] : undefined;

  return <RawMarkDown
    variant={"caption"}
    color={"textSecondary"}
    value={typeof cellValue === "string" ? cellValue : `${cellValue ?? ""}`}
    width={"100%"}
    searchWords={searchWords}
    includeColor={true}
    includeNewLine={true}
    textDecoration={isHyperLink ? "underline" : undefined}
    cursor={isHyperLink ? "pointer" : undefined}
  />;
}

