import {useState} from "react";
import {useCallback} from "react";
import {useRef} from "react";
import {useEffect} from "react";
import {FieldValues} from "react-hook-form/dist/types/fields";
import {nextMetaIdFooter} from "../../api/meta/base/ApiPlus";
import {nextMetaIdHeader} from "../../api/meta/base/ApiPlus";
import {DefnDtoOption} from "../../api/meta/base/dto/DefnDtoOption";
import {DefnFieldChipSet} from "../../api/meta/base/dto/DefnFieldChipSet";
import {DefnFieldNumber} from "../../api/meta/base/dto/DefnFieldNumber";
import {DefnFieldPickEnum} from "../../api/meta/base/dto/DefnFieldPickEnum";
import {DefnFieldSwitch} from "../../api/meta/base/dto/DefnFieldSwitch";
import {DefnFieldText} from "../../api/meta/base/dto/DefnFieldText";
import {DefnSection} from "../../api/meta/base/dto/DefnSection";
import {DefnStudioCompArray} from "../../api/meta/base/dto/DefnStudioCompArray";
import {DefnStudioPickFieldId} from "../../api/meta/base/dto/DefnStudioPickFieldId";
import {DefnStudioPickLayoutGridId} from "../../api/meta/base/dto/DefnStudioPickLayoutGridId";
import {DefnTab} from "../../api/meta/base/dto/DefnTab";
import {StudioDtoLayoutTable} from "../../api/meta/base/dto/StudioDtoLayoutTable";
import {StudioDtoTableFooter} from "../../api/meta/base/dto/StudioDtoTableFooter";
import {StudioDtoTableHeader} from "../../api/meta/base/dto/StudioDtoTableHeader";
import {StudioGrid} from "../../api/meta/base/dto/StudioGrid";
import {StudioMapOfTableFooter} from "../../api/meta/base/dto/StudioMapOfTableFooter";
import {StudioMapOfTableHeader} from "../../api/meta/base/dto/StudioMapOfTableHeader";
import {MetaIdFooter} from "../../api/meta/base/Types";
import {MetaIdHeader} from "../../api/meta/base/Types";
import {MetaIdField} from "../../api/meta/base/Types";
import {EnumArrayDefnCompType} from "../../api/meta/base/Types";
import {MetaIdLayoutGrid} from "../../api/meta/base/Types";
import {MetaIdGrid} from "../../api/meta/base/Types";
import {EnumArrayDefnFields} from "../../api/meta/base/Types";
import {MetaIdForm} from "../../api/meta/base/Types";
import {stringToDefnDtoText} from "../../base/plus/ArgBinderPlus";
import {fnFieldValueToRawValue} from "../../base/plus/FieldValuePlus";
import {fnRawValueToFieldValue} from "../../base/plus/FieldValuePlus";
import {getFieldName} from "../../base/plus/FormPlus";
import {createDefaultDefnFormStudio, defaultSectionKey} from "../../base/plus/FormPlus";
import {loopStudioForm} from "../../base/plus/StudioFormPlus";
import {gapHalf} from "../../base/plus/ThemePlus";
import {IFormFieldError} from "../../base/types/TypesForm";
import {IFormRef} from "../../base/types/TypesForm";
import {FormStore} from "../../base/types/TypesForm";
import {SPREADSHEETS_LAYOUT_TABLE_COLUMN_ALIGNMENTS} from "../atom/assets/HelperTextStudio";
import {SPREADSHEETS_LAYOUT_TABLE_COLUMN_SIZE} from "../atom/assets/HelperTextStudio";
import {fieldKeyLabel} from "../form/builder/base/TypesFormBuilder";
import {fieldGap1} from "../form/builder/base/TypesFormBuilder";
import {getFieldGap} from "../form/builder/base/TypesFormBuilder";
import {fieldKeyDescription, fieldKeyName} from "../form/builder/base/TypesFormBuilder";
import {useDialogFormValidationError} from "./base/DialogPlus";
import DialogDefnForm from "./base/impl/DialogDefnForm";
import {getCommonDetailsTab} from "./base/LayoutBuilderPlus";
import {allowToSwitchLayoutsLabel} from "./base/TypesLayoutBuilder";

const dialogContentHeight = 643;
const dialogContentWidth = 950;

const tabVariant = "standard";

export default function DialogNewLayoutGridTable(props: {
  metaIdGrid: MetaIdGrid,
  formStore?: FormStore,
  metaIdForm?: MetaIdForm,
  validationError?: IFormFieldError[]
  values?: StudioDtoLayoutTable,
  isFormReadOnly?: boolean,
  onClickOk: (values: StudioDtoLayoutTable) => void,
  onClose?: () => void
})
{
  const metaIdGrid = props.metaIdGrid;
  const values = props.values;
  const formStore = props.formStore;
  const metaIdForm = props.metaIdForm;
  const formReadonly = props.isFormReadOnly;
  const onClickOk = props.onClickOk;
  const onClose = props.onClose;
  const validationError = props.validationError;

  const excludeGridIdSet = values?.metaId
    ? [values?.metaId]
    : undefined;
  const form = metaIdForm
    ? formStore?.formMap?.map[metaIdForm]
    : undefined;

  const cbRef = useRef({} as IFormRef);

  const [includeFieldIdSet, setIncludeFieldIdSet] = useState<MetaIdField[]>();
  const [showFieldIdSet, setShowFieldIdSet] = useState<MetaIdField[]>();

  useEffect(() =>
  {
    const fieldIdSet = [] as MetaIdField[];
    if(form)
    {
      loopStudioForm(form, (composite, field) =>
      {
        if(composite.type === "grid" && (composite as StudioGrid).metaId === metaIdGrid)
        {
          fieldIdSet.push(field.metaId);
        }
      });
    }
    setIncludeFieldIdSet(fieldIdSet);
    setShowFieldIdSet(values?.showCompIdSet);

  }, [metaIdGrid, formStore?.formMap]);

  useDialogFormValidationError({
    cbFormRef: cbRef.current,
    validationError: validationError
  });

  const onWatch = useCallback((key: MetaIdField, value: any) =>
  {
    if(key === fieldShowFieldIdSet && value)
    {
      setShowFieldIdSet(value);
    }

  }, []);

  return (
    <DialogDefnForm
      formProps={{
        cbRef: cbRef.current,
        store: formStore,
        defnForm: getDefnForm(
          metaIdForm,
          metaIdGrid,
          excludeGridIdSet,
          includeFieldIdSet,
          showFieldIdSet
        ),
        onWatch: onWatch,
        formReadonly: formReadonly,
        initValues: dtoToValue(values),
        onSubmit: value => onClickOk(valueToDto(value, values, formStore, props.metaIdForm))
      }}
      title={`${values ? "Update" : "New"} grid layout - Table`}
      addMoreCheckBoxLabel={!values
        ? "Add more grid layouts"
        : undefined}
      onClose={onClose}
      contentHeight={dialogContentHeight}
      contentWidth={dialogContentWidth}
      preventEnterKey={true}
    />
  );
}

const fieldTabDetails = "tabDetails";
const fieldTabTable = "tabTable";
const fieldTabHeader = "tabHeader";
const fieldTabFooter = "tabFooter";

//details tab
const fieldAllowToSwitchLayoutIdSet = "allowToSwitchLayoutIdSet";

//table tab
const fieldShowFieldIdSet = "showFieldIdSet";
const fieldColumnSizeSet = "columnSizeSet";
const fieldColumnAlignment = "columnAlignmentArray";
const fieldRowsPerPage = "rowsPerPage";
const fieldAllowCustomFilter = "allowCustomFilter";
const fieldRenderingMode = "renderingMode";

//header tab
const fieldArraySetHeader = "fieldArraySetHeader";
const fieldPickerHeader = "fieldPickerHeader";
const fieldDisplayTextHeader = "displayTextHeader";

//footer tab
const fieldArraySetFooter = "fieldArraySetFooter";
const fieldPickerFooter = "fieldPickerFooter";
const fieldDisplayFieldFooter = "displayFieldFooter";
const fieldAlignmentFooter = "alignmentFooter";
const fieldShowLabelFooter = "showLabelFooter";

function getDefnForm(
  metaIdForm?: MetaIdForm,
  metaIdGrid?: MetaIdGrid,
  excludeLayoutGridIdSet?: MetaIdLayoutGrid[],
  includeFieldIdSet?: MetaIdField[],
  showFieldIdSet?: MetaIdField[]
)
{
  const includeOptionSet: DefnDtoOption[] = [];

  EnumArrayDefnFields.forEach(optionId =>
  {
    if(optionId === "$CreatedBy" || optionId === "$UpdatedBy")
    {
      includeOptionSet.push({
        value: optionId,
        metaId: optionId
      });
    }
  });

  const filterFieldTypeSet: string[] = EnumArrayDefnCompType.filter(compType => compType !== "ref");

  return createDefaultDefnFormStudio({

    ...getDetailsTab(metaIdForm, metaIdGrid, includeOptionSet, excludeLayoutGridIdSet),

    ...getTableTab(metaIdForm, metaIdGrid, includeFieldIdSet, filterFieldTypeSet),

    ...getHeaderTab(metaIdForm, metaIdGrid, showFieldIdSet),

    ...getFooterTab(metaIdForm, metaIdGrid, includeFieldIdSet, showFieldIdSet),

    [defaultSectionKey]: {
      type: "tab",
      metaId: defaultSectionKey,
      tabVariant: tabVariant,
      tabIdSet: [
        fieldTabDetails,
        fieldTabTable,
        fieldTabHeader,
        fieldTabFooter
      ]
    } as DefnTab
  });
}

function dtoToValue(table?: StudioDtoLayoutTable)
{
  if(!table)
  {
    return {
      [fieldKeyName]: fnRawValueToFieldValue("symbol", "Table")
    } as FieldValues;
  }
  const fieldArrayValueHeader = fnRawValueToFieldValue("studioCompArray",
    table?.header
  ) as StudioMapOfTableHeader | undefined;

  const fieldArrayValueFooter = fnRawValueToFieldValue("studioCompArray",
    table?.footer
  ) as StudioMapOfTableFooter | undefined;

  return {
    [fieldKeyName]: fnRawValueToFieldValue("symbol", table.name ?? "Table"),
    [fieldKeyDescription]: fnRawValueToFieldValue("text", table.description),
    [fieldKeyLabel]: fnRawValueToFieldValue("text", table.label),
    [fieldRowsPerPage]: fnRawValueToFieldValue("number", table?.rowsPerPage),
    [fieldAllowCustomFilter]: fnRawValueToFieldValue("bool", table?.allowCustomFilters),
    [fieldAllowToSwitchLayoutIdSet]: fnRawValueToFieldValue("studioMapOfLayoutGrid", table?.allowToSwitchLayoutIdSet),
    [fieldShowFieldIdSet]: fnRawValueToFieldValue("studioSetOfFieldId", table?.showCompIdSet),
    [fieldColumnSizeSet]: fnRawValueToFieldValue("chipSet", table?.columnSizeSet),
    [fieldColumnAlignment]: fnRawValueToFieldValue("chipSet", table?.columnAlignmentArray),
    [fieldRenderingMode]: fnRawValueToFieldValue("enumGridRenderingMode", table?.renderingMode),
    [fieldArraySetHeader]: fieldArrayValueHeader
      ? Object(fieldArrayValueHeader.keys).map((headerId: MetaIdHeader) =>
      {
        const header = fieldArrayValueHeader.map[headerId];
        return {
          [fieldDisplayTextHeader]: fnRawValueToFieldValue("text", header.displayText),
          [fieldPickerHeader]: fnRawValueToFieldValue("studioSetOfFieldId", header.fieldIdSet)
        };
      })
      : undefined,
    [fieldArraySetFooter]: fieldArrayValueFooter
      ? Object(fieldArrayValueFooter.keys).map((footerId: MetaIdFooter) =>
      {
        const footer = fieldArrayValueFooter.map[footerId];
        return {
          [fieldDisplayFieldFooter]: fnRawValueToFieldValue("pickFieldId", footer.displayFieldId),
          [fieldPickerFooter]: fnRawValueToFieldValue("studioSetOfFieldId", footer.fieldIdSet),
          [fieldAlignmentFooter]: fnRawValueToFieldValue("enumPlacement", footer.alignment),
          [fieldShowLabelFooter]: fnRawValueToFieldValue("bool", footer.showLabel)
        };
      })
      : undefined
  };
}

function valueToDto(
  values: FieldValues,
  table?: StudioDtoLayoutTable,
  formStore?: FormStore,
  metaIdForm?: MetaIdForm): StudioDtoLayoutTable
{
  const fieldArrayValueHeader = fnFieldValueToRawValue("studioCompArray",
    values[fieldArraySetHeader]
  ) as FieldValues[] | undefined;

  const fieldArrayValueFooter = fnFieldValueToRawValue("studioCompArray",
    values[fieldArraySetFooter]
  ) as FieldValues[] | undefined;

  let header: StudioMapOfTableHeader | undefined = undefined;
  let footer: StudioMapOfTableFooter | undefined = undefined;

  if(fieldArrayValueHeader)
  {
    const keys = [] as MetaIdHeader[];
    const map = {} as Record<MetaIdHeader, StudioDtoTableHeader>;
    const dtoHeader = table?.header;

    fieldArrayValueHeader.forEach((value, index) =>
    {
      const headerId = dtoHeader?.keys[index] || nextMetaIdHeader();

      const fieldIdSet = fnFieldValueToRawValue("studioSetOfFieldId", value[fieldPickerHeader]);
      const displayText = fnFieldValueToRawValue("text", value[fieldDisplayTextHeader]);
      if(fieldIdSet || displayText)
      {
        keys.push(headerId);
        map[headerId] = {
          fieldIdSet: fieldIdSet,
          displayText: displayText,
          metaId: headerId
          // name: displayText
        } as StudioDtoTableHeader;
      }
    });

    header = keys.length > 0
      ? {
        keys: keys,
        map: map
      }
      : undefined;
  }

  if(fieldArrayValueFooter)
  {
    const keys = [] as MetaIdFooter[];
    const map = {} as Record<MetaIdFooter, StudioDtoTableFooter>;

    fieldArrayValueFooter.forEach(value =>
    {
      const footerId = nextMetaIdFooter();
      const fieldIdSet = fnFieldValueToRawValue("studioSetOfFieldId", value[fieldPickerFooter]);
      const displayFieldId = fnFieldValueToRawValue("pickFieldId", value[fieldDisplayFieldFooter]);
      const showLabel = fnFieldValueToRawValue("bool", value[fieldShowLabelFooter]);
      const alignment = fnFieldValueToRawValue("enumPlacement", value[fieldAlignmentFooter]);

      let displayFieldTextName: string | undefined = "";

      if(metaIdForm && formStore)
      {
        displayFieldTextName = getFieldName(displayFieldId as MetaIdField, metaIdForm, formStore);
      }

      if(fieldIdSet || displayFieldId || showLabel || alignment)
      {
        keys.push(footerId);
        map[footerId] = {
          fieldIdSet: fieldIdSet,
          displayFieldId: displayFieldId,
          metaId: footerId,
          alignment: alignment,
          showLabel: showLabel,
          name: displayFieldTextName
        } as StudioDtoTableFooter;
      }
    });

    footer = keys.length > 0 ? {
      keys: keys,
      map: map
    } : undefined;
  }

  return {
    name: fnFieldValueToRawValue("symbol", values[fieldKeyName]),
    description: fnFieldValueToRawValue("text", values[fieldKeyDescription]),
    label: fnFieldValueToRawValue("text", values[fieldKeyLabel]),
    allowToSwitchLayoutIdSet: fnFieldValueToRawValue("studioMapOfLayoutGrid", values[fieldAllowToSwitchLayoutIdSet]),
    kind: "table",
    rowsPerPage: fnFieldValueToRawValue("number", values[fieldRowsPerPage]),
    allowCustomFilters: fnFieldValueToRawValue("bool", values[fieldAllowCustomFilter]),
    showCompIdSet: fnFieldValueToRawValue("studioSetOfFieldId", values[fieldShowFieldIdSet]),
    columnSizeSet: fnFieldValueToRawValue("chipSet", values[fieldColumnSizeSet]),
    columnAlignmentArray: fnFieldValueToRawValue("chipSet", values[fieldColumnAlignment]),
    renderingMode: fnFieldValueToRawValue("enumGridRenderingMode", values[fieldRenderingMode]),
    header: header,
    footer: footer
  } as StudioDtoLayoutTable;
}

function getDetailsTab(
  metaIdForm?: MetaIdForm,
  metaIdGrid?: MetaIdGrid,
  includeOptionSet?: DefnDtoOption[],
  excludeLayoutGridIdSet?: MetaIdLayoutGrid[]
)
{
  return {
    ...getCommonDetailsTab(),

    [fieldAllowToSwitchLayoutIdSet]: {
      type: "studioSetOfLayoutGridId",
      metaId: fieldAllowToSwitchLayoutIdSet,
      name: fieldAllowToSwitchLayoutIdSet,
      label: allowToSwitchLayoutsLabel,
      excludeLayoutGridIdSet: excludeLayoutGridIdSet,
      formId: metaIdForm
    } as DefnStudioPickLayoutGridId,

    [fieldTabDetails]: {
      type: "section",
      metaId: fieldTabDetails,
      name: fieldTabDetails,
      label: "Details",
      fieldIdSet: [
        fieldKeyName,
        fieldKeyLabel,
        fieldKeyDescription,
        fieldAllowToSwitchLayoutIdSet
      ]
    } as DefnSection
  };
}

function getTableTab(
  metaIdForm?: MetaIdForm,
  metaIdGrid?: MetaIdGrid,
  includeFieldIdSet?: MetaIdField[],
  filterFieldTypeSet?: string[]
)
{
  return {
    [fieldShowFieldIdSet]: {
      type: "studioSetOfFieldId",
      required: true,
      metaId: fieldShowFieldIdSet,
      name: fieldShowFieldIdSet,
      label: "Show fields",
      showChip: true,
      formId: metaIdForm,
      filterFieldTypeSet: filterFieldTypeSet,
      includeFieldIdSet: includeFieldIdSet,
      compositeIdSet: metaIdGrid ? [metaIdGrid] : undefined
    } as DefnStudioPickFieldId,

    [fieldColumnSizeSet]: {
      type: "chipSet",
      metaId: fieldColumnSizeSet,
      name: fieldColumnSizeSet,
      label: "Column sizes",
      allowDuplicate: true,
      helperTextVar: stringToDefnDtoText(SPREADSHEETS_LAYOUT_TABLE_COLUMN_SIZE)
    } as DefnFieldChipSet,

    [fieldColumnAlignment]: {
      type: "chipSet",
      metaId: fieldColumnAlignment,
      name: fieldColumnAlignment,
      label: "Column alignments",
      allowDuplicate: true,
      helperTextVar: stringToDefnDtoText(SPREADSHEETS_LAYOUT_TABLE_COLUMN_ALIGNMENTS)
    } as DefnFieldChipSet,

    [fieldAllowCustomFilter]: {
      type: "bool",
      name: fieldAllowCustomFilter,
      metaId: fieldAllowCustomFilter,
      label: "Allow custom filters",
      showAsCheckboxVar: true
    } as DefnFieldSwitch,

    ...getFieldGap(fieldGap1, "thick"),

    [fieldRowsPerPage]: {
      type: "number",
      metaId: fieldRowsPerPage,
      name: fieldRowsPerPage,
      label: "Rows per page",
      min: 1
    } as DefnFieldNumber,

    [fieldRenderingMode]: {
      type: "enumGridRenderingMode",
      metaId: fieldRenderingMode,
      name: fieldRenderingMode,
      label: "Rendering Mode"
    } as DefnFieldPickEnum,

    [fieldTabTable]: {
      type: "section",
      metaId: fieldTabDetails,
      name: fieldTabDetails,
      label: "Table",
      fieldIdSet: [
        fieldShowFieldIdSet,
        fieldColumnSizeSet,
        fieldColumnAlignment,
        fieldAllowCustomFilter,
        fieldGap1,
        fieldRowsPerPage,
        fieldRenderingMode
      ]
    } as DefnSection
  };
}

function getHeaderTab(
  metaIdForm?: MetaIdForm,
  metaIdGrid?: MetaIdGrid,
  showFieldIdSet?: MetaIdField[]
)
{
  return {

    [fieldDisplayTextHeader]: {
      type: "text",
      label: "Display text",
      name: fieldDisplayTextHeader,
      metaId: fieldDisplayTextHeader,
      pl: 0,
      pr: 0
    } as DefnFieldText,

    [fieldPickerHeader]: {
      type: "studioSetOfFieldId",
      label: "Show for these fields",
      name: fieldPickerHeader,
      metaId: fieldPickerHeader,
      formId: metaIdForm,
      compositeIdSet: metaIdGrid,
      pl: 0,
      pr: 0,
      includeFieldIdSet: showFieldIdSet ?? []
    } as DefnStudioPickFieldId,

    [fieldArraySetHeader]: {
      type: "studioCompArray",
      name: fieldArraySetHeader,
      metaId: fieldArraySetHeader,
      fieldIdSet: [
        fieldDisplayTextHeader,
        fieldPickerHeader
      ]
    } as DefnStudioCompArray,
    [fieldTabHeader]: {
      type: "section",
      metaId: fieldTabHeader,
      name: fieldTabHeader,
      label: "Header",
      fieldIdSet: [
        fieldArraySetHeader
      ]
    } as DefnSection
  };
}

function getFooterTab(
  metaIdForm?: MetaIdForm,
  metaIdGrid?: MetaIdGrid,
  excludeFieldIdSet?: MetaIdField[],
  showFieldIdSet?: MetaIdField[]
)
{
  return {
    [fieldDisplayFieldFooter]: {
      type: "pickFieldId",
      label: "Display field",
      name: fieldDisplayFieldFooter,
      metaId: fieldDisplayFieldFooter,
      formId: metaIdForm,
      excludeFieldIdSet: excludeFieldIdSet,
      pl: 0,
      pr: 0
    } as DefnStudioPickFieldId,

    [fieldPickerFooter]: {
      type: "studioSetOfFieldId",
      label: "Show for these fields",
      name: fieldPickerFooter,
      metaId: fieldPickerFooter,
      formId: metaIdForm,
      compositeIdSet: metaIdGrid,
      pl: 0,
      pr: 0,
      includeFieldIdSet: showFieldIdSet ?? []
    } as DefnStudioPickFieldId,

    [fieldAlignmentFooter]: {
      type: "enumPlacement",
      name: fieldAlignmentFooter,
      metaId: fieldAlignmentFooter,
      label: "Alignment",
      filterOptionSet: ["start", "end", "center", "spaceBetween"],
      pl: 0,
      pr: 0
    } as DefnFieldPickEnum,

    [fieldShowLabelFooter]: {
      type: "bool",
      name: fieldShowLabelFooter,
      metaId: fieldShowLabelFooter,
      label: "Show label",
      showAsCheckboxVar: true,
      pt: gapHalf,
      pl: 0,
      pr: 0
    } as DefnFieldSwitch,

    [fieldArraySetFooter]: {
      type: "studioCompArray",
      name: fieldArraySetFooter,
      metaId: fieldArraySetFooter,
      fieldIdSet: [
        fieldDisplayFieldFooter,
        fieldPickerFooter,
        fieldAlignmentFooter,
        fieldShowLabelFooter
      ]
    } as DefnStudioCompArray,
    [fieldTabFooter]: {
      type: "section",
      metaId: fieldTabFooter,
      name: fieldTabFooter,
      label: "Footer",
      fieldIdSet: [
        fieldArraySetFooter
      ]
    } as DefnSection
  };
}
