import {isProdEnvironment} from "../../../base/plus/SysPlus";

const REDIRECT_URI_DEVELOPMENT = "https://web.orgbeat.com/signIn";
const REDIRECT_URI_PRODUCTION = "https://web.neome.ai/signIn";
const CLIENT_ID_DEVELOPMENT = "e8a93c6e-ff6d-4c22-b941-7e295ab3c486";
const CLIENT_ID_PRODUCTION = "9055f31d-bdbf-4fcf-8cad-5261b0c4d5f7";

export const msalConfig = {
  auth: {
    clientId: getAzureClientId(),
    authority: "https://login.microsoftonline.com/common",
    redirectUri: getAuthRedirectUri()
  }
};

export function getAuthRedirectUri()
{
  return isProdEnvironment()
    ? REDIRECT_URI_PRODUCTION
    : REDIRECT_URI_DEVELOPMENT;
}

function getAzureClientId()
{
  return isProdEnvironment()
    ? CLIENT_ID_PRODUCTION
    : CLIENT_ID_DEVELOPMENT;
}
