import {isEmpty} from "lodash";
import {useState} from "react";
import {useMemo} from "react";
import {useCallback} from "react";
import {useEffect} from "react";
import {FieldValues} from "react-hook-form/dist/types/fields";
import {isSectionId} from "../../../../api/meta/base/ApiPlus";
import {DefnDtoOption} from "../../../../api/meta/base/dto/DefnDtoOption";
import {DefnField} from "../../../../api/meta/base/dto/DefnField";
import {DefnFieldLabel} from "../../../../api/meta/base/dto/DefnFieldLabel";
import {DefnFieldSwitch} from "../../../../api/meta/base/dto/DefnFieldSwitch";
import {DefnSection} from "../../../../api/meta/base/dto/DefnSection";
import {DefnStudioMapOfDtoOption} from "../../../../api/meta/base/dto/DefnStudioMapOfDtoOption";
import {DefnTab} from "../../../../api/meta/base/dto/DefnTab";
import {FieldDtoTree} from "../../../../api/meta/base/dto/FieldDtoTree";
import {FieldValueNumber} from "../../../../api/meta/base/dto/FieldValueNumber";
import {FieldValueSwitch} from "../../../../api/meta/base/dto/FieldValueSwitch";
import {StudioBuildDate} from "../../../../api/meta/base/dto/StudioBuildDate";
import {StudioBuildDateTime} from "../../../../api/meta/base/dto/StudioBuildDateTime";
import {StudioComposite} from "../../../../api/meta/base/dto/StudioComposite";
import {StudioField} from "../../../../api/meta/base/dto/StudioField";
import {StudioFieldPickReportRow} from "../../../../api/meta/base/dto/StudioFieldPickReportRow";
import {StudioFieldPickText} from "../../../../api/meta/base/dto/StudioFieldPickText";
import {StudioFieldPickTree} from "../../../../api/meta/base/dto/StudioFieldPickTree";
import {StudioFieldRef} from "../../../../api/meta/base/dto/StudioFieldRef";
import {StudioFieldRefReport} from "../../../../api/meta/base/dto/StudioFieldRefReport";
import {StudioFieldSetOfText} from "../../../../api/meta/base/dto/StudioFieldSetOfText";
import {StudioForm} from "../../../../api/meta/base/dto/StudioForm";
import {StudioGrid} from "../../../../api/meta/base/dto/StudioGrid";
import {StudioSection} from "../../../../api/meta/base/dto/StudioSection";
import {StudioVarCondition} from "../../../../api/meta/base/dto/StudioVarCondition";
import {StudioVarSetOfText} from "../../../../api/meta/base/dto/StudioVarSetOfText";
import {StudioVarTree} from "../../../../api/meta/base/dto/StudioVarTree";
import {EnumDefnTextValidationPattern} from "../../../../api/meta/base/Types";
import {EnumArrayDefnMonth} from "../../../../api/meta/base/Types";
import {EnumDefnLayoutGridKind} from "../../../../api/meta/base/Types";
import {EnumDefnButtonTargetType} from "../../../../api/meta/base/Types";
import {EnumDefnDate} from "../../../../api/meta/base/Types";
import {MetaIdReport} from "../../../../api/meta/base/Types";
import {MetaIdGrid} from "../../../../api/meta/base/Types";
import {MetaIdVar} from "../../../../api/meta/base/Types";
import {MetaIdSpreadsheet} from "../../../../api/meta/base/Types";
import {MetaIdForm} from "../../../../api/meta/base/Types";
import {PluginApiId} from "../../../../api/meta/base/Types";
import {MetaIdPlugin} from "../../../../api/meta/base/Types";
import {MetaIdSection} from "../../../../api/meta/base/Types";
import {MetaIdComposite} from "../../../../api/meta/base/Types";
import {EnumStudioCompType} from "../../../../api/meta/base/Types";
import {MetaIdField} from "../../../../api/meta/base/Types";
import {fnRawValueToFieldValue} from "../../../../base/plus/FieldValuePlus";
import {fnFieldValueToRawValue} from "../../../../base/plus/FieldValuePlus";
import {defaultSectionKey} from "../../../../base/plus/FormPlus";
import {createDefaultDefnFormStudio} from "../../../../base/plus/FormPlus";
import {toLabel} from "../../../../base/plus/StringPlus";
import {getExcludePluginApiIdSetByFieldType} from "../../../../base/plus/StudioFormPlus";
import {fnUseStudioResolver} from "../../../../base/plus/StudioPlus";
import {getExcludeMappingVarIdSet} from "../../../../base/plus/StudioPlus";
import {IResolvedPluginIOForms} from "../../../../base/plus/StudioPlus";
import {IFormRef} from "../../../../base/types/TypesForm";
import {FormStore} from "../../../../base/types/TypesForm";
import {getDefnButtonActionTab} from "../field/pro/FieldBuilderButton";
import {getDefnLayoutTabRef} from "../field/pro/FieldBuilderRef";
import {getDefnLayoutTabRefReport} from "../field/pro/FieldBuilderRefReport";
import {getFieldBuilderSourceFieldIdSet} from "./FieldBuilderFactory";
import {getFieldBuilderPropertyFieldIdSet} from "./FieldBuilderFactory";
import {fieldBuilderFactory} from "./FieldBuilderFactory";
import {getSourceTab} from "./FieldBuilderFactory";
import {getCompPermissionMatrix} from "./FormBuilderPlus";
import {propKeyTextValidationPattern} from "./TypesFormBuilder";
import {propKeyEditableFieldIdSet} from "./TypesFormBuilder";
import {propKeyCopyFields} from "./TypesFormBuilder";
import {fieldValueMaxTimeEnum} from "./TypesFormBuilder";
import {fieldValueMinTimeEnum} from "./TypesFormBuilder";
import {fieldValueDefaultTimeEnum} from "./TypesFormBuilder";
import {propKeyStep} from "./TypesFormBuilder";
import {propKeyStrikeThroughFieldId} from "./TypesFormBuilder";
import {propKeyUnderlineFieldId} from "./TypesFormBuilder";
import {propKeyStrikeThroughVar} from "./TypesFormBuilder";
import {propKeyUnderlineVar} from "./TypesFormBuilder";
import {propKeyStrikeThrough} from "./TypesFormBuilder";
import {propKeyUnderline} from "./TypesFormBuilder";
import {fieldLayoutTab} from "./TypesFormBuilder";
import {propKeyMin} from "./TypesFormBuilder";
import {propKeyMax} from "./TypesFormBuilder";
import {propKeyToDefault} from "./TypesFormBuilder";
import {propKeyFromDefault} from "./TypesFormBuilder";
import {propKeyDefaultValue} from "./TypesFormBuilder";
import {propKeyFieldRefreshOn} from "./TypesFormBuilder";
import {propKeyRefReportOutputFormGridLayoutId} from "./TypesFormBuilder";
import {fieldReport} from "./TypesFormBuilder";
import {propKeyTargetType} from "./TypesFormBuilder";
import {propKeyRefCopyFields} from "./TypesFormBuilder";
import {propKeyShowAsDropdown} from "./TypesFormBuilder";
import {propKeyRefReportOutputFormGridId} from "./TypesFormBuilder";
import {fieldTabProperties} from "./TypesFormBuilder";
import {propKeyVarIcon} from "./TypesFormBuilder";
import {propKeyAllowRangePicker} from "./TypesFormBuilder";
import {fieldTabSource} from "./TypesFormBuilder";
import {propKeyConditionVarId} from "./TypesFormBuilder";
import {propKeyGridDisplayField} from "./TypesFormBuilder";
import {propKeyPickLayoutGridId} from "./TypesFormBuilder";
import {propKeyPlugin} from "./TypesFormBuilder";
import {propKeyPickGridId} from "./TypesFormBuilder";
import {propKeySourceVarId} from "./TypesFormBuilder";
import {propKeyButtonSpreadsheetId} from "./TypesFormBuilder";
import {propKeyButtonKind} from "./TypesFormBuilder";
import {fieldActionTab} from "./TypesFormBuilder";
import {propKeyActionTab} from "./TypesFormBuilder";
import {fieldButtonActionTabSection} from "./TypesFormBuilder";
import {fieldButtonActionCheckBox} from "./TypesFormBuilder";
import {fieldNormalButtonLabel} from "./TypesFormBuilder";
import {fieldGap4} from "./TypesFormBuilder";
import {getFieldGap} from "./TypesFormBuilder";
import {propKeyActionCloseAside} from "./TypesFormBuilder";
import {fieldTabPermissions} from "./TypesFormBuilder";
import {fieldKeyName} from "./TypesFormBuilder";
import {fieldKeyLabel} from "./TypesFormBuilder";
import {getStudioDetailsCompMap} from "./TypesFormBuilder";
import {fieldTabDetails} from "./TypesFormBuilder";

export type TypeTextValidationPattern = EnumDefnTextValidationPattern | "custom"

export function useFieldBuilder(props: {
  formStore: FormStore,
  formRef: IFormRef,
  form?: StudioForm,
  composite?: StudioComposite,
  field?: StudioField,
  fieldType?: EnumStudioCompType,

  // extra
  isRefChild: boolean,
  isRefSystemChild: boolean,
  isPluginForm?: boolean,
  selectedPluginId?: MetaIdPlugin,
  selectedPluginApiId?: PluginApiId
  pluginApiIOForms?: IResolvedPluginIOForms,
  selectedRefFieldIdSet?: MetaIdField[],
  sourceFormId?: MetaIdForm,
  isFieldRefOverlayLayoutFilledSpreadsheet?: boolean,
  isFieldRefOverlayLayoutFilledGrid?: boolean,
  isFieldRefOverlayLayoutDisabled?: boolean,
  isFieldRefReportOverlayLayoutDisabled?: boolean
  fieldRefCategoryDisplayFields?: DefnStudioMapOfDtoOption,
})
{
  const fieldType = props.fieldType;
  const isRefChild = props.isRefChild;
  const isRefSystemChild = props.isRefSystemChild;
  const form = props.form;
  const composite = props.composite;
  const isPluginForm = props.isPluginForm;
  const selectedPluginId = props.selectedPluginId;
  const selectedPluginApiId = props.selectedPluginApiId;
  const pluginApiIOForms = props.pluginApiIOForms;
  const field = props.field;
  const formStore = props.formStore;
  const selectedRefFieldIdSet = props.selectedRefFieldIdSet;
  const sourceFormId = props.sourceFormId;
  const formRef = props.formRef;
  const fieldRefCategoryDisplayFields = props.fieldRefCategoryDisplayFields;
  const isFieldRefOverlayLayoutFilledSpreadsheet = props.isFieldRefOverlayLayoutFilledSpreadsheet;
  const isFieldRefOverlayLayoutFilledGrid = props.isFieldRefOverlayLayoutFilledGrid;
  const isFieldRefOverlayLayoutDisabled = props.isFieldRefOverlayLayoutDisabled;
  const isFieldRefReportOverlayLayoutDisabled = props.isFieldRefReportOverlayLayoutDisabled;

  const varMap = formStore.varMap;
  const importMap = formStore.pluginMap;
  const reportMap = formStore.reportMap;
  const formId = form?.metaId;
  const fieldId = field?.metaId;
  const compositeId = (composite as StudioGrid | StudioSection)?.metaId;
  const isFieldTypeRef = fieldType === "ref";
  const isFieldTypeRefReport = fieldType === "refReport";
  const isFieldTypeButton = fieldType === "button";
  const isFieldTypeError = fieldType === "error";
  const isFieldTypePickText = fieldType === "pickText";
  const isFieldTypeSetOfText = fieldType === "setOfText";
  const isFieldTypePickTree = fieldType === "pickTree";
  const spreadsheetId = (field as StudioFieldRef)?.spreadsheetId;
  const compositeIds = form?.compositeMap.keys;
  const hidePropertyTab = isRefSystemChild;
  const excludeLabelField = isRefSystemChild || isFieldTypeError;

  const isPluginApiHelperTextVisible = Boolean(selectedPluginApiId);
  const isPluginInputFormPresent = Boolean(pluginApiIOForms?.inputForm);
  const isPluginOutputFormPresent = Boolean(pluginApiIOForms?.outputForm);
  const defaultGridId = (field as StudioFieldPickReportRow)?.reportOutputFormGridId
    || (field as StudioFieldRefReport)?.gridId;

  const [pickTextOptionArray, setPickTextOptionArray] = useState<DefnDtoOption[]>([]);
  const [pickTreeOptionArray, setPickTreeOptionArray] = useState<FieldDtoTree>();
  const [includeFieldIdSet, setIncludeFieldIdSet] = useState<MetaIdField[]>([]);
  const [labelHelperTextName, setLabelHelperTextName] = useState<string | undefined>();
  const [showLabelHelperTextName, setShowLabelHelperTextName] = useState<boolean>();
  const [sectionIdSet, setSectionIdSet] = useState<MetaIdSection[]>([]);
  const [gridId, setGridId] = useState<MetaIdGrid | undefined>(defaultGridId);
  const [excludeGridRowVarIdSet, setExcludeGridRowVarIdSet] = useState<MetaIdVar[]>();
  const [excludePluginApiIdSet, setExcludePluginApiIdSet] = useState<PluginApiId[]>();
  const [refReportReportId, setRefReportReportId] = useState<MetaIdReport>();
  const [reportOutputFormId, setReportOutputFormId] = useState<MetaIdForm>();
  const [allowRangePicker, setAllowRangePicker] = useState<boolean>(false);
  const [showIconPosition, setShowIconPosition] = useState<boolean>(true);
  const [isNormalFieldButtonKind, setIsNormalFieldButtonKind] = useState<boolean>(true);
  const [targetType, setTargetType] = useState<EnumDefnButtonTargetType>();
  const [buttonSpreadsheetId, setButtonSpreadsheetId] = useState<MetaIdSpreadsheet>();
  const [disableCopyFields, setDisableCopyFields] = useState<boolean>(false);
  const [callReportId, setCallReportId] = useState<MetaIdReport>();
  const [hideRefreshInMenu, setHideHideRefreshInMenu] = useState<boolean>(true);
  const [validationPattern, setValidationPattern] = useState<TypeTextValidationPattern | undefined>();

  // for Date fields
  const [showCustom, setCustomDate] = useState(false);
  const [defaultValue, setDefaultValue] = useState<EnumDefnDate>();
  const [showCustomFrom, setShowCustomFrom] = useState(false);
  const [showCustomTo, setShowCustomTo] = useState(false);
  const [showCustomMax, setShowCustomMax] = useState(false);
  const [showCustomMin, setShowCustomMin] = useState(false);
  const [defaultStepCount, setDefaultStepCount] = useState<number>();
  const [defaultValueTime, setDefaultValueTime] = useState<string>();
  const [minValueTime, setMinValueTime] = useState<string>();
  const [maxValueTime, setMaxValueTime] = useState<string>();

  const {
    getSpreadSheetFormName,
    getPluginApiIOFormNames,
    getSpreadSheetForm,
    getReportFormNames,
    getReportForms
  } = fnUseStudioResolver(formStore);

  // region for field button
  const buttonSpreadSheetFormId = useMemo(() => buttonSpreadsheetId
    ? getSpreadSheetForm(buttonSpreadsheetId)?.metaId
    : undefined, [buttonSpreadsheetId, getSpreadSheetForm]);

  const notValidPluginInputFormMappingVars = useMemo(() => varMap
    ? getExcludeMappingVarIdSet("both", varMap, formId, pluginApiIOForms?.inputForm?.metaId)
    : undefined, [formId, pluginApiIOForms?.inputForm?.metaId, varMap]);

  const notValidPluginOutputFormMappingVars = useMemo(() => varMap
    ? getExcludeMappingVarIdSet("both", varMap, pluginApiIOForms?.outputForm?.metaId, formId)
    : undefined, [formId, pluginApiIOForms?.outputForm?.metaId, varMap]);

  const excludeButtonSpreadSheetMappingVars = useMemo(() => varMap && buttonSpreadSheetFormId
    ? getExcludeMappingVarIdSet("both", varMap, formId, buttonSpreadSheetFormId)
    : undefined, [buttonSpreadSheetFormId, formId, varMap]);

  const spreadsheetFormName = buttonSpreadsheetId
    ? getSpreadSheetFormName(buttonSpreadsheetId)
    : undefined;

  const pluginApiIOFormName = selectedPluginId && selectedPluginApiId
    ? getPluginApiIOFormNames(selectedPluginId, selectedPluginApiId)
    : undefined;

  const isReportInputFormPresent = Boolean(callReportId && Boolean(getReportForms(callReportId).inputForm));
  const isReportOutputFormPresent = Boolean(callReportId && Boolean(getReportForms(callReportId).outputForm));

  const reportIOFormName = refReportReportId
    ? getReportFormNames(refReportReportId)
    : undefined;
  // endregion

  const sectionIdSetWithCurrentGridId = useMemo(() => getSectionIdSetWithCurrentGridId(sectionIdSet, composite),
    [sectionIdSet, composite]
  );

  const includeSourceTab = showSourceTab(fieldType);

  const includeLayoutTab = showLayoutTab(fieldType);

  const hidePermissionTab = isPluginForm
    || isFieldTypeRef
    || isFieldTypeRefReport;

  const tabIdSet = getDefaultSectionFieldIdSet(
    hidePermissionTab,
    isFieldTypeButton,
    includeSourceTab,
    hidePropertyTab,
    includeLayoutTab
  );

  const getPickTextOptionArray = useCallback((varId: MetaIdVar): DefnDtoOption[] =>
  {
    const studioVar = varMap?.map[varId] as StudioVarSetOfText;
    const studioVarValue = studioVar?.value;
    const options = [] as DefnDtoOption[];
    if(studioVarValue?.keys && studioVarValue.keys.length > 0)
    {
      studioVarValue.keys.forEach((metaIdOption) =>
      {
        options.push(studioVarValue.map[metaIdOption]);
      });
    }
    return options;
  }, [varMap]);

  const getPickTreeOptionArray = useCallback((varId: MetaIdVar): FieldDtoTree =>
  {
    const studioVar = varMap?.map[varId] as StudioVarTree;
    const studioVarValue = studioVar?.value;
    return studioVarValue?.value as FieldDtoTree;
  }, [varMap]);

  const getPickTextIncludeFieldIdSet = useCallback((varId: MetaIdVar): MetaIdField[] =>
    {
      const fieldIdSet = [] as MetaIdField[];

      sectionIdSetWithCurrentGridId.forEach(compId =>
      {
        const composite = form?.compositeMap.map[compId] as StudioSection | StudioGrid;
        const fieldMap = composite.fieldMap;

        fieldMap.keys.forEach(_fieldId =>
        {
          const field = fieldMap.map[_fieldId];
          const fieldType = field?.type;
          if(isFieldTypePickText && fieldType === "pickText")
          {
            const sourceVarId = (field as StudioFieldPickText).sourceVarId;
            if(varId === sourceVarId && _fieldId !== fieldId)
            {
              fieldIdSet.push(_fieldId);
            }
          }
          if(isFieldTypeSetOfText && fieldType === "setOfText")
          {
            const sourceVarId = (field as StudioFieldSetOfText).sourceVarId;
            if(varId === sourceVarId && _fieldId !== fieldId)
            {
              fieldIdSet.push(_fieldId);
            }
          }
        });

      });

      return fieldIdSet;

    },
    [fieldId, form?.compositeMap.map, isFieldTypePickText, isFieldTypeSetOfText, sectionIdSetWithCurrentGridId]
  );

  const getPickTreeIncludeFieldIdSet = useCallback((varId: MetaIdVar): MetaIdField[] =>
    {
      const fieldIdSet = [] as MetaIdField[];

      sectionIdSetWithCurrentGridId.forEach(compId =>
      {
        const composite = form?.compositeMap.map[compId] as StudioSection | StudioGrid;
        const fieldMap = composite.fieldMap;

        fieldMap.keys.forEach(_fieldId =>
        {
          const field = fieldMap.map[_fieldId];
          const fieldType = field?.type;
          if(isFieldTypePickTree && fieldType === "pickTree")
          {
            const sourceVarId = (field as StudioFieldPickTree).sourceVarId;
            if(varId === sourceVarId && _fieldId !== fieldId)
            {
              fieldIdSet.push(_fieldId);
            }
          }
        });

      });

      return fieldIdSet;

    },
    [fieldId, form?.compositeMap.map, isFieldTypePickTree, isFieldTypeSetOfText, sectionIdSetWithCurrentGridId]
  );

  const onWatch = useCallback((key: MetaIdField, value: any) =>
  {
    switch(key)
    {
      case fieldKeyLabel:
        if(fnFieldValueToRawValue("text", value))
        {
          setShowLabelHelperTextName(false);
        }
        else
        {
          setShowLabelHelperTextName(true);
        }
        break;
      case fieldKeyName:
        if(fnFieldValueToRawValue("symbol", value))
        {
          setLabelHelperTextName(toLabel(fnFieldValueToRawValue("symbol", value) as string));
        }
        break;
      case propKeyButtonKind:
        setIsNormalFieldButtonKind(Boolean(value === "normal"));
        break;
      case propKeyTargetType:
        setTargetType(fnFieldValueToRawValue("enumTargetType", value) as EnumDefnButtonTargetType | undefined);
        break;
      case propKeyButtonSpreadsheetId:
        setButtonSpreadsheetId(value);
        break;
      case propKeySourceVarId:
        setPickTextOptionArray(getPickTextOptionArray(value));
        setPickTreeOptionArray(getPickTreeOptionArray(value));
        if(fieldType === "pickText" || fieldType === "setOfText")
        {
          setIncludeFieldIdSet(getPickTextIncludeFieldIdSet(value));
        }
        else if(fieldType === "pickTree")
        {
          setIncludeFieldIdSet(getPickTreeIncludeFieldIdSet(value));
        }
        break;
      case propKeyPickGridId:
        const excludeGridRowVarIdSet = [] as MetaIdVar[];

        formRef.resetField(propKeyPickLayoutGridId);
        formRef.resetField(propKeyGridDisplayField);
        formRef.resetField(propKeyConditionVarId);

        varMap?.keys.forEach((varId) =>
        {
          const variable = varMap?.map[varId];
          if(variable?.kind === "condition")
          {
            const conditionVar = variable as StudioVarCondition;
            if(conditionVar.value?.sourceGridId !== value)
            {
              excludeGridRowVarIdSet.push(varId);
            }
          }
          else
          {
            excludeGridRowVarIdSet.push(varId);
          }
        });

        setExcludeGridRowVarIdSet(excludeGridRowVarIdSet);
        setGridId(fnFieldValueToRawValue("pickGridId", value) as MetaIdGrid);
        if(!value)
        {
          formRef.resetField(propKeyPickLayoutGridId);
        }
        break;
      case propKeyPlugin:
        if(importMap)
        {
          const plugin = importMap?.map[value];
          const pluginApiMap = plugin?.pluginApiIdMap;

          if(pluginApiMap)
          {
            const excludePluginApiIdSet = getExcludePluginApiIdSetByFieldType(pluginApiMap, fieldType);
            setExcludePluginApiIdSet(excludePluginApiIdSet);
          }
        }
        break;
      case fieldReport:
        const reportId = fnFieldValueToRawValue("pickReportId", value) as MetaIdReport;
        const report = (reportMap && reportId)
          ? reportMap.map[reportId]
          : undefined;

        setRefReportReportId(reportId);
        setReportOutputFormId(report?.outputFormId);
        setCallReportId(reportId);
        break;
      case propKeyAllowRangePicker:
        setAllowRangePicker((value as FieldValueSwitch)?.value);
        break;

      case propKeyRefReportOutputFormGridId:
        setGridId(fnFieldValueToRawValue("pickGridId", value) as MetaIdGrid);
        formRef.setValue(propKeyRefReportOutputFormGridLayoutId, null);
        formRef.setValue(propKeyCopyFields, null);
        formRef.setValue(propKeyEditableFieldIdSet, null);
        break;

      case propKeyVarIcon:
        setShowIconPosition(Boolean(value));
        break;

      case propKeyShowAsDropdown:
        if((value as FieldValueSwitch)?.value)
        {
          setDisableCopyFields(true);
          formRef.setValue(propKeyRefCopyFields, null);
        }
        else
        {
          setDisableCopyFields(false);
        }
        break;

      case propKeyFieldRefreshOn:
      {
        const fieldValue = fnRawValueToFieldValue("enumRefreshOn", value);
        if(fieldValue === "refreshOnDemand")
        {
          setHideHideRefreshInMenu(false);
        }
        else
        {
          setHideHideRefreshInMenu(true);
        }
        break;
      }
      case propKeyDefaultValue:
      {
        setDefaultValue(value);
        if(value === "custom")
        {
          setCustomDate(true);
        }
        else
        {
          setCustomDate(false);
        }
        break;
      }
      case propKeyFromDefault:
      {
        if(value === "custom")
        {
          setShowCustomFrom(true);
        }
        else
        {
          setShowCustomFrom(false);
        }
        break;
      }
      case propKeyToDefault:
      {
        if(value === "custom")
        {
          setShowCustomTo(true);
        }
        else
        {
          setShowCustomTo(false);
        }
        break;
      }
      case propKeyMax:
      {
        if(value === "custom")
        {
          setShowCustomMax(true);
        }
        else
        {
          setShowCustomMax(false);
        }
        break;
      }
      case propKeyMin:
      {
        if(value === "custom")
        {
          setShowCustomMin(true);
        }
        else
        {
          setShowCustomMin(false);
        }
        break;
      }
      case propKeyUnderline:
      {
        if(value && fnFieldValueToRawValue("bool", value))
        {
          formRef.setValue(propKeyStrikeThrough, fnRawValueToFieldValue("bool", false));
        }
      }
        break;
      case propKeyStrikeThrough:
      {
        if(value && fnFieldValueToRawValue("bool", value))
        {
          formRef.setValue(propKeyUnderline, fnRawValueToFieldValue("bool", false));
        }
      }
        break;
      case propKeyUnderlineVar:
      case propKeyUnderlineFieldId:
      {
        if(value)
        {
          formRef.setValue(propKeyStrikeThroughVar, null);
          formRef.setValue(propKeyStrikeThroughFieldId, null);
        }
      }
        break;
      case propKeyStrikeThroughVar:
      case propKeyStrikeThroughFieldId:
      {
        if(value)
        {
          formRef.setValue(propKeyUnderlineVar, null);
          formRef.setValue(propKeyUnderlineFieldId, null);
        }
      }
        break;
      case propKeyStep:
      {
        setDefaultStepCount((value as FieldValueNumber)?.value);
      }
        break;
      case fieldValueDefaultTimeEnum:
      {
        setDefaultValueTime(fnFieldValueToRawValue("pickText", value) as string);
      }
        break;
      case fieldValueMinTimeEnum:
      {
        setMinValueTime(fnFieldValueToRawValue("pickText", value) as string);
      }
        break;
      case fieldValueMaxTimeEnum:
      {
        setMaxValueTime(fnFieldValueToRawValue("pickText", value) as string);
      }
        break;
      case propKeyTextValidationPattern:
        setValidationPattern(fnFieldValueToRawValue("pickOption", value) as EnumDefnTextValidationPattern);
        break;
      default:
        break;
    }

  }, [
    fieldType,
    formRef,
    getPickTextOptionArray,
    importMap,
    reportMap,
    varMap?.keys,
    varMap?.map,
    field?.type,
    fieldId,
    form?.compositeMap.map,
    isFieldTypePickText,
    isFieldTypeSetOfText,
    sectionIdSetWithCurrentGridId
  ]);

  const detailsTab = getStudioDetailsCompMap(
    labelHelperTextName,
    showLabelHelperTextName,
    excludeLabelField,
    fieldType,
    formId,
    sectionIdSetWithCurrentGridId,
    compositeId,
    isRefChild
  );

  const sourceTab = useMemo(() => (formId && fieldType)
      ? getSourceTab({
        formId,
        fieldType,
        metaIdPlugin: selectedPluginId,
        excludeGridId: compositeId,
        gridId: gridId,
        isPluginInputFormPresent,
        helperTextPluginApiIOFormName: pluginApiIOFormName,
        helperTextReportFormName: reportIOFormName,
        isPluginApiHelperTextVisible,
        excludePluginApiIdSet,
        notValidPluginInputFormMappingVars,
        isPluginForm,
        excludeGridRowVarIdSet,
        selectedRefFieldIdSet,
        reportOutputFormId,
        disableCopyFields
      })
      : {},
    [
      compositeId,
      disableCopyFields,
      selectedRefFieldIdSet,
      excludeGridRowVarIdSet,
      excludePluginApiIdSet,
      fieldType,
      formId,
      isPluginApiHelperTextVisible,
      isPluginForm,
      isPluginInputFormPresent,
      notValidPluginInputFormMappingVars,
      gridId,
      pluginApiIOFormName,
      reportIOFormName,
      reportOutputFormId,
      selectedPluginId
    ]
  );

  const propertyTab = useMemo(() => (fieldType && formId)
      ? fieldBuilderFactory(
        fieldType,
        formId,
        spreadsheetId,
        pickTextOptionArray,
        pickTreeOptionArray,
        includeFieldIdSet,
        fieldId,
        spreadsheetFormName,
        showIconPosition,
        allowRangePicker,
        selectedRefFieldIdSet,
        sourceFormId,
        varMap,
        reportOutputFormId,
        gridId,
        reportIOFormName,
        hideRefreshInMenu,
        showCustom,
        defaultValue,
        showCustomFrom,
        showCustomTo,
        showCustomMax,
        showCustomMin,
        sectionIdSetWithCurrentGridId,
        defaultStepCount,
        defaultValueTime,
        minValueTime,
        maxValueTime,
        compositeId,
        fieldRefCategoryDisplayFields,
        validationPattern
      )
      : {},
    [
      fieldType,
      formId,
      spreadsheetId,
      pickTextOptionArray,
      pickTreeOptionArray,
      includeFieldIdSet,
      fieldId,
      spreadsheetFormName,
      showIconPosition,
      allowRangePicker,
      selectedRefFieldIdSet,
      sourceFormId,
      varMap,
      reportOutputFormId,
      gridId,
      reportIOFormName,
      hideRefreshInMenu,
      showCustom,
      defaultValue,
      showCustomFrom,
      showCustomTo,
      showCustomMax,
      showCustomMin,
      sectionIdSetWithCurrentGridId,
      defaultStepCount,
      defaultValueTime,
      minValueTime,
      maxValueTime,
      compositeId,
      fieldRefCategoryDisplayFields,
      validationPattern
    ]
  );

  const actionTab = getDefnButtonActionTab({
    fieldId: fieldId,
    pluginId: selectedPluginId,
    isNormalFieldButtonKind: isNormalFieldButtonKind,
    formId,
    isPluginForm,
    targetType: targetType,
    excludeButtonSpreadSheetMappingVars,
    notValidPluginInputFormMappingVars,
    notValidPluginOutputFormMappingVars,
    isPluginApiHelperTextVisible,
    isPluginInputFormPresent,
    isPluginOutputFormPresent,
    isReportInputFormPresent,
    isReportOutputFormPresent,
    helperTextSpreadsheetFormName: spreadsheetFormName,
    helperTextPluginApiIOFormName: pluginApiIOFormName
  });

  const getLayoutTab = useCallback(() =>
    {
      if(fieldType === "ref")
      {
        return getDefnLayoutTabRef(
          spreadsheetId,
          isFieldRefOverlayLayoutDisabled,
          isFieldRefOverlayLayoutFilledSpreadsheet,
          fieldRefCategoryDisplayFields,
          formId
        );
      }
      else if(fieldType === "refReport")
      {
        return getDefnLayoutTabRefReport(
          reportOutputFormId,
          gridId,
          isFieldRefReportOverlayLayoutDisabled,
          isFieldRefOverlayLayoutFilledGrid
        );
      }
      else
      {
        return {};
      }

    },
    [
      fieldRefCategoryDisplayFields,
      fieldType,
      formId,
      gridId,
      isFieldRefOverlayLayoutDisabled,
      isFieldRefOverlayLayoutFilledGrid,
      isFieldRefOverlayLayoutFilledSpreadsheet,
      isFieldRefReportOverlayLayoutDisabled,
      reportOutputFormId,
      spreadsheetId
    ]
  );

  const layoutTab = getLayoutTab();

  const permissionTab = getCompPermissionMatrix(fieldTabPermissions, fieldType);

  const getDefn = useCallback(() =>
    {
      return createDefaultDefnFormStudio({
        ...detailsTab,

        ...includeSourceTab ? sourceTab : {},

        ...propertyTab,

        ...!hidePermissionTab && {
          ...permissionTab
        },

        ...isFieldTypeButton ? actionTab : {},

        ...includeLayoutTab ? layoutTab : {},

        [fieldTabDetails]: {
          type: "section",
          fieldIdSet: Object.keys(detailsTab),
          metaId: fieldTabDetails
        } as DefnSection,

        ...includeSourceTab && {
          [fieldTabSource]: {
            type: "section",
            metaId: fieldTabSource,
            fieldIdSet: getFieldBuilderSourceFieldIdSet(sourceTab, fieldType)
          } as DefnSection
        },

        ...includeLayoutTab && {
          [fieldLayoutTab]: {
            type: "section",
            metaId: fieldLayoutTab,
            fieldIdSet: Object.keys(layoutTab)
          } as DefnSection
        },

        [fieldTabProperties]: {
          type: "section",
          metaId: fieldTabProperties,
          fieldIdSet: getFieldBuilderPropertyFieldIdSet(propertyTab, fieldType)
        } as DefnSection,

        ...isFieldTypeButton && {
          ...getButtonActionTabCheckBox(isNormalFieldButtonKind)
        },

        [defaultSectionKey]: {
          type: "tab",
          tabIdSet: tabIdSet,
          metaId: defaultSectionKey
        } as DefnTab

      } as Record<MetaIdField, DefnField>);

    },
    [
      actionTab,
      layoutTab,
      detailsTab,
      fieldType,
      hidePermissionTab,
      includeSourceTab,
      includeLayoutTab,
      isFieldTypeButton,
      isNormalFieldButtonKind,
      permissionTab,
      propertyTab,
      sourceTab,
      tabIdSet
    ]
  );

  useEffect(() =>
  {
    if(form)
    {
      setSectionIdSet(compositeIds?.filter((key) => isSectionId(key)) as MetaIdComposite[]);
    }

  }, [compositeIds]);

  return {
    getDefn,
    onWatch,
    buttonSpreadSheetFormId,
    gridId: gridId,
    hideRefreshInMenu
  };
}

function getDefaultSectionFieldIdSet(
  hidePermissionTab?: boolean,
  isFieldTypeButton?: boolean,
  includeSourceTab?: boolean,
  hidePropertyTab?: boolean,
  includeLayoutTab?: boolean
): MetaIdField[]
{
  const fieldIdSet = [fieldTabDetails];

  if(includeSourceTab)
  {
    fieldIdSet.push(fieldTabSource);
  }

  if(!hidePropertyTab)
  {
    fieldIdSet.push(fieldTabProperties);
  }

  if(includeLayoutTab)
  {
    fieldIdSet.push(fieldLayoutTab);
  }

  if(isFieldTypeButton)
  {
    fieldIdSet.push(fieldActionTab);
  }

  if(!hidePermissionTab)
  {
    fieldIdSet.push(fieldTabPermissions);
  }

  return fieldIdSet;
}

function showSourceTab(fieldType?: EnumStudioCompType)
{
  const fieldTypesWithSourceTab = {
    "pickText": true,
    "pickTree": true,
    "pickUser": true,
    "pickGridRow": true,
    "setOfText": true,
    "setOfUser": true,
    "pickReportRow": true,
    "refUser": true
  } as Record<EnumStudioCompType, boolean>;
  return Boolean(fieldType && fieldTypesWithSourceTab[fieldType]);
}

function showLayoutTab(fieldType?: EnumStudioCompType)
{
  return fieldType === "refReport" || fieldType === "ref";
}

function getSectionIdSetWithCurrentGridId(sectionIdSet: MetaIdSection[], composite?: StudioComposite)
{
  const grid = composite as StudioGrid;
  return [
    ...sectionIdSet,
    ...grid?.type === "grid" ? [grid.metaId] : []
  ];

}

function getButtonActionTabCheckBox(showNormalSection?: boolean)
{
  return {
    [propKeyActionCloseAside]: {
      type: "bool",
      metaId: propKeyActionCloseAside,
      name: "Close aside",
      label: "Close aside",
      showAsCheckboxVar: true
    } as DefnFieldSwitch,
    ...getFieldGap(fieldGap4, "thick"),
    [fieldNormalButtonLabel]: {
      type: "label",
      metaId: fieldNormalButtonLabel,
      label: "Normal button handlers",
      disabled: true
    } as DefnFieldLabel,
    [fieldButtonActionCheckBox]: {
      type: "section",
      metaId: fieldButtonActionCheckBox,
      fieldIdSet: showNormalSection
        ? [propKeyActionCloseAside, fieldGap4, fieldNormalButtonLabel]
        : [propKeyActionCloseAside]
    } as DefnSection,

    [fieldButtonActionTabSection]: {
      type: "section",
      metaId: fieldButtonActionTabSection,
      fieldIdSet: [propKeyActionTab]
    } as DefnSection,

    [fieldActionTab]: {
      type: "section",
      metaId: fieldActionTab,
      fieldIdSet: [fieldButtonActionCheckBox, fieldButtonActionTabSection]
    } as DefnSection
  };
}

export function showPropertiesSubTab(fieldType?: EnumStudioCompType)
{
  const fieldTypesWithPropertiesSubTab = {
    text: true,
    paragraph: true,
    date: true,
    number: true,
    decimal: true,
    audio: true,
    counter: true,
    dateRange: true,
    dateTime: true,
    dateTimeRange: true,
    time: true,
    video: true,
    voice: true,
    html: true,
    info: true,
    showCode: true,
    symbol: true,
    dynamic: true,
    bool: true,
    hyperlink: true,
    email: true,
    error: true,
    textSize: true,
    lineStroke: true,
    quarter: true,
    handle: true,
    location: true,
    pinShape: true,
    month: true,
    rating: true,
    duration: true,
    label: true,
    button: true,
    mobileNumber: true,
    image: true,
    slider: true,
    pickGridRow: true,
    pickText: true,
    pickTree: true,
    pickUser: true,
    pickReportRow: true,
    chipSet: true,
    chipSetDate: true,
    chipSetDateTime: true,
    chipSetDay: true,
    chipSetDeviceSize: true,
    chipSetDeviceType: true,
    chipSetTime: true,
    currency: true,
    icon: true,
    language: true,
    timeZone: true,
    divider: true,
    document: true,
    identifier: true,
    propertyMap: true,
    rowId: true,
    hyperlinkRow: true,
    userId: true,
    camera: true,
    color: true,
    refUser: true,
    logNumber: true,
    logDecimal: true,
    logCounter: true,
    setOfUser: true,
    setOfText: true,
    signature: true,
    scanCode: true,
    paymentStatus: true,
    pickRole: true,
    setOfRole: true
  } as Record<EnumStudioCompType, boolean>;

  return Boolean(fieldType && fieldTypesWithPropertiesSubTab[fieldType]);
}

export function showSourceSubTab(fieldType?: EnumStudioCompType)
{
  const fieldTypesWithSourceSubTab = {
    "refUser": true,
    "pickUser": true,
    "setOfUser": true,
    "pickTree": true,
    "pickText": true,
    "setOfText": true
  } as Record<EnumStudioCompType, boolean>;

  return Boolean(fieldType && fieldTypesWithSourceSubTab[fieldType]);
}

type DateType =
  | EnumDefnDate
  | "custom"

export function getDefaultDate(values: FieldValues, enumKey: string, customKey: string)
{
  const enumDateValue = fnFieldValueToRawValue("enumDate", values[enumKey]) as DateType | undefined;
  const customValue = fnFieldValueToRawValue("date", values[customKey]);

  const isCustom = enumDateValue === "custom";

  return ((isCustom && customValue) || (!isCustom && enumDateValue))
    ? {
      value: !isCustom ? enumDateValue : undefined,
      customValue: isCustom ? customValue : undefined
    } as StudioBuildDate
    : undefined;
}

export function getDateTimeValue(values: FieldValues, enumKey: string, customKey: string, timeKey: string)
{
  const defaultValue = fnFieldValueToRawValue("enumDate", values[enumKey]);
  const customDateTime = fnFieldValueToRawValue("dateTime", values[customKey]);
  const customTime = fnFieldValueToRawValue("time", values[timeKey]);

  return (defaultValue === "custom" && customDateTime) ? {
    customValue: customDateTime
  } as StudioBuildDateTime : (defaultValue && defaultValue !== "custom") ? {
    value: defaultValue,
    time: isEmpty(customTime) ? undefined : customTime
  } as StudioBuildDateTime : undefined;
}

export function getLayoutLabel(kind: EnumDefnLayoutGridKind)
{
  switch(kind)
  {
    case "card":
      return "Card";
    case "list":
      return "List";
    case "table":
      return "Table";
    case "map":
      return "Map";
    case "xyChartBarGraph":
      return "Bar graph";
    case "xyChartLineChart":
      return "Line chart";
    case "xyChartPieChart":
      return "Pie chart";
    case "xyChartDoughnut":
      return "Doughnut chart";
    case "xyChartScatterPlot":
      return "Scatter plot chart";
    case "kanban":
      return "Kanban";
    case "calendar":
      return "Calendar";
  }
}

export function getMonthEnum()
{
  const decemberIndex = EnumArrayDefnMonth.indexOf("December");
  const currentMonthIndex = EnumArrayDefnMonth.indexOf("currentMonth");

  return [
    ...EnumArrayDefnMonth.slice(0, decemberIndex + 1),
    "",
    ...EnumArrayDefnMonth.slice(currentMonthIndex)
  ];
}

