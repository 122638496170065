import {ChatId} from "../../../../api/meta/base/Types";
import {EntId} from "../../../../api/meta/base/Types";
import ISrvc from "../../../../base/ISrvc";
import {selectCallerEnt} from "../../../../cache/app/callerEnt/SrvcCacheCallerEnt";
import {getCallerEntUserIdGlobal} from "../../../../Store";
import {store} from "../../../../Store";

export default class SrvcHomeMainFooterInputBarEntGroup extends ISrvc
{

  canSendMessageEntGroup(entId: EntId, chatId: ChatId, cbCanSendMsg: (canSendMsg: boolean) => void)
  {
    const rootState = store.getState();

    const callerEntUserId = getCallerEntUserIdGlobal(rootState);
    const groupInfo = selectCallerEnt(rootState, entId)?.groupMap[chatId];
    const adminMap = groupInfo?.adminMap ? Object.keys(groupInfo?.adminMap) : [];

    const groupAction = selectCallerEnt(rootState, entId)?.groupActionPermissionMap[chatId];
    if(groupAction !== undefined && Object.keys(groupAction).length === 0)
    {
      if(groupInfo?.settings.onlyAdminCanSendMessages && !adminMap.includes(callerEntUserId))
      {
        cbCanSendMsg(false);
      }
    }
    else
    {
      cbCanSendMsg(true);
    }
  }
}
