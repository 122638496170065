import {ICellRendererParams} from "ag-grid-community";
import {IDataGridCell} from "../../../../base/types/TypeDataGrid";
import RawHighlighter from "../../../atom/raw/RawHighlighter";
import {isGridColIndex} from "../DataGridPlus";

export default function GridCellIndex(props: ICellRendererParams<IDataGridCell>)
{
  const colId = props.colDef?.colId;
  const rowCount = props.api.getDisplayedRowCount();
  const isDesc = props.column?.getSort() === "desc";
  const index = getRowIndex(props);
  const isIndexCol = colId && isGridColIndex(colId);

  if(!isIndexCol)
  {
    return null;
  }

  if(index !== undefined && index >= 0)
  {
    return <RawHighlighter
      variant={"caption"}
      color={"textSecondary"}
      value={`${isDesc ? rowCount - index : (index + 1)}`}
      width={"100%"}
    />;
  }

}

function getRowIndex(props: ICellRendererParams<IDataGridCell>)
{
  if(!props.node.group)
  {
    return props.node.childIndex;
  }
}
