import {useTheme} from "@mui/material";
import {ICellRendererParams} from "ag-grid-community";
import {CssColor} from "../../../../base/plus/ThemePlus";
import {AgGridContext} from "../../../../base/types/TypeDataGrid";
import {IDataGridCell} from "../../../../base/types/TypeDataGrid";
import RawHighlighter from "../../../atom/raw/RawHighlighter";

export default function GridCellDefault(props: ICellRendererParams<IDataGridCell> & {color?: CssColor})
{
  const theme = useTheme();
  const searchWords = (props.context as AgGridContext).searchWords;
  const colId = props.colDef?.colId;
  const isHyperLink = colId ? (props.context as AgGridContext).hyperLinkColIdMap?.[colId] : undefined;
  const cellValue = props.valueFormatted
    ? props.valueFormatted
    : props.value;

  return (
    <RawHighlighter
      variant={"caption"}
      color={isHyperLink ? theme.common.hyperLinkColor : (props.color || "textSecondary")}
      value={typeof cellValue === "string" ? cellValue : `${cellValue ?? ""}`}
      width={"100%"}
      searchWords={searchWords}
      textDecoration={isHyperLink ? "underline" : undefined}
      cursor={isHyperLink ? "pointer" : undefined}
    />
  );
}
