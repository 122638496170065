import {isArray} from "lodash";
import {DefnDtoConditionStatement} from "../../../../api/meta/base/dto/DefnDtoConditionStatement";
import {DefnForm} from "../../../../api/meta/base/dto/DefnForm";
import {DefnMapOfCondition} from "../../../../api/meta/base/dto/DefnMapOfCondition";
import {FieldDtoArg} from "../../../../api/meta/base/dto/FieldDtoArg";
import {FormValue} from "../../../../api/meta/base/dto/FormValue";
import {MetaIdCondition} from "../../../../api/meta/base/Types";
import {resolveSymbolicText} from "../../../../base/plus/ArgBinderPlus";
import {fnFieldValueToRawValue} from "../../../../base/plus/FieldValuePlus";
import {ICallerEnt} from "../../../../cache/app/callerEnt/TypesCacheCallerEnt";

export function validateCondition(
  condition: DefnMapOfCondition,
  defnForm: DefnForm,
  formValue?: FormValue,
  callerEnt?: ICallerEnt)
{
  let result: boolean = false;

  if(condition.statement)
  {
    result = validateStatement(condition.statement, defnForm, formValue, callerEnt);
  }
  else if(condition.keys)
  {
    const conditionResult = {} as Record<MetaIdCondition, boolean>;

    condition.keys.forEach(key =>
    {
      const dto = condition.map?.[key];
      if(dto)
      {
        const resolvedCondition = validateCondition(dto, defnForm, formValue, callerEnt);
        if(resolvedCondition !== undefined)
        {
          conditionResult[dto.metaId] = resolvedCondition;
        }
      }
    });

    if(Boolean(condition.andOr))
    {
      result = Object.values(conditionResult).every((value) => value);
    }
    else if(!Boolean(condition.andOr))
    {
      result = Object.values(conditionResult).some((value) => value);
    }
  }

  return result;
}

function validateStatement(
  statement: DefnDtoConditionStatement,
  defnForm: DefnForm,
  formValue?: FormValue,
  callerEnt?: ICallerEnt)
{
  const lhs = statement.lhs;
  const rhs = statement.rhs;
  const operator = statement.operator;

  const lhsValue = lhs ? getArgValue(lhs, defnForm, formValue, callerEnt) : undefined;
  const rhsValue = rhs ? getArgValue(rhs, defnForm, formValue, callerEnt) : undefined;

  switch(operator)
  {
    case "hasNoValue":
      return !lhsValue;
    case "hasValue":
      return !!lhsValue;
    case "equalTo":
      return (isArray(rhsValue) && typeof lhsValue === "string")
        ? rhsValue.includes(lhsValue)
        : lhsValue === rhsValue;
    case "notEqualTo":
      return (isArray(rhsValue) && typeof lhsValue === "string")
        ? !rhsValue.includes(lhsValue)
        : lhsValue !== rhsValue;
    default:
      return false;
  }
}

function getArgValue(
  arg: FieldDtoArg,
  defnForm: DefnForm,
  formValue?: FormValue,
  callerEnt?: ICallerEnt)
{
  if(arg.valueBoolean !== undefined)
  {
    return arg.valueBoolean;
  }
  else if(arg.valueFieldId)
  {
    const comp = defnForm.compMap[arg.valueFieldId];
    const value = formValue?.valueMap[arg.valueFieldId];
    return fnFieldValueToRawValue(comp.type, value);
  }
  else if(arg.valueDate)
  {
    return arg.valueDate;
  }
  else if(arg.valueDouble !== undefined)
  {
    return arg.valueDouble;
  }
  else if(arg.valueLong !== undefined)
  {
    return arg.valueLong;
  }
  else if(arg.valueSysId !== undefined)
  {
    return arg.valueSysId;
  }
  else if(arg.valueSysIdSet !== undefined)
  {
    return arg.valueSysIdSet;
  }
  else if(arg.valueText !== undefined)
  {
    return resolveSymbolicText(
      defnForm,
      arg.valueText,
      formValue,
      callerEnt
    );
  }
  else
  {
    return undefined;
  }
}
