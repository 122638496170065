import {DtoTopic} from "../../../api/core/base/dto/DtoTopic";
import {SpreadsheetFilterValue} from "../../../api/ent/base/dto/SpreadsheetFilterValue";
import {MsgSpreadsheetRowsGet} from "../../../api/ent/entAside/msg/MsgSpreadsheetRowsGet";
import {RpcEntAside} from "../../../api/ent/entAside/RpcEntAside";
import {SigSpreadsheetRowsGet} from "../../../api/ent/entAside/sig/SigSpreadsheetRowsGet";
import {MsgSpreadsheetBulkRowGet} from "../../../api/ent/entMain/msg/MsgSpreadsheetBulkRowGet";
import {MsgSpreadsheetClear} from "../../../api/ent/entMain/msg/MsgSpreadsheetClear";
import {MsgSpreadsheetRowExpiryGet} from "../../../api/ent/entMain/msg/MsgSpreadsheetRowExpiryGet";
import {MsgSpreadsheetRowGet} from "../../../api/ent/entMain/msg/MsgSpreadsheetRowGet";
import {MsgSpreadsheetRowRemove} from "../../../api/ent/entMain/msg/MsgSpreadsheetRowRemove";
import {MsgSpreadsheetRowUpdate} from "../../../api/ent/entMain/msg/MsgSpreadsheetRowUpdate";
import {RpcEntMain} from "../../../api/ent/entMain/RpcEntMain";
import {SigSpreadsheetRowExpiry} from "../../../api/ent/entMain/sig/SigSpreadsheetRowExpiry";
import {WsocEntMain} from "../../../api/ent/entMain/WsocEntMain";
import {MsgSpreadsheetBulkRowInsert} from "../../../api/ent/main/msg/MsgSpreadsheetBulkRowInsert";
import {SigSpreadsheetBulkRowGet} from "../../../api/home/main/sig/SigSpreadsheetBulkRowGet";
import {SigSpreadsheetRow} from "../../../api/home/main/sig/SigSpreadsheetRow";
import {nextTransactionId} from "../../../api/meta/base/ApiPlus";
import {EnvValidationError} from "../../../api/meta/base/dto/EnvValidationError";
import {FormValueRaw} from "../../../api/meta/base/dto/FormValueRaw";
import {EnumDefnSortOrder} from "../../../api/meta/base/Types";
import {EntUserId} from "../../../api/meta/base/Types";
import {MetaIdRole} from "../../../api/meta/base/Types";
import {MetaIdForm} from "../../../api/meta/base/Types";
import {SpreadsheetPartitionId} from "../../../api/meta/base/Types";
import {RowId} from "../../../api/meta/base/Types";
import {MetaIdField} from "../../../api/meta/base/Types";
import {MetaIdSpreadsheet} from "../../../api/meta/base/Types";
import {MetaIdAction} from "../../../api/meta/base/Types";
import {EntId} from "../../../api/meta/base/Types";
import {EnvError} from "../../../api/nucleus/base/dto/EnvError";
import ISrvc from "../../../base/ISrvc";
import {matchRoles} from "../../../base/plus/SrvcPlus";
import {insertSystemRoleIds} from "../../../base/plus/SrvcPlus";
import {CbSuccess} from "../../../base/types/TypesGlobal";
import {TypeSubscriberId} from "../../../base/types/TypesGlobal";
import {selectCallerEnt} from "../../../cache/app/callerEnt/SrvcCacheCallerEnt";
import {RootState} from "../../../Store";
import {store} from "../../../Store";
import {Srvc} from "../../Srvc";
import {isCommentableForm} from "../form/SrvcForm";
import {topicToString} from "../session/SrvcSession";
import {stringToTopic} from "../session/SrvcSession";
import SrvcSsEditor from "./editor/SrvcSsEditor";
import SrvcSpreadSheetBr from "./SrvcSpreadSheetBr";

export default class SrvcSpreadsheet extends ISrvc
{
  public readonly SsBr = new SrvcSpreadSheetBr(state => state.app.form.ssBrList);
  public readonly SsEditor = new SrvcSsEditor();
  private subscribeAvatarMap = new Map<string, boolean>();

  constructor()
  {
    super();
    this.setSrvcArray(
      this.SsBr,
      this.SsEditor
    );
  }

  async doBulkCalls<T>(
    arr: T[],
    cb: (chunk: T[], totalCount: number) => Promise<void>,
    bulkCallTopicLimit: number = 100,
    breakOnError?: boolean)
  {
    let count = 0;
    if(arr.length > bulkCallTopicLimit)
    {
      while(arr.length > 0)
      {
        const partialDtoTopics = arr.splice(0, bulkCallTopicLimit);
        count += partialDtoTopics.length;
        try
        {
          await cb(partialDtoTopics, count);
        }
        catch(err)
        {
          if(breakOnError)
          {
            return;
          }
        }
      }
    }
    else if(arr.length > 0)
    {
      count += arr.length;
      return cb(arr, count);
    }
    return;
  }

  //region rpc

  rpcSpreadsheetRowGet(
    entId: EntId,
    rowId: RowId,
    spreadsheetId: MetaIdSpreadsheet,
    version?: string,
    cbSuccess?: (sig: SigSpreadsheetRow) => void,
    onError?: (error: EnvError) => void): void
  {
    const msg = {
      rowId: rowId,
      spreadsheetId: spreadsheetId,
      version: version
    } as MsgSpreadsheetRowGet;

    RpcEntMain.spreadsheetRowGet(entId as EntId, msg, (envSignal) =>
    {
      if(envSignal.sig)
      {
        cbSuccess && cbSuccess(envSignal.sig);
      }
      else if(envSignal.error)
      {
        onError && onError(envSignal.error as EnvError);
      }
    });
  }

  rpcSpreadsheetBulkRowGet(
    entId: EntId,
    rowIds: RowId[],
    spreadsheetId: MetaIdSpreadsheet,
    cbSuccess?: (sig: SigSpreadsheetBulkRowGet) => void,
    cbError?: () => void
  ): void
  {
    const rootState = store.getState();
    const rowIdVersionMap: Record<RowId, string> = rowIds.reduce((rowIdVersionMap, rowId) =>
    {
      const spreadsheetRow = selectCacheRow(rootState, entId, rowId);
      rowIdVersionMap[rowId] = spreadsheetRow?.version || "";
      return rowIdVersionMap;
    }, {} as Record<RowId, string>);

    const msg = {
      spreadsheetId: spreadsheetId,
      rowIdVersionMap: rowIdVersionMap
    } as MsgSpreadsheetBulkRowGet;

    RpcEntMain.spreadsheetBulkRowGet(entId as EntId, msg, (envSignal) =>
    {
      if(!Srvc.app.toast.showErrorToast(envSignal))
      {
        cbSuccess && cbSuccess(envSignal.sig as SigSpreadsheetBulkRowGet);
      }
      else
      {
        cbError && cbError();
      }
    });
  }

  rpcSpreadsheetRowsGet(
    entId: EntId,
    actionId: MetaIdAction,
    spreadsheetId: MetaIdSpreadsheet,
    groupByFieldId?: MetaIdField,
    sortByFieldIdSet?: MetaIdField[],
    sortOrder?: EnumDefnSortOrder,
    filterValueSet?: SpreadsheetFilterValue[],
    searchStr?: string,
    inputFormValueRaw?: FormValueRaw,
    cbResult?: (sig: SigSpreadsheetRowsGet) => void,
    cbError?: (sig: EnvError) => void)
  {
    const msg = {
      actionId: actionId,
      spreadsheetId: spreadsheetId,
      groupByFieldId: groupByFieldId,
      searchText: searchStr,
      inputFormValueRaw: inputFormValueRaw,
      sortByFieldIdSet: sortByFieldIdSet,
      filterValueSet: filterValueSet,
      ascOrder: sortOrder === "ascending"
    } as MsgSpreadsheetRowsGet;

    RpcEntAside.entSpreadsheetRowsGet(entId, msg, envSig =>
      {
        if(Srvc.app.toast.showErrorToast(envSig))
        {
          cbError && cbError(envSig.error as EnvError);
        }
        else
        {
          cbResult && cbResult(envSig.sig as SigSpreadsheetRowsGet);
        }
      }
    );
  }

  getSpreadsheetBulkRowGet(
    entId: EntId,
    rowIds: RowId[],
    spreadsheetId: MetaIdSpreadsheet,
    cbSuccess?: (rowMap: Record<RowId, SigSpreadsheetRow>) => void)
  {
    Srvc.cache.app.spreadsheet.ssRow.setCacheSpreadsheetBulkRow(entId, rowIds, spreadsheetId, cbSuccess);
  }

  rpcSpreadsheetBulkRowInsert(
    entId: EntId,
    rowMap: Record<RowId, FormValueRaw>,
    spreadsheetId: MetaIdSpreadsheet,
    cbSuccess?: (errorMap?: Record<RowId, EnvValidationError>) => void,
    cbError?: () => void
  ): void
  {
    const msg = {
      spreadsheetId: spreadsheetId,
      rowMap: rowMap
    } as MsgSpreadsheetBulkRowInsert;

    RpcEntMain.spreadsheetBulkRowInsert(entId as EntId, msg, (envSignal) =>
    {
      const errorMap = envSignal.sig?.errorMap;

      if(!envSignal.error)
      {
        cbSuccess && cbSuccess(errorMap);
      }
      else
      {
        Srvc.app.toast.showErrorToast(envSignal);
        cbError && cbError();
      }
    });
  }

  rpcSpreadsheetClear(
    entId: EntId,
    spreadsheetId: MetaIdSpreadsheet,
    cbSuccess?: () => void,
    cbError?: () => void
  ): void
  {
    const msg = {
      spreadsheetId: spreadsheetId
    } as MsgSpreadsheetClear;

    RpcEntMain.spreadsheetClear(entId as EntId, msg, (envSignal) =>
    {
      if(!envSignal.error)
      {
        Srvc.app.toast.showSuccessToast("Data cleared successfully");
        cbSuccess && cbSuccess();
      }
      else
      {
        Srvc.app.toast.showErrorToast(envSignal);
        cbError && cbError();
      }
    });
  }

  rpcSpreadsheetRowUpdate(
    entId: EntId,
    metaIdForm: MetaIdForm,
    metaIdSpreadsheet: MetaIdSpreadsheet,
    formValueRaw: FormValueRaw,
    cbSuccess?: CbSuccess,
    cbError?: () => void
  )
  {
    const msg: MsgSpreadsheetRowUpdate = {
      formId: metaIdForm,
      spreadsheetId: metaIdSpreadsheet,
      transactionId: nextTransactionId(),
      formValueRaw: formValueRaw
    };

    RpcEntMain.spreadsheetRowUpdate(entId, msg, envSig =>
    {
      if(!Srvc.app.toast.showErrorToast(envSig))
      {
        cbSuccess && cbSuccess();
      }
      else
      {
        cbError && cbError();
      }
    });
  }

  //endregion

  //region wsoc

  wsocSpreadsheetRowGet(
    entId: EntId,
    rowId: RowId,
    spreadsheetId: MetaIdSpreadsheet,
    version?: string,
    cbSuccess?: (sig: SigSpreadsheetRow) => void,
    onError?: (error: EnvError) => void): void
  {
    const msg = {
      rowId: rowId,
      spreadsheetId: spreadsheetId,
      version: version
    } as MsgSpreadsheetRowGet;

    WsocEntMain.spreadsheetRowGet(entId as EntId, msg, (envSignal) =>
    {
      if(envSignal.sig)
      {
        cbSuccess && cbSuccess(envSignal.sig);
      }
      else if(envSignal.error)
      {
        onError && onError(envSignal.error as EnvError);
      }
    });
  }

  rpcSpreadsheetRowBulkRemove(
    entId: EntId,
    metaIdForm: MetaIdForm,
    metaIdSpreadsheet: MetaIdSpreadsheet,
    rowIdSet: RowId[],
    cbSuccess?: CbSuccess,
    cbError?: () => void)
  {
    const msg: MsgSpreadsheetRowRemove = {
      formId: metaIdForm,
      spreadsheetId: metaIdSpreadsheet,
      rowIdSet: rowIdSet
    };

    RpcEntMain.spreadsheetRowRemove(entId, msg, envSig =>
    {
      if(!Srvc.app.toast.showErrorToast(envSig))
      {
        cbSuccess && cbSuccess();
      }
      else
      {
        cbError && cbError();
      }
    });
  }

  rpcSpreadsheetRowRemove(
    entId: EntId,
    metaIdForm: MetaIdForm,
    metaIdSpreadsheet: MetaIdSpreadsheet,
    rowId: RowId,
    cbSuccess?: CbSuccess)
  {
    const msg: MsgSpreadsheetRowRemove = {
      formId: metaIdForm,
      spreadsheetId: metaIdSpreadsheet,
      rowId: rowId
    };

    RpcEntMain.spreadsheetRowRemove(entId, msg, envSig =>
    {
      if(!Srvc.app.toast.showErrorToast(envSig))
      {
        cbSuccess && cbSuccess();
      }
    });
  }

  wsocSpreadsheetRowUpdate(
    entId: EntId,
    metaIdForm: MetaIdForm,
    metaIdSpreadsheet: MetaIdSpreadsheet,
    formValueRaw: FormValueRaw,
    cbSuccess?: CbSuccess)
  {
    const msg: MsgSpreadsheetRowUpdate = {
      formId: metaIdForm,
      spreadsheetId: metaIdSpreadsheet,
      formValueRaw: formValueRaw
    };

    WsocEntMain.spreadsheetRowUpdate(entId, msg, envSig =>
    {
      if(!Srvc.app.toast.showErrorToast(envSig))
      {
        cbSuccess && cbSuccess();
      }
    });
  }

  wsocSpreadsheetRowExpiryGet(
    entId: EntId,
    spreadsheetPartitionId: SpreadsheetPartitionId,
    spreadsheetId: MetaIdSpreadsheet,
    version?: string,
    onSuccess?: (sig: SigSpreadsheetRowExpiry) => void): void
  {
    const msg: MsgSpreadsheetRowExpiryGet = {
      spreadsheetPartitionId: spreadsheetPartitionId,
      spreadsheetId: spreadsheetId,
      version: version
    };
    WsocEntMain.spreadsheetRowExpiryGet(entId, msg, (envSignal) =>
    {
      const sig = envSignal.sig;
      if(sig)
      {
        onSuccess && onSuccess(sig);
      }
    });
  }

  //endregion

  //region subscribe

  subscribeSpreadsheet(
    subscriberId: TypeSubscriberId,
    entId: EntId,
    spreadsheetId: MetaIdSpreadsheet,
    unSubscribe?: boolean)
  {
    Srvc.app.pubsub.msg.spreadsheet(subscriberId, entId, spreadsheetId, unSubscribe);
  }

  subscribeSsUser(
    subscriberId: TypeSubscriberId,
    entId: EntId,
    entUserId: EntUserId)
  {
    const topic = Srvc.app.pubsub.user.getUserAvatarTopic(entId, entUserId);
    const topicString = topicToString(topic);
    if(this.subscribeAvatarMap.has(topicString))
    {
      return;
    }
    this.subscribeAvatarMap.set(topicString, true);
    const rootState = store.getState();
    const entUser = rootState.cache.app.user.userAvatarMap[entUserId];
    if(!entUser)
    {
      Srvc.cache.app.user.wsocUserAvatarGet(entId, entUserId);
    }
    Srvc.app.pubsub.user.userAvatar(subscriberId, entId, entUserId);
  }

  unSubscribeAllSsUser(subscriberId: TypeSubscriberId)
  {
    const topicList = [] as DtoTopic[];
    this.subscribeAvatarMap.forEach((_, key) =>
    {
      topicList.push(stringToTopic(key));
    });
    if(topicList.length > 0)
    {
      Srvc.app.session.unSubscribeBulk(subscriberId, topicList);
    }
    this.subscribeAvatarMap.clear();
  }

  subscribeSpreadsheetRows(
    subscriberId: TypeSubscriberId,
    entId: EntId,
    rowIds: RowId[],
    spreadsheetId: MetaIdSpreadsheet,
    unSubscribe?: boolean)
  {
    const dtoTopicList = [] as DtoTopic[];
    const rootState = store.getState();
    rowIds.forEach((rowId) =>
    {
      const spreadsheetRow = selectCacheRow(rootState, entId, rowId);
      const spreadsheetPartitionId = spreadsheetRow?.spreadsheetPartitionId;
      const canExpire = this.canSubscribeSpreadsheetRowExpiry(rootState, entId, rowId, spreadsheetId);
      if(canExpire && spreadsheetPartitionId)
      {
        dtoTopicList.push({
          type: "spreadsheetRowExpiry",
          artifactId: entId,
          aboutId: spreadsheetPartitionId
        });
      }
      const formId = selectCallerEnt(rootState, entId)?.spreadsheetMap?.[spreadsheetId]?.spreadsheetFormId;
      const canComment = isCommentableForm(rootState, entId, formId, spreadsheetRow?.formValue?.createdBy);
      if(canComment)
      {
        dtoTopicList.push({
          type: "formComment",
          artifactId: entId,
          aboutId: rowId
        });
      }
      dtoTopicList.push({
        type: "formResult",
        artifactId: entId,
        aboutId: rowId
      });

    });

    if(Boolean(unSubscribe))
    {
      Srvc.app.session.unSubscribeBulk(subscriberId, dtoTopicList);
    }
    else
    {
      Srvc.app.session.subscribeBulk(subscriberId, dtoTopicList);
    }
  }

  subscribeSpreadsheetRow(
    subscriberId: TypeSubscriberId,
    entId: EntId,
    rowId: RowId,
    spreadsheetId: MetaIdSpreadsheet,
    unSubscribe?: boolean)
  {
    const rootState = store.getState();
    Srvc.cache.app.spreadsheet.ssRow.setRowIdToSsIdMap(entId, rowId, spreadsheetId);
    const spreadsheetRow = selectCacheRow(rootState, entId, rowId);
    const formId = selectCallerEnt(rootState, entId)?.spreadsheetMap?.[spreadsheetId]?.spreadsheetFormId;

    if(!spreadsheetRow)
    {
      Srvc.cache.app.spreadsheet.ssRow.setCacheSpreadsheetRow(entId as EntId, rowId as RowId,
        (sig) =>
        {
          this.subscribeSpreadsheetRowExpiry(subscriberId, entId, rowId, spreadsheetId, unSubscribe);
          const canComment = isCommentableForm(rootState, entId, formId, sig.formValue?.createdBy);
          if(canComment)
          {
            Srvc.app.pubsub.msg.formComment(subscriberId, entId, rowId, unSubscribe);
          }
        }
      );
    }
    else
    {
      this.subscribeSpreadsheetRowExpiry(subscriberId, entId, rowId, spreadsheetId, unSubscribe);
      const canComment = isCommentableForm(rootState, entId, formId, spreadsheetRow.formValue?.createdBy);
      if(canComment)
      {
        Srvc.app.pubsub.msg.formComment(subscriberId, entId, rowId, unSubscribe);
      }
    }
    Srvc.app.pubsub.msg.formResult(subscriberId, entId, rowId, false, unSubscribe);
  }

  //endregion
  canInsert(rootState: RootState, entId: EntId, spreadsheetId: MetaIdSpreadsheet)
  {
    const callerEnt = selectCallerEnt(rootState, entId);
    const spreadsheet = selectCallerEnt(rootState, entId)?.spreadsheetMap?.[spreadsheetId];
    return this.resolvePermission(rootState, entId, spreadsheet?.insertRoleIdSet, callerEnt?.entUserId);
  }

  canUpdate(rootState: RootState, entId: EntId, spreadsheetId: MetaIdSpreadsheet, senderId?: EntUserId)
  {
    const spreadsheet = selectCallerEnt(rootState, entId)?.spreadsheetMap?.[spreadsheetId];
    return this.resolvePermission(rootState, entId, spreadsheet?.updateRoleIdSet, senderId);
  }

  canRemove(rootState: RootState, entId: EntId, spreadsheetId: MetaIdSpreadsheet, senderId?: EntUserId)
  {
    const spreadsheet = selectCallerEnt(rootState, entId)?.spreadsheetMap?.[spreadsheetId];
    return this.resolvePermission(rootState, entId, spreadsheet?.removeRoleIdSet, senderId);
  }

  canForward(rootState: RootState, entId: EntId, spreadsheetId: MetaIdSpreadsheet, senderId?: EntUserId)
  {
    const spreadsheet = selectCallerEnt(rootState, entId)?.spreadsheetMap?.[spreadsheetId];
    return this.resolvePermission(rootState, entId, spreadsheet?.forwardRoleIdSet, senderId);
  }

  private resolvePermission(
    rootState: RootState,
    entId: EntId,
    itemRoleIdSet?: MetaIdRole[],
    senderId?: EntUserId)
  {
    const callerEnt = selectCallerEnt(rootState, entId);
    if(!callerEnt)
    {
      return false;
    }
    if(itemRoleIdSet)
    {
      const roleIdSet: MetaIdRole[] = [...callerEnt?.roleIdSet];
      if(senderId)
      {
        insertSystemRoleIds(callerEnt, senderId, roleIdSet);
      }
      return matchRoles(roleIdSet, itemRoleIdSet);
    }
    return false;
  }

  private canSubscribeSpreadsheetRowExpiry(
    rootState: RootState,
    entId: EntId,
    rowId: RowId,
    spreadsheetId: MetaIdSpreadsheet)
  {
    const spreadsheetRowExpiry = rootState.cache.app.spreadsheet.ssRow.entSpreadsheetRowMap[entId]?.spreadsheetRowExpiryMap[rowId];
    const canExpire = Boolean(selectCallerEnt(rootState, entId)?.spreadsheetMap?.[spreadsheetId]?.canExpire);
    const isExpired = Boolean(canExpire && spreadsheetRowExpiry?.remainingInvisibleTimeMillis === 0);
    return canExpire && !isExpired;
  }

  private subscribeSpreadsheetRowExpiry(
    subscriberId: TypeSubscriberId,
    entId: EntId,
    rowId: RowId,
    spreadsheetId: MetaIdSpreadsheet,
    unSubscribe?: boolean)
  {
    const rootState = store.getState();
    const canExpire = this.canSubscribeSpreadsheetRowExpiry(rootState, entId, rowId, spreadsheetId);

    if(!unSubscribe)
    {
      return;
    }

    const spreadsheetRow = selectCacheRow(rootState, entId, rowId);
    const spreadsheetPartitionId = spreadsheetRow?.spreadsheetPartitionId;
    if(canExpire)
    {
      if(!spreadsheetPartitionId)
      {
        // if spreadsheetRow is not available, then get it from the server
        Srvc.cache.app.spreadsheet.ssRow.setCacheSpreadsheetRow(entId as EntId, rowId as RowId,
          sig =>
          {
            if(sig.spreadsheetPartitionId)
            {
              const spreadsheetPartitionId = sig.spreadsheetPartitionId;
              Srvc.cache.app.spreadsheet.ssRow.setSsPartitionIdToRowIdMap(entId, spreadsheetPartitionId, rowId);
              Srvc.app.pubsub.msg.spreadsheetRowExpiry(subscriberId,
                entId,
                spreadsheetPartitionId,
                false,
                unSubscribe
              );
            }
          }
        );
      }
      else
      {
        Srvc.cache.app.spreadsheet.ssRow.setSsPartitionIdToRowIdMap(entId, spreadsheetPartitionId, rowId);
        Srvc.app.pubsub.msg.spreadsheetRowExpiry(subscriberId, entId, spreadsheetPartitionId, false, unSubscribe);
      }
    }
  }
}

export function selectCacheSpreadsheetId(state: RootState, entId: EntId, rowId: RowId)
{
  return state.cache.app.spreadsheet.ssRow.entSpreadsheetRowMap[entId]?.rowIdToSsIdMap[rowId] as MetaIdSpreadsheet | undefined;
}

export function selectCacheRowId(state: RootState, entId: EntId, spreadsheetPartitionId: SpreadsheetPartitionId)
{
  return state.cache.app.spreadsheet.ssRow.entSpreadsheetRowMap[entId]?.ssPartitionIdToRowIdMap[spreadsheetPartitionId] as RowId | undefined;
}

export function selectCacheRow(state: RootState, entId: EntId, rowId: RowId)
{
  return state.cache.app.spreadsheet.ssRow.entSpreadsheetRowMap[entId]?.spreadsheetRowMap[rowId] as SigSpreadsheetRow | undefined;
}

