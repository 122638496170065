import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnFieldLabel} from "../../../../../api/meta/base/dto/DefnFieldLabel";
import {DefnFieldPickEnum} from "../../../../../api/meta/base/dto/DefnFieldPickEnum";
import {DefnFieldSetOfRole} from "../../../../../api/meta/base/dto/DefnFieldSetOfRole";
import {DefnFieldSwitch} from "../../../../../api/meta/base/dto/DefnFieldSwitch";
import {DefnStudioPickFieldId} from "../../../../../api/meta/base/dto/DefnStudioPickFieldId";
import {DefnStudioPickImportPluginApiId} from "../../../../../api/meta/base/dto/DefnStudioPickImportPluginApiId";
import {DefnStudioPickImportPluginId} from "../../../../../api/meta/base/dto/DefnStudioPickImportPluginId";
import {DefnStudioPickVarId} from "../../../../../api/meta/base/dto/DefnStudioPickVarId";
import {StudioFieldRefUser} from "../../../../../api/meta/base/dto/StudioFieldRefUser";
import {MetaIdComposite} from "../../../../../api/meta/base/Types";
import {EnumDefnRoles} from "../../../../../api/meta/base/Types";
import {MetaIdVar} from "../../../../../api/meta/base/Types";
import {PluginApiId} from "../../../../../api/meta/base/Types";
import {MetaIdPlugin} from "../../../../../api/meta/base/Types";
import {MetaIdForm} from "../../../../../api/meta/base/Types";
import {MetaIdField} from "../../../../../api/meta/base/Types";
import {stringToDefnDtoText} from "../../../../../base/plus/ArgBinderPlus";
import {fnFieldValueToRawValue} from "../../../../../base/plus/FieldValuePlus";
import {fnRawValueToFieldValue} from "../../../../../base/plus/FieldValuePlus";
import {IResolvedIOFormNames} from "../../../../../base/plus/StudioPlus";
import {IExcludeMappingVars} from "../../../../../base/plus/StudioPlus";
import {getFieldBuilderSourceSubTabs} from "../../base/FieldBuilderFactory";
import {getFieldBuilderPropertySubTabs} from "../../base/FieldBuilderFactory";
import {propKeyDataSourceVarId} from "../../base/TypesFormBuilder";
import {fieldGap3} from "../../base/TypesFormBuilder";
import {propKeyShowAsDropdownVarId} from "../../base/TypesFormBuilder";
import {propKeyShowAsDropdownFieldId} from "../../base/TypesFormBuilder";
import {propKeyShowAsDropdown} from "../../base/TypesFormBuilder";
import {propKeyIconValue} from "../../base/TypesFormBuilder";
import {propKeyRefUserFields} from "../../base/TypesFormBuilder";
import {propKeyDefaultValueRoleId} from "../../base/TypesFormBuilder";
import {propKeySuffixVar} from "../../base/TypesFormBuilder";
import {propKeyPrefixVar} from "../../base/TypesFormBuilder";
import {propKeyHelperTextValue} from "../../base/TypesFormBuilder";
import {propKeyPlaceHolderValue} from "../../base/TypesFormBuilder";
import {propKeyAutoFocus} from "../../base/TypesFormBuilder";
import {propKeyDisabled} from "../../base/TypesFormBuilder";
import {propKeyRequired} from "../../base/TypesFormBuilder";
import {propKeyVarHelperText} from "../../base/TypesFormBuilder";
import {propKeyVarIcon} from "../../base/TypesFormBuilder";
import {propKeyVarPlaceholder} from "../../base/TypesFormBuilder";
import {propKeyDisabledVarId} from "../../base/TypesFormBuilder";
import {propKeyRequiredVarId} from "../../base/TypesFormBuilder";
import {propKeyHelperTextFieldId} from "../../base/TypesFormBuilder";
import {propKeyPlaceHolderFieldId} from "../../base/TypesFormBuilder";
import {propKeyDisabledField} from "../../base/TypesFormBuilder";
import {propKeyRequiredField} from "../../base/TypesFormBuilder";
import {propKeyPlugin} from "../../base/TypesFormBuilder";
import {fieldGap1} from "../../base/TypesFormBuilder";
import {propKeyPluginErrorField} from "../../base/TypesFormBuilder";
import {propKeyButtonPluginInputMappingVarId} from "../../base/TypesFormBuilder";
import {propKeyPluginApi} from "../../base/TypesFormBuilder";
import {propKeyRolesDataSource} from "../../base/TypesFormBuilder";

const propKeyPluginLabel = "pluginLabel";

export function getDefnFieldRefUser(
  formId: string,
  fieldId?: MetaIdField,
  sectionIdSetWithCurrentGridId?: MetaIdComposite[])
{
  const fieldIds = [
    propKeyRequiredField,
    propKeyDisabledField,
    fieldGap1,
    propKeyPlaceHolderFieldId,
    propKeyHelperTextFieldId
  ];

  const varIds = [
    propKeyRequiredVarId,
    propKeyDisabledVarId,
    fieldGap1,
    propKeyVarPlaceholder,
    propKeyVarIcon,
    propKeyVarHelperText,
    propKeyPrefixVar,
    propKeySuffixVar
  ];

  const constIds = [
    propKeyRequired,
    propKeyDisabled,
    propKeyAutoFocus,
    fieldGap1,
    propKeyDefaultValueRoleId,
    fieldGap3,
    propKeyPlaceHolderValue,
    propKeyIconValue,
    propKeyHelperTextValue
  ];

  return {
    ...getFieldBuilderPropertySubTabs(
      "refUser",
      formId,
      undefined,
      fieldId,
      fieldIds,
      varIds,
      constIds,
      undefined,
      sectionIdSetWithCurrentGridId
    ),

    [propKeyDefaultValueRoleId]: {
      type: "enumRoles",
      metaId: propKeyDefaultValueRoleId,
      name: propKeyDefaultValueRoleId,
      label: "Default value",
      filterOptionSet: ["$Self", "$Manager", "$GrandManager"] as EnumDefnRoles[]
    } as DefnFieldPickEnum,

    [propKeyShowAsDropdown]: {
      type: "bool",
      name: propKeyShowAsDropdown,
      metaId: propKeyShowAsDropdown,
      label: "Show as dropdown",
      showAsCheckboxVar: true
    } as DefnFieldSwitch,

    [propKeyShowAsDropdownFieldId]: {
      type: "pickFieldId",
      name: propKeyShowAsDropdownFieldId,
      metaId: propKeyShowAsDropdownFieldId,
      label: "Show as dropdown",
      formId: formId,
      filterFieldTypeSet: ["bool"]
    } as DefnStudioPickFieldId,

    [propKeyShowAsDropdownVarId]: {
      type: "pickVarId",
      name: propKeyShowAsDropdownVarId,
      metaId: propKeyShowAsDropdownVarId,
      label: "Show as dropdown",
      showAsEdit: true,
      varKind: "bool"
    } as DefnStudioPickVarId
  };
}

export function getDefnFieldRefUserSourceTab(
  formId: MetaIdForm,
  metaIdPlugin?: MetaIdPlugin,
  isPluginInputFormPresent?: boolean,
  helperTextPluginApiIOFormName?: IResolvedIOFormNames,
  isPluginApiHelperTextVisible?: boolean,
  excludePluginApiIdSet?: PluginApiId[],
  notValidPluginInputFormMappingVars?: IExcludeMappingVars,
  isPluginForm?: boolean
)
{
  const constFieldIdSet = [
    propKeyRolesDataSource
  ] as MetaIdField[];

  const variableFieldIdSet = [
    propKeyDataSourceVarId,
    propKeyRefUserFields
  ] as MetaIdField[];

  const pluginFieldIdSet: MetaIdField[] = !isPluginForm
    ? [
      propKeyPlugin,
      propKeyPluginApi,
      ...isPluginInputFormPresent ? [propKeyButtonPluginInputMappingVarId] : [],
      propKeyPluginErrorField,
      propKeyPluginLabel
    ]
    : [];

  return {
    [propKeyRolesDataSource]: {
      type: "setOfRole",
      name: propKeyRolesDataSource,
      metaId: propKeyRolesDataSource,
      allowSystemRoles: true
    } as DefnFieldSetOfRole,

    [propKeyDataSourceVarId]: {
      type: "pickVarId",
      metaId: propKeyDataSourceVarId,
      name: propKeyDataSourceVarId,
      label: "Set of user variable",
      varKind: "setOfUser",
      formId: formId,
      showAsEdit: true
    } as DefnStudioPickVarId,

    [propKeyRefUserFields]: {
      type: "studioSetOfVarId",
      varKind: "userSetting",
      name: propKeyRefUserFields,
      metaId: propKeyRefUserFields,
      showAsEdit: true,
      label: "Copy user setting variables"
    } as DefnStudioPickVarId,

    ...!isPluginForm && {
      [propKeyPlugin]: {
        type: "pickImportPluginId",
        metaId: propKeyPlugin,
        name: propKeyPlugin,
        label: "Plugin"
      } as DefnStudioPickImportPluginId,

      [propKeyPluginApi]: {
        type: "pickImportPluginApiId",
        name: propKeyPluginApi,
        metaId: propKeyPluginApi,
        label: "Plugin API",
        metaIdPlugin: metaIdPlugin,
        excludePluginApiIdSet: excludePluginApiIdSet,
        helperTextVar: isPluginApiHelperTextVisible
          ? stringToDefnDtoText(`Input form: ${helperTextPluginApiIOFormName?.inputFormName
          ?? "None"}, Output form: ${helperTextPluginApiIOFormName?.outputFormName ?? "None"}`)
          : undefined
      } as DefnStudioPickImportPluginApiId,

      ...isPluginInputFormPresent && {
        [propKeyButtonPluginInputMappingVarId]: {
          type: "pickVarId",
          metaId: propKeyButtonPluginInputMappingVarId,
          name: propKeyButtonPluginInputMappingVarId,
          label: "Plugin input mapping variable",
          disabled: !notValidPluginInputFormMappingVars?.src || !notValidPluginInputFormMappingVars?.target,
          excludeVarIdSet: notValidPluginInputFormMappingVars?.excludeIdSet,
          varKind: "mapping",
          showAsEdit: true
        } as DefnStudioPickVarId
      },

      [propKeyPluginErrorField]: {
        type: "pickFieldId",
        name: propKeyPluginErrorField,
        metaId: propKeyPluginErrorField,
        label: "Plugin error field",
        filterFieldTypeSet: ["error"],
        showCompositeName: true,
        formId: formId
      } as DefnStudioPickFieldId,

      [propKeyPluginLabel]: {
        type: "label",
        metaId: propKeyPluginLabel,
        label: `Note: Plugin overrides the data source`,
        name: propKeyPluginLabel,
        textSizeVar: "caption",
        italicVar: true,
        disabled: true
      } as DefnFieldLabel

    },
    ...getFieldBuilderSourceSubTabs(constFieldIdSet, variableFieldIdSet, pluginFieldIdSet)
  };
}

export function defnValueToStudioFieldRefUser(values: FieldValues): StudioFieldRefUser
{
  const refUserField = fnFieldValueToRawValue("studioSetOfVarId", values[propKeyRefUserFields]) as MetaIdVar[];
  const copyFieldVarMap = {} as Record<MetaIdField, MetaIdVar>;
  if(refUserField && Array.isArray(refUserField))
  {
    refUserField.forEach((refFieldChildId, index) =>
    {
      const refUserFieldId = "copyField" + index;
      copyFieldVarMap[refUserFieldId] = refFieldChildId;
    });
  }

  return {
    type: "refUser",
    defaultValue: fnFieldValueToRawValue("enumRoles", values[propKeyDefaultValueRoleId]),
    copyFieldVarMap: copyFieldVarMap,
    dataSourceVarId: fnFieldValueToRawValue("pickVarId", values[propKeyDataSourceVarId]),
    roleIdDataSource: fnFieldValueToRawValue("setOfRole", values[propKeyRolesDataSource]),
    pluginApi: (values[propKeyPlugin])
      ? {
        metaIdPlugin: fnFieldValueToRawValue("pickImportPluginId", values[propKeyPlugin]),
        pluginApiId: fnFieldValueToRawValue("pickImportPluginApiId", values[propKeyPluginApi])
      }
      : undefined,
    pluginInputMappingVarId: fnFieldValueToRawValue("pickVarId", values[propKeyButtonPluginInputMappingVarId]),
    pluginErrorFieldId: fnFieldValueToRawValue("pickFieldId", values[propKeyPluginErrorField])
  } as StudioFieldRefUser;
}

export function studioFieldRefUserToDefnValue(studioField: StudioFieldRefUser): FieldValues
{
  return {
    [propKeyDefaultValueRoleId]: fnRawValueToFieldValue("enumRoles", studioField.defaultValue),
    [propKeyRefUserFields]: Object.values(studioField.copyFieldVarMap ?? {}),
    [propKeyDataSourceVarId]: fnRawValueToFieldValue("pickVarId", studioField.dataSourceVarId),
    [propKeyRolesDataSource]: fnRawValueToFieldValue("setOfRole", studioField.roleIdDataSource),
    [propKeyPlugin]: fnRawValueToFieldValue("pickImportPluginId", studioField.pluginApi?.metaIdPlugin),
    [propKeyPluginApi]: fnRawValueToFieldValue("pickImportPluginApiId", studioField.pluginApi?.pluginApiId),
    [propKeyButtonPluginInputMappingVarId]: fnRawValueToFieldValue("pickVarId", studioField.pluginInputMappingVarId),
    [propKeyPluginErrorField]: fnRawValueToFieldValue("pickFieldId", studioField.pluginErrorFieldId)
  };
}
