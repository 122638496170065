import {useTheme} from "@mui/material";
import {Box} from "@mui/material";
import {Stack} from "@mui/material";
import {useRef} from "react";
import React from "react";
import {DefnDtoFormTheme} from "../../../../api/meta/base/dto/DefnDtoFormTheme";
import {DefnField} from "../../../../api/meta/base/dto/DefnField";
import {DefnFieldEditable} from "../../../../api/meta/base/dto/DefnFieldEditable";
import {DefnSection} from "../../../../api/meta/base/dto/DefnSection";
import {MetaIdField} from "../../../../api/meta/base/Types";
import {EnumDefnPlacement} from "../../../../api/meta/base/Types";
import {FORM_FIELD_MEDIUM_PADDING} from "../../../../base/plus/ConstantsPlus";
import {getComp} from "../../../../base/plus/FormPlus";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {getCompKeyArray} from "../../../../base/plus/FormPlus";
import {px} from "../../../../base/plus/StringPlus";
import {IListBinderAll} from "../../../../base/types/list/TypesList";
import {DefnFieldUi} from "../../../../base/types/TypesForm";
import {DefnFormUi} from "../../../../base/types/TypesForm";
import RawHighlighter from "../../../atom/raw/RawHighlighter";
import {useFormCtx} from "../base/CtxForm";
import {ctxFormSection} from "../base/CtxFormSection";
import IFormSectionCtx from "../base/CtxFormSection";
import FieldFactory from "../base/FieldFactory";
import FieldBase from "../raw/FieldBase";

export default function FieldSection<SI1, SI2, SI3, SI4, SI5, SI6, SI7, SI8, SI9, SI10>(props: {
  defnForm: DefnFormUi,
  defnTheme: DefnDtoFormTheme,
  defn: DefnSection,
  listBinderMap?: Record<MetaIdField, IListBinderAll<SI1, SI2, SI3, SI4, SI5, SI6, SI7, SI8, SI9, SI10>>
})
{
  const formCtx = useFormCtx();
  const theme = useTheme();
  const formSectionCtx = useRef({} as IFormSectionCtx);
  formSectionCtx.current.getParent = () => defnSection;
  formSectionCtx.current.getDefnForm = () => defnForm;
  formSectionCtx.current.getDefn = (key) => defnForm.compMap[key] as DefnFieldUi;

  const defnForm = props.defnForm;
  const defnTheme = props.defnTheme;
  const defnSection = props.defn as DefnSection;

  const fieldId = getFieldKey(defnSection);
  const sectionVariant = defnSection.sectionVariant;
  const flexGrow = defnSection.flexGrow;
  const bgColor = defnSection.backgroundColorVar;
  const foregroundColorVar = defnSection.foregroundColorVar;
  const labelKey = "label-" + fieldId;
  const sectionDirection = defnSection.sectionDirection ?? "column";
  const justifyContent = getJustifyContent(defnSection.justifyContent);
  const alignItems = fnAlignItemsSx(defnSection.alignItems);
  const borderCss = `${px(theme.common.sizeDivider)} solid ${theme.common.borderColor}`;
  const spacing = (sectionDirection === "vertical" || sectionDirection === "column")
    ? defnTheme.colSpacing
    : defnTheme.rowSpacing;

  const isDisable = formCtx.isFieldDisable(defnSection as DefnFieldEditable);
  const gapStd = theme.common.gapStd;
  const isReport = defnTheme.formVariant === "report";
  const padding = isReport && spacing ? spacing * 2 : 0;

  const isMainSection = defnForm.compMap
    ? (defnForm.compMap[defnForm?.displayCompositeId] as DefnField)?.metaId === fieldId
    : false;

  const direction = (sectionVariant === "propertyEditor" && !isReport)
    ? "column"
    : sectionDirection === "horizontal"
      ? "row"
      : "column";

  const visibilityOption = formCtx.getFieldVisibilityOption(defnSection.metaId);
  const isHidden = visibilityOption?.hidden
    || visibilityOption?.invisible
    || defnSection?.hidden
    || defnSection?.invisible;

  return (
    <ctxFormSection.Provider value={formSectionCtx.current}>
      {(defnSection.propertyEditorLabel && !isHidden) &&
        <FieldBase
          fieldId={labelKey}
          pr={FORM_FIELD_MEDIUM_PADDING}
          pl={FORM_FIELD_MEDIUM_PADDING}
          pb={px(theme.common.gapHalf)}
        >
          <RawHighlighter
            key={labelKey}
            variant={"body2"}
            value={defnSection.propertyEditorLabel}
            color={theme.common.color("textPrimary")}
            bold={true}
          />
        </FieldBase>
      }
      <Stack
        key={fieldId}
        direction={direction}
        spacing={!isReport ? spacing : 0}
        className={labelKey}
        justifyContent={justifyContent}
        alignItems={alignItems ?? (direction === "column" ? "start" : "stretch")}
        pl={defnSection.pl !== undefined ? px(defnSection.pl) : padding}
        pr={defnSection.pr !== undefined ? px(defnSection.pr) : padding}
        pt={defnSection.pt !== undefined ? px(defnSection.pt) : isMainSection ? px(gapStd) : 0}
        pb={defnSection.pb !== undefined ? px(defnSection.pb) : isMainSection ? px(gapStd) : 0}
        width={"100%"}
        useFlexGap={true}
        bgcolor={bgColor?.value ? theme.common.colorWithShade(bgColor?.value, bgColor?.shade) : undefined}
        sx={{
          borderTop: defnSection.borderTop ? borderCss : undefined,
          borderBottom: defnSection.borderBottom ? borderCss : undefined,
          borderLeft: defnSection.borderLeft ? borderCss : undefined,
          borderRight: defnSection.borderRight ? borderCss : undefined,
          opacity: 1,
          pointerEvents: isDisable ? "none" : undefined,
          ...(flexGrow !== false) &&
          {
            flexGrow: 1,
            ...!isReport && {
              ":last-child": {
                height: "inherit"
              }
            },
            "& > *": {
              width: "100%"
            }
          },
          ...isHidden && {
            display: "none"
          },
          ...foregroundColorVar?.value && {
            "&  .MuiTypography-root": {
              color: theme.common.colorWithShade(foregroundColorVar?.value, bgColor?.shade)
            }
          },
          overflow: defnSection.overflowHidden ? "hidden" : undefined
        }}
      >
        {
          getCompKeyArray(defnSection).map((compId) =>
          {
            const defnChildComp = getComp(defnForm, compId);

            const childSection = defnChildComp?.type === "section"
              ? (defnChildComp as DefnSection)
              : undefined;
            const childSectionOverflowHidden = childSection?.overflowHidden;

            return (defnSection.fieldSpanMap)
              ? <Box
                className={"field-" + compId}
                key={compId}
                height={"inherit"}
                flex={defnSection.fieldSpanMap
                  ? defnSection.fieldSpanMap[compId]
                  : undefined}
                component={"span"}
                justifyContent={justifyContent}
                sx={{
                  ...(flexGrow !== false) && {
                    width: "100%"
                  },
                  overflow: childSectionOverflowHidden ? "hidden" : "visible"
                }}
              >
                <FieldFactory
                  key={getFieldKey(defnChildComp)}
                  defnForm={defnForm}
                  defnTheme={defnTheme}
                  defnComp={defnChildComp}
                  listBinderMap={props.listBinderMap}
                />
              </Box>
              : <FieldFactory
                key={compId}
                defnForm={defnForm}
                defnTheme={defnTheme}
                defnComp={defnChildComp}
                listBinderMap={props.listBinderMap}
              />;
          })
        }
      </Stack>
    </ctxFormSection.Provider>
  );
}

function getJustifyContent(justifyContent?: EnumDefnPlacement)
{
  if(!justifyContent)
  {
    return "start";
  }
  switch(justifyContent)
  {
    case "center":
    case "end":
    case "start":
      return justifyContent;
    case "spaceBetween":
      return "space-between";
    default:
      return justifyContent;
  }
}

function fnAlignItemsSx(justifyContent?: EnumDefnPlacement)
{
  switch(justifyContent)
  {
    case "center":
      return justifyContent;
    case "end":
      return "flex-end";
    case "start":
      return "flex-start";
  }
}
