import {DeleteRounded} from "@mui/icons-material";
import {FormHelperText, IconButton} from "@mui/material";
import {useTheme} from "@mui/material";
import {Stack} from "@mui/material";
import {useRef} from "react";
import React from "react";
import {useFieldArray} from "react-hook-form";
import {Controller} from "react-hook-form";
import {DefnDtoFormTheme} from "../../../../api/meta/base/dto/DefnDtoFormTheme";
import {DefnFieldEditable} from "../../../../api/meta/base/dto/DefnFieldEditable";
import {DefnStudioCompArray} from "../../../../api/meta/base/dto/DefnStudioCompArray";
import {MetaIdComp} from "../../../../api/meta/base/Types";
import {STR_NOTHING_HERE} from "../../../../base/plus/ConstantsPlus";
import {ensureInitValues} from "../../../../base/plus/FieldValuePlus";
import {getComp} from "../../../../base/plus/FormPlus";
import {px} from "../../../../base/plus/StringPlus";
import {DefnFieldUi} from "../../../../base/types/TypesForm";
import {DefnFormUi} from "../../../../base/types/TypesForm";
import LayoutFlexCol from "../../../atom/layout/LayoutFlexCol";
import LayoutFlexRow from "../../../atom/layout/LayoutFlexRow";
import RawButtonStrip from "../../../atom/raw/RawButtonStrip";
import RawNothingHere from "../../../atom/raw/RawNothingHere";
import {useFormCtx} from "../base/CtxForm";
import IFormSectionCtx from "../base/CtxFormSection";
import {ctxFormSection} from "../base/CtxFormSection";
import FieldFactory from "../base/FieldFactory";
import FieldBase from "../raw/FieldBase";

export default function FieldStudioArray(props: {
  defnForm: DefnFormUi,
  defnTheme: DefnDtoFormTheme,
  defn: DefnStudioCompArray,
})
{
  const defnFieldArray = props.defn as DefnStudioCompArray;

  const formSectionCtx = useRef({} as IFormSectionCtx);
  const formCtx = useFormCtx();
  const theme = useTheme();

  const control = formCtx.control();
  formSectionCtx.current.getParent = () => defnFieldArray;
  const defnForm = props.defnForm;
  formSectionCtx.current.getDefnForm = () => defnForm;
  formSectionCtx.current.getDefn = (key) => defnForm.compMap[key] as DefnFieldUi;

  const {
    fields,
    append,
    remove
  } = useFieldArray({
    control,
    name: defnFieldArray.metaId
  });

  return (
    <ctxFormSection.Provider value={formSectionCtx.current}>
      <Controller
        name={defnFieldArray.metaId}
        control={formCtx.control()}
        render={({
          field,
          fieldState
        }) =>
        {
          const {error} = fieldState;

          const isError = Boolean(error);

          const isDisabled = formCtx.isFieldDisable(defnFieldArray as DefnFieldEditable) || defnFieldArray.disabled;

          return (
            <FieldBase
              fieldId={"label-1"}
              width={"100%"}
            >
              <LayoutFlexCol
                overflowY={"visible"}
                overflowX={"visible"}
              >
                {fields.length === 0 && <RawNothingHere
                  helperTextData={{
                    title: STR_NOTHING_HERE
                  }}
                />}
                <Stack
                  spacing={props.defnTheme.rowSpacing}
                  width={"100%"}
                >
                  {fields?.map((item, index) =>
                    {
                      return (
                        <Stack
                          spacing={props.defnTheme.colSpacing}
                          direction={"row"}
                          key={item.id}
                        >
                          {
                            defnFieldArray?.fieldIdSet?.map(compKey =>
                            {
                              const defnChildComp = {
                                ...getComp(defnForm, compKey),
                                disabled: isDisabled,
                                metaId: `${defnFieldArray.name}[${index}][${compKey}]`,
                                _key: `${defnFieldArray.name}[${index}][${compKey}]`
                              } as DefnFieldUi;

                              return (
                                <FieldFactory
                                  defnForm={defnForm}
                                  defnTheme={props.defnTheme}
                                  defnComp={defnChildComp}
                                  key={compKey}
                                />
                              );
                            })
                          }
                          <LayoutFlexRow>
                            <IconButton
                              onClick={() =>
                              {
                                remove(index);
                              }}
                              size={"small"}
                              disabled={isDisabled}
                            >
                              <DeleteRounded />
                            </IconButton>
                          </LayoutFlexRow>
                        </Stack>
                      );
                    }
                  )}
                </Stack>
              </LayoutFlexCol>
              {isError &&
                <LayoutFlexRow
                  width={"100%"}
                  justifyContent={"flex-start"}
                  pl={px(theme.common.gapStd)}
                >
                  <FormHelperText error={isError}>
                    {error?.message}
                  </FormHelperText>
                </LayoutFlexRow>
              }
              <LayoutFlexRow
                width={"100%"}
                justifyContent={"flex-end"}
                pt={px(theme.common.gapStd)}
              >
                <RawButtonStrip
                  iconButtonList={["add"]}
                  onClick={(iconName) =>
                  {
                    if(iconName === "add")
                    {
                      append(getInitValues(defnForm, defnFieldArray?.fieldIdSet), {});
                    }
                  }}
                  iconButtonDisable={isDisabled ? ["add"] : []}
                />
              </LayoutFlexRow>

            </FieldBase>
          );
        }
        }
      />
    </ctxFormSection.Provider>
  );
}

function getInitValues(defnForm: DefnFormUi, fieldIdSet?: MetaIdComp[])
{
  const initObj: Record<MetaIdComp, any> = ensureInitValues(defnForm, {});
  const initValues: Record<MetaIdComp, any> = {};
  fieldIdSet?.forEach(value =>
  {
    initValues[value] = initObj[value];
  });
  return initValues;
}
