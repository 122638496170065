import {Typography} from "@mui/material";
import {Box} from "@mui/material";
import {isEmpty} from "lodash";
import {Fragment} from "react";
import {useCallback} from "react";
import React from "react";
import {FieldValues} from "react-hook-form";
import {useFormContext} from "react-hook-form";
import {DefnComp} from "../../../../api/meta/base/dto/DefnComp";
import {DefnDtoFormTheme} from "../../../../api/meta/base/dto/DefnDtoFormTheme";
import {DefnDtoLayoutCardItem} from "../../../../api/meta/base/dto/DefnDtoLayoutCardItem";
import {DefnDtoLayoutCardItemLine} from "../../../../api/meta/base/dto/DefnDtoLayoutCardItemLine";
import {DefnDtoLayoutCardItemLineSegment} from "../../../../api/meta/base/dto/DefnDtoLayoutCardItemLineSegment";
import {DefnField} from "../../../../api/meta/base/dto/DefnField";
import {DefnFieldCounter} from "../../../../api/meta/base/dto/DefnFieldCounter";
import {DefnFieldEditable} from "../../../../api/meta/base/dto/DefnFieldEditable";
import {DefnFieldFormListItem} from "../../../../api/meta/base/dto/DefnFieldFormListItem";
import {TextSize} from "../../../../api/meta/base/StudioSetsFieldType";
import {Color} from "../../../../api/meta/base/StudioSetsFieldType";
import {MetaIdField} from "../../../../api/meta/base/Types";
import {EnumDefnThemeColor} from "../../../../api/meta/base/Types";
import {EnumDefnTextSize} from "../../../../api/meta/base/Types";
import {getFormFieldValueAsText} from "../../../../base/plus/FieldValuePlus";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {toLabel} from "../../../../base/plus/StringPlus";
import {findSubStrBetweenPrefixAndSuffix} from "../../../../base/plus/StringPlus";
import {px} from "../../../../base/plus/StringPlus";
import {gapStd} from "../../../../base/plus/ThemePlus";
import {gapHalf} from "../../../../base/plus/ThemePlus";
import {gapQuarter} from "../../../../base/plus/ThemePlus";
import theme from "../../../../base/plus/ThemePlus";
import {DefnFormUi} from "../../../../base/types/TypesForm";
import {TypeTextColor} from "../../../../base/types/TypesGlobal";
import LayoutFlexRow from "../../../atom/layout/LayoutFlexRow";
import RawIcon from "../../../atom/raw/RawIcon";
import {TypeLayoutItemLine} from "../../../dialog/base/TypesLayoutBuilder";
import FieldFactory from "../base/FieldFactory";
import {isLineHasContent} from "../base/FormViewerPlus";
import {isSegmentHasContent} from "../base/FormViewerPlus";
import {listImageMinHeight} from "./FieldCarousel";
import {ListItemCarousel} from "./FieldCarousel";

export default function FieldFormListItem(props: {
  defn: DefnFieldFormListItem,
  defnForm: DefnFormUi,
  defnTheme: DefnDtoFormTheme,
})
{
  const defn = props.defn;
  const isCard = defn.isCard;

  return <Box
    flexGrow={1}
    padding={px(2)}
    display={"flex"}
    flexDirection={isCard ? "column" : "row"}
    gap={px(gapQuarter)}
  >
    {
      (defn.layout?.mediaFieldIdSet?.length || defn.layout?.mediaVarSet?.length)
        ? <LayoutFlexRow
          alignItems={"flex-start"}
          width={isCard ? "100%" : px(listImageMinHeight * 1.2)}
          pt={isCard ? undefined : px(gapStd)}
          pb={isCard ? undefined : px(gapStd)}
        >
          <ListItemCarousel
            defn={props.defn}
            defnForm={props.defnForm}
          />
        </LayoutFlexRow>
        : null
    }
    <ListItemLines
      defn={props.defn}
      defnForm={props.defnForm}
      defnTheme={props.defnTheme}
    />
  </Box>;
}

function ListItemLines(props: {
  defn: DefnFieldFormListItem,
  defnForm: DefnFormUi,
  defnTheme: DefnDtoFormTheme,
})
{
  const defn = props.defn;
  const layout = defn.layout;
  const editableFieldIdSet = defn.editableFieldIdSet;
  const fieldId = getFieldKey(defn);
  const getFieldIdPathValue = useFieldPathValueListItem();
  const valueMap = getFieldIdPathValue(fieldId);
  const isCard = defn.isCard;
  const hasMedia = layout?.mediaFieldIdSet?.length || layout?.mediaVarSet?.length;

  const lines: (keyof DefnDtoLayoutCardItem)[] =
    ["firstLine", "secondLine", "thirdLine", "fourthLine", "fifthLine"];

  return <Box
    flexGrow={1}
    paddingLeft={px(hasMedia && !isCard ? 0 : gapStd)}
    paddingRight={px(gapStd)}
    paddingY={px(gapHalf)}
    display={"flex"}
    flexDirection={"column"}
    gap={px(gapQuarter)}
    overflow={"hidden"}
  >
    {
      lines.map(lineName =>
      {
        const layoutLine = layout?.[lineName] as DefnDtoLayoutCardItemLine;
        return layoutLine && isLineHasContent(layoutLine)
          ? <ListItemLine
            line={layoutLine}
            key={lineName}
            valueMap={isEmpty(valueMap) ? {} : valueMap}
            editableFieldIdSet={editableFieldIdSet}
            defnForm={props.defnForm}
            defnTheme={props.defnTheme}
            lineName={lineName}
            isCard={isCard}
          />
          : null;
      })
    }
  </Box>;
}

function ListItemLine(props: {
  line: DefnDtoLayoutCardItemLine,
  lineName: keyof DefnDtoLayoutCardItem,
  valueMap: FieldValues,
  editableFieldIdSet?: MetaIdField[],
  defnForm: DefnFormUi,
  defnTheme: DefnDtoFormTheme,
  isCard?: boolean
})
{
  const line = props.line;
  const valueMap = props.valueMap;

  const segments: (keyof DefnDtoLayoutCardItemLine)[] = ["first", "middle", "caption"];
  const filterSegments = segments.filter(segmentName =>
  {
    const layoutSegment = line[segmentName] as DefnDtoLayoutCardItemLineSegment;
    return layoutSegment && isSegmentHasContent(layoutSegment);
  });

  const isOnlyFirst = filterSegments.length === 1 && filterSegments[0] === "first";
  const isOnlyMiddle = filterSegments.length === 1 && filterSegments[0] === "middle";
  const isOnlyCaption = filterSegments.length === 1 && filterSegments[0] === "caption";
  const isFirstAndMiddle = filterSegments.length === 2 && filterSegments.includes("first") && filterSegments.includes(
    "middle");
  const isFirstAndCaption = filterSegments.length === 2 && filterSegments.includes("first") && filterSegments.includes(
    "caption");
  const isMiddleAndCaption = filterSegments.length === 2 && filterSegments.includes("middle")
    && filterSegments.includes("caption");
  const isAllSegments = filterSegments.length === 3;

  function getSegmentFlex(segmentName: keyof DefnDtoLayoutCardItemLine)
  {
    if(isOnlyFirst)
    {
      return 1;
    }
    if(isOnlyMiddle)
    {
      return 0.6;
    }
    if(isOnlyCaption)
    {
      return 0.6;
    }
    if(isFirstAndMiddle)
    {
      return segmentName === "first" ? 0.4 : 0.6;
    }
    if(isFirstAndCaption)
    {
      return segmentName === "first" ? 0.7 : 0.3;
    }
    if(isMiddleAndCaption)
    {
      return 0.3;
    }
    if(isAllSegments)
    {
      return segmentName === "first" ? 0.4 : 0.3;
    }
  }

  if(!filterSegments.length)
  {
    return null;
  }

  return <Box
    display={"flex"}
    width={"100%"}
    justifyContent={filterSegments.includes("caption") ? "space-between" : "flex-start"}
    overflow={"visible"}
    alignItems={"center"}
    flexShrink={1}
    gap={px(gapQuarter)}
    minHeight={px(theme.common.heightLine)}
  >
    {
      isOnlyCaption
        ? <Box flex={0.7} />
        : isMiddleAndCaption
          ? <Box flex={0.4} />
          : null
    }
    {
      filterSegments.map(segmentName =>
      {
        const layoutSegment = line[segmentName] as DefnDtoLayoutCardItemLineSegment;
        const flex = getSegmentFlex(segmentName);

        return <ListItemLineSegment
          segment={layoutSegment}
          valueMap={valueMap}
          key={segmentName}
          defnTheme={props.defnTheme}
          defnForm={props.defnForm}
          editableFieldIdSet={props.editableFieldIdSet}
          lineNumber={props.lineName}
          segmentName={segmentName}
          flex={flex}
          isCard={props.isCard}
        />;
      })
    }
  </Box>;
}

function ListItemLineSegment(props: {
  segment: DefnDtoLayoutCardItemLineSegment,
  valueMap: FieldValues,
  editableFieldIdSet?: MetaIdField[],
  defnForm: DefnFormUi,
  defnTheme: DefnDtoFormTheme,
  segmentName: TypeLayoutItemLine,
  lineNumber: keyof DefnDtoLayoutCardItem,
  flex?: number,
  isCard?: boolean
})
{
  const segment = props.segment;
  const editableFieldIdSet = props.editableFieldIdSet;
  const segmentName = props.segmentName;
  const lineNumber = props.lineNumber;
  const isCard = props.isCard;

  const lineFieldIdSet = segment?.lineFieldIdSet;
  const lineVar = segment?.lineVar;
  const line = segment?.line;

  const firstFieldId = lineFieldIdSet?.at(0);
  const isEditable = firstFieldId && editableFieldIdSet?.length && editableFieldIdSet?.includes(firstFieldId);

  if(isEditable)
  {
    return <ItemLineEditable
      editableFieldId={firstFieldId}
      defnForm={props.defnForm}
      defnTheme={props.defnTheme}
      segmentName={segmentName}
      lineNumber={lineNumber}
      flex={props.flex}
    />;
  }

  const {font, color} = fnGetLayoutColorAndFont({
    compMap: props.defnForm.compMap,
    segment: segment,
    segmentName: segmentName,
    lineNumber: lineNumber,
    valueMap: props.valueMap
  });

  if(lineFieldIdSet?.length)
  {
    return <ItemLineIdSet
      segment={segment}
      valueMap={props.valueMap}
      lineFieldIdSet={lineFieldIdSet}
      defnForm={props.defnForm}
      segmentName={segmentName}
      lineNumber={lineNumber}
      flex={props.flex}
      font={font}
      color={color}
      isCard={isCard}
    />;
  }
  else if(lineVar?.value?.length)
  {
    return <Typography
      color={color}
      variant={font}
      textOverflow={"ellipsis"}
      overflow={"hidden"}
      whiteSpace={isCard
        ? "nowrap"
        : lineNumber === "firstLine" && segmentName === "first"
          ? "wrap"
          : "nowrap"}
    >
      {lineVar.value.join(", ")}
    </Typography>;
  }
  else if(line)
  {
    return <Typography
      color={color}
      variant={font}
      textOverflow={"ellipsis"}
      overflow={"hidden"}
      whiteSpace={isCard
        ? "nowrap"
        : lineNumber === "firstLine" && segmentName === "first"
          ? "wrap"
          : "nowrap"}
    >
      {line}
    </Typography>;
  }

  return null;
}

function ItemLineEditable(props: {
  editableFieldId: MetaIdField,
  defnForm: DefnFormUi,
  defnTheme: DefnDtoFormTheme,
  segmentName: TypeLayoutItemLine,
  lineNumber: keyof DefnDtoLayoutCardItem,
  flex?: number,
})
{
  const {defnForm, editableFieldId, flex, segmentName} = props;
  const defnComp = defnForm.compMap[editableFieldId] as DefnFieldEditable;
  if(defnComp.type === "counter")
  {
    defnComp.hideLabel = true;
    (defnComp as DefnFieldCounter).justifyContent = segmentName === "caption" ? "end" : "start";
  }
  defnComp.pl = 0;
  defnComp.pr = 0;
  defnComp.pt = 0;
  defnComp.pb = 0;

  return <Box
    flex={flex}
    className={"editableField"}
    overflow={"visible"}
    display={"flex"}
    justifyContent={segmentName === "caption" ? "flex-end" : "flex-start"}
    alignSelf={"flex-start"}
    flexShrink={0}
    mt={props.lineNumber === "firstLine" ? undefined : px(gapHalf)}
    onClick={(e) => e.stopPropagation()}
  >
    <FieldFactory
      defnForm={props.defnForm}
      defnTheme={props.defnTheme}
      defnComp={defnComp as DefnField}
    />
  </Box>;
}

function ItemLineIdSet(props: {
  segment: DefnDtoLayoutCardItemLineSegment,
  valueMap: FieldValues,
  lineFieldIdSet: MetaIdField[],
  defnForm: DefnFormUi,
  segmentName: TypeLayoutItemLine,
  lineNumber: keyof DefnDtoLayoutCardItem,
  flex?: number,
  font: EnumDefnTextSize,
  color?: string,
  isCard?: boolean
})
{
  const {
    lineFieldIdSet,
    lineNumber,
    flex,
    defnForm,
    segment,
    segmentName,
    valueMap,
    font,
    color,
    isCard
  } = props;

  return <Box
    flex={flex}
    flexShrink={0}
    display={"flex"}
    overflow={"hidden"}
    textOverflow={"ellipsis"}
    justifyContent={segmentName === "caption" ? "flex-end" : "flex-start"}
  >
    {
      lineFieldIdSet.map((lineFieldId, index) =>
      {
        const comp = defnForm.compMap[lineFieldId];
        const fieldIdPath = getFieldIdPath(lineFieldId).at(-1);
        const fieldValue = fieldIdPath ? valueMap[fieldIdPath] : undefined;
        const value = getFormFieldValueAsText(comp as DefnFieldEditable, fieldValue);

        if(!value)
        {
          return null;
        }

        if(comp.type === "icon" && value)
        {
          return <RawIcon
            key={lineFieldId}
            icon={value}
            width={px(theme.common.heightLine)}
            color={color as TypeTextColor}
          />;
        }

        return <Fragment key={lineFieldId}>
          {
            segment.showLabels && <Typography
              color={"textSecondary"}
              variant={font}
              whiteSpace={"nowrap"}
            >
              {`${index > 0 ? ", " : ""}${toLabel(comp.label || comp.name)}:`}&nbsp;
            </Typography>
          }

          <Typography
            color={color}
            variant={font}
            textOverflow={"ellipsis"}
            overflow={"hidden"}
            whiteSpace={isCard
              ? "nowrap"
              : lineNumber === "firstLine" && segmentName === "first"
                ? "wrap"
                : "nowrap"}
          >
            {`${!segment.showLabels && index > 0 ? ", " : ""}${value}`}
          </Typography>
        </Fragment>;
      })
    }
  </Box>;
}

function fnGetLayoutColorAndFont(params: {
  compMap: Record<MetaIdField, DefnComp>,
  segment: DefnDtoLayoutCardItemLineSegment,
  segmentName: TypeLayoutItemLine,
  lineNumber: keyof DefnDtoLayoutCardItem,
  valueMap: FieldValues
})
{
  const {
    compMap,
    segment,
    segmentName,
    lineNumber,
    valueMap
  } = params;

  const showLabels = segment.showLabels;

  // color
  let color: string | EnumDefnThemeColor | undefined;
  if(segmentName === "first" && lineNumber === "firstLine")
  {
    color = "textPrimary";
  }
  else
  {
    color = showLabels ? "primary" : "textSecondary";
  }

  const colorFieldId = segment.colorFieldId;

  if(colorFieldId)
  {
    const colorField = compMap[colorFieldId];
    const fieldIdPath = getFieldIdPath(colorFieldId).at(-1);
    const fieldValue = fieldIdPath ? valueMap[fieldIdPath] : undefined;
    const value = getFormFieldValueAsText(colorField as DefnFieldEditable, fieldValue);
    if(Color.includes(colorField.type) && value)
    {
      color = value;
    }
  }
  else if(segment.colorVar?.value)
  {
    color = theme.common.colorWithShade(segment.colorVar.value, segment.colorVar.shade);
  }
  else if(segment.color?.value)
  {
    color = theme.common.colorWithShade(segment.color.value, segment.color?.shade);
  }

  // font
  let font: EnumDefnTextSize | undefined;

  if(lineNumber === "firstLine")
  {
    font = segmentName === "first" ? "subtitle1" : "caption";
  }
  else
  {
    font = segmentName === "first" ? "body2" : "caption";
  }

  const textSizeFieldId = segment.textSizeFieldId;
  if(textSizeFieldId)
  {
    const fontField = compMap[textSizeFieldId];
    const fieldIdPath = getFieldIdPath(textSizeFieldId).at(-1);
    const fieldValue = fieldIdPath ? valueMap[fieldIdPath] : undefined;
    const value = getFormFieldValueAsText(fontField as DefnFieldEditable, fieldValue) as EnumDefnTextSize;
    if(TextSize.includes(fontField?.type) && value)
    {
      font = value;
    }
  }
  else if(segment.textSizeVar)
  {
    font = segment.textSizeVar;
  }
  else if(segment.textSize)
  {
    font = segment.textSize;
  }

  return {
    color,
    font
  };
}

export function getFieldIdPath(fieldId: string)
{
  const dto = findSubStrBetweenPrefixAndSuffix(fieldId, "[", "]");
  const parentIndex = fieldId.indexOf("[");
  const parentId = parentIndex > -1 ? fieldId.substring(0, parentIndex) : fieldId;
  return [parentId, ...dto];
}

export function useFieldPathValueListItem()
{
  const hookFormCtx = useFormContext();
  const valueMap = hookFormCtx.getValues();

  return useCallback((listItemFieldId: MetaIdField) =>
  {
    const valueMapFieldIdPath = getFieldIdPath(listItemFieldId);
    const valueMapFieldId = valueMapFieldIdPath.slice(0, valueMapFieldIdPath.length - 1);
    return valueMapFieldId.reduce((previousValue, currentValue) =>
    {
      return previousValue?.[currentValue];
    }, valueMap);
  }, [valueMap]);
}
