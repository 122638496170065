import {useEffect} from "react";
import {useCallback} from "react";
import {useState} from "react";
import React from "react";
import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnField} from "../../api/meta/base/dto/DefnField";
import {DefnForm} from "../../api/meta/base/dto/DefnForm";
import {DefnSection} from "../../api/meta/base/dto/DefnSection";
import {DefnStudioBuildArgBinder} from "../../api/meta/base/dto/DefnStudioBuildArgBinder";
import {DefnStudioPickFieldId} from "../../api/meta/base/dto/DefnStudioPickFieldId";
import {DefnStudioPickPluginFieldId} from "../../api/meta/base/dto/DefnStudioPickPluginFieldId";
import {StudioBuildArgBinder} from "../../api/meta/base/dto/StudioBuildArgBinder";
import {StudioForm} from "../../api/meta/base/dto/StudioForm";
import {StudioSection} from "../../api/meta/base/dto/StudioSection";
import {StudioVarUserSetting} from "../../api/meta/base/dto/StudioVarUserSetting";
import {NumberMappingTypeSet} from "../../api/meta/base/StudioSetsFieldType";
import {DecimalMappingTypeSet} from "../../api/meta/base/StudioSetsFieldType";
import {DateRangeMappingTypeSet} from "../../api/meta/base/StudioSetsFieldType";
import {DateMappingTypeSet} from "../../api/meta/base/StudioSetsFieldType";
import {TextMappingTypeSet} from "../../api/meta/base/StudioSetsFieldType";
import {EntUserIdMappingTypeSet} from "../../api/meta/base/StudioSetsFieldType";
import {AllFieldTypes} from "../../api/meta/base/StudioSetsFieldType";
import {MetaIdVar} from "../../api/meta/base/Types";
import {MetaIdComp} from "../../api/meta/base/Types";
import {EnumDefnCompType} from "../../api/meta/base/Types";
import {EnumDefnFields} from "../../api/meta/base/Types";
import {EnumArrayDefnFields} from "../../api/meta/base/Types";
import {MetaIdPlugin} from "../../api/meta/base/Types";
import {MetaIdField, MetaIdForm} from "../../api/meta/base/Types";
import {getFieldArgBinderValue} from "../../base/plus/ArgBinderPlus";
import {fnFieldValueToRawValueArgBinder} from "../../base/plus/FieldValuePlus";
import {fnRawValueToFieldValueArgBinder} from "../../base/plus/FieldValuePlus";
import {fnRawValueToFieldValue} from "../../base/plus/FieldValuePlus";
import {fnFieldValueToRawValue} from "../../base/plus/FieldValuePlus";
import {loopDefnForm} from "../../base/plus/FormPlus";
import {createDefaultDefnFormStudio, defaultSectionKey} from "../../base/plus/FormPlus";
import {arrayToMapOfOption} from "../../base/plus/JsPlus";
import {loopStudioForm} from "../../base/plus/StudioFormPlus";
import theme from "../../base/plus/ThemePlus";
import {FormStore} from "../../base/types/TypesForm";
import {useMappingVarFunctions} from "../form/viewer/base/FieldVarMappingPlus";
import DialogDefnForm from "./base/impl/DialogDefnForm";

const fieldFrom = "from";
const fieldTo = "to";

const contentWidth = theme.common.appMinTabletWidth;

const mappingFieldTypeValidationMap = {
  text: new Set(["paragraph", "text"]),
  pickText: new Set(["pickText", "text"]),
  pickTree: new Set(["pickTree", "text"]),
  pickReportRow: new Set(["pickReportRow", "text"]),
  rowId: new Set(["rowId", "hyperlinkRow"]),
  hyperlinkRow: new Set(["rowId", "hyperlinkRow"]),
  $RowId: new Set(["rowId", "hyperlinkRow"]),
  $CreatedBy: new Set(["pickUser", "userId"]),
  $UpdatedBy: new Set(["pickUser", "userId"]),
  $CreatedOn: new Set(["dateTime"]),
  $UpdatedOn: new Set(["dateTime"]),
  $RowOrder: new Set(["number"])
} as Record<EnumDefnCompType | EnumDefnFields, Set<EnumDefnCompType>>;

const pushMappingFieldTypeValidation = (arr: EnumDefnCompType[]) =>
{
  arr.forEach((fieldType) =>
  {
    const previousSet =
      mappingFieldTypeValidationMap[fieldType]
        ? [...mappingFieldTypeValidationMap[fieldType], ...arr]
        : arr;
    mappingFieldTypeValidationMap[fieldType] = new Set(previousSet);
  });
};
pushMappingFieldTypeValidation(DateMappingTypeSet as EnumDefnCompType[]);
pushMappingFieldTypeValidation(DateRangeMappingTypeSet as EnumDefnCompType[]);
pushMappingFieldTypeValidation(DecimalMappingTypeSet as EnumDefnCompType[]);
pushMappingFieldTypeValidation(EntUserIdMappingTypeSet as EnumDefnCompType[]);
pushMappingFieldTypeValidation(NumberMappingTypeSet as EnumDefnCompType[]);
pushMappingFieldTypeValidation(TextMappingTypeSet as EnumDefnCompType[]);

function getFieldTypes(fieldType?: EnumDefnCompType): EnumDefnCompType[] | undefined
{
  if(fieldType)
  {
    if(mappingFieldTypeValidationMap[fieldType])
    {
      return [...mappingFieldTypeValidationMap[fieldType]];
    }
    return [fieldType];
  }
}

interface IFieldMapping
{
  from: StudioBuildArgBinder,
  to: MetaIdField
}

export default function DialogNewFieldMapping(props: {
  fromFormId?: MetaIdForm,
  toFormId: MetaIdForm,
  fromCompositeId?: MetaIdField,
  toCompositeId?: MetaIdField,
  fromPluginId?: MetaIdPlugin;
  toPluginId?: MetaIdPlugin;
  formStore?: FormStore,
  values?: IFieldMapping,
  readOnly?: boolean,
  onClickOk: (values: IFieldMapping) => void,
  onClose?: () => void,
})
{
  const values = props.values;
  const formStore = props.formStore;
  const onClickSubmit = props.onClickOk;
  const fromCompositeId = props.fromCompositeId;
  const toCompositeId = props.toCompositeId;
  const fromPluginId = props.fromPluginId;
  const toPluginId = props.toPluginId;
  const fromFormId = props.fromFormId;
  const toFormId = props.toFormId;
  const readOnly = props.readOnly;

  const [fromSelectedFieldType, setFromSelectedFieldType] = useState<EnumDefnCompType[]>();
  const [fromCompositeIdSet, setFromCompositeIdSet] = useState<MetaIdField[]>();
  const [toCompositeIdSet, setToCompositeIdSet] = useState<MetaIdField[]>();

  const {
    form: fromForm,
    isDefnForm: isFromDefnForm,
    getField: getFromField
  } = useMappingVarFunctions("from", {
    fromFormId,
    fromPluginId,
    toFormId,
    toPluginId
  }, props.formStore);

  const {
    form: toForm,
    isDefnForm: isToDefnForm,
    getField: getToField
  } = useMappingVarFunctions("to", {
    fromFormId,
    fromPluginId,
    toFormId,
    toPluginId
  }, props.formStore);

  const defnForm = getDefnForm(
    toFormId,
    fromFormId,
    fromPluginId,
    toPluginId,
    fromSelectedFieldType,
    fromCompositeIdSet,
    toCompositeIdSet
  );

  const onWatch = useCallback((key: string, value: any) =>
    {
      if(key === fieldFrom)
      {
        const argBinder = fnFieldValueToRawValue("studioBuildArgBinder", value) as StudioBuildArgBinder;
        const form = fromFormId
          ? formStore?.formMap?.map[fromFormId]
          : undefined;

        const argBinderValue = argBinder
          ? getFieldArgBinderValue(argBinder, form)
          : undefined;

        if(argBinderValue)
        {
          if(argBinderValue.kind === "field" || argBinderValue.kind === "derived")
          {
            const fieldId: MetaIdField = argBinderValue.kind === "field"
              ? argBinderValue.value as MetaIdField
              : argBinderValue.derivedFieldId as MetaIdField;

            const fromField = fieldId
              ? argBinderValue.kind === "field"
                ? getFromField(fieldId, fromCompositeId)
                : getToField(fieldId, toCompositeId)
              : undefined;

            const toFieldIdTypes = getFieldTypes(fromField?.type);
            setFromSelectedFieldType(toFieldIdTypes);
          }
          else if(argBinderValue.kind === "context" && argBinderValue.contextKind === "callerSetting")
          {
            const userSettingVarId = argBinderValue.value as MetaIdVar | undefined;
            const userSettingKind = userSettingVarId
              ? (formStore?.varMap?.map[userSettingVarId] as StudioVarUserSetting).value?.userSettingOptionKind
              : undefined;
            if(userSettingKind)
            {
              const filterUserSettingKind = (() =>
              {
                switch(userSettingKind)
                {
                  case "pickMany":
                    return "setOfText";
                  case "text":
                    return "text";
                  case "pickOne":
                    return "pickText";
                  default:
                    return userSettingKind as EnumDefnCompType;
                }
              })();

              setFromSelectedFieldType([filterUserSettingKind]);
            }
          }
          else if(argBinderValue.kind === "constant" && argBinderValue.constantType)
          {
            setFromSelectedFieldType([argBinderValue.constantType]);
          }
          else
          {
            setFromSelectedFieldType(undefined);
          }
        }
      }

    },
    [
      fromFormId,
      formStore?.formMap?.map,
      formStore?.varMap?.map,
      getFromField,
      fromCompositeId,
      getToField,
      toCompositeId
    ]
  );

  const valueToDto = (values: FieldValues) =>
  {
    const mapping = {
      from: fnFieldValueToRawValueArgBinder(values[fieldFrom]),
      to: fnFieldValueToRawValue("pickFieldId", values[fieldTo])
    } as IFieldMapping;

    onClickSubmit(mapping);

    setFromSelectedFieldType(undefined);
  };

  useEffect(() =>
  {
    if(values)
    {
      onWatch(fieldFrom, values.from);
      onWatch(fieldTo, values.to);
    }
  }, []);

  useEffect(() =>
  {
    const fromCompositeIdSet = new Set<MetaIdComp>();
    const toCompositeIdSet = new Set<MetaIdComp>();

    if(fromCompositeId)
    {
      fromCompositeIdSet.add(fromCompositeId);
    }
    if(toCompositeId)
    {
      toCompositeIdSet.add(toCompositeId);
    }
    if(fromForm)
    {
      if(!isFromDefnForm)
      {
        loopStudioForm(fromForm as StudioForm, (parent) =>
        {
          if(parent.type === "section")
          {
            const metaId = (parent as StudioSection).metaId;
            fromCompositeIdSet.add(metaId);
          }
        });
      }
      else
      {
        loopDefnForm(fromForm as DefnForm, (parent) =>
        {
          if(parent.type === "section")
          {
            const metaId = (parent as DefnSection).metaId;
            fromCompositeIdSet.add(metaId);
          }
        });
      }
    }
    if(toForm)
    {
      if(!isToDefnForm && !toCompositeId)
      {
        loopStudioForm(toForm as StudioForm, (parent) =>
        {
          if(parent.type === "section")
          {
            const metaId = (parent as StudioSection).metaId;
            toCompositeIdSet.add(metaId);
          }
        });
      }
      else
      {
        loopDefnForm(toForm as DefnForm, (parent) =>
        {
          if(parent.type === "section")
          {
            const metaId = (parent as DefnSection).metaId;
            toCompositeIdSet.add(metaId);
          }
        });
      }
    }

    setFromCompositeIdSet(fromCompositeIdSet.size > 0 ? [...fromCompositeIdSet] : undefined);
    setToCompositeIdSet(toCompositeIdSet.size > 0 ? [...toCompositeIdSet] : undefined);

  }, [fromForm, toForm, fromCompositeId, toCompositeId, isFromDefnForm, isToDefnForm]);

  return (
    <DialogDefnForm
      title={`${values ? "Update" : "New"} field mapping`}
      formProps={{
        onWatch: onWatch,
        defnForm: defnForm,
        onSubmit: (values) => valueToDto(values),
        store: formStore,
        initValues: dtoToValue(values),
        formReadonly: readOnly
      }}
      addMoreCheckBoxLabel={!values
        ? "Add more field mappings"
        : undefined}
      onClose={props.onClose}
      contentWidth={contentWidth}
    />
  );
}

function dtoToValue(dto?: IFieldMapping)
{
  if(!dto)
  {
    return;
  }

  return {
    ...fnRawValueToFieldValueArgBinder(fieldFrom, dto.from),
    [fieldTo]: fnRawValueToFieldValue("pickFieldId", dto?.to)
  } as FieldValues;
}

function getDefnForm(
  toFormId: MetaIdForm,
  fromFormId?: MetaIdForm,
  fromPluginId?: MetaIdPlugin,
  toPluginId?: MetaIdPlugin,
  fromSelectedFieldType?: EnumDefnCompType[],
  fromCompositeIdSet?: MetaIdField[],
  toCompositeIdSet?: MetaIdField[])
{
  return createDefaultDefnFormStudio({

    ...(fromPluginId !== undefined || toPluginId !== undefined)
      ? {
        [fieldFrom]: {
          type: "studioBuildArgBinder",
          name: fieldFrom,
          metaId: fieldFrom,
          label: "From",
          filterFieldTypeSet: AllFieldTypes,
          filterDerivedFieldTypeSet: AllFieldTypes.filter(fieldType => fieldType !== "pickTree"),
          compositeIdSet: fromCompositeIdSet,
          derivedCompositeIdSet: toCompositeIdSet,
          required: true,
          formId: fromFormId,
          derivedFormId: toFormId,
          pluginId: fromPluginId,
          derivedPluginId: toPluginId,
          includeOptionMap: arrayToMapOfOption(EnumArrayDefnFields, true),
          filterKindSet: !fromFormId ? ["field"] : undefined
        } as DefnStudioBuildArgBinder
      }
      : {
        [fieldFrom]: {
          type: "studioBuildArgBinder",
          name: fieldFrom,
          metaId: fieldFrom,
          label: "From",
          filterFieldTypeSet: AllFieldTypes,
          filterDerivedFieldTypeSet: AllFieldTypes.filter(fieldType => fieldType !== "pickTree"),
          compositeIdSet: fromCompositeIdSet,
          derivedCompositeIdSet: toCompositeIdSet,
          required: true,
          formId: fromFormId,
          derivedFormId: toFormId,
          includeOptionMap: arrayToMapOfOption(EnumArrayDefnFields, true),
          filterKindSet: !fromFormId ? ["field"] : undefined
        } as DefnStudioBuildArgBinder
      },

    ...toPluginId !== undefined
      ? {
        [fieldTo]: {
          type: "pickPluginFieldId",
          name: fieldTo,
          metaId: fieldTo,
          label: "To",
          filterFieldTypeSet: fromSelectedFieldType,
          compositeIdSet: toCompositeIdSet,
          required: true,
          formId: toFormId,
          pluginId: toPluginId,
          showCompositeName: true
        } as DefnStudioPickPluginFieldId
      }
      : {
        [fieldTo]: {
          type: "pickFieldId",
          name: fieldTo,
          metaId: fieldTo,
          label: "To",
          filterFieldTypeSet: fromSelectedFieldType,
          compositeIdSet: toCompositeIdSet,
          required: true,
          formId: toFormId,
          showCompositeName: true
        } as DefnStudioPickFieldId
      },

    [defaultSectionKey]: {
      type: "section",
      name: defaultSectionKey,
      metaId: defaultSectionKey,
      fieldIdSet: [fieldFrom, fieldTo]
    } as DefnSection
  } as Record<MetaIdField, DefnField>);
}

