import {Typography} from "@mui/material";
import {useTheme} from "@mui/material";
import {ICellRendererParams} from "ag-grid-community";
import React from "react";
import {resolveBubbleFooterTimer} from "../../../../base/plus/BubblePlus";
import {px} from "../../../../base/plus/StringPlus";
import {IDataGridCell} from "../../../../base/types/TypeDataGrid";
import LayoutFlexRow from "../../../atom/layout/LayoutFlexRow";
import RawProgress from "../../../atom/raw/RawProgress";

export default function GridCellTimer(props: ICellRendererParams<IDataGridCell>)
{
  const theme = useTheme();
  const gapHalf = theme.common.gapHalf;

  const value = props.data?.sigSpreadsheetRowExpiry;
  const expired = props.data?.expired;

  const resolveProgress = resolveBubbleFooterTimer(value || (expired
    ? {
      showTimer: true,
      remainingInvisibleProgressPercentage: 0,
      remainingReadProgressPercentage: 0,
      remainingInvisibleTimeMillis: 0,
      remainingReadTimeMillis: 0
    }
    : undefined));

  return (
    <LayoutFlexRow
      justifyContent={"start"}
      height={"100%"}
    >
      <LayoutFlexRow
        pr={px(gapHalf)}
      >
        <RawProgress
          value={resolveProgress?.timer || 0}
          color={"error"}
          size={theme.common.bubbleFooterTimerSize}
          innerIcon={resolveProgress?.icon}
        />
      </LayoutFlexRow>
      <LayoutFlexRow>
        <Typography
          textAlign={"center"}
          variant={"caption"}
          width={"100%"}
          children={resolveProgress?.value || ""}
        />
      </LayoutFlexRow>
    </LayoutFlexRow>
  );
}
