import {PayloadAction} from "@reduxjs/toolkit";
import {TypeListItemId} from "../../types/list/TypesList";
import {IListItemId} from "../../types/list/TypesList";
import {IListItem} from "../../types/list/TypesList";
import {IList} from "../../types/list/TypesList";
import {IListItemMPSL} from "../../types/list/TypesListAPSA";
import {IListItemAPSA} from "../../types/list/TypesListAPSA";
import {ILineBadge} from "../../types/TypesGlobal";
import {ILineNumber} from "../../types/TypesGlobal";
import {IAvatar} from "../../types/TypesGlobal";
import {TypeUserField} from "../../types/TypesGlobal";
import {ILineSecondary} from "../../types/TypesGlobal";
import {ILineCaption} from "../../types/TypesGlobal";
import {ILinePrimary} from "../../types/TypesGlobal";
import {EnumIconListItemSecondary4} from "../../types/TypesIcon";
import {EnumIconListItemSecondary3} from "../../types/TypesIcon";
import {EnumIconListItemSecondary2} from "../../types/TypesIcon";
import {EnumIconListItemSecondary1} from "../../types/TypesIcon";

export type ListActionSetP = {
  version: string,
  itemId: TypeListItemId,
  primary: ILinePrimary,
  userField?: TypeUserField
};
export type ListActionSetPS = ListActionSetP & {secondary: ILineSecondary};
export type ListActionSetAPS = ListActionSetPS & {avatar: IAvatar};
export type ListActionSetAPSA = ListActionSetPS & {avatarLeft: IAvatar, avatarRight?: IAvatar};

export const sliceListAPSA =
  {
    listSetP: (state: IList, action: PayloadAction<ListActionSetP>) =>
    {
      const item = action.payload;
      state.itemsById[item.itemId] = {
        version: item.version,
        itemId: item.itemId,
        type: "p",
        primary: item.primary,
        userField: item.userField
      } as IListItem;
    },
    listSetPS: (state: IList, action: PayloadAction<ListActionSetPS>) =>
    {
      const item = action.payload;
      state.itemsById[item.itemId] = {
        version: item.version,
        itemId: item.itemId,
        type: "ps",
        primary: item.primary,
        secondary: item.secondary,
        userField: item.userField
      } as IListItem;
    },
    listSetAPS: (state: IList, action: PayloadAction<ListActionSetAPS>) =>
    {
      const item = action.payload;
      state.itemsById[item.itemId] = {
        version: item.version,
        itemId: item.itemId,
        type: "aps",
        avatarLeft: item.avatar,
        primary: item.primary,
        secondary: item.secondary,
        userField: item.userField
      } as IListItem;
    },
    listSetAPSA: (state: IList, action: PayloadAction<ListActionSetAPSA>) =>
    {
      const item = action.payload;
      state.itemsById[item.itemId] = {
        version: item.version,
        itemId: item.itemId,
        type: "apsa",
        avatarLeft: item.avatarLeft,
        avatarRight: item.avatarRight,
        primary: item.primary,
        secondary: item.secondary,
        userField: item.userField
      } as IListItem;
    },
    listSetIfExistPrimaryLine: (state: IList, action: PayloadAction<Omit<IListItemId & ILinePrimary, "caption">>) =>
    {
      const params = action.payload;
      const item = state.itemsById[params.itemId] as IListItemAPSA;
      if(item && item.primary)
      {
        const primary = item.primary;
        params.text && (primary.text = params.text);
        params.optional && (primary.optional = params.optional);
        params.color && (primary.color = params.color);
        params.variant && (primary.variant = params.variant);
        params.bold && (primary.bold = params.bold);
      }
    },
    listSetIfExistPrimaryLineCaption: (state: IList, action: PayloadAction<IListItemId & ILineCaption>) =>
    {
      const params = action.payload;
      const item = state.itemsById[params.itemId] as IListItemAPSA;
      if(!item || !item.primary)
      {
        return;
      }
      const primary = item.primary;
      let caption = primary.caption;
      if(!caption)
      {
        caption = {} as ILineCaption;
        primary.caption = caption;
      }
      caption.text = params.text;
      caption.color = params.color;
      caption.variant = params.variant;
      caption.ignoreSelection = params.ignoreSelection;
      caption.value = params.value;
      caption.type = params.type;

    },
    listSetIfExistPrimaryLineMiddle: (state: IList, action: PayloadAction<IListItemId & ILineCaption>) =>
    {
      const params = action.payload;
      const item = state.itemsById[params.itemId] as IListItemAPSA;
      if(!item || !item.primary)
      {
        return;
      }
      const primary = item.primary;
      let middle = primary.middle;
      if(!middle)
      {
        middle = {} as ILineCaption;
        primary.caption = middle;
      }
      middle.text = params.text;
      middle.color = params.color;
      middle.variant = params.variant;
      middle.ignoreSelection = params.ignoreSelection;
      middle.value = params.value;
      middle.type = params.type;

    },
    listSetIfExistSecondaryLine: (state: IList, action: PayloadAction<IListItemId & ILineSecondary>) =>
    {
      const params = action.payload;
      const item = state.itemsById[params.itemId] as IListItemAPSA;
      if(item && item.secondary)
      {
        item.secondary = {
          ...item.secondary,
          ...params
        };
      }
    },
    listSetIfExistPrimaryLineNumber: (state: IList, action: PayloadAction<IListItemId & ILineNumber>) =>
    {
      const params = action.payload;
      const item = state.itemsById[params.itemId] as IListItemAPSA;
      if(!item || !item.primary)
      {
        return;
      }

      const primary = item.primary;

      let numObj = primary.number;
      if(!numObj)
      {
        numObj = {} as ILineNumber;
        primary.number = numObj;
      }

      if(params.value || params.color || params.valueType || params.variant)
      {
        fnListSetLineNumber(params, numObj);
      }
      if(!params.value)
      {
        numObj.value = 0;
      }
    },
    listSetIfExistSecondaryLineNumber: (state: IList, action: PayloadAction<IListItemId & ILineNumber>) =>
    {
      const params = action.payload;
      const item = state.itemsById[params.itemId] as IListItemAPSA;
      if(!item || !item.secondary)
      {
        return;
      }
      const secondary = item.secondary;
      fnListSetSecondaryLineNumber(secondary, action);
    },
    listSetIfExistSecondaryLineMiddle: (state: IList, action: PayloadAction<IListItemId & ILineCaption>) =>
    {
      const params = action.payload;
      const item = state.itemsById[params.itemId] as IListItemAPSA;
      if(!item || !item.secondary)
      {
        return;
      }

      const secondary = item.secondary;
      fnListSetSecondaryLineMiddle(secondary, action);
    },
    listSetIfExistSecondaryLineCaption: (state: IList, action: PayloadAction<IListItemId & ILineCaption>) =>
    {
      const params = action.payload;
      const item = state.itemsById[params.itemId] as IListItemAPSA;
      if(!item || !item.secondary)
      {
        return;
      }
      const secondary = item.secondary;
      fnListSetSecondaryLineCaption(secondary, action);
    },
    listSetIfExistSecondaryLineBadge: (state: IList, action: PayloadAction<IListItemId & ILineBadge>) =>
    {
      const params = action.payload;
      const item = state.itemsById[params.itemId] as IListItemAPSA;
      if(!item || !item.secondary)
      {
        return;
      }
      const secondary = item.secondary;
      if(params.value || params.color)
      {
        let badge = secondary.badge;
        if(!badge)
        {
          badge = {} as ILineBadge;
          secondary.badge = badge;
        }

        if(params.value)
        {
          badge.value = params.value;
        }

        if(params.color)
        {
          badge.color = params.color;
        }
      }
      else
      {
        secondary.badge = {} as ILineBadge;
      }
    },
    listSetIfExistMultiSecondaryLineNumber: (
      state: IList,
      action: PayloadAction<IListItemId & ILineNumber & {indexes: number[]}>) =>
    {
      const params = action.payload;
      const item = state.itemsById[params.itemId] as IListItemMPSL;
      if(!item || item.type !== "mpsl" || !item?.secondaryList?.length)
      {
        return;
      }
      const secondaryList = item.secondaryList;
      action.payload.indexes.forEach(index =>
      {
        const secondary = secondaryList[index];
        fnListSetSecondaryLineNumber(secondary, action);
      });
    },
    listSetIfExistMultiSecondaryLineMiddle: (
      state: IList,
      action: PayloadAction<IListItemId & ILineCaption & {indexes: number[]}>) =>
    {
      const params = action.payload;
      const item = state.itemsById[params.itemId] as IListItemMPSL;
      if(!item || item.type !== "mpsl" || !item?.secondaryList?.length)
      {
        return;
      }
      const secondaryList = item.secondaryList;
      action.payload.indexes.forEach(index =>
      {
        const secondary = secondaryList[index];
        fnListSetSecondaryLineMiddle(secondary, action);
      });
    },
    listSetIfExistSecondaryLineIcon: (state: IList, action: PayloadAction<IListItemId & {
      icon1?: EnumIconListItemSecondary1 | null, //null means remove
      icon2?: EnumIconListItemSecondary2 | null,
      icon3?: EnumIconListItemSecondary3 | null,
      icon4?: EnumIconListItemSecondary4 | null
    }>) =>
    {
      const params = action.payload;
      const item = state.itemsById[params.itemId] as IListItemAPSA;
      if(!item || !item.secondary)
      {
        return;
      }

      if(params.icon1 || params.icon2 || params.icon3 || params.icon4)
      {
        const secondary = item.secondary;
        params.icon1 !== null && (secondary.icon1 = params.icon1);
        params.icon2 !== null && (secondary.icon2 = params.icon2);
        params.icon3 !== null && (secondary.icon3 = params.icon3);
        params.icon4 !== null && (secondary.icon4 = params.icon4);
      }

    },
    listSetIfExistMultiSecondaryLineCaption: (
      state: IList,
      action: PayloadAction<IListItemId & ILineCaption & {indexes: number[]}>) =>
    {
      const params = action.payload;
      const item = state.itemsById[params.itemId] as IListItemMPSL;
      if(!item || item.type !== "mpsl" || !item?.secondaryList?.length)
      {
        return;
      }
      const secondaryList = item.secondaryList;
      action.payload.indexes.forEach(index =>
      {
        const secondary = secondaryList[index];
        fnListSetSecondaryLineCaption(secondary, action);
      });
    },
    listSetIfExistAvatar: (state: IList, action: PayloadAction<IListItemId & IAvatar>) =>
    {
      const params = action.payload;
      const item = state.itemsById[params.itemId] as IListItemAPSA;
      if(item && item.avatarLeft)
      {
        const avatar = item.avatarLeft;
        fnListSetAvatar(params, avatar);
      }
    },
    listSetIfExistAvatarRight: (state: IList, action: PayloadAction<IListItemId & IAvatar>) =>
    {
      const params = action.payload;
      const item = state.itemsById[params.itemId] as IListItemAPSA;
      if(item && item.avatarRight)
      {
        const avatar = item.avatarRight;
        fnListSetAvatar(params, avatar);
      }
    },
    listRemoveIfExistOverwriteSecondary: (state: IList, action: PayloadAction<TypeListItemId>) =>
    {
      const item = state.itemsById[action.payload] as IListItemAPSA;
      if(item && item.secondary)
      {
        const secondary = item.secondary;
        secondary.overwriteIcon1Icon2AndText = undefined;
        secondary.overwriteIcon1Icon2AndTextColor = undefined;
        secondary.overwriteIcon1Icon2AndTextVariant = undefined;
      }
    }
  };

function fnListSetAvatar(params: IListItemId & IAvatar, avatar: IAvatar)
{
  params.icon && (avatar.icon = params.icon);
  params.text && (avatar.text = params.text);
  params.dotColor && (avatar.dotColor = params.dotColor);
  params.src && (avatar.src = params.src);
  params.bgcolor && (avatar.bgcolor = params.bgcolor);
  params.color && (avatar.color = params.color);
  params.tooltip && (avatar.tooltip = params.tooltip);
  params.iconSize && (avatar.iconSize = params.iconSize);
  params.iconFontSize && (avatar.iconFontSize = params.iconFontSize);
}

function fnListSetSecondaryLineNumber(secondary: ILineSecondary, action: PayloadAction<IListItemId & ILineNumber>)
{
  const params = action.payload;
  let numObj = secondary.number;
  if(!numObj)
  {
    numObj = {} as ILineNumber;
    secondary.number = numObj;
  }
  if(params.value || params.color || params.valueType || params.variant)
  {
    fnListSetLineNumber(params, numObj);
  }
  else if(!params.value)
  {
    numObj.value = 0;
  }
}

function fnListSetLineNumber(params: ILineNumber, lineNumber: ILineNumber)
{
  if(params.value)
  {
    lineNumber.value = params.value;
  }

  if(params.valueType)
  {
    lineNumber.valueType = params.valueType;
  }

  if(params.color)
  {
    lineNumber.color = params.color;
  }

  if(params.variant)
  {
    lineNumber.variant = params.variant;
  }
}

function fnListSetSecondaryLineCaption(secondary: ILineSecondary, action: PayloadAction<IListItemId & ILineCaption>)
{
  const params = action.payload;

  if(params.text || params.color || params.variant || params.value !== undefined)
  {
    let caption = secondary.caption;
    if(!caption)
    {
      caption = {} as ILineCaption;
      secondary.caption = caption;
    }

    if(params.text)
    {
      caption.text = params.text;
    }

    if(params.type === "counter" && params.value !== undefined)
    {
      caption.value = params.value;
    }
    else if(params.value === 0)
    {
      delete caption.value;
    }

    if(params.color)
    {
      caption.color = params.color;
    }

    if(params.variant)
    {
      caption.variant = params.variant;
    }
  }
}

function fnListSetSecondaryLineMiddle(secondary: ILineSecondary, action: PayloadAction<IListItemId & ILineCaption>)
{
  const params = action.payload;

  if(params.text || params.color || params.variant || params.value !== undefined)
  {
    let middle = secondary.middle;
    if(!middle)
    {
      middle = {} as ILineCaption;
      secondary.middle = middle;
    }

    if(params.text)
    {
      middle.text = params.text;
    }

    if(params.type === "counter" && params.value !== undefined)
    {
      middle.value = params.value;
    }
    else if(params.value === 0)
    {
      delete middle.value;
    }

    if(params.color)
    {
      middle.color = params.color;
    }

    if(params.variant)
    {
      middle.variant = params.variant;
    }
  }
}


