import _ from "lodash";
import {MsgOtpVerify} from "../../../api/core/otp/msg/MsgOtpVerify";
import {RpcOtp} from "../../../api/core/otp/RpcOtp";
import {SigVerifyKey} from "../../../api/core/otp/sig/SigVerifyKey";
import {MsgEntVariableUpdate} from "../../../api/ent/entDrawer/msg/MsgEntVariableUpdate";
import {RpcEntDrawer} from "../../../api/ent/entDrawer/RpcEntDrawer";
import {SigEntAvatarUser} from "../../../api/ent/entDrawer/sig/SigEntAvatarUser";
import {MsgCallerHandleChange} from "../../../api/home/drawer/msg/MsgCallerHandleChange";
import {MsgCallerPasswordReset} from "../../../api/home/drawer/msg/MsgCallerPasswordReset";
import {MsgCallerProfilePatch} from "../../../api/home/drawer/msg/MsgCallerProfilePatch";
import {RpcDrawer} from "../../../api/home/drawer/RpcDrawer";
import {SigCallerDeviceList} from "../../../api/home/drawer/sig/SigCallerDeviceList";
import {SigGroupAvatar} from "../../../api/home/drawer/sig/SigGroupAvatar";
import {SigUserAvatar} from "../../../api/home/drawer/sig/SigUserAvatar";
import {WsocDrawer} from "../../../api/home/drawer/WsocDrawer";
import {EntId} from "../../../api/meta/base/Types";
import {DeviceId} from "../../../api/meta/base/Types";
import {ISigAcceptor} from "../../../api/nucleus/base/ISigAcceptor";
import {SigCallback} from "../../../api/nucleus/base/sig/SigCallback";
import {SigDone} from "../../../api/nucleus/base/sig/SigDone";
import ISrvc from "../../../base/ISrvc";
import {STR_YOU} from "../../../base/plus/ConstantsPlus";
import {formatCaptionDateTime} from "../../../base/plus/DatePlus";
import {dispatchList} from "../../../base/plus/ListPlus";
import {listRecursiveCalls} from "../../../base/plus/ListPlus";
import {getImageThumbnail} from "../../../base/plus/MediaPlus";
import {getLabel} from "../../../base/plus/StringPlus";
import {hasValue} from "../../../base/plus/StringPlus";
import {listResetItem} from "../../../base/slices/list/SliceListSharedActions";
import {listSetItem} from "../../../base/slices/list/SliceListSharedActions";
import {listRefresh} from "../../../base/slices/list/SliceListSharedActions";
import {IListData} from "../../../base/types/list/TypesList";
import {IListBinder} from "../../../base/types/list/TypesList";
import {TypeListItemId} from "../../../base/types/list/TypesList";
import {IListItemsById} from "../../../base/types/list/TypesList";
import {IListItemAPSA} from "../../../base/types/list/TypesListAPSA";
import {toComboIdDto} from "../../../base/types/TypesComboId";
import {TypeComboId} from "../../../base/types/TypesComboId";
import {IAvatar} from "../../../base/types/TypesGlobal";
import {ILineSecondary} from "../../../base/types/TypesGlobal";
import {ILinePrimary} from "../../../base/types/TypesGlobal";
import {store} from "../../../Store";
import {RootState} from "../../../Store";
import {Srvc} from "../../Srvc";

type TypeSigRecentAvatar = SigGroupAvatar | SigUserAvatar | undefined;
export default class SrvcHomeDrawerSettings extends ISrvc
{
  //region rpc

  rpcCallerDeviceListGet()
  {
    RpcDrawer.callerDeviceListGet(envSig => listRecursiveCalls<SigCallerDeviceList>(
      envSig,
      state => state.home.drawer.homeDeviceList,
      this.rpcCallerDeviceListGet,
      this.doLoad
    ));
  }

  rpcCallerDeviceRemove(deviceId: DeviceId)
  {
    RpcDrawer.callerDeviceRemove({deviceId: deviceId}, Srvc.app.toast.showErrorToast);
  }

  rpcCallerHandleChange(msg: MsgCallerHandleChange, sigAcceptor: ISigAcceptor<SigVerifyKey>)
  {
    RpcDrawer.callerHandleChange(msg, sigAcceptor);
  }

  rpcCallerPasswordReset(msg: MsgCallerPasswordReset, sigAcceptor: ISigAcceptor<SigDone>)
  {
    RpcDrawer.callerPasswordReset(msg, sigAcceptor);
  }

  rpcCallerAccountRemove(sigAcceptor: ISigAcceptor<SigDone>)
  {
    RpcDrawer.callerAccountRemove(sigAcceptor);
  }

  rpcOtpVerify(msg: MsgOtpVerify, sigAcceptor: ISigAcceptor<SigCallback>)
  {
    RpcOtp.otpVerify(msg, sigAcceptor);
  }

  rpcEntCallerVariableUpdate(entId: EntId, msg: MsgEntVariableUpdate, sigAcceptor: ISigAcceptor<SigDone>)
  {
    RpcEntDrawer.entCallerVariableUpdate(entId, msg, sigAcceptor);
  }

  rpcCallerChatSetting(msg: MsgCallerProfilePatch, sigAcceptor: ISigAcceptor<SigCallback>)
  {
    RpcDrawer.callerProfilePatch(this.getUpdatedMsgCallerProfilePatch(msg), sigAcceptor);
  }

  wsocCallerChatSetting(msg: MsgCallerProfilePatch, sigAcceptor: ISigAcceptor<SigCallback>)
  {
    WsocDrawer.callerProfilePatch(this.getUpdatedMsgCallerProfilePatch(msg), sigAcceptor);
  }

  //endregion

  getListBinder()
  {
    return {
      selectSourceItem1: this.selectAvatar.bind(this),
      onBindSourceItem1: this.onBindAvatar.bind(this)
    } as IListBinder<TypeSigRecentAvatar>;
  }

  doLoadEntUserSetting(listName: string, entIdAdminSet: EntId[])
  {
    const listData = {
      itemIds: [..._.intersection(entIdAdminSet)],
      itemsById: {}
    } as IListData;
    dispatchList(listName, listRefresh(listData));
  }

  //region private

  private selectCaller(state: RootState)
  {
    return state.cache.app.caller;
  }

  private getUpdatedMsgCallerProfilePatch(msg: MsgCallerProfilePatch)
  {
    let newMsg = {} as MsgCallerProfilePatch;

    store.dispatch((_, getState) =>
    {
      const rootState = getState();
      const caller = this.selectCaller(rootState);
      const callerFirstName = caller.callerFirstName;
      const callerLastName = caller.callerLastName;
      const callerMediaIdAvatar = caller.callerMediaIdAvatar;
      const callerAbout = caller.callerAbout;
      const enterIsSendMobile = caller.enterIsSendMobile;
      const enterIsSendDesktop = caller.enterIsSendDesktop;
      const callerLanguage = caller.callerLanguage;
      const globalNotificationSetting = caller.globalNotificationSetting;

      newMsg = {
        firstName: callerFirstName,
        lastName: callerLastName,
        about: callerAbout,
        mediaIdAvatar: callerMediaIdAvatar,
        enterIsSendDesktop: enterIsSendDesktop,
        enterIsSendMobile: enterIsSendMobile,
        globalNotificationSetting: globalNotificationSetting,
        languageKey: callerLanguage
      } as MsgCallerProfilePatch;

      if(msg.firstName)
      {
        newMsg.firstName = msg.firstName;
      }
      if(msg.lastName)
      {
        newMsg.lastName = msg.lastName;
      }
      if(msg.about)
      {
        newMsg.about = msg.about;
      }
      if(msg.mediaIdAvatar)
      {
        newMsg.mediaIdAvatar = msg.mediaIdAvatar;
      }
      if(msg.enterIsSendDesktop !== undefined)
      {
        newMsg.enterIsSendDesktop = msg.enterIsSendDesktop;
      }
      if(msg.enterIsSendMobile !== undefined)
      {
        newMsg.enterIsSendMobile = msg.enterIsSendMobile;
      }
      if(msg.globalNotificationSetting)
      {
        newMsg.globalNotificationSetting = msg.globalNotificationSetting;
      }
      if(msg.languageKey)
      {
        newMsg.languageKey = msg.languageKey;
      }
    });

    return newMsg;
  }

  private doLoad(listName: string, sig: SigCallerDeviceList)
  {
    const uiItemIds: TypeListItemId[] = [];
    const uiItemsById = {} as IListItemsById;

    sig.deviceList.forEach((device, index) =>
    {
      const itemId = device.deviceId;
      uiItemIds.push(itemId);

      const lastActive = formatCaptionDateTime(new Date(device?.lastOnlineTime ?? device?.creationTime), true);
      const isOnline = index === 0 || device?.isOnline; // if it's you then isOnline is true or device?.isOnline

      uiItemsById[itemId] = {
        type: "ps",
        primary: {
          text: device.deviceName,
          caption: {
            text: index === 0 && STR_YOU,
            color: "textSecondary",
            ignoreSelection: true
          }
        } as ILinePrimary,
        secondary: {
          text: isOnline
            ? "Online"
            : `Last active: ${lastActive}`,
          color: isOnline && "successLight"
        } as ILineSecondary
      } as IListItemAPSA;
    });

    dispatchList(listName, listRefresh({
      itemsById: uiItemsById,
      itemIds: uiItemIds
    }));
  }

  private selectAvatar(state: RootState, itemId: EntId): SigEntAvatarUser
  {
    return state.cache.app.caller.entIdUserAvatarMap[itemId as EntId];
  }

  private onBindAvatar(listName: string, entChatId: TypeComboId, avatar?: TypeSigRecentAvatar): void
  {
    if(avatar)
    {
      const rootState = store.getState();
      const sig = avatar as SigGroupAvatar;

      const dtoComboId = toComboIdDto(entChatId);
      const entId = dtoComboId.entId;
      const entAvatar = rootState.cache.app.caller.entIdUserAvatarMap[entId];

      const groupAvtar = avatar as SigGroupAvatar;
      const primaryText = getLabel(groupAvtar) as string;
      const secondaryText = (hasValue(groupAvtar.about) ? groupAvtar.about : groupAvtar.about) as string;

      const newItem = {
        version: sig.version,
        type: "aps",
        avatarLeft: this.getAvatarLeft(entAvatar),
        primary: {
          text: primaryText
        },
        secondary: {
          text: secondaryText
        },
        hideMenu: true
      } as IListItemAPSA;

      dispatchList(listName, listSetItem({
        itemId: entChatId,
        newItem: newItem
      }));
    }
    else
    {
      dispatchList(listName, listResetItem(entChatId));
    }
  }

  private getAvatarLeft(entAvatar: SigEntAvatarUser)
  {
    return {
      src: getImageThumbnail(entAvatar?.avatarId),
      icon: "enterprise"
    } as IAvatar;
  }
}
