import {QrCodeScanner} from "@mui/icons-material";
import {FormHelperText} from "@mui/material";
import {TextField} from "@mui/material";
import {Typography} from "@mui/material";
import {useState} from "react";
import React from "react";
import {FieldError} from "react-hook-form";
import {Controller} from "react-hook-form";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import {DefnFieldEditable} from "../../../../api/meta/base/dto/DefnFieldEditable";
import {DefnFieldScanCode} from "../../../../api/meta/base/dto/DefnFieldScanCode";
import {FieldValueScanCode} from "../../../../api/meta/base/dto/FieldValueScanCode";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {px} from "../../../../base/plus/StringPlus";
import {gapHalf} from "../../../../base/plus/ThemePlus";
import DialogAtom from "../../../atom/dialog/DialogAtom";
import {IDialogAtomRef} from "../../../atom/dialog/DialogAtom";
import LayoutFlexCol from "../../../atom/layout/LayoutFlexCol";
import LayoutFlexRow from "../../../atom/layout/LayoutFlexRow";
import RawIconButton from "../../../atom/raw/RawIconButton";
import RawIconStrip from "../../../atom/raw/RawIconStrip";
import {useAppCtx} from "../../../ctx/CtxApp";
import {usePageCtx} from "../../../ctx/CtxPage";
import {useFieldPropertiesResolver} from "../../base/FormHooks";
import {useFormCtx} from "../base/CtxForm";
import {getCompLabel} from "../base/FormViewerPlus";
import FieldRawRefButton from "../raw/FieldRawRefButton";
import FieldRawTemplate from "../raw/FieldRawTemplate";
import RawCaptureExtraProperties from "../raw/RawCaptureExtraProperties";

export default function FieldScanCode(props: {
  defn: DefnFieldScanCode
})
{
  const formCtx = useFormCtx();
  const defnTheme = formCtx.getDefnTheme();
  const defn = props.defn;

  const {
    getFieldPlaceHolder,
    getFieldHelperText,
    getFieldRequired,
    getFieldIcon
  } = useFieldPropertiesResolver(defn);

  const required = getFieldRequired();
  const helperText = getFieldHelperText();
  const placeHolder = getFieldPlaceHolder();
  const icon = getFieldIcon();

  const fieldId = getFieldKey(defn);
  const label = getCompLabel(defn);
  const readOnly = formCtx.isFieldReadonly(defn);
  const disabled = formCtx.isFieldDisable(defn as DefnFieldEditable);
  const pageCtx = usePageCtx();
  const cbDialogRef = {} as IDialogAtomRef;
  const [isDialogClose, setIsDialogClose] = useState(false);
  const isQrCode = defn.qrCode;
  const isBarCode = defn.barCode;
  const showCapturedAsHelperText = defn.showCapturedValuesOnAside;
  const appCtx = useAppCtx();
  const isMobile = appCtx.isMobile();

  return (
    <Controller
      name={fieldId}
      control={formCtx.control()}
      render={({
        field,
        fieldState
      }) =>
      {
        const {
          isTouched,
          error
        } = fieldState;

        const isError = isTouched || Boolean(error);
        const fieldValue = field.value as FieldValueScanCode | undefined;

        const onChange = (value: FieldValueScanCode | undefined) =>
        {
          if(value)
          {
            field.onChange(value);
          }
          else
          {
            field.onChange(null);
          }
        };

        return (
          <FieldRawTemplate
            defn={defn}
            fieldValue={fieldValue}
          >
            <RawScanner
              defn={defn}
              error={error}
              fieldValue={fieldValue}
              onChange={onChange}
              isMobile={isMobile}
            />
            {(isError || helperText) &&
              <FormHelperText
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  flexGrow: 1
                }}
              >
                {error?.message ? error.message : helperText}
              </FormHelperText>
            }

            {
              <RawCaptureExtraProperties
                captureTime={fieldValue?.captureTime}
                captureUser={fieldValue?.captureUser}
                captureLocation={fieldValue?.captureLocation}
                showCapturedAsHelperText={showCapturedAsHelperText}
              />
            }

          </FieldRawTemplate>
        );

      }}
    />
  );

  function RawScanner(props: {
    error?: FieldError | undefined,
    defn: DefnFieldScanCode,
    fieldValue?: FieldValueScanCode,
    onChange: (value?: FieldValueScanCode) => void,
    isMobile?: boolean
  })
  {
    const {
      error,
      defn,
      fieldValue,
      onChange
    } = props;

    const fieldBorderColor = formCtx.getFieldBorderColor;
    const borderColor = fieldBorderColor && fieldBorderColor(fieldId);
    const getOnClick = formCtx.getOnClick();

    const onClick = () =>
    {
      !readOnly && pageCtx.showDialog(
        <DialogAtom
          cbRef={cbDialogRef}
          title={label}
          onClose={() =>
          {
            pageCtx.showDialog(undefined);
            setIsDialogClose(true);
          }}
          contentHeight={440}
          contentWidth={540}
          content={
            <LayoutFlexCol
              width={"100%"}
              flexGrow={1}
            >
              <Scanner
                onChange={onChange}
                isMobile={isMobile}
              />
            </LayoutFlexCol>
          }
        />
      );
      getOnClick && getOnClick(fieldId, "field");
    };
    return (
      <LayoutFlexRow
        overflowX={"visible"}
        overflowY={"visible"}
        width={"100%"}
      >
        <TextField
          sx={{
            ...borderColor && {
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: borderColor
                }
              }
            }
          }}
          fullWidth
          type={"text"}
          aria-readonly={true}
          size={defnTheme.fieldSize}
          margin={defnTheme.fieldMargin}
          variant={defnTheme.fieldVariant}
          error={Boolean(error?.message)}
          onClick={onClick}
          autoComplete={defn.autoFill ? "on" : "off"}
          autoFocus={defn.autoFocus}
          disabled={disabled}
          hiddenLabel={defn.hideLabel}
          label={!defn.hideLabel ? label : undefined}
          name={fieldId}
          placeholder={placeHolder}
          InputProps={{
            readOnly: readOnly,
            endAdornment: icon ? <RawIconButton
              name={icon}
              icon={icon}
            /> : <QrCodeScanner color={"action"} />
          }}
          required={required}
          value={fieldValue?.scanCode ?? ""}
          onKeyDown={(event) =>
          {
            if(event.key === "Enter")
            {
              onClick();
            }
          }}
        />
        {!(readOnly || disabled) &&
          <LayoutFlexRow
            ml={px(gapHalf)}
            mr={`-${gapHalf}px`}
          >
            <RawIconStrip
              iconStrip={["delete"]}
              onClick={(icon) =>
              {
                if(icon === "delete")
                {
                  onChange(undefined);
                }
              }}
              iconStripDisable={(!fieldValue || readOnly || disabled) ? ["delete"] : []}
            />
          </LayoutFlexRow>
        }
        <FieldRawRefButton
          defn={defn}
        />
      </LayoutFlexRow>
    );
  }

  function Scanner(props: {
    onChange: (value?: FieldValueScanCode) => void,
    isMobile?: boolean
  })
  {
    const [displayMessage, setDisplayMessage] = useState<string>("");

    const onChange = props.onChange;
    const scanCodeType = isQrCode ? "qrCode" : "barCode";
    const isMobile = props.isMobile;

    return (
      <>
        {/* @ts-expect-error Server Component */}
        <BarcodeScannerComponent
          torch={isMobile}
          delay={30}
          height={405}
          width={640}
          stopStream={isDialogClose}
          onUpdate={(err, result) =>
          {
            if(result)
            {
              if((!isQrCode && !isBarCode) || (isBarCode && isQrCode))
              {
                if(result.getText())
                {
                  onChange({
                    scanCode: result.getText(),
                    scanCodeType: scanCodeType
                  });
                  pageCtx.showDialog(undefined);
                }
                else
                {
                  onChange(undefined);
                }
              }
              else if(isQrCode)
              {
                if(result.getResultMetadata() !== null)
                {
                  onChange({
                    scanCode: result.getText(),
                    scanCodeType: scanCodeType
                  });
                  pageCtx.showDialog(undefined);
                }
                else
                {
                  onChange(undefined);
                  setDisplayMessage("please scan qr code");
                }
              }
              else if(isBarCode)
              {
                if(result.getResultMetadata() === null)
                {
                  onChange({
                    scanCode: result.getText(),
                    scanCodeType: scanCodeType
                  });
                  pageCtx.showDialog(undefined);
                }
                else
                {
                  onChange(undefined);
                  setDisplayMessage("please scan bar code");
                }
              }
            }
          }}
        />
        <Typography
          variant={"caption"}
          color={"red"}
        >
          {displayMessage}
        </Typography>

      </>
    );
  }

}
