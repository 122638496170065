import * as MuiIcons from "@mui/icons-material";
import {GridOnOutlined} from "@mui/icons-material";
import React from "react";
import {EnumDefnThemeColorShade} from "../../../api/meta/base/Types";
import {stripIconSx} from "../../../base/plus/ThemePlus";
import theme from "../../../base/plus/ThemePlus";
import {TypeTextColor} from "../../../base/types/TypesGlobal";

export default function RawIcon(props: {
  icon: string,
  disabled?: boolean,
  color?: TypeTextColor,
  shade?: EnumDefnThemeColorShade,
  width?: string,
})
{
  const iconName = props.icon;
  const disabled = props.disabled;
  const color = props.color;
  const width = props.width;
  const shade = props.shade;

  // @ts-ignore
  const ActionIcon = iconName ? MuiIcons[iconName] : null;

  const sx = stripIconSx(disabled ? theme.common.fgcolorIconDisabled : color);
  const filteredSx = {
    ...sx,
    ...width && {
      width: width
    },
    ...(color && !disabled) && {
      color: theme.common.colorWithShade(color, shade)
    }
  };

  return (
    ActionIcon
      ? <ActionIcon
        sx={filteredSx}
      />
      : <GridOnOutlined
        sx={filteredSx}
      />
  );
}

export function getRawIcon(iconName: string)
{
  // @ts-ignore
  return MuiIcons[iconName];

}
