import {useEffect} from "react";
import {useRef} from "react";
import {FieldValues} from "react-hook-form/dist/types/fields";
import {DefnComp} from "../../../api/meta/base/dto/DefnComp";
import {DefnDtoColor} from "../../../api/meta/base/dto/DefnDtoColor";
import {DefnField} from "../../../api/meta/base/dto/DefnField";
import {DefnFieldDivider} from "../../../api/meta/base/dto/DefnFieldDivider";
import {DefnFieldLabel} from "../../../api/meta/base/dto/DefnFieldLabel";
import {DefnFieldText} from "../../../api/meta/base/dto/DefnFieldText";
import {DefnSection} from "../../../api/meta/base/dto/DefnSection";
import {DefnStudioBuildColor} from "../../../api/meta/base/dto/DefnStudioBuildColor";
import {DefnStudioPickVarId} from "../../../api/meta/base/dto/DefnStudioPickVarId";
import {DefnTab} from "../../../api/meta/base/dto/DefnTab";
import {StudioDtoSwimlane} from "../../../api/meta/base/dto/StudioDtoSwimlane";
import {StudioMapOfChartXAxis} from "../../../api/meta/base/dto/StudioMapOfChartXAxis";
import {StudioMapOfChartYAxis} from "../../../api/meta/base/dto/StudioMapOfChartYAxis";
import {StudioMapOfSwimlane} from "../../../api/meta/base/dto/StudioMapOfSwimlane";
import {EnumStudioVarKind} from "../../../api/meta/base/Types";
import {EnumDefnKindAutomationStep} from "../../../api/meta/base/Types";
import {EnumDefnCompType} from "../../../api/meta/base/Types";
import {MetaIdOption} from "../../../api/meta/base/Types";
import {MetaIdSwimlane} from "../../../api/meta/base/Types";
import {MetaIdField} from "../../../api/meta/base/Types";
import {fnRawValueToFieldValue} from "../../../base/plus/FieldValuePlus";
import {fnFieldValueToRawValue} from "../../../base/plus/FieldValuePlus";
import {hasValues} from "../../../base/plus/JsPlus";
import {px} from "../../../base/plus/StringPlus";
import {gapStd} from "../../../base/plus/ThemePlus";
import {IListItemAPSA} from "../../../base/types/list/TypesListAPSA";
import {DefnFormUi} from "../../../base/types/TypesForm";
import {IFormFieldError} from "../../../base/types/TypesForm";
import {IFormRef} from "../../../base/types/TypesForm";
import {fieldSwimlaneNote} from "./TypesLayoutBuilder";

export function getSwimlaneCompMap(
  swimlaneMap?: StudioMapOfSwimlane | StudioMapOfChartYAxis | StudioMapOfChartXAxis,
  excludeNote?: boolean
)
{
  const compMap = {} as Record<MetaIdField, DefnField>;
  const swimlaneSectionKeys = swimlaneMap?.keys?.map(swimlaneId => getSwimlaneSectionKey(swimlaneId)) ?? [];

  if(swimlaneMap && swimlaneMap.keys?.length)
  {
    swimlaneMap.keys.forEach((swimlaneId, index) =>
    {
      const option = swimlaneMap.map[swimlaneId];
      const optionId = option.metaId;
      const swimlaneSectionKey = getSwimlaneSectionKey(optionId);
      const swimlaneValueKey = getSwimlaneValueKey(optionId);
      const swimlaneLabelKey = getSwimlaneLabelKey(optionId);
      const swimlaneColorVarKey = getSwimlaneColorKeyVar(optionId);
      const swimlaneColorKey = getSwimlaneColorKey(optionId);

      compMap[swimlaneValueKey] = {
        type: "label",
        metaId: swimlaneValueKey,
        name: swimlaneValueKey,
        label: `${option.label}`
      } as DefnFieldLabel;

      compMap[swimlaneLabelKey] = {
        type: "text",
        metaId: swimlaneLabelKey,
        name: swimlaneLabelKey,
        label: "Label"
      } as DefnFieldText;

      compMap[fieldSeparator] = {
        type: "divider",
        metaId: fieldSeparator,
        name: fieldSeparator,
        dividerKind: "thin"
      } as DefnFieldDivider;

      compMap[swimlaneColorKey] = {
        type: "studioBuildColor",
        metaId: swimlaneColorKey,
        name: swimlaneColorKey,
        label: "Color",
        allowShades: true,
        direction: "horizontal"
      } as DefnStudioBuildColor;

      compMap[fieldLabelOR] = {
        type: "label",
        metaId: fieldLabelOR,
        name: fieldLabelOR,
        label: "OR",
        justifyText: "center",
        disabled: true,
        italic: true
      } as DefnFieldLabel;

      compMap[swimlaneColorVarKey] = {
        type: "pickVarId",
        metaId: swimlaneColorVarKey,
        name: swimlaneColorVarKey,
        label: "Color variable",
        varKind: "color"
      } as DefnStudioPickVarId;

      compMap[swimlaneSectionKey] = {
        type: "section",
        metaId: swimlaneSectionKey,
        name: swimlaneSectionKey,
        sectionDirection: "horizontal",
        pb: swimlaneMap && swimlaneMap.keys?.length
          ? index === swimlaneMap.keys.length - 1
            ? px(gapStd)
            : undefined
          : undefined,
        pl: gapStd,
        pr: gapStd,
        fieldIdSet: [
          swimlaneValueKey,
          swimlaneLabelKey,
          fieldSeparator,
          swimlaneColorKey,
          fieldLabelOR,
          swimlaneColorVarKey
        ],
        fieldSpanMap: {
          [swimlaneValueKey]: 1,
          [swimlaneLabelKey]: 1,
          [fieldSeparator]: 0,
          [swimlaneColorKey]: 2,
          [fieldLabelOR]: 0.2,
          [swimlaneColorVarKey]: 1
        }
      } as DefnSection;
    });

    if(!excludeNote)
    {
      compMap[fieldSwimlaneNote] = {
        type: "label",
        metaId: fieldSwimlaneNote,
        name: fieldSwimlaneNote,
        label: "Note: The default color will be variable SetOfText's color.",
        disabled: true
      } as DefnFieldLabel;

      swimlaneSectionKeys.push(fieldSwimlaneNote);
    }
  }

  return {
    swimlaneSectionKeys,
    compMap
  };
}

const getSwimlaneSectionKey = (optionId: MetaIdOption) => `swimlane_${optionId}`;
const getSwimlaneValueKey = (optionId: MetaIdOption) => `swimlaneValue_${optionId}`;
export const getSwimlaneColorKeyVar = (optionId: MetaIdOption) => `swimlaneColorVar_${optionId}`;
export const getSwimlaneColorKey = (optionId: MetaIdOption) => `swimlaneColor_${optionId}`;
export const getSwimlaneLabelKey = (optionId: MetaIdOption) => `swimlaneLabel_${optionId}`;

const fieldSeparator = "fieldSeparator";
const fieldLabelOR = "fieldLabelOR";

export function getSwimLaneMapValueToDto(
  values: FieldValues,
  swimlane?: StudioMapOfSwimlane
): StudioMapOfSwimlane
{
  const swimlaneMap = {
    keys: [],
    map: {}
  } as StudioMapOfSwimlane;

  if(swimlane && swimlane.keys?.length)
  {
    const swimlaneKeys = swimlane.keys;
    const _swimlaneMap = {} as Record<MetaIdSwimlane, StudioDtoSwimlane>;

    Object.values(swimlane.map).forEach(option =>
    {
      const optionId = option.metaId;
      const swimLaneColorVarKey = getSwimlaneColorKeyVar(optionId);
      const swimLaneColorKey = getSwimlaneColorKey(optionId);
      const swimLaneLabelKey = getSwimlaneLabelKey(optionId);

      const optionLabelValue = fnFieldValueToRawValue("text", values[swimLaneLabelKey]);
      const colorVarId = fnFieldValueToRawValue("pickVarId", values[swimLaneColorVarKey]);
      const color = fnFieldValueToRawValue("studioBuildColor", values[swimLaneColorKey]) as DefnDtoColor;

      _swimlaneMap[optionId] = {
        label: optionLabelValue,
        colorVarId: colorVarId,
        color: hasValues(color) ? color : undefined,
        metaId: optionId,
        valueOptionId: option.valueOptionId
      } as StudioDtoSwimlane;

      swimlaneMap.keys = swimlaneKeys;
      swimlaneMap.map = _swimlaneMap;
    });
  }

  return swimlaneMap;

}

export function getSwimLaneMapDtoToValue(
  swimlaneMap?: StudioMapOfSwimlane
): FieldValues
{
  const filteredSwimlaneMap = {} as FieldValues;

  if(swimlaneMap && swimlaneMap.keys?.length)
  {
    Object.values(swimlaneMap.keys).forEach(swimlaneId =>
    {
      const swimlane = swimlaneMap?.map[swimlaneId];

      if(swimlane)
      {
        const colorVarKey = getSwimlaneColorKeyVar(swimlaneId);
        const colorKey = getSwimlaneColorKey(swimlaneId);
        const labelValueKey = getSwimlaneLabelKey(swimlaneId);

        filteredSwimlaneMap[colorVarKey] = fnRawValueToFieldValue("pickVarId", swimlane.colorVarId);
        filteredSwimlaneMap[colorKey] = fnRawValueToFieldValue("studioBuildColor", swimlane.color);
        filteredSwimlaneMap[labelValueKey] = fnRawValueToFieldValue("text", swimlane.label);
      }
    });
  }

  return filteredSwimlaneMap;
}

export function useDialogFormValidationError(props: {
  cbFormRef: IFormRef,
  validationError?: IFormFieldError[]
})
{
  const validationError = props.validationError;
  const cbRef = props.cbFormRef;
  const timeOutId = useRef<NodeJS.Timeout | undefined>();

  useEffect(() =>
  {
    if(validationError)
    {
      timeOutId.current = setTimeout(() => cbRef.setErrors && cbRef.setErrors(validationError));
    }
    return () =>
    {
      if(timeOutId.current)
      {
        clearTimeout(timeOutId.current);
      }
    };
  }, [cbRef, validationError]);
}

export function dialogGetFieldValueMap(form?: DefnFormUi, defaultValue?: any)
{
  const compMap = form?.compMap;
  const resetKeys = {} as Record<string, any>;
  const displaySectionKey = form?.displayCompositeId;
  if(compMap)
  {
    if(displaySectionKey && compMap[displaySectionKey] as DefnComp)
    {
      const tabField = compMap[displaySectionKey] as DefnComp;
      const tabFieldMap = (tabField as DefnTab)?.tabIdSet;
      if(tabFieldMap)
      {
        Object.values(tabFieldMap).forEach((field) =>
        {
          const type = compMap[field]?.type;
          if(type !== "tab" && type !== "section")
          {
            resetKeys[field] = defaultValue;
          }
        });
      }
    }
  }
  return resetKeys;
}

type TypeDialogKindType =
  | EnumDefnCompType
  | EnumStudioVarKind
  | EnumDefnKindAutomationStep

export function convertEnumArrToDialogListArr<T = TypeDialogKindType>(
  enumArr: T[],
  getDescription?: (kind: T) => string | undefined): IListItemAPSA[]
{
  return enumArr.reduce((prev, curr) =>
  {
    return [
      ...prev,
      {
        primary: {
          text: curr
        },
        secondary: {
          text: getDescription && getDescription(curr)
        }
      }
    ] as IListItemAPSA[];

  }, [] as IListItemAPSA[]);
}


