import {DtoTopic} from "../../../api/core/base/dto/DtoTopic";
import {SigTopic} from "../../../api/core/session/sig/SigTopic";
import {ENT_ID_GLOBAL} from "../../../api/meta/base/ApiPlus";
import {StoreItemId} from "../../../api/meta/base/Types";
import {PluginBundleId} from "../../../api/meta/base/Types";
import {EntId} from "../../../api/meta/base/Types";
import ISrvc from "../../../base/ISrvc";
import {Delta} from "../../../base/plus/JsPlus";
import {toComboIdDto} from "../../../base/types/TypesComboId";
import {TypeSubscriberId} from "../../../base/types/TypesGlobal";
import {removeEntAvatarUser} from "../../../cache/app/caller/SliceCacheCaller";
import {removeStoreItemAvatarAdmin} from "../../../cache/app/caller/SliceCacheCaller";
import {removePluginAvatarAdmin} from "../../../cache/app/caller/SliceCacheCaller";
import {removeEntAvatarAdmin} from "../../../cache/app/caller/SliceCacheCaller";
import {removeCallerEnt} from "../../../cache/app/callerEnt/SliceCacheCallerEnt";
import {store} from "../../../Store";
import {Srvc} from "../../Srvc";

export default class SrvcPubSubCaller extends ISrvc
{
  private readonly subscriberId = "SrvcPubSubCaller";

  subCallerEnt(entId: EntId, unsubscribe?: boolean): void
  {
    this.callerEnt(this.subscriberId, entId, unsubscribe);
  }

  bootPubSub(
    subscriberId: TypeSubscriberId,
    firstBoot: boolean,
    groupDelta?: Delta,
    entIdUserSetDelta?: Delta,
    cbSuccess?: () => void): void
  {
    const rootState = store.getState();
    const callerId = rootState.cache.app.caller.callerUserId;
    const subscribeTopicList = [] as DtoTopic[];
    const unSubscribeTopicList = [] as DtoTopic[];
    if(groupDelta)
    {
      groupDelta.inserts.forEach(entGroupId =>
      {
        const split = toComboIdDto(entGroupId);
        subscribeTopicList.push(Srvc.app.pubsub.group.getGroupAvatarTopic(split.entId, split.chatId));
      });

      groupDelta.deletes.forEach(entGroupId =>
      {
        const split = toComboIdDto(entGroupId);
        unSubscribeTopicList.push(Srvc.app.pubsub.group.getGroupAvatarTopic(split.entId, split.chatId));
      });
    }
    if(entIdUserSetDelta)
    {
      const dtoTopic = {
        artifactId: ENT_ID_GLOBAL,
        aboutId: callerId,
        type: "callerEnt"
      } as DtoTopic;
      entIdUserSetDelta.inserts.forEach((entId, index) =>
      {
        if(index <= 2)
        {
          subscribeTopicList.push({
            ...dtoTopic,
            artifactId: entId
          });
        }
        subscribeTopicList.push(Srvc.app.pubsub.caller.getEntAvatarUserTopic(entId));
      });
      entIdUserSetDelta.deletes.forEach(entId =>
      {
        entIdUserSetDelta.deletes.forEach(entId => unSubscribeTopicList.push({
          ...dtoTopic,
          artifactId: entId
        }));
        subscribeTopicList.push(Srvc.app.pubsub.caller.getEntAvatarUserTopic(entId));
      });
    }

    const dtoTopicRecentList = {
      aboutId: callerId,
      artifactId: ENT_ID_GLOBAL,
      type: "callerRecentList"
    } as DtoTopic;
    const dtoTopicBadgeMap = {
      aboutId: callerId,
      artifactId: ENT_ID_GLOBAL,
      type: "callerBadgeMap"
    } as DtoTopic;
    const dtoTopicCaller = {
      aboutId: callerId,
      artifactId: ENT_ID_GLOBAL,
      type: "caller"
    } as DtoTopic;
    const dtoTopicStarMessageList = {
      aboutId: callerId,
      artifactId: ENT_ID_GLOBAL,
      type: "callerStarMessageList"
    } as DtoTopic;

    subscribeTopicList.push(dtoTopicStarMessageList);
    subscribeTopicList.push(dtoTopicRecentList);
    subscribeTopicList.push(dtoTopicBadgeMap);
    subscribeTopicList.push(dtoTopicCaller);

    if(subscribeTopicList.length)
    {
      Srvc.app.session.subscribeBulk(subscriberId, subscribeTopicList);
      subscribeTopicList.forEach(dtoTopic =>
      {
        if(dtoTopic.type === "groupAvatar")
        {
          Srvc.cache.app.group.wsocGroupAvatarGet(dtoTopic.artifactId, dtoTopic.aboutId);
        }
        else
        {
          this.handleSigTopic(dtoTopic);
        }
      });
      Srvc.cache.home.drawer.recent.wsocRecentListGet(firstBoot, cbSuccess);
    }
    if(unSubscribeTopicList.length)
    {
      Srvc.app.session.unsubscribeForceBulk(subscriberId, unSubscribeTopicList);
    }
  }

  getEntAvatarUserTopic(entId: EntId)
  {
    return {
      aboutId: entId,
      artifactId: ENT_ID_GLOBAL,
      type: "entAvatarUser"
    } as DtoTopic;
  }

  caller(): void
  {
    const callerId = store.getState().cache.app.caller.callerUserId;
    const dtoTopic = {
      aboutId: callerId,
      artifactId: ENT_ID_GLOBAL,
      type: "caller"
    } as DtoTopic;

    Srvc.app.session.subscribe(this.subscriberId, dtoTopic);
  }

  callerEnt(subscriberId: TypeSubscriberId, entId: EntId, unsubscribe?: boolean): void
  {
    const callerId = store.getState().cache.app.caller.callerUserId;
    const dtoTopic = {
      aboutId: callerId,
      artifactId: entId,
      type: "callerEnt"
    } as DtoTopic;

    if(Boolean(unsubscribe))
    {
      Srvc.app.session.unsubscribe(subscriberId, dtoTopic);
    }
    else
    {
      Srvc.app.session.subscribe(subscriberId, dtoTopic);
    }
  }

  entAvatarUser(subscriberId: TypeSubscriberId, entId: EntId, unsubscribe?: boolean): void
  {
    const dtoTopic = this.getEntAvatarUserTopic(entId);

    if(Boolean(unsubscribe))
    {
      Srvc.app.session.unsubscribe(subscriberId, dtoTopic);
    }
    else
    {
      Srvc.app.session.subscribe(subscriberId, dtoTopic);
    }
  }

  entAvatarAdmin(subscriberId: TypeSubscriberId, entId: EntId, unsubscribe?: boolean): void
  {
    const dtoTopic = {
      aboutId: entId,
      artifactId: ENT_ID_GLOBAL,
      type: "entAvatarAdmin"
    } as DtoTopic;

    if(Boolean(unsubscribe))
    {
      Srvc.app.session.unsubscribe(subscriberId, dtoTopic);
    }
    else
    {
      Srvc.app.session.subscribe(subscriberId, dtoTopic);
    }
  }

  addressBook(subscriberId: TypeSubscriberId, unsubscribe?: boolean): void
  {
    const dtoTopic = {
      aboutId: store.getState().cache.app.caller.callerUserId,
      artifactId: ENT_ID_GLOBAL,
      type: "callerAddrBook"
    } as DtoTopic;

    if(Boolean(unsubscribe))
    {
      Srvc.app.session.unsubscribe(subscriberId, dtoTopic);
    }
    else
    {
      Srvc.app.session.subscribe(subscriberId, dtoTopic);
    }
  }

  deviceList(subscriberId: TypeSubscriberId, unsubscribe?: boolean): void
  {
    const dtoTopic = {
      aboutId: store.getState().cache.app.caller.callerUserId,
      artifactId: ENT_ID_GLOBAL,
      type: "callerDeviceList"
    } as DtoTopic;

    if(Boolean(unsubscribe))
    {
      Srvc.app.session.unsubscribe(subscriberId, dtoTopic);
    }
    else
    {
      Srvc.app.session.subscribe(subscriberId, dtoTopic);
    }
  }

  pluginAvatarAdmin(subscriberId: TypeSubscriberId, pluginBundleId: PluginBundleId, unsubscribe?: boolean): void
  {
    const dtoTopic = {
      aboutId: pluginBundleId,
      artifactId: ENT_ID_GLOBAL,
      type: "studioPluginAvatar"
    } as DtoTopic;

    if(Boolean(unsubscribe))
    {
      Srvc.app.session.unsubscribe(subscriberId, dtoTopic);
    }
    else
    {
      Srvc.app.session.subscribe(subscriberId, dtoTopic);
    }
  }

  storeItemAvatar(subscriberId: TypeSubscriberId, storeItemId: StoreItemId, unsubscribe?: boolean): void
  {
    const dtoTopic = {
      aboutId: storeItemId,
      artifactId: ENT_ID_GLOBAL,
      type: "storeItemAvatar"
    } as DtoTopic;

    if(Boolean(unsubscribe))
    {
      Srvc.app.session.unsubscribe(subscriberId, dtoTopic);
    }
    else
    {
      Srvc.app.session.subscribe(subscriberId, dtoTopic);
    }
  }

  handleSigTopic(sig: SigTopic, isOnSub?: boolean): boolean
  {
    const type = sig.type;
    switch(type)
    {
      case "caller":
        Srvc.app.boot.wsocCallerGet();
        return true;
      case "callerEnt":
        Srvc.cache.app.callerEnt.wsocCallerEntGet(sig.artifactId);
        return true;
      case "entAvatarUser":
        Srvc.cache.app.caller.wsocEntAvatarUserGet(sig.aboutId);
        return true;
      case "entAvatarAdmin":
        Srvc.cache.app.caller.wsocEntAvatarAdminGet(sig.aboutId);
        break;
      case "callerAddrBook":
        Srvc.home.drawer.addressBook.rpcAddressBookGet();
        return true;
      case "callerBadgeMap":
        Srvc.cache.app.badge.wsocBadgeMapGet();
        return true;
      case "callerRecentList":
        Srvc.cache.home.drawer.recent.wsocRecentListGet(false);
        return true;
      case "callerDeviceList":
        Srvc.home.drawer.settings.rpcCallerDeviceListGet();
        break;
      case "callerStarMessageList":
        Srvc.cache.app.startMsg.wsocStarMessageListGet();
        break;
      case "studioPluginAvatar":
        Srvc.cache.app.caller.wsocPluginAvatarAdminGet(sig.aboutId);
        break;
      case "storeItemAvatar":
        Srvc.cache.app.caller.wsocStoreItemAvatarGet(sig.aboutId);
        break;
    }

    return false;
  }

  handleTopicUnSub(sig: SigTopic): boolean
  {
    const type = sig.type;
    switch(type)
    {
      case "caller":
        // ignore
        return true;
      case "callerEnt":
        store.dispatch(removeCallerEnt(sig.artifactId as EntId));
        return true;
      case "entAvatarUser":
        store.dispatch(removeEntAvatarUser(sig.aboutId as EntId));
        return true;
      case "entAvatarAdmin":
        store.dispatch(removeEntAvatarAdmin(sig.aboutId as EntId));
        break;
      case "callerAddrBook":
        Srvc.home.drawer.addressBook.clearAddressBook();
        return true;
      case "callerBadgeMap":
      case "callerDeviceList":
      case "callerRecentList":
      case "callerStarMessageList":
        // ignore
        return true;
      case "studioPluginAvatar":
        store.dispatch(removePluginAvatarAdmin(sig.aboutId as PluginBundleId));
        return true;
      case "storeItemAvatar":
        store.dispatch(removeStoreItemAvatarAdmin(sig.aboutId as StoreItemId));
        return true;
        break;
    }

    return false;
  }

}
