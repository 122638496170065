import {Typography} from "@mui/material";
import React from "react";
import {DefnField} from "../../../../api/meta/base/dto/DefnField";
import {DefnFieldLabel} from "../../../../api/meta/base/dto/DefnFieldLabel";
import {getFormFieldValueAsText} from "../../../../base/plus/FieldValuePlus";
import {getDefnFieldPadding} from "../../../../base/plus/FormPlus";
import {getFieldKey} from "../../../../base/plus/FormPlus";
import {fieldReportLeftLabel} from "../../../../base/plus/FormPlus";
import theme from "../../../../base/plus/ThemePlus";
import RawHighlighter from "../../../atom/raw/RawHighlighter";
import {useFormCtx} from "../base/CtxForm";
import {useFormSectionCtx} from "../base/CtxFormSection";
import {getCompLabel} from "../base/FormViewerPlus";
import FieldLabel from "../basic/FieldLabel";
import FieldBase from "./FieldBase";
import FieldRawKeyValuePair from "./FieldRawKeyValuePair";

export default function FieldRawTemplate(props: {
  defn: DefnField,
  fieldValue?: any,
  reportNode?: React.ReactNode
  children: React.ReactNode,
  flexGrow?: number
})
{
  const formCtx = useFormCtx();
  const formSectionCtx = useFormSectionCtx();

  const defn = props.defn;
  const fieldNode = props.children;
  const flexGrow = props.flexGrow;

  const fieldId = getFieldKey(defn);
  const label = getCompLabel(defn);
  const defnTheme = formCtx.getDefnTheme();
  const isReport = defnTheme.formVariant === "report";

  const formSection = formSectionCtx.getParent();
  const isPropertyEditor = formSection.sectionVariant === "propertyEditor";

  const padding = getDefnFieldPadding(defn);

  if(isReport)
  {
    return (
      <FieldBase
        fieldId={fieldId}
        {...padding}
      >
        <FieldRawKeyValuePair
          left={
            <RawHighlighter
              variant={"caption"}
              value={fieldReportLeftLabel(defn)}
            />
          }
          right={
            <ReportFactory
              {...props}
            />
          }
        />
      </FieldBase>
    );
  }

  if(isPropertyEditor)
  {
    const labelHeight = defnTheme.fieldSize === "small" ? 46 : 56;
    const defnLabel = {
      label: label
    } as DefnFieldLabel;

    return (
      <FieldBase
        fieldId={fieldId}
        flexGrow={flexGrow}
        {...padding}
      >
        <FieldRawKeyValuePair
          leftHeight={labelHeight}
          left={
            <FieldLabel defn={defnLabel} />
          }
          right={fieldNode}
          flexGrow={flexGrow}
        />
      </FieldBase>
    );
  }

  return (
    <FieldBase
      fieldId={fieldId}
      flexGrow={flexGrow}
      {...padding}
    >
      {fieldNode}
    </FieldBase>
  );

}

function ReportFactory(props: {
  defn: DefnField,
  fieldValue?: any,
  reportNode?: React.ReactNode
})
{
  const formCtx = useFormCtx();
  const defn = props.defn;
  const fieldValue = props.fieldValue;
  const reportNode = props.reportNode;

  const fieldId = getFieldKey(defn);
  const getOnClick = formCtx.getOnClick();
  const fieldHyperlinkRow = formCtx.getFieldIdHyperlinkRow(fieldId);

  if(reportNode)
  {
    return reportNode;
  }

  return (
    <Typography
      component={"span"}
      variant={"caption"}
      color={fieldHyperlinkRow ? theme.palette.primary.main : "textSecondary"}
      sx={{
        textDecoration: fieldHyperlinkRow ? "underline" : "none"
      }}
      onClick={() =>
      {
        if(fieldHyperlinkRow)
        {
          getOnClick && getOnClick(fieldId, "hyperlinkRow", fieldValue);
        }
      }}
    >
      {getFormFieldValueAsText(defn, fieldValue)}
    </Typography>
  );
}


