import {MsgDeeplinkCode} from "../../api/core/deeplink/msg/MsgDeeplinkCode";
import {RpcDeeplink} from "../../api/core/deeplink/RpcDeeplink";
import {SigDeeplinkData} from "../../api/core/deeplink/sig/SigDeeplinkData";
import {EnvError} from "../../api/nucleus/base/dto/EnvError";
import {getErrorMessage} from "../../api/nucleus/base/Protocol";
import ISrvc from "../../base/ISrvc";
import {CbSuccess} from "../../base/types/TypesGlobal";
import {logError} from "../../base/util/AppLog";
import {Srvc} from "../Srvc";
import SrvcDeeplinkAsideChat from "./SrvcDeeplinkAsideChat";

export type CbDeeplinkDataGetSuccess = (deeplinkMeta: SigDeeplinkData) => void;
export type CbDeeplinkDataGetError = (error: EnvError) => void;

export default class SrvcDeepLink extends ISrvc
{

  public readonly asideChat = new SrvcDeeplinkAsideChat();

  constructor()
  {
    super();

    this.setSrvcArray(
      this.asideChat
    );
  }

  rpcDeeplinkDataGet(msg: MsgDeeplinkCode, cbSuccess: CbDeeplinkDataGetSuccess, cbError?: CbDeeplinkDataGetError)
  {
    RpcDeeplink.deeplinkDataGet(msg, envSig =>
    {
      const deeplinkData = envSig.sig;
      if(!deeplinkData || deeplinkData.deeplinkActionType !== "signIn")
      {
        Srvc.app.auth.rpcGrantBearerToken(false, (envSig) =>
        {
          if(envSig.error && deeplinkData && deeplinkData.bearerToken)
          {
            Srvc.app.auth.setBearerToken(deeplinkData.bearerToken);
          }
        });
      }
      if(envSig.error)
      {
        const errorMsg = getErrorMessage(envSig.error);
        errorMsg && logError(errorMsg);
        cbError && cbError(envSig.error);
      }
      else
      {
        deeplinkData && cbSuccess(deeplinkData);
      }
    });
  }

  rpcDeeplinkAction(msg: MsgDeeplinkCode, cbSuccess: CbSuccess, cbError?: (error: string) => void)
  {
    RpcDeeplink.deeplinkAction(msg, envSig =>
    {
      if(envSig.error)
      {
        const errorMsg = getErrorMessage(envSig.error);
        errorMsg && logError(errorMsg);
        errorMsg && Srvc.app.toast.showErrorToast(errorMsg);
        cbError && errorMsg && cbError(errorMsg);
        return;
      }

      cbSuccess();
    });
  }

}
